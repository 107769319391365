import _ from 'lodash';
import React from 'react';
import {
  ApprovalStatusFilter,
  ClientApprovalStatusFilter,
  DisciplineFilter,
  InvoicedFilter,
  TimeBillableTypeFilter,
  TimeOffTypeFilter,
} from '~/components';
import { useFeatures } from '~/hooks';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

export default function TimeFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Time Filters"
      filters={[
        filters.createdPeriod?.start,
        filters.createdPeriod?.end,
        filters.modifiedPeriod?.start,
        filters.modifiedPeriod?.end,
        filters.billableTypes,
        filters.timeOffTypes,
        filters.approvalStatuses,
        filters.clientApprovalStatuses,
        filters.invoiced,
        filters.roleDisciplines,
      ]}>
      {_.has(filters, 'createdPeriod') && (
        <Filter>
          <ReportPeriodFilter
            value={filters.createdPeriod}
            label="Created Date Range"
            onChange={({ target: { value } }) => onChange({ createdPeriod: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'modifiedPeriod') && (
        <Filter>
          <ReportPeriodFilter
            value={filters.modifiedPeriod}
            label="Modified Date Range"
            onChange={({ target: { value } }) => onChange({ modifiedPeriod: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'billableTypes') && (
        <Filter>
          <TimeBillableTypeFilter
            value={filters.billableTypes}
            onChange={({ target: { value } }) => onChange({ billableTypes: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'timeOffTypes') && (
        <Filter>
          <TimeOffTypeFilter
            value={filters.timeOffTypes}
            onChange={({ target: { value } }) => onChange({ timeOffTypes: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'approvalStatuses') && (
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => onChange({ approvalStatuses: value })}
          />
        </Filter>
      )}

      {features.clientApprovals && _.has(filters, 'clientApprovalStatuses') && (
        <Filter>
          <ClientApprovalStatusFilter
            value={filters.clientApprovalStatuses}
            onChange={({ target: { value } }) => onChange({ clientApprovalStatuses: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'invoiced') && (
        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => onChange({ invoiced: value })}
          />
        </Filter>
      )}

      {features.disciplines && _.has(filters, 'roleDisciplines') && (
        <Filter>
          <DisciplineFilter
            value={filters.roleDisciplines}
            materialPlaceholder="Role Discipline"
            onChange={({ target: { value } }) => onChange({ roleDisciplines: value })}
          />
        </Filter>
      )}
    </FiltersGroup>
  );
}
