import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  Currency,
  Hours,
  Icon,
  InternalClientTooltip,
  Percent,
  PeriodFilter,
  ProjectLink,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientFiltersBar from '../components/ClientFiltersBar';
import CurrencyFilter from '../components/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import List from '../components/List.jsx';
import ListTooltip from '../components/ListTooltip.jsx';
import MultiCurrency from '../components/MultiCurrency.jsx';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import Report from '../components/Report';
import Table from '../components/table';
import { intervals, intervalsByScope } from './intervals';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Byline = styled.small`
  display: block;
`;

export default function PerformancePlanByProject() {
  useDocumentTitle('Performance Plan by Project');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
  }));

  const [params, setParams] = useState({
    period: null,
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: intervals.next_6_months,
          keys: ['start', 'end'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');
              return intervals.next_6_months;
            }

            const option = _.find(intervalsByScope.all, (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().performancePlanByProject(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performancePlanByProject(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/plan-and-forecast`} />

        <Report.Info>
          <Report.Eyebrow>Plan & Forecast Reports</Report.Eyebrow>
          <Report.Title>Performance Plan by Project</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter
          value={params.period}
          intervals={intervalsByScope.all}
          onChange={(period) => handleApplyFilters({ period })}
        />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const auth = useAuth();

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Project" value={report.records.length} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column sticky name="name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="6rem" align="right" name="totalHours" onSort={onSort} sort={params.sort}>
            Hours
          </Table.Column>
          <Table.Column width="7.5rem" align="right" name="realizationRate" onSort={onSort} sort={params.sort}>
            Realization Rate
          </Table.Column>
          <Table.Column width="10rem" align="right" name="totalRevenue" onSort={onSort} sort={params.sort}>
            Revenue
          </Table.Column>
          <Table.Column width="7rem" align="right" name="effectiveRate" onSort={onSort} sort={params.sort}>
            Effective Bill Rate
          </Table.Column>
          <Table.Column
            width="10rem"
            align="right"
            name="cost"
            isVisible={auth.projects.viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Cost
          </Table.Column>
          <Table.Column
            width="10rem"
            align="right"
            name="profit"
            isVisible={auth.projects.viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Profit
          </Table.Column>
          <Table.Column
            width="6rem"
            align="right"
            name="margin"
            isVisible={auth.projects.viewMargin}
            onSort={onSort}
            sort={params.sort}>
            Margin
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((project) => {
            return (
              <Table.Row key={project.id}>
                <Table.Cell>
                  <ProjectInfo>
                    <p>
                      <ProjectLink project={project} />
                    </p>
                    <small>
                      <ClientLink client={project.client} />
                      {project.client.isInternal && <InternalClientTooltip />}
                    </small>
                  </ProjectInfo>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Billable">
                          <Hours value={project.billableHours} />
                        </List.Item>
                        <List.Item label="Non-Billable">
                          <Hours value={project.nonBillableHours} />
                        </List.Item>
                        <List.Item label="Internal">
                          <Hours value={project.internalHours} />
                        </List.Item>
                      </List>
                    }>
                    <Hours value={project.totalHours} />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <Percent value={project.realizationRate} />
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesRevenue, currency: report.currency },
                              { value: project.servicesRevenue, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Other Items Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedOtherItemsRevenue, currency: report.currency },
                              { value: project.otherItemsRevenue, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Expenses Revenue">
                          <MultiCurrency
                            value={[
                              { value: project.convertedExpensesRevenue, currency: report.currency },
                              { value: project.expensesRevenue, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedTotalRevenue, currency: report.currency },
                        { value: project.totalRevenue, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    <Currency value={project.convertedEffectiveRate} currency={report.currency} />
                    {project.currency !== report.currency && (
                      <Byline>
                        <Currency value={project.effectiveRate} currency={project.currency} />
                      </Byline>
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedLaborCost, currency: report.currency },
                              { value: project.laborCost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                        <List.Item label="Expenses Cost">
                          <MultiCurrency
                            value={[
                              { value: project.convertedExpensesCost, currency: report.currency },
                              { value: project.expensesCost, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <p>
                      <MultiCurrency
                        value={[
                          { value: project.convertedCost, currency: report.currency },
                          { value: project.cost, currency: project.currency },
                        ]}
                      />
                    </p>
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Profit">
                          <MultiCurrency
                            value={[
                              { value: project.convertedServicesProfit, currency: report.currency },
                              { value: project.servicesProfit, currency: project.currency },
                            ]}
                          />
                        </List.Item>
                      </List>
                    }>
                    <MultiCurrency
                      value={[
                        { value: project.convertedProfit, currency: report.currency },
                        { value: project.profit, currency: project.currency },
                      ]}
                    />
                  </ListTooltip>
                </Table.Cell>
                <Table.Cell>
                  <ListTooltip
                    message={
                      <List>
                        <List.Item label="Services Margin">
                          <Percent value={project.servicesMargin} />
                        </List.Item>
                      </List>
                    }>
                    <Percent value={project.margin} />
                  </ListTooltip>
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Billable">
                      <Hours value={report.totals.billableHours} />
                    </List.Item>
                    <List.Item label="Non-Billable">
                      <Hours value={report.totals.nonBillableHours} />
                    </List.Item>
                    <List.Item label="Internal">
                      <Hours value={report.totals.internalHours} />
                    </List.Item>
                  </List>
                }>
                <Hours value={report.totals.totalHours} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Percent value={report.totals.realizationRate} />
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Revenue">
                      <Currency value={report.totals.servicesRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Other Items Revenue">
                      <Currency value={report.totals.otherItemsRevenue} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Expenses Revenue">
                      <Currency value={report.totals.expensesRevenue} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.totalRevenue} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.effectiveRate} currency={report.currency} />
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Cost">
                      <Currency value={report.totals.laborCost} currency={report.currency} />
                    </List.Item>
                    <List.Item label="Expenses Cost">
                      <Currency value={report.totals.expensesCost} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.cost} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Profit">
                      <Currency value={report.totals.servicesProfit} currency={report.currency} />
                    </List.Item>
                  </List>
                }>
                <Currency value={report.totals.profit} currency={report.currency} />
              </ListTooltip>
            </Table.Cell>
            <Table.Cell>
              <ListTooltip
                message={
                  <List>
                    <List.Item label="Services Margin">
                      <Percent value={report.totals.servicesMargin} />
                    </List.Item>
                  </List>
                }>
                <Percent value={report.totals.margin} />
              </ListTooltip>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <PeriodFilter
          clearable={false}
          scope="day"
          intervals={intervalsByScope.all}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
