import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';

export default function useSessionStorage({ key }) {
  const { workspace } = useWorkspace();
  key = `${_.snakeCase(workspace.key)}_${key}`;

  const enabled = !!(key && sessionStorage);

  const get = useCallback(() => {
    if (!enabled) return;
    return sessionStorage.getItem(key);
  }, [enabled, key]);

  const set = useCallback(
    (value) => {
      if (!enabled) return;
      sessionStorage.setItem(key, value);
    },
    [enabled, key],
  );

  const hasValue = useCallback(() => {
    return !!get();
  }, [get]);

  return useMemo(() => {
    return { enabled, get, set, hasValue };
  }, [enabled, get, set, hasValue]);
}
