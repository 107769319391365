import { useApi, useWorkspace } from '~/contexts';
import { useAuth, useField, useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AutoComplete from './AutoComplete';
import Dropdown from './Dropdown';
import FieldControl from './FieldControl';
import Icon from './Icon';
import InternalClientTooltip from './InternalClientTooltip';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

const ClientSelect = React.forwardRef(
  (
    {
      value,
      initialValue,
      activeOnly = true,
      companyId = null,
      allowNew,
      isInternal,
      permission,
      withoutCompanyAssigned,
      onChange,
      ...props
    },
    ref,
  ) => {
    const api = useApi();
    const isMounted = useIsMounted();
    const { workspace } = useWorkspace();
    const [defaultOptions, setDefaultOptions] = useState([]);
    const auth = useAuth();

    const handleSearch = useCallback(
      async (q) => {
        const { data } = await api.www
          .workspaces(workspace.id)
          .clients()
          .get({
            q,
            isInternal,
            recordStatusId: activeOnly ? 'active' : undefined,
            include: initialValue ? initialValue.id : undefined,
            companyId: companyId ? companyId : undefined,
            withoutCompanyAssigned: withoutCompanyAssigned ? withoutCompanyAssigned : undefined,
            size: 1000,
            permission,
          });

        const groups = _.orderBy(
          data.reduce((a, v) => {
            const key = v.active ? 'Active' : 'Archived';
            a[key] = a[key] || { key, clients: [] };
            a[key].clients.push(v);
            return a;
          }, {}),
          'key',
        );

        const options = [];
        for (const group of groups) {
          options.push({ type: 'group', group, disabled: true });
          group.clients.forEach((client) => {
            options.push({ type: 'client', client, disabled: false });
          });
        }
        return options;
      },
      [workspace.id, initialValue, activeOnly, isInternal, permission, withoutCompanyAssigned, companyId, api],
    );

    const handleAddNew = async (value) => {
      const { data } = await api.www.workspaces(workspace.id).clients().upsert({ name: value });

      onChange({ target: { name: props.name, value: data } });
    };

    const refreshDefaultOptions = useCallback(async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    }, [handleSearch, isMounted]);

    useEffect(() => {
      refreshDefaultOptions();
    }, [refreshDefaultOptions]);

    const handleChange = (event) => {
      const changeArgument = { target: { name: event.target.name, value: event.target.value?.client } };
      onChange(changeArgument);
    };

    return (
      <AutoComplete
        ref={ref}
        onSearch={handleSearch}
        value={value}
        displayText={value ? value.name : ''}
        defaultOptions={defaultOptions}
        onChange={handleChange}
        renderOption={(option) => option.client.name}
        {...props}>
        {({ options, inputValue, handleBlur, handleLoading }) => {
          const showAddNew = allowNew && auth.clients.create && inputValue && inputValue !== value?.name;
          let index = 0;
          return (
            <>
              {showAddNew && (
                <>
                  <AutoComplete.AddNewOption
                    disabled={!inputValue}
                    onClick={async () => {
                      handleLoading(true);
                      await handleAddNew(inputValue);
                      await refreshDefaultOptions();
                      handleBlur();
                      handleLoading(false);
                    }}>
                    <Icon icon="plus" size="xs" spaceRight />
                    Add {inputValue ? <strong>&nbsp;{inputValue}</strong> : ' New'}
                  </AutoComplete.AddNewOption>
                  {options.length > 0 && <AutoComplete.Separator />}
                </>
              )}
              {options.length > 0 ? (
                options.map((o) => {
                  switch (o.type) {
                    case 'group':
                      return (
                        <Group key={o.group.key}>
                          <strong>{o.group.key}</strong>
                        </Group>
                      );
                    case 'client':
                      return (
                        <AutoComplete.Option
                          grey={o.client.recordStatusId === 'archived' ? 'true' : undefined}
                          padding="0.25rem 0 0.25rem 2rem"
                          key={o.client.id}
                          tooltip={o.client.name}
                          value={o}
                          index={index++}>
                          {o.client.name}
                          {o.client.isInternal && <InternalClientTooltip />}
                        </AutoComplete.Option>
                      );
                    default:
                      return null;
                  }
                })
              ) : (
                <Dropdown.Text>None</Dropdown.Text>
              )}
            </>
          );
        }}
      </AutoComplete>
    );
  },
);

const FieldClientSelect = React.forwardRef((props, ref) => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ClientSelect ref={ref} {...field} {...props} />
    </FieldControl>
  );
});

export default ClientSelect;
export { FieldClientSelect };
