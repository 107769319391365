import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, DeleteButton, Icon, SingleSelect } from '~/components';
import { useApi } from '~/contexts';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull } from '~/utils';
import SendInvite from './SendInvite';

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchFilters = styled.div`
  display: flex;
`;

const SearchInput = styled.div`
  flex: 1;
  position: relative;
  margin-right: 1rem;
`;

const StatusInput = styled.div`
  width: 13rem;
  margin-left: 1rem;
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const SearchIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  color: ${colors.primary};
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const SearchResult = styled.div`
  display: flex;
  min-height: 5rem;
  margin-top: 1rem;
  padding: 1rem 1.25rem;
  border-radius: 0.3125rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const InfoGraphic = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: auto 0;

  &:not(:first-child) {
    margin-left: 0.75rem;
  }
`;

const InfoIcon = styled(Icon)`
  font-size: 2rem;
  color: ${colors.grey10};
`;

const Info = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 10rem;
  margin: auto 0;
  margin-left: 0.75rem;
`;

const Title = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Subtitle = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  a {
    color: ${colors.grey55};

    &:hover span {
      text-decoration: underline;
    }
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.125rem 0.875rem;
`;

const Tag = styled.div`
  display: flex;
  max-width: 20rem;
  margin: 0.125rem;
  padding: 0.125rem;
  font-size: 0.625rem;
  background-color: ${({ color }) => color ?? colors.grey55};
  border-radius: 0.25rem;
`;

const TagLabel = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0.125rem 0.5rem;
  color: ${colors.grey55};
  background-color: ${colors.white};
  border-radius: 0.25rem;
`;

const TagValue = styled.div`
  display: flex;
  padding: 0.125rem 0.5rem;
  color: ${colors.white};
  font-weight: ${weights.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  > :not(:first-child) {
    margin-left: 1rem;
  }
`;

export default function Search() {
  const api = useApi();
  const history = useHistory();
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchStatus, setSearchStatus] = useState('new,invited');
  const [searchResults, setSearchResults] = useState();
  const [sendInvite, setSendInvite] = useState();
  const searchRef = useRef();

  const search = useCallback(async () => {
    const params = { text: searchText, statusId: searchStatus };
    const cleanedParams = _.omitBy(emptyStringToNull(params), _.isNil);

    setIsSearching(true);
    try {
      const { data } = await api.admin.workspaceRequests.get(cleanedParams);
      setSearchResults(data);
    } finally {
      setIsSearching(false);
    }
  }, [api, searchText, searchStatus]);

  useEffect(() => {
    search();
    return search.cancel;
  }, [search]);

  useEffect(() => {
    if (!searchText) {
      setSearchResults();
      return;
    }
    search(searchText);
    return search.cancel;
  }, [search, searchText]);

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const emitSearchChange = useCallback(
    _.debounce((value) => setSearchText(value), 250),
    [],
  );

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    emitSearchChange(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const handleRequestChange = (request) => {
    setSearchResults((results) => results.map((result) => (result.id === request.id ? request : result)));
  };

  const handleRejectRequest = async (request) => {
    const { data } = await api.admin.workspaceRequests.reject(request.id);
    handleRequestChange(data);
  };

  const handleNavigateWorkspace = (request) => {
    history.push(`/admin/workspaces?search=${request.workspace.id}`);
  };

  return (
    <>
      <SearchArea>
        <SearchFilters>
          <SearchInput>
            <input
              ref={searchRef}
              value={searchValue}
              onChange={handleSearchChange}
              type="search"
              placeholder="Find a workspace request..."
            />
            {!!searchValue && (
              <ClearButton isAnchor={true} onClick={handleClearSearch}>
                <Icon icon="times" />
              </ClearButton>
            )}
            {isSearching && (
              <SearchIndicator>
                <Icon icon="spinner" spin={true} />
              </SearchIndicator>
            )}
          </SearchInput>
          <StatusInput>
            <SingleSelect
              placeholder="Status"
              showEmptyOption={true}
              value={searchStatus}
              onChange={(event) => setSearchStatus(event.target.value)}>
              <option value="new">New</option>
              <option value="invited">Invited</option>
              <option value="created">Created</option>
              <option value="rejected">Rejected</option>
              <option value="new,invited">New &amp; Invited</option>
            </SingleSelect>
          </StatusInput>
        </SearchFilters>
        {searchResults && (
          <SearchResults>
            {searchResults.map((request) => (
              <SearchResult key={`workspace_request_${request.id}`}>
                <InfoGraphic>
                  <InfoIcon icon="building" />
                </InfoGraphic>
                <Info>
                  <Title title={request.companyName}>{request.companyName}</Title>
                  <Subtitle title={request.website}>
                    <a
                      href={request.website.startsWith('http') ? request.website : `https://${request.website}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span>{request.website.replace(/(^\w+:|^)\/\//, '')}</span> <Icon icon="external-link-alt" />
                    </a>
                  </Subtitle>
                </Info>
                <InfoGraphic>
                  <InfoIcon icon="user" />
                </InfoGraphic>
                <Info>
                  <Title title={request.memberName}>{request.memberName}</Title>
                  <Subtitle title={request.email}>
                    <a href={`mailto:${request.email}`}>
                      <span>{request.email}</span>
                    </a>
                  </Subtitle>
                </Info>
                <Tags>
                  <Tag
                    color={{ new: colors.primary, invited: colors.warning, rejected: colors.danger }[request.statusId]}>
                    <TagLabel>Status</TagLabel>
                    <TagValue>{request.status.name}</TagValue>
                  </Tag>
                  {request.statusId === 'invited' && (
                    <Tag color={request.activeInvitationCount === 0 ? colors.danger : null}>
                      <TagLabel>Invitations</TagLabel>
                      <TagValue>{request.activeInvitationCount}</TagValue>
                    </Tag>
                  )}
                  <Tag title={moment(request.createdAt).format(dateFormats.dateTime)}>
                    <TagLabel>Created</TagLabel>
                    <TagValue>{moment(request.createdAt).format(dateFormats.compactDate)}</TagValue>
                  </Tag>
                  {request.utmSource && (
                    <Tag title={request.utmSource}>
                      <TagLabel>UTM Source</TagLabel>
                      <TagValue>{request.utmSource}</TagValue>
                    </Tag>
                  )}
                  {request.utmCampaign && (
                    <Tag title={request.utmCampaign}>
                      <TagLabel>UTM Campaign</TagLabel>
                      <TagValue>{request.utmCampaign}</TagValue>
                    </Tag>
                  )}
                </Tags>
                <Actions>
                  {request.statusId !== 'created' && (
                    <>
                      <Button onClick={() => setSendInvite(request)}>Invite</Button>
                      {request.statusId !== 'rejected' && (
                        <DeleteButton onClick={() => handleRejectRequest(request)}>Reject</DeleteButton>
                      )}
                    </>
                  )}
                  {request.statusId === 'created' && request.workspace && (
                    <Button isOutline onClick={() => handleNavigateWorkspace(request)}>
                      Workspace
                    </Button>
                  )}
                </Actions>
              </SearchResult>
            ))}
          </SearchResults>
        )}
      </SearchArea>
      {sendInvite && (
        <SendInvite workspaceRequest={sendInvite} onChange={handleRequestChange} onClose={() => setSendInvite()} />
      )}
    </>
  );
}
