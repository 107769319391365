import styled from 'styled-components';

const ListViewStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.875rem;
  .icon {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

export default ListViewStatus;
