import React from 'react';
import styled from 'styled-components';
import TableScroll from './TableScroll';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableColumn from './TableColumn';
import { TableProvider } from './TableContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableTotal from './TableTotal';
import TableGroupRow from './TableGroupRow';

const TableContents = styled.div`
  position: absolute;
  min-width: 100%;
`;

function Table({ ...props }) {
  return (
    <TableProvider>
      <TableScroll>
        <TableContents {...props} />
      </TableScroll>
    </TableProvider>
  );
}

Table.Header = TableHeader;
Table.Column = TableColumn;
Table.Body = TableBody;
Table.Row = TableRow;
Table.GroupRow = TableGroupRow;
Table.Cell = TableCell;
Table.Total = TableTotal;

export default Table;
