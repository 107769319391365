import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding-bottom: 0.75rem;
  }
`;

export default function SyncSettingsDrawer({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      autoSync: false,
      syncStartDate: '',
      statusOpen: integration.settings.syncExpensifyStatuses.includes('Open'),
      statusProcessing: integration.settings.syncExpensifyStatuses.includes('Processing'),
      statusApproved: integration.settings.syncExpensifyStatuses.includes('Approved'),
      statusReimbursed: integration.settings.syncExpensifyStatuses.includes('Reimbursed'),
      statusArchived: integration.settings.syncExpensifyStatuses.includes('Archived'),
    },
    integration.settings,
  );

  return (
    <Drawer
      isOpen
      title="Sync Settings"
      byline="Expensify Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const statuses = [
              values.statusOpen ? 'Open' : '',
              values.statusProcessing ? 'Processing' : '',
              values.statusApproved ? 'Approved' : '',
              values.statusReimbursed ? 'Reimbursed' : '',
              values.statusArchived ? 'Archived' : '',
            ];

            const enabledStatuses = _.filter(statuses, (status) => !!status);
            if (enabledStatuses.length === 0) {
              form.error({ message: 'You must select at least one Expensify status.' });
              return;
            }

            const body = emptyStringToNull({
              ..._.omit(
                values,
                'statusOpen',
                'statusProcessing',
                'statusApproved',
                'statusReimbursed',
                'statusArchived',
              ),
              syncExpensifyStatuses: enabledStatuses,
            });

            await api.www.workspaces(workspace.id).expensify.updateSyncSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              autoSync: Yup.bool().label('Automatically Sync').required(),
              syncStartDate: Yup.date().label('Start Date').nullable().required(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Automatically Sync"
                    subtitle="If turned on, every hour this will sync expense reports created or submitted after the start date.">
                    <Form.Control>
                      <Field.RadioGroup name="autoSync">
                        <Radio value={true} label="On" />
                        <Radio value={false} label="Off" />
                      </Field.RadioGroup>
                    </Form.Control>
                    <Form.Control>
                      <Field.DayPicker name="syncStartDate" placeholder="Start Date" clearable={false} />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section title="Expensify Statuses" subtitle="Choose the statuses of Expensify reports to sync.">
                    <Form.Control>
                      <Checkboxes>
                        <Field.Checkbox name="statusOpen" label="Open" />
                        <Field.Checkbox name="statusProcessing" label="Processing" />
                        <Field.Checkbox name="statusApproved" label="Approved" />
                        <Field.Checkbox name="statusReimbursed" label="Reimbursed" />
                        <Field.Checkbox name="statusArchived" label="Archived" />
                      </Checkboxes>
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
