import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi } from '~/contexts';
import { useAdminSession } from '../contexts';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Footer = styled.div`
  display: flex;
`;

export default function ChangePassword({ onClose }) {
  const [passwordChanged, setPasswordChanged] = useState(false);

  return (
    <ModalCard title="Change Password" onClose={onClose}>
      {passwordChanged ? (
        <ChangePasswordSuccess onClose={onClose} />
      ) : (
        <ChangePasswordForm onClose={onClose} onChanged={() => setPasswordChanged(true)} />
      )}
    </ModalCard>
  );
}

function ChangePasswordForm({ onClose, onChanged }) {
  const api = useApi();
  const { admin } = useAdminSession();
  const [error, setError] = useState();

  const handleSubmit = async (values, formik) => {
    // Only submit selected fields
    const toSubmit = _.pick(values, ['currentPassword', 'newPassword', 'code']);
    // Remove whitespace from the code
    toSubmit.code = toSubmit.code.replace(/\W/g, '');

    setError();

    try {
      await api.admin.user.changePassword(toSubmit);
      onChanged();
    } catch ({ message }) {
      setError(message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        newPasswordVerification: '',
        code: '',
        codeRequired: admin.mfaIsVerified,
      }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().label('Current Password').required(),
        newPassword: Yup.string()
          .label('New Password')
          .min(8, 'Password must be at least 8 characters long')
          .max(255, 'Password must be at most 255 characters long')
          .required(),
        newPasswordVerification: Yup.string()
          .label('Confirm New Password')
          .required()
          .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
        code: Yup.string().label('Code').when('codeRequired', { is: true, then: Yup.string().required() }),
      })}>
      {({ isSubmitting }) => (
        <Form>
          <ModalCard.Body>
            {error && <FormMessage.Error style={{ marginBottom: '1rem' }}>{error}</FormMessage.Error>}
            <Form.Control>
              <Field.Text
                name="currentPassword"
                placeholder="Current Password"
                type="password"
                autoComplete="current-password"
              />
            </Form.Control>
            <Form.Control>
              <Field.Text name="newPassword" placeholder="New Password" type="password" autoComplete="new-password" />
            </Form.Control>
            <Form.Control>
              <Field.Text
                name="newPasswordVerification"
                placeholder="Confirm New Password"
                type="password"
                autoComplete="new-password"
              />
            </Form.Control>
            <Form.Control>
              <Field.Text name="code" placeholder="MFA Code" type="text" autoComplete="one-time-code" />
            </Form.Control>
          </ModalCard.Body>
          <ModalCard.Footer>
            <Footer>
              <Buttons align="left">
                <CancelButton onClick={onClose}>Cancel</CancelButton>
              </Buttons>
              <Buttons align="right">
                <Button type="submit" isLoading={isSubmitting}>
                  Submit
                </Button>
              </Buttons>
            </Footer>
          </ModalCard.Footer>
        </Form>
      )}
    </Formik>
  );
}

function ChangePasswordSuccess({ onClose }) {
  return (
    <>
      <ModalCard.Body>
        <Description>
          <strong>Your password has been updated.</strong>
        </Description>
        <Description>You have been logged out of all other admin sessions besides this one.</Description>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Footer>
          <Buttons align="right">
            <CancelButton onClick={onClose}>Close</CancelButton>
          </Buttons>
        </Footer>
      </ModalCard.Footer>
    </>
  );
}
