import React, { useMemo } from 'react';
import { SingleSelect } from '~/components';
import { useFeatures } from '~/hooks';

export default function ProjectTimeViewSelect({ project, value, onChange }) {
  const features = useFeatures();

  const options = useMemo(() => {
    const options = [];

    if (project.useRoles) options.push({ value: 'role', label: 'Time by Role' });
    options.push({ value: 'member', label: 'Time by Member' });
    if (features.timesheets) options.push({ value: 'timesheets', label: 'Timesheets' });

    return options;
  }, [project.useRoles, features.timesheets]);

  if (options.length === 1) return null;

  return (
    <SingleSelect placeholder="View" value={value} onChange={onChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SingleSelect>
  );
}
