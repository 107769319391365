import QRCode from 'qrcode';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, FormMessage, ModalCard } from '~/components';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import { useAdminSession } from '../contexts';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Setup = styled.div`
  display: flex;
`;

const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 1.625rem;
  border: solid 0.0625rem ${colors.grey10};
  border-radius: 0.625rem;
  overflow: hidden;
`;

const CodeImage = styled.img`
  display: block;
  width: 14.25rem;
  height: 14.25rem;
`;

const Footer = styled.div`
  display: flex;
`;

export default function MFA({ onClose }) {
  const { admin, identify } = useAdminSession();
  const api = useApi();
  const [error, setError] = useState();
  const [mfaSetup, setMfaSetup] = useState();
  const [codeValue, setCodeValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSetup = async () => {
    setError();
    try {
      const { data } = await api.admin.user.setupMfa();
      data.dataUrl = await QRCode.toDataURL(data.secretUri);
      setMfaSetup(data);
    } catch ({ message }) {
      setError(message);
    }
  };

  const handleVerify = async () => {
    setIsSubmitting(true);
    setError();
    try {
      // Remove whitespace before submitting
      await api.admin.user.verifyMfa(codeValue.replace(/\W/g, ''));
      await identify();
    } catch ({ message }) {
      setError(message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalCard title="Multi-factor Authentication" onClose={onClose}>
      <ModalCard.Body>
        {error && <FormMessage.Error style={{ marginBottom: '1rem' }}>{error}</FormMessage.Error>}
        {admin.mfaIsVerified ? (
          <>
            <Description>
              <strong>MFA is enabled for your account.</strong>
            </Description>
            <Description>
              If you need to change it, please contact Mason or another administrator with database access.
            </Description>
          </>
        ) : mfaSetup ? (
          <Setup>
            <CodeWrapper>
              <CodeImage src={mfaSetup.dataUrl} alt="Scan this QR code with your authenticator app on your phone." />
            </CodeWrapper>
            <div>
              <Description>
                Use your authenticator app to scan the QR code. Once it's setup, enter the code it generates in the box
                below and submit it for verification.
              </Description>
              <Description>
                <input
                  value={codeValue}
                  onChange={(event) => setCodeValue(event.target.value)}
                  type="text"
                  placeholder="Authenticator code"
                />
              </Description>
            </div>
          </Setup>
        ) : (
          <>
            <Description>
              <strong>You do not have MFA enabled.</strong>
            </Description>
            <Description>Make sure you have an authenticator app ready and click the button below.</Description>
          </>
        )}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Footer>
          <Buttons align="left">
            {!admin.mfaIsVerified && <CancelButton onClick={onClose}>Cancel</CancelButton>}
          </Buttons>
          <Buttons align="right">
            {admin.mfaIsVerified ? (
              <CancelButton onClick={onClose}>Close</CancelButton>
            ) : mfaSetup ? (
              <Button isLoading={isSubmitting} disabled={codeValue.length === 0} onClick={handleVerify}>
                Verify
              </Button>
            ) : (
              <Button onClick={handleSetup}>Setup App</Button>
            )}
          </Buttons>
        </Footer>
      </ModalCard.Footer>
    </ModalCard>
  );
}
