import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  BillableIcon,
  Checkbox,
  DateTime,
  Duration,
  Hours,
  Icon,
  InlineTooltip,
  Level,
  Page,
  Spinner,
  Table,
  TimeApprovalPopover,
  TimeLockIcon,
  Tooltip,
} from '~/components';
import { useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import {
  ApprovalAction,
  ApprovalActions,
  Approve,
  Body,
  Cell,
  Details,
  List,
  ListGroup,
  ListGroupContent,
  ListGroupHeader,
  ListHeader,
  NoResultsCell,
  Notes,
  Reject,
  Row,
  Scroller,
  SelectAll,
  ToggleSwitch,
} from './StyledComponents';

const Byline = styled.small`
  display: block;
`;

const Time = styled.div`
  position: relative;
  padding: 0.2rem 1.5rem;
  font-weight: ${weights.medium};
  border-radius: 999rem;
  width: 6.25rem;
  text-align: center;

  &,
  &.icon {
    ${({ status }) =>
      ({
        not_submitted: css`
          background: ${colors.grey10};
          color: ${colors.black};
        `,
        pending_approval: css`
          background: ${colors.warning10};
          color: ${colors.warning};
        `,
        rejected: css`
          background: ${colors.danger10};
          color: ${colors.danger};
        `,
        approved: css`
          background: ${colors.primary10};
          color: ${colors.primary};
        `,
      })[status]}
  }
`;

const PaperClipIcon = styled(Icon)`
  color: ${colors.grey55};

  &:hover {
    color: ${colors.grey75};
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TimeEntryInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
`;

const ApproverQueueWarning = styled.div`
  position: relative;
  font-size: 1rem;
`;

function TimeApprovalResults({
  results,
  selection,
  isSubmitting,
  isApproverQueue,
  isAdminQueue,
  onSelectionChange,
  onStatusChange,
  onGroupAction,
  onResultClick,
  onChange,
  action,
  showNotes,
}) {
  const features = useFeatures();

  const groups = useMemo(() => {
    return results.reduce((a, v) => {
      a[v.member.id] = a[v.member.id] || { member: v.member, entries: [] };
      a[v.member.id].entries.push(v);
      return a;
    }, {});
  }, [results]);

  const enabledItems = useMemo(() => results.filter((entry) => !entry.isLocked), [results]);

  const handleSelectAllChange = () =>
    onSelectionChange(selection.length > 0 ? [] : enabledItems.map((entry) => entry.id));

  const isQueue = isApproverQueue || isAdminQueue;

  return (
    <>
      <Page.Section>
        <Level>
          <Level.Item>
            <Table.Status>
              {!!action && <Spinner />}
              <Table.Total value={results.length} label="Time Entry" />
            </Table.Status>
          </Level.Item>
        </Level>
      </Page.Section>

      <Scroller>
        <List>
          <ListHeader>
            <Cell style={{ width: '3rem', padding: '0.7rem 2rem 0.7rem 1.125rem' }}>
              <SelectAll>
                <Checkbox
                  data-testid="select-all"
                  checked={selection.length > 0}
                  partial={selection.length < enabledItems.length}
                  disabled={isSubmitting || enabledItems.length === 0 || action === 'filter' || !isQueue}
                  onChange={handleSelectAllChange}
                />
                {!isQueue && <ApproverQueueTooltip />}
              </SelectAll>
            </Cell>

            <Cell style={{ minWidth: '15rem', flex: '1' }}>Member</Cell>

            <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}># of Items</Cell>

            <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>Client Billable</Cell>

            <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>
              Client
              <br />
              Non-billable
            </Cell>

            <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>Internal</Cell>

            <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>Time Off</Cell>

            <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>Total</Cell>

            <Cell style={{ width: '8rem' }}>
              {!isQueue && (
                <ApproverQueueWarning>
                  <Icon icon="warning" color={colors.warning} />
                  <ApproverQueueTooltip />
                </ApproverQueueWarning>
              )}
            </Cell>
          </ListHeader>

          {results.length === 0 && (
            <Table.Row>
              <NoResultsCell style={{ fontSize: '0.875rem' }} fade={action === 'filter'}>
                No results
              </NoResultsCell>
            </Table.Row>
          )}

          <Body>
            {_.map(groups, (group, key) => {
              return (
                <Group
                  key={key}
                  group={group}
                  selection={selection}
                  isSubmitting={isSubmitting}
                  isApproverQueue={isApproverQueue}
                  isQueue={isQueue}
                  onSelectionChange={onSelectionChange}
                  onAction={onGroupAction}
                  action={action}>
                  {group.entries.map((entry) => {
                    const checked = !!selection?.includes(entry.id);

                    const submitting =
                      (isSubmitting === 'batch' && selection?.includes(entry.id)) ||
                      isSubmitting?.group?.entries.some((timeEntry) => timeEntry.id === entry.id) ||
                      isSubmitting === entry.id;

                    const disableCheckbox = submitting || !isQueue || entry.isLocked;
                    const disableActions = submitting || !isApproverQueue || entry.isLocked;

                    const handleRowClick = () => {
                      onResultClick(entry, entry.isLocked ? 'view' : 'edit');
                    };

                    const handleViewAttachments = () => {
                      onResultClick(entry, 'viewAttachments');
                    };

                    const handleSelectionChange = () =>
                      onSelectionChange(checked ? selection.filter((id) => id !== entry.id) : [...selection, entry.id]);

                    const handleActionClick = (statusId) => {
                      onStatusChange(entry, statusId);
                    };

                    return (
                      <Row key={entry.id} clickable={!!handleRowClick} data-testid="row" onClick={handleRowClick}>
                        <Cell
                          style={{
                            width: '2.5rem',
                            padding: '0.5rem 0rem 0.5rem 3.5rem',
                            position: 'relative',
                          }}
                          onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            data-testid="select-entry"
                            checked={checked}
                            disabled={disableCheckbox}
                            onChange={handleSelectionChange}
                          />
                          {!isQueue && <ApproverQueueTooltip />}
                          {entry.isLocked && <LockedTooltip />}
                        </Cell>

                        <Cell style={{ width: '2.875rem' }}>
                          {entry.isLocked && <TimeLockIcon value={entry.lockStatusId} />}
                        </Cell>

                        <Cell style={{ width: '7.5rem' }}>
                          <DateTime value={entry.date} />
                        </Cell>

                        <Cell style={{ minWidth: '15rem', flex: '1', flexWrap: 'wrap' }}>
                          <Details>
                            <div>
                              {entry.typeId === 'project_time' ? (
                                entry.project && (
                                  <p>
                                    {entry.project.name}
                                    <Byline>{entry.project.client.name}</Byline>
                                  </p>
                                )
                              ) : (
                                <p>{entry.timeOffType?.name}</p>
                              )}
                            </div>
                            <div>
                              {entry.task && <p>{entry.task.name}</p>}
                              {entry.project?.useRoles && entry.role && <p>{entry.role.name}</p>}
                            </div>
                          </Details>

                          {showNotes && entry.notes && (
                            <Notes>
                              <em>{entry.notes}</em>
                            </Notes>
                          )}
                        </Cell>

                        <Cell style={{ width: '8rem', justifyContent: 'flex-end' }}>
                          <Container>
                            <TimeApprovalPopover timeEntryId={entry.id} showActions onChange={onChange}>
                              <TimeEntryInfo>
                                <BillableIcon value={entry.isActuallyBillable} />
                                <Time status={entry.statusId}>
                                  <Duration minutes={entry.minutes} timerStartedAt={entry.timerStartedAt} trim />
                                </Time>
                              </TimeEntryInfo>
                            </TimeApprovalPopover>
                            {features.timeAttachments && entry.hasWeekFile && (
                              <Tooltip message={'Has attachments'} onClick={(e) => e.stopPropagation()}>
                                <PaperClipIcon
                                  icon="paperclip-vertical"
                                  type="far"
                                  onClick={handleViewAttachments}
                                  data-testid="paper-clip"
                                />
                              </Tooltip>
                            )}
                          </Container>
                        </Cell>

                        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', position: 'relative' }}>
                          <ApprovalActions onClick={(e) => e.stopPropagation()}>
                            <ApprovalAction>
                              <Approve
                                data-testid="approve"
                                disabled={disableActions}
                                status={entry.statusId}
                                onClick={() => handleActionClick('approved')}>
                                <Icon icon="thumbs-up" />
                                {!isApproverQueue && <ApproverQueueTooltip />}
                                {entry.isLocked && <LockedTooltip />}
                              </Approve>
                            </ApprovalAction>
                            <ApprovalAction>
                              <Reject
                                data-testid="reject"
                                disabled={disableActions}
                                status={entry.statusId}
                                onClick={() => handleActionClick('rejected')}>
                                <Icon icon="thumbs-down" />
                                {!isApproverQueue && <ApproverQueueTooltip />}
                                {entry.isLocked && <LockedTooltip />}
                              </Reject>
                            </ApprovalAction>
                          </ApprovalActions>
                        </Cell>
                      </Row>
                    );
                  })}
                </Group>
              );
            })}
          </Body>
        </List>
      </Scroller>
    </>
  );
}

const sum = (entry) => _.round(entry.minutes / 60, 2);

function Group({
  group,
  selection,
  isSubmitting,
  isApproverQueue,
  isQueue,
  children,
  onSelectionChange,
  onAction,
  action,
}) {
  const [collapsed, setCollapsed] = useState(true);

  const hours = useMemo(() => {
    return {
      clientBillable: _.round(
        _(group.entries)
          .filter((entry) => entry.project?.client?.isInternal === false && entry.isActuallyBillable === true)
          .sumBy(sum),
        2,
      ),
      clientNonbillable: _.round(
        _(group.entries)
          .filter((entry) => entry.project?.client?.isInternal === false && entry.isActuallyBillable === false)
          .sumBy(sum),
        2,
      ),
      internal: _.round(
        _(group.entries)
          .filter((entry) => entry.project?.client?.isInternal === true)
          .sumBy(sum),
        2,
      ),
      timeOff: _.round(
        _(group.entries)
          .filter((entry) => entry.typeId === 'time_off')
          .sumBy(sum),
        2,
      ),
      total: _.round(_(group.entries).sumBy(sum), 2),
    };
  }, [group]);

  const enabledItems = useMemo(() => group.entries.filter((entry) => !entry.isLocked), [group.entries]);

  const groupSelection = useMemo(() => {
    return selection?.filter((id) => group.entries.some((entry) => entry.id === id));
  }, [group, selection]);

  const groupSelected = useMemo(() => {
    return group.entries.some((e) => groupSelection.includes(e.id));
  }, [group, groupSelection]);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const handleGroupSelectionChange = () => {
    onSelectionChange(
      groupSelected
        ? selection.filter((id) => !group.entries.map(({ id }) => id).includes(id))
        : [...selection, ...enabledItems.map(({ id }) => id)],
    );
  };

  const handleActionClick = (statusId) => {
    onAction({ ...group, entries: enabledItems.filter((e) => e.statusId === 'pending_approval') }, statusId);
  };

  const submitting = isSubmitting?.group?.member.id === group.member.id || (isSubmitting === 'batch' && groupSelected);
  const hasLockedItem = group.entries.some((entry) => entry.isLocked);

  const disableCheckbox = submitting || enabledItems.length === 0 || !isQueue;
  const disableActions = submitting || !isApproverQueue || hasLockedItem;

  return (
    <ListGroup fade={action === 'filter'}>
      <ListGroupHeader data-testid="group" onClick={handleToggleClick}>
        <Cell flex="0" padding="0.5rem 0.25rem 0.5rem 0.75rem">
          <ToggleSwitch data-testid="group-toggle">
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Cell>

        <Cell flex="0" padding="0" style={{ position: 'relative' }} onClick={(event) => event.stopPropagation()}>
          <Checkbox
            data-testid="select-member"
            disabled={disableCheckbox}
            checked={groupSelected}
            partial={groupSelection.length < enabledItems.length}
            onChange={handleGroupSelectionChange}
          />
          {!isQueue && <ApproverQueueTooltip />}
        </Cell>

        <Cell style={{ minWidth: '15rem', flex: '1' }}>{group.member.name}</Cell>

        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>{group.entries.length}</Cell>

        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right', color: colors.success }}>
          <Hours value={hours.clientBillable} />
        </Cell>

        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right', color: colors.danger }}>
          <Hours value={hours.clientNonbillable} />
        </Cell>

        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right', color: colors.warning }}>
          <Hours value={hours.internal} />
        </Cell>

        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right', color: colors.primary50 }}>
          <Hours value={hours.timeOff} />
        </Cell>

        <Cell style={{ width: '8.5rem', justifyContent: 'flex-end', textAlign: 'right' }}>
          <Hours value={hours.total} />
        </Cell>

        <Cell style={{ width: '8rem', justifyContent: 'flex-end' }}>
          <ApprovalActions onClick={(e) => e.stopPropagation()}>
            <ApprovalAction>
              <Approve data-testid="approve" disabled={disableActions} onClick={() => handleActionClick('approved')}>
                <Icon icon="thumbs-up" />
                {!isApproverQueue && <ApproverQueueTooltip />}
              </Approve>
            </ApprovalAction>
            <ApprovalAction>
              <Reject data-testid="reject" disabled={disableActions} onClick={() => handleActionClick('rejected')}>
                <Icon icon="thumbs-down" />
                {!isApproverQueue && <ApproverQueueTooltip />}
              </Reject>
            </ApprovalAction>
          </ApprovalActions>
        </Cell>
      </ListGroupHeader>

      {!collapsed && <ListGroupContent>{children}</ListGroupContent>}
    </ListGroup>
  );
}

function ApproverQueueTooltip() {
  return <InlineTooltip message="Select an Approver to enable approval actions." />;
}

function LockedTooltip() {
  return <InlineTooltip message="This item is locked based on your security role." />;
}

export default TimeApprovalResults;
