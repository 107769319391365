import styled from 'styled-components';

const FiltersBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 0.875rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
  font-size: 0.875rem;

  &:empty {
    display: none;
  }
`;

export default FiltersBar;
