import Big from 'big.js';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  BillableIcon,
  Checkbox,
  ClientLink,
  Currency,
  Hours,
  Icon,
  InternalClientTooltip,
  ProjectLink,
  RouteLink,
  Tooltip,
  YesNoFilter,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import projectBillingTypes from '~/lookups/project-billing-types.js';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import CurrencyFilter from '../components/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import MemberFiltersBar from '../components/MemberFiltersBar';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import Table from '../components/table';
import TimeFiltersBar from '../components/TimeFiltersBar';
import TimeFiltersGroup from '../components/TimeFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const ProjectInfo = styled.div`
  line-height: 1;
  margin-left: 1rem;
`;

const MemberRole = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

const ClientInfo = styled.div`
  display: flex;
`;

const Warning = styled(Tooltip)`
  margin-right: 1rem;
`;

const Byline = styled.small`
  display: block;
`;

export default function HourlyFees() {
  useDocumentTitle('Hourly Revenue by Client and Project');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: {
      table: false,
    },
  });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectBillingTypes: {
      ...searchParamsConfig.projectBillingTypes,
      default: [projectBillingTypes.tm],
    },
  }));
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    invoiced: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    includeMembersAndRolesWithoutTime: false,
    projectRoles: [],
    currency: workspace.currency,
    sort: new QuerySort('project:client.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        invoiced: searchParamsConfig.invoiced,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        includeMembersAndRolesWithoutTime: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        projectRoles: searchParamsConfig.projectRoles,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('project:client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      invoiced: params.invoiced ?? undefined,
      approvalStatusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      includeMembersAndRolesWithoutTime: params.includeMembersAndRolesWithoutTime ? 'true' : undefined,
      projectRoleId: params.projectRoles?.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().hourlyFees(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .hourlyFees(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/financial`} />

        <Report.Info>
          <Report.Eyebrow>Financial Reports</Report.Eyebrow>
          <Report.Title>Hourly Revenue by Client and Project</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter value={params.period} onChange={(period) => handleApplyFilters({ period })} />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <YesNoFilter
          materialPlaceholder="Include members and roles without time"
          value={params.includeMembersAndRolesWithoutTime ? 'yes' : 'no'}
          onChange={({ target: { value } }) =>
            handleApplyFilters({ includeMembersAndRolesWithoutTime: value === 'yes' })
          }
        />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const groups = useMemo(() => {
    return report.records.reduce((a, v) => {
      a[v.project.client.id] = a[v.project.client.id] || {
        client: v.project.client,
        projects: {},
        billableHours: 0,
        nonBillableHours: 0,
        totalHours: 0,
        convertedTotalFees: 0,
      };

      a[v.project.client.id].billableHours = Big(a[v.project.client.id].billableHours)
        .add(v.billableHours || 0)
        .toNumber();

      a[v.project.client.id].nonBillableHours = Big(a[v.project.client.id].nonBillableHours)
        .add(v.nonBillableHours || 0)
        .toNumber();

      a[v.project.client.id].totalHours = Big(a[v.project.client.id].totalHours)
        .add(v.totalHours || 0)
        .toNumber();

      a[v.project.client.id].convertedTotalFees = Big(a[v.project.client.id].convertedTotalFees)
        .add(v.convertedTotalFees || 0)
        .toNumber();

      a[v.project.client.id].projects[v.project.id] = a[v.project.client.id].projects[v.project.id] || {
        project: v.project,
        billableHours: 0,
        nonBillableHours: 0,
        totalHours: 0,
        totalFees: 0,
        convertedTotalFees: 0,
      };

      a[v.project.client.id].projects[v.project.id].billableHours = Big(
        a[v.project.client.id].projects[v.project.id].billableHours,
      )
        .add(v.billableHours || 0)
        .toNumber();

      a[v.project.client.id].projects[v.project.id].nonBillableHours = Big(
        a[v.project.client.id].projects[v.project.id].nonBillableHours,
      )
        .add(v.nonBillableHours || 0)
        .toNumber();

      a[v.project.client.id].projects[v.project.id].totalHours = Big(
        a[v.project.client.id].projects[v.project.id].totalHours,
      )
        .add(v.totalHours || 0)
        .toNumber();

      a[v.project.client.id].projects[v.project.id].totalFees = Big(
        a[v.project.client.id].projects[v.project.id].totalFees,
      )
        .add(v.totalFees || 0)
        .toNumber();

      a[v.project.client.id].projects[v.project.id].convertedTotalFees = Big(
        a[v.project.client.id].projects[v.project.id].convertedTotalFees,
      )
        .add(v.convertedTotalFees || 0)
        .toNumber();

      if (v.role) {
        if (!a[v.project.client.id].projects[v.project.id].roles) {
          a[v.project.client.id].projects[v.project.id].roles = {};
        }

        a[v.project.client.id].projects[v.project.id].roles[v.role.id] = a[v.project.client.id].projects[v.project.id]
          .roles[v.role.id] || {
          role: v.role,
          members: {},
          billableHours: 0,
          nonBillableHours: 0,
          totalHours: 0,
          totalFees: 0,
          convertedTotalFees: 0,
        };

        a[v.project.client.id].projects[v.project.id].roles[v.role.id].hasOverriddenRate =
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].hasOverriddenRate || v.hasOverriddenRate;

        a[v.project.client.id].projects[v.project.id].roles[v.role.id].billableHours = Big(
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].billableHours,
        )
          .add(v.billableHours || 0)
          .toNumber();

        a[v.project.client.id].projects[v.project.id].roles[v.role.id].nonBillableHours = Big(
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].nonBillableHours,
        )
          .add(v.nonBillableHours || 0)
          .toNumber();

        a[v.project.client.id].projects[v.project.id].roles[v.role.id].totalHours = Big(
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].totalHours,
        )
          .add(v.totalHours || 0)
          .toNumber();

        a[v.project.client.id].projects[v.project.id].roles[v.role.id].totalFees = Big(
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].totalFees,
        )
          .add(v.totalFees || 0)
          .toNumber();

        a[v.project.client.id].projects[v.project.id].roles[v.role.id].convertedTotalFees = Big(
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].convertedTotalFees,
        )
          .add(v.convertedTotalFees || 0)
          .toNumber();

        if (v.member) {
          a[v.project.client.id].projects[v.project.id].roles[v.role.id].members[v.member.id] = {
            member: v.member,
            billableHours: v.billableHours,
            nonBillableHours: v.nonBillableHours,
            totalHours: v.totalHours,
            totalFees: v.totalFees,
            convertedTotalFees: v.convertedTotalFees,
          };
        }
      } else {
        if (!a[v.project.client.id].projects[v.project.id].members) {
          a[v.project.client.id].projects[v.project.id].members = {};
        }

        a[v.project.client.id].projects[v.project.id].members[v.member.id] = {
          member: v.member,
          billableHours: v.billableHours,
          nonBillableHours: v.nonBillableHours,
          totalHours: v.totalHours,
          totalFees: v.totalFees,
          hasOverriddenRate: v.hasOverriddenRate,
          convertedTotalFees: v.convertedTotalFees,
        };
      }

      return a;
    }, {});
  }, [report.records]);

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          currency: params.currency ?? undefined,
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Client" value={_.size(groups)} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header sticky>
          <Table.Column sticky name="project:client.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Billable Hours
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Non-Billable Hours
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Total Hours
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Bill Rate
          </Table.Column>
          <Table.Column width="11rem" align="right">
            Fees
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {_.map(groups, ({ client, billableHours, nonBillableHours, totalHours, convertedTotalFees, projects }) => {
            return (
              <React.Fragment key={client.id}>
                <Table.GroupRow top="4rem">
                  <Table.Cell>
                    <strong>
                      <ClientInfo>
                        <ClientLink client={client} />
                        {client.isInternal && <InternalClientTooltip />}
                      </ClientInfo>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ client: client.id, billableType: 'billable' })}>
                        <Hours value={billableHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ client: client.id, billableType: ['non_billable', 'internal'] })}>
                        <Hours value={nonBillableHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ client: client.id })}>
                        <Hours value={totalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell>
                    <strong>
                      <Currency value={convertedTotalFees} currency={report.currency} />
                    </strong>
                  </Table.Cell>
                </Table.GroupRow>

                {_.map(
                  projects,
                  ({
                    project,
                    billableHours,
                    nonBillableHours,
                    totalHours,
                    totalFees,
                    convertedTotalFees,
                    roles,
                    members,
                  }) => {
                    return (
                      <React.Fragment key={project.id}>
                        <Table.Row>
                          <Table.Cell>
                            <ProjectInfo>
                              <p>
                                <ProjectLink project={project} />
                              </p>
                            </ProjectInfo>
                          </Table.Cell>
                          <Table.Cell>
                            <strong>
                              <RouteLink to={timeDetail({ project: project.id, billableType: 'billable' })}>
                                <Hours value={billableHours} />
                              </RouteLink>
                            </strong>
                          </Table.Cell>
                          <Table.Cell>
                            <strong>
                              <RouteLink
                                to={timeDetail({ project: project.id, billableType: ['non_billable', 'internal'] })}>
                                <Hours value={nonBillableHours} />
                              </RouteLink>
                            </strong>
                          </Table.Cell>
                          <Table.Cell>
                            <strong>
                              <RouteLink to={timeDetail({ project: project.id })}>
                                <Hours value={totalHours} />
                              </RouteLink>
                            </strong>
                          </Table.Cell>
                          <Table.Cell />
                          <Table.Cell>
                            <p>
                              <strong>
                                <Currency value={convertedTotalFees} currency={report.currency} />
                              </strong>
                              {project.currency !== report.currency && (
                                <Byline>
                                  <Currency value={totalFees} currency={project.currency} />
                                </Byline>
                              )}
                            </p>
                          </Table.Cell>
                        </Table.Row>

                        {_.map(
                          members,
                          ({
                            member,
                            billableHours,
                            nonBillableHours,
                            totalHours,
                            totalFees,
                            convertedTotalFees,
                            hasOverriddenRate,
                          }) => {
                            return (
                              <Table.Row key={member.id}>
                                <Table.Cell>
                                  <MemberRole>
                                    <BillableIcon value={member.isBillable} />
                                    {member.name}
                                  </MemberRole>
                                </Table.Cell>
                                <Table.Cell>
                                  <RouteLink
                                    to={timeDetail({
                                      project: project.id,
                                      member: member.id,
                                      billableType: 'billable',
                                    })}>
                                    <Hours value={billableHours} />
                                  </RouteLink>
                                </Table.Cell>
                                <Table.Cell>
                                  <RouteLink
                                    to={timeDetail({
                                      project: project.id,
                                      member: member.id,
                                      billableType: ['non_billable', 'internal'],
                                    })}>
                                    <Hours value={nonBillableHours} />
                                  </RouteLink>
                                </Table.Cell>
                                <Table.Cell>
                                  <RouteLink to={timeDetail({ project: project.id, member: member.id })}>
                                    <Hours value={totalHours} />
                                  </RouteLink>
                                </Table.Cell>
                                <Table.Cell>
                                  <Currency value={member.rate} maximumFractionDigits={7} currency={project.currency} />
                                </Table.Cell>
                                <Table.Cell>
                                  {hasOverriddenRate && (
                                    <Warning
                                      maxWidth="30rem"
                                      message="One or more time entries have an overridden bill rate.">
                                      <Icon icon="exclamation-triangle" color={colors.warning} />
                                    </Warning>
                                  )}
                                  <p>
                                    <strong>
                                      <Currency value={convertedTotalFees} currency={report.currency} />
                                    </strong>
                                    {project.currency !== report.currency && (
                                      <Byline>
                                        <Currency value={totalFees} currency={project.currency} />
                                      </Byline>
                                    )}
                                  </p>
                                </Table.Cell>
                              </Table.Row>
                            );
                          },
                        )}

                        {_.map(
                          roles,
                          ({
                            role,
                            billableHours,
                            nonBillableHours,
                            totalHours,
                            totalFees,
                            convertedTotalFees,
                            members,
                            hasOverriddenRate,
                          }) => {
                            return (
                              <React.Fragment key={role.id}>
                                <Table.Row>
                                  <Table.Cell>
                                    <MemberRole>
                                      <BillableIcon value={role.isBillable} />
                                      {role.name}
                                    </MemberRole>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <RouteLink
                                      to={timeDetail({
                                        project: project.id,
                                        projectRole: role.id,
                                        billableType: 'billable',
                                      })}>
                                      <Hours value={billableHours} />
                                    </RouteLink>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <RouteLink
                                      to={timeDetail({
                                        project: project.id,
                                        projectRole: role.id,
                                        billableType: ['non_billable', 'internal'],
                                      })}>
                                      <Hours value={nonBillableHours} />
                                    </RouteLink>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <RouteLink to={timeDetail({ project: project.id, projectRole: role.id })}>
                                      <Hours value={totalHours} />
                                    </RouteLink>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Currency value={role.rate} maximumFractionDigits={7} currency={project.currency} />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {hasOverriddenRate && (
                                      <Warning
                                        maxWidth="30rem"
                                        message="One or more time entries have an overridden bill rate.">
                                        <Icon icon="exclamation-triangle" color={colors.warning} />
                                      </Warning>
                                    )}
                                    <p>
                                      <strong>
                                        <Currency value={convertedTotalFees} currency={report.currency} />
                                      </strong>
                                      {project.currency !== report.currency && (
                                        <Byline>
                                          <Currency value={totalFees} currency={project.currency} />
                                        </Byline>
                                      )}
                                    </p>
                                  </Table.Cell>
                                </Table.Row>

                                {_.map(
                                  members,
                                  ({
                                    member,
                                    billableHours,
                                    nonBillableHours,
                                    totalHours,
                                    totalFees,
                                    convertedTotalFees,
                                  }) => {
                                    return (
                                      <Table.Row key={`${role.id}_${member.id}`}>
                                        <Table.Cell>
                                          <MemberRole style={{ marginLeft: '3rem' }}>{member.name}</MemberRole>
                                        </Table.Cell>
                                        <Table.Cell>
                                          <RouteLink
                                            to={timeDetail({
                                              project: project.id,
                                              projectRole: role.id,
                                              member: member.id,
                                              billableType: 'billable',
                                            })}>
                                            <Hours value={billableHours} />
                                          </RouteLink>
                                        </Table.Cell>
                                        <Table.Cell>
                                          <RouteLink
                                            to={timeDetail({
                                              project: project.id,
                                              projectRole: role.id,
                                              member: member.id,
                                              billableType: ['non_billable', 'internal'],
                                            })}>
                                            <Hours value={nonBillableHours} />
                                          </RouteLink>
                                        </Table.Cell>
                                        <Table.Cell>
                                          <RouteLink
                                            to={timeDetail({
                                              project: project.id,
                                              projectRole: role.id,
                                              member: member.id,
                                            })}>
                                            <Hours value={totalHours} />
                                          </RouteLink>
                                        </Table.Cell>
                                        <Table.Cell />
                                        <Table.Cell>
                                          <p>
                                            <strong>
                                              <Currency value={convertedTotalFees} currency={report.currency} />
                                            </strong>
                                            {project.currency !== report.currency && (
                                              <Byline>
                                                <Currency value={totalFees} currency={project.currency} />
                                              </Byline>
                                            )}
                                          </p>
                                        </Table.Cell>
                                      </Table.Row>
                                    );
                                  },
                                )}
                              </React.Fragment>
                            );
                          },
                        )}
                      </React.Fragment>
                    );
                  },
                )}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <RouteLink
                to={timeDetail({
                  billableType: ['billable'],
                })}>
                <Hours value={report.totals.billableHours} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink
                to={timeDetail({
                  billableType: ['non_billable', 'internal'],
                })}>
                <Hours value={report.totals.nonBillableHours} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink to={timeDetail()}>
                <Hours value={report.totals.totalHours} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.totalFees} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <Checkbox
          checked={filters.includeMembersAndRolesWithoutTime}
          label="Include members and roles without tracked time"
          onChange={({ target: { checked } }) => handleFilter({ includeMembersAndRolesWithoutTime: checked })}
        />
      </Filter>

      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
