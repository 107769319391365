import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, ClientLink, Hours, Icon, InternalClientTooltip, Percent, RouteLink, Widget } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import timeBillableTypes from '~/lookups/time-billable-types.js';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import MemberFiltersBar from '../components/MemberFiltersBar';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import Table from '../components/table';
import TimeFiltersBar from '../components/TimeFiltersBar';
import TimeFiltersGroup from '../components/TimeFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const ClientInfo = styled.div`
  margin-left: 1rem;
  line-height: 1;
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
`;

export default function TimeSummaryByMemberAndClient() {
  useDocumentTitle('Time Summary by Member and Client');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    invoiced: null,
    projectRoles: [],
    billableTypes: [],
    sort: new QuerySort('member.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        invoiced: searchParamsConfig.invoiced,
        projectRoles: searchParamsConfig.projectRoles,
        billableTypes: {
          ...searchParamsConfig.timeBillableTypes,
          default: [timeBillableTypes.billable, timeBillableTypes.non_billable, timeBillableTypes.internal],
        },
        sort: { default: new QuerySort('member.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      approvalStatusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      invoiced: params.invoiced ?? undefined,
      projectRoleId: params.projectRoles?.map((v) => v.id),
      billableTypeId: params.billableTypes?.map((v) => v.id),
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByMemberAndClient(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByMemberAndClient(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/time`} />

        <Report.Info>
          <Report.Eyebrow>Time Reports</Report.Eyebrow>
          <Report.Title>Time Summary by Member and Client</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.pdf);
                    setIsOpen(false);
                  }}>
                  Export to PDF
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} data-testid="show-filters-button" />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter value={params.period} onChange={(period) => handleApplyFilters({ period })} />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const metrics = useMemo(() => {
    const include = (metric) => _.isEmpty(params.billableTypes) || _.includes(params.billableTypes, metric);

    const metrics = [];

    if (include(timeBillableTypes.billable))
      metrics.push({ label: 'Client Billable', value: report.totals.billable, color: colors.success, key: 'billable' });
    if (include(timeBillableTypes.non_billable))
      metrics.push({
        label: 'Client Non-Billable',
        value: report.totals.nonBillable,
        color: colors.danger,
        key: 'non_billable',
      });
    if (include(timeBillableTypes.internal))
      metrics.push({ label: 'Internal', value: report.totals.internal, color: colors.warning, key: 'internal' });
    if (include(timeBillableTypes.time_off))
      metrics.push({ label: 'Time Off', value: report.totals.timeOff, color: colors.primary50, key: 'time_off' });

    return metrics;
  }, [params.billableTypes, report]);

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          projectRole: params.projectRoles?.map((v) => v.id),
          status: params.approvalStatuses?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          billableType: params.billableTypes?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary>
        <Widget>
          <Meter>
            {metrics.map((metric) => (
              <Meter.Value
                key={metric.key}
                label={metric.label}
                value={metric.value}
                color={metric.color}
                tooltip={
                  <>
                    <Hours value={metric.value} /> hours (<Percent value={metric.value / report.totals.total} />)
                  </>
                }
              />
            ))}
          </Meter>

          <Metrics>
            {metrics.map((metric) => (
              <Metric key={metric.key}>
                <RouteLink to={timeDetail({ billableType: metric.key })}>
                  <Metric.Value>
                    <Hours value={metric.value} />
                  </Metric.Value>
                  <Metric.Label>{metric.label}</Metric.Label>
                </RouteLink>
              </Metric>
            ))}

            <Metric>
              <RouteLink to={timeDetail()}>
                <Metric.Value>
                  <Hours value={report.totals.total} />
                </Metric.Value>
                <Metric.Label>Total</Metric.Label>
              </RouteLink>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Member" value={report.records.length} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="member.name" onSort={onSort} sort={params.sort}>
            Member
          </Table.Column>
          <Table.Column
            isVisible={!params.billableTypes.length || _.includes(params.billableTypes, timeBillableTypes.billable)}
            width="10rem"
            align="right">
            Client <br />
            Billable
          </Table.Column>
          <Table.Column
            isVisible={!params.billableTypes.length || _.includes(params.billableTypes, timeBillableTypes.non_billable)}
            width="10rem"
            align="right">
            Client <br />
            Non-Billable
          </Table.Column>
          <Table.Column
            isVisible={!params.billableTypes.length || _.includes(params.billableTypes, timeBillableTypes.internal)}
            width="10rem"
            align="right">
            Internal
          </Table.Column>
          <Table.Column
            isVisible={!params.billableTypes.length || _.includes(params.billableTypes, timeBillableTypes.time_off)}
            width="10rem"
            align="right">
            Time Off
          </Table.Column>
          <Table.Column width="10rem" align="right">
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((member) => {
            return (
              <React.Fragment key={member.id}>
                <Table.GroupRow top="4rem">
                  <Table.Cell>
                    <Member>{member.name}</Member>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ member: member.id, billableType: 'billable' })}>
                        <Hours value={member.billableHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ member: member.id, billableType: 'non_billable' })}>
                        <Hours value={member.nonBillableHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ member: member.id, billableType: 'internal' })}>
                        <Hours value={member.internalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink to={timeDetail({ member: member.id, billableType: 'time_off' })}>
                        <Hours value={member.timeOffHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                  <Table.Cell>
                    <strong>
                      <RouteLink
                        to={timeDetail({
                          member: member.id,
                        })}>
                        <Hours value={member.totalHours} />
                      </RouteLink>
                    </strong>
                  </Table.Cell>
                </Table.GroupRow>
                {member.groups.map((group) => {
                  const client = group.client;

                  return (
                    <Table.Row key={client ? client.id : 'time_off'}>
                      <Table.Cell>
                        <ClientInfo>
                          {client ? (
                            <>
                              <p style={{ display: 'flex' }}>
                                <ClientLink client={client} />
                                {client.isInternal && <InternalClientTooltip />}
                              </p>
                            </>
                          ) : (
                            <p>Time Off</p>
                          )}
                        </ClientInfo>
                      </Table.Cell>
                      <Table.Cell>
                        {client ? (
                          <RouteLink
                            to={timeDetail({
                              client: group.clientId ?? undefined,
                              member: member.id,
                              billableType: 'billable',
                            })}>
                            <Hours value={group.billableHours} />
                          </RouteLink>
                        ) : (
                          <Hours value={group.billableHours} />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {client ? (
                          <RouteLink
                            to={timeDetail({
                              client: group.clientId ?? undefined,
                              member: member.id,
                              billableType: 'non_billable',
                            })}>
                            <Hours value={group.nonBillableHours} />
                          </RouteLink>
                        ) : (
                          <Hours value={group.nonBillableHours} />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {client ? (
                          <RouteLink
                            to={timeDetail({
                              client: group.clientId ?? undefined,
                              member: member.id,
                              billableType: 'internal',
                            })}>
                            <Hours value={group.internalHours} />
                          </RouteLink>
                        ) : (
                          <Hours value={group.internalHours} />
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <RouteLink
                          to={timeDetail({
                            client: group.clientId ?? undefined,
                            member: member.id,
                            billableType: 'time_off',
                          })}>
                          <Hours value={group.timeOffHours} />
                        </RouteLink>
                      </Table.Cell>
                      <Table.Cell>
                        <RouteLink
                          to={timeDetail({
                            client: group.clientId ?? undefined,
                            member: member.id,
                            billableType: !client ? 'time_off' : undefined,
                          })}>
                          <Hours value={group.totalHours} />
                        </RouteLink>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <RouteLink
                to={timeDetail({
                  billableType: 'billable',
                })}>
                <Hours value={report.totals.billable} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink
                to={timeDetail({
                  billableType: 'non_billable',
                })}>
                <Hours value={report.totals.nonBillable} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink
                to={timeDetail({
                  billableType: 'internal',
                })}>
                <Hours value={report.totals.internal} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink
                to={timeDetail({
                  billableType: 'internal',
                })}>
                <Hours value={report.totals.timeOff} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink to={timeDetail()}>
                <Hours value={report.totals.total} />
              </RouteLink>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
