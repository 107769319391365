import React from 'react';
import styled from 'styled-components';
import ListViewBody from './ListViewBody';
import ListViewCell from './ListViewCell';
import ListViewCellContent from './ListViewCellContent.jsx';
import ListViewColumn from './ListViewColumn';
import { ListViewProvider } from './ListViewContext';
import ListViewEmpty from './ListViewEmpty';
import ListViewHeader from './ListViewHeader';
import ListViewLoader from './ListViewLoader';
import ListViewRow from './ListViewRow';
import ListViewScroll from './ListViewScroll';
import ListViewStatus from './ListViewStatus';
import ListViewTotal from './ListViewTotal';

const ListViewContents = styled.div`
  position: absolute;
  min-width: 100%;
`;

function ListView(props) {
  return (
    <ListViewProvider>
      <ListViewScroll>
        <ListViewContents {...props} />
      </ListViewScroll>
    </ListViewProvider>
  );
}

ListView.Header = ListViewHeader;
ListView.Column = ListViewColumn;
ListView.Body = ListViewBody;
ListView.Row = ListViewRow;
ListView.Cell = ListViewCell;
ListView.CellContent = ListViewCellContent;
ListView.Total = ListViewTotal;
ListView.Status = ListViewStatus;
ListView.Loader = ListViewLoader;
ListView.Empty = ListViewEmpty;

export default ListView;
