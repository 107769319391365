import _ from 'lodash';
import React from 'react';
import CreditNoteSentFilter from '~/components/filters/CreditNoteSentFilter';
import CreditNoteStatusFilter from '~/components/filters/CreditNoteStatusFilter';

export default function CreditNoteFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.statuses) && (
        <CreditNoteStatusFilter
          value={filters.statuses}
          onChange={({ target: { value } }) => onChange({ statuses: value })}
        />
      )}

      {!_.isEmpty(filters.creditNoteSent) && (
        <CreditNoteSentFilter
          value={filters.creditNoteSent}
          onChange={({ target: { value } }) => onChange({ creditNoteSent: value })}
        />
      )}
    </>
  );
}
