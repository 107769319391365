import {
  Button,
  Confirmation,
  DateTime,
  Icon,
  InlineTooltip,
  Level,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
  Spinner,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import FilePreview from './FilePreview';
import CompanyFilesDrawer from './CompanyFilesDrawer';

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const WarningIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${colors.warning};
  font-size: 1.25rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  margin-top: -4rem;
  font-weight: ${weights.light};
`;

export const AddButton = styled(Button)`
  position: relative;
  margin-left: auto;
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const NoFilesMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0;
  color: ${colors.grey40};
  font-size: 1.5rem;
  font-weight: ${weights.bold};
`;

const ViewButton = styled(Button)`
  position: relative;
  margin-left: auto;
  font-size: 0.875rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  background: ${colors.grey5};
  transition: all 100ms ease-in-out;
  border: none;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }
`;

export default function CompanyFilesTab({ company }) {
  const api = useApi();
  const auth = useAuth();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const [showDrawer, setShowDrawer] = useState(false);

  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [params, setParams] = useState({
    q: '',
    sort: new QuerySort('name', 'asc'),
    view: 'list',
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: params.q },
        sort: { default: params.sort, ...searchParamsConfig.sort },
        view: { default: 'list', valid: ['grid', 'list'] },
      }),
      [params, searchParamsConfig],
    ),
    sessionKey: 'company_files_tab',
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).companies(company.id).files().get(urlSearchParams);
      setQuery({ status: 'ready', data, error: null });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [api, workspace.id, company.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const hasFiles = useMemo(() => query.data?.length > 0, [query.data]);

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams((state) => ({ ...state, sort: querySort }));
    searchParams.set({ sort: querySort });
  };

  const handleViewChange = (view) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const querySort = new QuerySort('name', 'asc');
    setParams((state) => ({ ...state, q: '', sort: querySort, view }));
    searchParams.set({ q: null, sort: null, view });
  };

  async function handleDeleteConfirmation(file) {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this file?</Confirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).companies(company.id).files(file.id).delete();
    await fetchData();
  }

  return (
    <>
      <Page.Section>
        <Level>
          {params.view === 'list' && (
            <Level.Item width="20rem">
              <SearchInput
                value={params.q}
                placeholder="Search"
                materialPlaceholder="Filename"
                materialAlwaysVisible
                onChange={handleChange}
              />
            </Level.Item>
          )}
          <Level.Item right>
            {
              {
                list: (
                  <ViewButton onClick={() => handleViewChange('grid')}>
                    <Tooltip message="Grid View" delay={500}>
                      <Icon icon="grid-2" />
                    </Tooltip>
                  </ViewButton>
                ),
                grid: (
                  <ViewButton onClick={() => handleViewChange('list')}>
                    <Tooltip message="List View" delay={500}>
                      <Icon icon="table-rows" />
                    </Tooltip>
                  </ViewButton>
                ),
              }[params.view]
            }
          </Level.Item>
          <Level.Item right narrow>
            <AddButton onClick={() => setShowDrawer(true)} disabled={!auth.pipeline.manage}>
              Add Files
              {!auth.pipeline.manage && <InlineTooltip message="Your security role prohibits you from adding files." />}
            </AddButton>
          </Level.Item>
        </Level>
      </Page.Section>
      {query.status === 'loading' ? (
        <PageLoader />
      ) : query.error ? (
        <ErrorContainer>
          <ErrorMessage>
            <WarningIcon icon="exclamation-triangle" />
            There was a problem getting the company files
          </ErrorMessage>
          <Button onClick={() => fetchData()}>Retry</Button>
        </ErrorContainer>
      ) : (
        <>
          <Page.Section>
            <ListView.Status>
              {query.status !== 'ready' && <Spinner />}
              <ListView.Total label="Company File" value={query.data?.length} />
            </ListView.Status>
          </Page.Section>

          {
            {
              list: (
                <ListView>
                  <ListView.Header>
                    <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
                      Filename
                    </ListView.Column>
                    <ListView.Column width="8rem" align="right" name="createdAt" onSort={handleSort} sort={params.sort}>
                      Created
                    </ListView.Column>

                    <ListViewActions.Column />
                  </ListView.Header>

                  <ListView.Body fade={query.status !== 'ready'}>
                    {query.data.map((file) => {
                      const { id, name, url, createdAt } = file;

                      return (
                        <ListView.Row key={id} onClick={() => window.open(url, '_blank')}>
                          <ListView.Cell>{name}</ListView.Cell>
                          <ListView.Cell>
                            <DateTime value={createdAt} />
                          </ListView.Cell>
                          <ListViewActions>
                            <Button onClick={() => window.open(url, '_blank')}>
                              <Icon icon="external-link-alt" />
                            </Button>

                            <hr />

                            <ListViewMenu>
                              {({ setIsOpen }) => {
                                const handleAction = (action) => setIsOpen(false) || action;

                                return (
                                  <>
                                    <ListViewMenu.Item onClick={() => handleAction(window.open(url, '_blank'))}>
                                      View
                                    </ListViewMenu.Item>
                                    <ListViewMenu.Item onClick={() => handleAction(window.open(url + '?download=1'))}>
                                      Download
                                    </ListViewMenu.Item>
                                    <ListViewMenu.Item
                                      disabled={!auth.pipeline.manage}
                                      tooltip={
                                        !auth.pipeline.manage
                                          ? 'Insufficient permissions to delete this file.'
                                          : undefined
                                      }
                                      onClick={() => handleAction(handleDeleteConfirmation(file))}>
                                      Delete
                                    </ListViewMenu.Item>
                                  </>
                                );
                              }}
                            </ListViewMenu>
                          </ListViewActions>
                        </ListView.Row>
                      );
                    })}

                    {query.data.length === 0 && <ListView.Empty />}
                  </ListView.Body>
                </ListView>
              ),
              grid: (
                <>
                  <>
                    <Title>Company Files</Title>

                    <Files fade={query.status !== 'ready'}>
                      {_(query.data)
                        .map((file) => (
                          <FilePreview
                            key={file.id}
                            file={file}
                            onRemove={auth.pipeline.manage ? handleDeleteConfirmation : undefined}
                          />
                        ))
                        .value()}
                    </Files>
                  </>

                  {!hasFiles && <NoFilesMessage>No Files have been uploaded.</NoFilesMessage>}
                </>
              ),
            }[params.view]
          }
        </>
      )}

      {showDrawer && (
        <CompanyFilesDrawer company={company} onClose={() => setShowDrawer(false)} onChanged={() => fetchData()} />
      )}
    </>
  );
}
