import { Formik } from 'formik';
import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form, FormMessage, Level } from '~/components';
import { useApi } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import { colors, devices, weights } from '~/styles';
import { Footer, Header } from '../components';
import EnvelopeIcon from './assets/envelope.svg?react';

const Container = styled.div`
  display: flex;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
  margin-bottom: 2rem;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 2rem 5% 2rem 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 23.4375rem;

  @media ${devices.mobile} {
    padding: 4rem 5%;
    height: auto;

    ${Level} {
      flex-direction: column;

      ${Level.Item} {
        padding: 0;
        text-align: center;

        &:first-child {
          margin-bottom: 2rem;
        }
      }
    }
  }
`;

const BoxTitle = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  margin-bottom: 2rem;
`;

const BoxByline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const FormBox = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 10% 2.78rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormTitle = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto;
  }
`;

function ForgotWorkspaceForm({ view = 'form', status, message, isSubmitting, onSubmit }) {
  return (
    <>
      <Header />
      <Container>
        {{
          form: () => <ForgotWorkspacePageForm {...{ status, message, isSubmitting, onSubmit }} />,
          success: () => <ForgotWorkspaceSuccess />,
        }[view]()}
      </Container>

      <Footer />
    </>
  );
}

function ForgotWorkspacePageForm({ status, message, isSubmitting, onSubmit }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return (
    <FormBox>
      <FormTitle>Get Workspace Links</FormTitle>
      <Byline style={{ textAlign: 'center' }}>
        Enter your account's email address and we will send you a an email with links to login to your workspaces.
      </Byline>
      <Formik
        initialValues={{
          email: params.get('email') ?? '',
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          email: Yup.string().label('Email').email().required(),
        })}>
        {() => (
          <Form autoComplete="off">
            <Form.Control>
              <Field.Text autoFocus name="email" placeholder="Email" type="email" />
            </Form.Control>

            {status &&
              {
                error: <FormMessage.Error spaceTop>{message}</FormMessage.Error>,
              }[status]}

            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Get Workspace Links
              </Button>
            </FormAction>
          </Form>
        )}
      </Formik>
    </FormBox>
  );
}

function ForgotWorkspaceSuccess() {
  return (
    <Box>
      <Level gap="5%">
        <Level.Item narrow>
          <EnvelopeIcon />
        </Level.Item>
        <Level.Item>
          <BoxTitle>Check your email</BoxTitle>
          <BoxByline>
            If a Ruddr user account exists for the email address you provided, you will receive an email with links to
            login to your workspaces.
          </BoxByline>
        </Level.Item>
      </Level>
    </Box>
  );
}

const initialState = {
  view: 'form',
  isSubmitting: false,
  status: null,
  message: null,
};

const handlers = {
  submit: () => ({ isSubmitting: true, status: null, message: null }),
  success: () => ({ view: 'success', isSubmitting: false, status: null }),
  error: ({ message } = {}) => ({
    isSubmitting: false,
    status: 'error',
    message,
  }),
};

export default function ForgotWorkspacePage() {
  useDocumentTitle('Get Workspace Links');

  const api = useApi();
  const [{ view, isSubmitting, status, message }, actions] = useActions(handlers, initialState);
  const location = useLocation();

  async function handleSubmit(values) {
    try {
      actions.submit();
      const params = new URLSearchParams(location.search);
      const workspaceKey = params.get('workspace');
      const credentials = { ...values };
      if (workspaceKey) credentials.workspaceKey = workspaceKey;
      await api.www.sendWorkspaceLinksEmail(credentials);
      actions.success();
    } catch ({ status, message }) {
      actions.error({ message });
    }
  }

  return <ForgotWorkspaceForm onSubmit={handleSubmit} {...{ view, isSubmitting, status, message }} />;
}
