import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, DayPickerInput, Icon, SingleSelect, Table, TextInput } from '~/components';
import { useApi } from '~/contexts';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { ReportLoader } from '../reports/components';

const PageArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const FilterInput = styled.div`
  margin-left: 1rem;
  width: 12rem;

  &:first-child {
    flex: 1;
    position: relative;
    margin-left: 0; // Optional: Remove left margin for the first child
    width: auto; // Optional: Allow it to take up available space
  }

  &:last-child {
    width: 7rem;
  }
`;

const ClearButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
`;

const EmptyRow = styled(Table.Cell)`
  display: flex;
  justify-content: center;
  color: ${colors.grey40};
`;

const eventTypes = {
  connect: 'Connect',
  disconnect: 'Disconnect',
  signup: 'Sign Up',
};

export default function History() {
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchEvent, setSearchEvent] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const search = async () => {
    setIsLoading(true);
    try {
      const params = {};
      if (searchTerm) params.search = searchTerm;
      if (searchEvent) params.event = searchEvent;
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      const { data } = await api.admin.xero.getHistory(params);
      setConnections(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEventChange = (event) => {
    setSearchEvent(event.target.value);
  };

  const handleFromDateChange = (date) => {
    moment(date).isAfter(endDate) && setEndDate(null);
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    moment(date).isBefore(startDate) && setStartDate(null);
    setEndDate(date);
  };

  return (
    <PageArea>
      <Filters>
        <FilterInput>
          <TextInput
            value={searchTerm}
            onChange={handleSearchChange}
            label="Search"
            type="search"
            placeholder="Search by Workspace, Tenant, or Email"
          />
          <ClearButton isAnchor={true} onClick={handleClearSearch}>
            <Icon icon="times" />
          </ClearButton>
        </FilterInput>
        <FilterInput>
          <SingleSelect
            placeholder="Event Type"
            showEmptyOption={true}
            value={searchEvent}
            onChange={handleEventChange}>
            {Object.keys(eventTypes).map((key) => (
              <option key={key} value={key}>
                {eventTypes[key]}
              </option>
            ))}
          </SingleSelect>
        </FilterInput>
        <FilterInput>
          <DayPickerInput value={startDate} placeholder="From" onChange={handleFromDateChange} />
        </FilterInput>
        <FilterInput>
          <DayPickerInput value={endDate} placeholder="To" onChange={handleToDateChange} />
        </FilterInput>
        <FilterInput>
          <Button onClick={search}>Search</Button>
        </FilterInput>
      </Filters>
      <Table>
        <Table.Status>
          <Table.Total label="/ 1000 History Item" value={connections.length} />
        </Table.Status>
        <Table.Header>
          <Table.Column>Workspace</Table.Column>
          <Table.Column>Tenant</Table.Column>
          <Table.Column>Event Issuer</Table.Column>
          <Table.Column width="6rem">Is Admin</Table.Column>
          <Table.Column width="8rem">Event Type</Table.Column>
          <Table.Column width="14rem">Event Time</Table.Column>
        </Table.Header>
        {isLoading ? (
          <ReportLoader />
        ) : !connections.length ? (
          <Table.Row>
            <EmptyRow>No results found</EmptyRow>
          </Table.Row>
        ) : (
          connections.map((connection) => (
            <Table.Row key={connection.id}>
              <Table.Cell>{connection.workspaceName}</Table.Cell>
              <Table.Cell>{connection.xeroTenantName}</Table.Cell>
              <Table.Cell>{connection.issuer.email}</Table.Cell>
              <Table.Cell>{connection.issuer.isAdmin ? 'Yes' : 'No'}</Table.Cell>
              <Table.Cell>{eventTypes[connection.event]}</Table.Cell>
              <Table.Cell>{moment(connection.eventTime).format(dateFormats.dateTime)}</Table.Cell>
            </Table.Row>
          ))
        )}
      </Table>
    </PageArea>
  );
}
