import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Icon } from '~/components';
import { colors, weights } from '~/styles';
import { useAdminSession } from '../contexts';
import ChangePassword from './ChangePassword';
import MFA from './MFA';

const AngleIcon = styled(Icon)`
  margin-left: 1.25rem;
`;

const UserIcon = styled(Icon)`
  color: ${colors.grey55};
  font-size: 1.5rem;
`;

const Selector = styled.div`
  display: flex;
  align-items: center;
  height: 4.5rem;
  padding: 0 1.25rem;
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${colors.grey75};
  }

  ${AngleIcon} {
    color: ${colors.grey25};
  }

  &:hover ${AngleIcon} {
    color: ${colors.grey75};
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 5rem;
  right: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 16rem;
  padding: 1rem 1.5rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey25};
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  margin-left: 1rem;

  ${({ isOpen = false }) =>
    isOpen &&
    css`
      ${Selector} {
        border-color: ${colors.grey75};
      }

      ${AngleIcon} {
        color: ${colors.grey75};
      }

      ${Menu} {
        display: flex;
      }
    `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: solid 1px ${colors.grey5};
`;

const Name = styled.div`
  font-weight: ${weights.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Email = styled.div`
  color: ${colors.grey55};
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: solid 1px ${colors.grey5};
`;

const LinkButton = styled(Button)`
  margin-top: 1rem;
  font-weight: bold;
`;

const LogoutButton = styled(Button)`
  margin-top: 1rem;
  color: ${colors.grey55};

  &:hover {
    color: ${colors.black};
  }
`;

export default function User() {
  const { admin, logout, showMfaReg, setShowMfaReg } = useAdminSession();

  const [isOpen, setOpen] = useState(false);
  const [mfaOpen, setMfaOpen] = useState(showMfaReg);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const menuElement = useRef();
  const selectorElement = useRef();

  useEffect(() => {
    function closeMenu(event) {
      // Prevent this firing when the user clicks the menu/selector
      if (menuElement.current?.contains(event.target) || selectorElement.current?.contains(event.target)) {
        return false;
      }
      setOpen(false);
    }

    // Only attach event listener if menu is open
    if (isOpen) {
      document.addEventListener('mouseup', closeMenu);
    }

    return function cleanup() {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, [isOpen]);

  const handleLogout = async () => {
    await logout();
    history.push('/admin');
  };

  return (
    <Container isOpen={isOpen}>
      <Selector ref={selectorElement} onClick={() => setOpen(!isOpen)}>
        <UserIcon icon="user" />
        <AngleIcon icon="angle-down" />
      </Selector>
      <Menu ref={menuElement}>
        {admin && (
          <Info>
            <Name>{admin.name}</Name>
            <Email>{admin.email}</Email>
          </Info>
        )}
        <Links>
          <LinkButton
            isAnchor
            onClick={() => {
              setOpen(false);
              setMfaOpen(true);
            }}>
            Multi-factor Auth
          </LinkButton>
          <LinkButton
            isAnchor
            onClick={() => {
              setOpen(false);
              setChangePasswordOpen(true);
            }}>
            Change Password
          </LinkButton>
        </Links>
        <LogoutButton isAnchor onClick={handleLogout}>
          Logout
        </LogoutButton>
      </Menu>
      {mfaOpen && (
        <MFA
          onClose={() => {
            setMfaOpen(false);
            setShowMfaReg(false);
          }}
        />
      )}
      {changePasswordOpen && <ChangePassword onClose={() => setChangePasswordOpen(false)} />}
    </Container>
  );
}
