import _ from 'lodash';
import React from 'react';
import {
  ProjectTaskFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
} from '~/components';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';

export default function TaskFiltersGroup({ filters, onChange }) {
  return (
    <FiltersGroup
      label="Task Filters"
      filters={[
        filters.projectTasks,
        filters.projectTaskRecordStatusId,
        filters.projectTaskStatuses,
        filters.projectTaskTags,
      ]}>
      {_.has(filters, 'projectTasks') && filters.projects.length === 1 && (
        <Filter>
          <ProjectTaskFilter
            project={filters.projects[0]}
            value={filters.projectTasks}
            onChange={({ target: { value } }) => onChange({ projectTasks: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'projectTaskStatuses') && (
        <Filter>
          <ProjectTaskStatusFilter
            value={filters.projectTaskStatuses}
            onChange={({ target: { value } }) => onChange({ projectTaskStatuses: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'projectTaskRecordStatusId') && (
        <Filter>
          <ProjectTaskRecordStatusFilter
            value={filters.projectTaskRecordStatusId}
            onChange={({ target: { value } }) => onChange({ projectTaskRecordStatusId: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'projectTaskTags') && (
        <Filter>
          <ProjectTaskTagFilter
            value={filters.projectTaskTags}
            onChange={({ target: { value } }) => onChange({ projectTaskTags: value })}
          />
        </Filter>
      )}
    </FiltersGroup>
  );
}
