import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  DateTime,
  Hours,
  Icon,
  InternalClientTooltip,
  Percent,
  PeriodFilter,
  ProjectLink,
  SingleSelect,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import MemberFiltersBar from '../components/MemberFiltersBar';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import Report from '../components/Report';
import ResourceFiltersBar from '../components/ResourceFiltersBar';
import ResourceFiltersGroup from '../components/ResourceFiltersGroup';
import Table from '../components/table';
import VarianceBillableTypeFilter from '../components/VarianceBillableTypeFilter';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const intervals = {
  get custom() {
    return intervalOptions.custom;
  },

  get past_30_days() {
    return {
      key: 'past_30_days',
      label: 'Past 30 days',
      start: moment().subtract(30, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_60_days() {
    return {
      key: 'past_60_days',
      label: 'Past 60 days',
      start: moment().subtract(60, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_90_days() {
    return {
      key: 'past_90_days',
      label: 'Past 90 days',
      start: moment().subtract(90, 'days').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').format(dateFormats.isoDate),
      unit: 'custom',
    };
  },

  get past_4_weeks() {
    return {
      key: 'past_4_weeks',
      label: 'Past 4 weeks',
      start: moment().subtract(4, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'week').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_12_weeks() {
    return {
      key: 'past_12_weeks',
      label: 'Past 12 weeks',
      start: moment().subtract(12, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_26_weeks() {
    return {
      key: 'past_26_weeks',
      label: 'Past 26 weeks',
      start: moment().subtract(26, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },

  get past_3_months() {
    return {
      key: 'past_3_months',
      label: 'Past 3 months',
      start: moment().subtract(3, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_6_months() {
    return {
      key: 'past_6_months',
      label: 'Past 6 months',
      start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_9_months() {
    return {
      key: 'past_9_months',
      label: 'Past 9 months',
      start: moment().subtract(9, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },

  get past_12_months() {
    return {
      key: 'past_12_months',
      label: 'Past 12 months',
      start: moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },
};

const intervalsByScope = {
  get day() {
    return [intervals.custom, intervals.past_30_days, intervals.past_60_days, intervals.past_90_days];
  },

  get week() {
    return [intervals.custom, intervals.past_4_weeks, intervals.past_12_weeks, intervals.past_26_weeks];
  },

  get month() {
    return [
      intervals.custom,
      intervals.past_3_months,
      intervals.past_6_months,
      intervals.past_9_months,
      intervals.past_12_months,
    ];
  },
};

export default function HoursVarianceByProjectAndResource() {
  useDocumentTitle('Hours Variance by Project and Resource');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    unit: '',

    // Allocation Filters
    varianceBillableTypes: [],

    // Resource Filters
    resourcePractices: [],
    resourceDisciplines: [],
    resourceLocations: [],
    resourceSkills: [],
    resourceStatusId: '',
    resourceTypeId: null,

    // Member Filters
    ...memberFilters.filters,

    // Client Filters
    ...clientFilters.filters,

    // Project filters
    ...projectFilters.filters,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: intervals.past_6_months,
          keys: ['start', 'end', 'unit'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            let unit = urlSearchParams.get('unit');
            if (!['day', 'week', 'month'].includes(unit)) unit = 'month';

            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');

              switch (unit) {
                case 'day':
                  return intervals.past_30_days;

                case 'week':
                  return intervals.past_12_weeks;

                case 'month':
                  return intervals.past_6_months;
              }
            }

            const option = _.find(intervalsByScope[unit], (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },

        // Allocation Filters
        varianceBillableTypes: searchParamsConfig.varianceBillableTypes,

        // Resource Filters
        resourcePractices: searchParamsConfig.practices,
        resourceDisciplines: searchParamsConfig.disciplines,
        resourceLocations: searchParamsConfig.locations,
        resourceSkills: searchParamsConfig.skills,
        resourceStatusId: searchParamsConfig.resourceStatus,
        resourceTypeId: { valid: ['member', 'placeholder'] },

        // Member Filters
        ...memberFilters.searchParamsConfig,

        // Client Filters
        ...clientFilters.searchParamsConfig,

        // Project Filters
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,

      // Allocation Filters
      varianceBillableTypeId: params.varianceBillableTypes?.map((v) => v.id),

      // Resource Filters
      resourcePracticeId: params.resourcePractices?.map((v) => v.id),
      resourceDisciplineId: params.resourceDisciplines?.map((v) => v.id),
      resourceLocationId: params.resourceLocations?.map((v) => v.id),
      resourceSkillId: params.resourceSkills?.map((v) => v.id),
      resourceStatusId: params.resourceStatusId ?? undefined,
      resourceTypeId: params.resourceTypeId ?? undefined,

      // Member Filters
      ...memberFilters.mapUrlSearchParams(params),

      // Client Filters
      ...clientFilters.mapUrlSearchParams(params),

      // Project Filters
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .hoursVarianceByProjectAndResource(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .hoursVarianceByProjectAndResource(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/plan-and-forecast`} />

        <Report.Info>
          <Report.Eyebrow>Plan & Forecast Reports</Report.Eyebrow>
          <Report.Title>Hours Variance by Project and Resource</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter
          value={params.period}
          intervals={intervalsByScope[params.unit]}
          onChange={(period) => handleApplyFilters({ period })}
        />

        <SingleSelect
          materialPlaceholder="Unit"
          value={params.unit}
          onChange={({ target: { value } }) =>
            handleApplyFilters({
              unit: value,
              period: {
                day: intervals.past_30_days,
                week: intervals.past_12_weeks,
                month: intervals.past_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />

        <ResourceFiltersBar filters={params} onChange={handleApplyFilters} />

        {!_.isEmpty(params.varianceBillableTypes) && (
          <VarianceBillableTypeFilter
            value={params.varianceBillableTypes}
            onChange={({ target: { value } }) => handleApplyFilters({ varianceBillableTypes: value })}
          />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params }) {
  const report = query.data;

  const { projects, resourcesById, records, periods } = useMemo(() => {
    const projects = _.orderBy(report.projects, (p) => p.name.toLowerCase());

    const resourcesById = _.keyBy(report.resources, 'id');

    const records = _.orderBy(report.records, (a) => resourcesById[a.resourceId].name.toLowerCase()).reduce(
      (a, v) => {
        a.total.worked += v.worked || 0;
        a.total.planned += v.planned || 0;

        // Projects
        a.projects[v.projectId] = a.projects[v.projectId] || {
          total: {
            worked: 0,
            planned: 0,
            get variance() {
              return this.worked - this.planned;
            },
            get variancePercentage() {
              if (!this.planned) return null;
              return this.variance / this.planned;
            },
          },
          periods: {},
          resources: {},
        };
        a.projects[v.projectId].total.worked += v.worked || 0;
        a.projects[v.projectId].total.planned += v.planned || 0;
        if (!a.projects[v.projectId].periods[v.start])
          a.projects[v.projectId].periods[v.start] = {
            worked: 0,
            planned: 0,
            get variance() {
              return this.worked - this.planned;
            },
            get variancePercentage() {
              if (!this.planned) return null;
              return this.variance / this.planned;
            },
          };
        a.projects[v.projectId].periods[v.start].worked += v.worked || 0;
        a.projects[v.projectId].periods[v.start].planned += v.planned || 0;
        a.projects[v.projectId].resources[v.resourceId] = a.projects[v.projectId].resources[v.resourceId] || {
          total: {
            worked: 0,
            planned: 0,
            get variance() {
              return this.worked - this.planned;
            },
            get variancePercentage() {
              if (!this.planned) return null;
              return this.variance / this.planned;
            },
          },
          periods: {},
        };

        // Resources
        a.projects[v.projectId].resources[v.resourceId].total.worked += v.worked || 0;
        a.projects[v.projectId].resources[v.resourceId].total.planned += v.planned || 0;
        a.projects[v.projectId].resources[v.resourceId].periods[v.start] = {
          worked: v.worked,
          planned: v.planned,
          variance: v.variance,
          get variancePercentage() {
            if (!this.planned) return null;
            return this.variance / this.planned;
          },
        };

        // Periods
        if (!a.periods[v.start])
          a.periods[v.start] = {
            worked: 0,
            planned: 0,
            get variance() {
              return this.worked - this.planned;
            },
            get variancePercentage() {
              if (!this.planned) return null;
              return this.variance / this.planned;
            },
          };
        a.periods[v.start].worked += v.worked || 0;
        a.periods[v.start].planned += v.planned || 0;

        return a;
      },
      {
        projects: {},
        periods: {},
        total: {
          worked: 0,
          planned: 0,
          get variance() {
            return this.worked - this.planned;
          },
          get variancePercentage() {
            if (!this.planned) return null;
            return this.variance / this.planned;
          },
        },
      },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      periods.push(moment(start).add(index, params.unit).format(dateFormats.isoDate));
    }

    return { projects, resourcesById, records, periods };
  }, [report, params.period.start, params.period.end, params.unit]);

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Project" value={projects.length} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem">
            Project/Client
          </Table.Column>

          {periods.map((p) => (
            <Table.Column key={p} align="right" width="7rem">
              <DateTime value={p} />
            </Table.Column>
          ))}

          <Table.Column align="right" width="7rem">
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body>
          {projects.map((project) => {
            return (
              <React.Fragment key={project.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={project} />
                      </p>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>

                  {periods.map((period) => {
                    const value = records.projects[project.id]?.periods[period];

                    return (
                      <Table.Cell key={period}>
                        <strong>
                          <CellValue value={value} />
                        </strong>
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <strong>
                      <CellValue value={records.projects[project.id]?.total} />
                    </strong>
                  </Table.Cell>
                </Table.GroupRow>

                {_.map(records.projects[project.id]?.resources, (resourceRecords, key) => {
                  const resource = resourcesById[key];

                  return (
                    <Table.Row key={key}>
                      <Table.Cell>
                        <Member>
                          {resource.name}
                          {{
                            member: () => (
                              <Tooltip message="Member">
                                <Icon icon="user" color={colors.grey25} spaceLeft />
                              </Tooltip>
                            ),
                            placeholder: () => (
                              <Tooltip message="Resource Placeholder">
                                <Icon icon="users" color={colors.grey25} spaceLeft />
                              </Tooltip>
                            ),
                          }[resource.resourceType]()}
                        </Member>
                      </Table.Cell>

                      {periods.map((period) => {
                        const value = resourceRecords.periods[period];

                        return (
                          <Table.Cell key={period}>
                            <CellValue value={value} />
                          </Table.Cell>
                        );
                      })}

                      <Table.Cell>
                        <CellValue value={resourceRecords.total} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>

            {periods.map((period) => {
              const value = records.periods[period];

              return (
                <Table.Cell key={period}>
                  <CellValue value={value} />
                </Table.Cell>
              );
            })}

            <Table.Cell>
              <CellValue value={records.total} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          materialPlaceholder="Unit"
          value={filters.unit}
          onChange={({ target: { value } }) =>
            handleFilter({
              unit: value,
              period: {
                day: intervals.past_30_days,
                week: intervals.past_12_weeks,
                month: intervals.past_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>
      </Filter>

      <Filter>
        <PeriodFilter
          clearable={false}
          scope={filters.unit}
          intervals={intervalsByScope[filters.unit]}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <Filter>
        <VarianceBillableTypeFilter
          value={filters.varianceBillableTypes}
          onChange={({ target: { value } }) => handleFilter({ varianceBillableTypes: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <ResourceFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

const TooltipContainer = styled.div`
  min-width: 14rem;
  padding: 0.75rem 0;
`;

const TooltipInfo = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const TooltipLabel = styled.div`
  width: 7rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: wrap;
`;

const TooltipValue = styled.div`
  white-space: wrap;
`;

function TooltipHours({ value }) {
  return (
    <>
      <Hours value={value} /> {_.isNumber(value) && pluralize('hours', value)}
    </>
  );
}

function CellValue({ value }) {
  if (!value) return <Hours value={null} />;

  return (
    <Tooltip
      message={
        <TooltipContainer>
          <TooltipInfo>
            <TooltipLabel>Worked</TooltipLabel>
            <TooltipValue>
              <TooltipHours value={value.worked} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Planned</TooltipLabel>
            <TooltipHours value={value.planned} />
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Variance</TooltipLabel>
            <TooltipValue style={{ color: value.variance < 0 ? colors.danger : colors.black }}>
              <TooltipHours value={value.variance} />
            </TooltipValue>
          </TooltipInfo>

          <TooltipInfo>
            <TooltipLabel>Variance %</TooltipLabel>
            <TooltipValue style={{ color: value.variancePercentage < 0 ? colors.danger : colors.black }}>
              <Percent value={value.variancePercentage} />
            </TooltipValue>
          </TooltipInfo>
        </TooltipContainer>
      }>
      <span style={{ color: value.variance < 0 ? colors.danger : colors.black }}>
        <Hours value={value.variance} />
      </span>
    </Tooltip>
  );
}
