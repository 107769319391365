import {
  ActionButton,
  ButtonBadge,
  ClientProjectSelect,
  FiltersBar,
  Level,
  MemberSelect,
  Page,
  PracticeSelect,
  SingleSelect,
  SplitButton,
  YesNoFilter,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useFeatures, useForm, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import ApprovalsPeriodFilter from './ApprovalsPeriodFilter.jsx';
import ExpenseItemDrawer from '../item/ExpenseItemDrawer';
import ExpenseApprovalResults from './ExpenseApprovalResults';
import RejectExpenseDialog from './RejectExpenseDialog';

const Actions = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }

  & > * {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const handlers = {
  load: () => ({ action: 'load' }),
  refetch: () => ({ action: 'refetch' }),
  ready: ({ data }) => ({
    isReady: true,
    action: null,
    data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params },
    searchParamsStatus: 'ready',
  }),
  updateItems: (items, { data, query }) => ({
    data: data
      .map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        return item ? { ...result, ...item } : result;
      })

      // Remove items from the queue if the status doesn't match the filter. This is mostly to
      // clear the queue when an approval action is taken.
      // This may eventually require a refetch, to exclude items based on other properties
      // (which may have changed when using the expense item drawer).
      .filter((result) => !query.status || result.statusId === query.status),
  }),
  removeItem: (id, { data }) => ({
    data: data.filter((i) => i.id !== id),
  }),
};

function ExpenseApprovalsPage({ parentUrl }) {
  const documentTitle = useDocumentTitle('Expense Approvals');

  const { workspace } = useWorkspace();
  const features = useFeatures();

  const initialState = useMemo(
    () => ({
      isReady: false,
      searchParamsStatus: 'pending',
      data: null,
      query: {
        period: {
          start: null,
          end: null,
        },
        project: null,
        member: null,
        memberPractice: null,
        approver: workspace.member,
        includeLockedItems: 'no',
      },
      action: 'load',
    }),
    [workspace.member],
  );

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);
  const [selection, setSelection] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [{ isSubmitting, saved }, form] = useForm();
  const { expenseItemId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const route = useRouteMatch();
  const api = useApi();
  const toast = useToast();
  const confirmation = useConfirmation();
  const { notify } = useSubscription();

  const searchParamsConfig = useSearchParamsConfig();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: searchParamsConfig.approvalsPeriod,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
        project: searchParamsConfig.project,
        member: searchParamsConfig.member,
        memberPractice: searchParamsConfig.practice,
        approver: {
          default: initialState.query.approver,
          serialize: (value) => value?.id ?? 'all',
          deserialize: searchParamsConfig.member.deserialize,
        },
        includeLockedItems: { default: initialState.query.includeLockedItems, valid: ['yes', 'no'] },
      }),
      [initialState.query, searchParamsConfig],
    ),
    sessionKey: 'expense_approvals',
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = query.period || {};

      const params = {
        ..._.omit(query, ['project', 'member', 'memberPractice', 'approver', 'period', 'includeLockedItems']),
        projectId: query.project?.id,
        memberId: query.member?.id,
        memberPracticeId: query.memberPractice?.id,
        approverId: query.approver?.id,
        start: start ?? undefined,
        end: end ?? undefined,
        includeLockedItems: query.includeLockedItems ?? undefined,
      };

      const { data } = await api.www.workspaces(workspace.id).expenseAdmin().approvals(params);

      actions.ready({ data });
      return data;
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, query, api]);

  const refetchData = async () => {
    actions.refetch();
    const data = await fetchData();
    const ids = data.map(({ id }) => id);
    setSelection(selection.filter((s) => ids.includes(s)));
  };

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = ({ target }) => {
    actions.setParams({ [target.name]: target.value });
    setSelection([]);
    searchParams.set({ [target.name]: target.value });
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleItemStatusChange = async (item, statusId) => {
    try {
      let notes;
      if (statusId === 'rejected') {
        notes = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={1} onResolve={(notes) => resolve(notes)} />
        ));
        if (!notes) return;
      }

      form.submit(item.id);

      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .batchUpdateStatus([{ ids: [item.id], statusId, notes }]);

      actions.updateItems(data);
      refetchData();

      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleBatchStatusChange = async (statusId) => {
    try {
      let notes;
      if (statusId === 'rejected') {
        notes = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={selection.length} onResolve={(notes) => resolve(notes)} />
        ));
        if (!notes) return;
      }

      form.submit('batch');

      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .batchUpdateStatus([{ ids: selection, statusId, notes }]);

      actions.updateItems(data);
      refetchData();

      toast.success(`${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${data.length} expense items.`);
      setSelection([]);
      form.save();
      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleGroupAction = async (group, statusId) => {
    const ids = group.items.map((item) => item.id);

    try {
      let groupActions;

      switch (statusId) {
        case 'rejected': {
          let notes;
          if (statusId === 'rejected') {
            notes = await confirmation.prompt((resolve) => (
              <RejectExpenseDialog count={ids.length} onResolve={(notes) => resolve(notes)} />
            ));
            if (!notes) return;
          }

          groupActions = [{ ids: group.items.map((item) => item.id), statusId, notes }];
          break;
        }

        default:
          groupActions = [{ statusId, ids: group.items.map((item) => item.id) }];
      }

      form.submit({ action: 'group', group });

      const { data } = await api.www.workspaces(workspace.id).expenseAdmin().batchUpdateStatus(groupActions);

      actions.updateItems(data);
      refetchData();

      toast.success(`${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${data.length} expense items.`);
      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleResultClick = (item) => {
    history.push({ pathname: `${route.url}/item/${item.id}`, search: location.search, state: { scrollToTop: false } });
  };

  const handleCloseDrawer = () => {
    history.push({ pathname: parentUrl, search: location.search, state: { scrollToTop: false } });
    documentTitle.set('Expense Approvals');
  };

  const handleItemSaved = (item) => {
    actions.updateItems([item]);
    refetchData();
  };

  const handleItemDeleted = (item) => {
    actions.removeItem(item.id);
    refetchData();
    handleSelectionChange(selection.filter((s) => s !== item.id));
    handleCloseDrawer();
  };

  // Actions are only enabled if it's an approver's queue.
  const isApproverQueue = !!query.approver;

  if (!isReady) return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Expenses</Page.Eyebrow>
            <Page.Title>Expense Approvals</Page.Title>
          </Page.Info>
        </Page.Header>

        <Page.Section style={{ zIndex: 4 }}>
          <FiltersBar>
            <ApprovalsPeriodFilter name="period" maxDays={180} value={query.period} onChange={handleFilterChange} />

            <ClientProjectSelect
              name="project"
              placeholder="All"
              materialPlaceholder="Project"
              materialAlwaysVisible
              assignedOnly={false}
              value={query.project}
              onChange={handleFilterChange}
            />

            <MemberSelect
              name="approver"
              placeholder="All"
              materialPlaceholder="Approver"
              materialAlwaysVisible
              value={query.approver}
              onChange={handleFilterChange}
            />

            <MemberSelect
              name="member"
              placeholder="All"
              materialPlaceholder="Member"
              materialAlwaysVisible
              value={query.member}
              onChange={handleFilterChange}
            />

            {features.practices && (
              <PracticeSelect
                name="memberPractice"
                placeholder="All"
                materialPlaceholder="Member Practice"
                materialAlwaysVisible
                value={query.memberPractice}
                onChange={handleFilterChange}
              />
            )}

            <SingleSelect
              name="includeLockedItems"
              materialPlaceholder="Include Locked Items"
              materialAlwaysVisible
              value={query.includeLockedItems}
              onChange={handleFilterChange}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </SingleSelect>

            <YesNoFilter
              materialPlaceholder="Show Notes"
              value={showNotes ? 'yes' : 'no'}
              onChange={({ target: { value } }) => setShowNotes(value === 'yes')}
            />
          </FiltersBar>
        </Page.Section>

        <ExpenseApprovalResults
          results={data}
          selection={selection}
          showNotes={showNotes}
          isSubmitting={isSubmitting}
          isApproverQueue={isApproverQueue}
          onResultClick={handleResultClick}
          onSelectionChange={handleSelectionChange}
          onStatusChange={handleItemStatusChange}
          onGroupAction={handleGroupAction}
          onChange={refetchData}
          action={action}
        />
      </Page>

      {selection?.length > 0 && (
        <Actions>
          <Level right>
            <Level.Item>
              <SplitButton>
                <ActionButton
                  isLoading={isSubmitting === 'batch'}
                  ok={saved}
                  onClick={() => handleBatchStatusChange('approved')}>
                  Approve <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
                </ActionButton>

                <SplitButton.Menu position="top" disabled={selection.length === 0}>
                  {({ setIsOpen }) => (
                    <SplitButton.Item onClick={() => setIsOpen(false) || handleBatchStatusChange('rejected')}>
                      Reject
                    </SplitButton.Item>
                  )}
                </SplitButton.Menu>
              </SplitButton>
            </Level.Item>
          </Level>
        </Actions>
      )}

      {expenseItemId && (
        <ExpenseItemDrawer onSaved={handleItemSaved} onDeleted={handleItemDeleted} onClose={handleCloseDrawer} />
      )}
    </>
  );
}

export default ExpenseApprovalsPage;
