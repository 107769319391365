import React from 'react';
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { useAuth, useFeatures } from '~/hooks';
import ExpenseReportsArea from './expenses/ExpenseReportsArea';
import FinancialReportsArea from './financial/FinancialReportsArea';
import ForecastReportsArea from './forecast/ForecastReportsArea';
import PipelineReportsArea from './pipeline/PipelineReportsArea';
import TimeReportsArea from './time/TimeReportsArea';
import UtilizationReportsArea from './utilization/UtilizationReportsArea';
import WorkspaceReportsArea from './workspace/WorkspaceReportsArea';

function ReportsArea() {
  const { path, url } = useRouteMatch();
  const { search } = useLocation();
  const auth = useAuth();
  const features = useFeatures();

  const legacyRoutesRedirect = [
    ['/forecast', '/plan-and-forecast'],
    ['/forecast/hours-forecast-by-project-and-resource', '/plan-and-forecast/hours-plan-by-project-and-resource'],
    ['/forecast/hours-forecast-by-resource-and-project', '/plan-and-forecast/hours-plan-by-resource-and-project'],
    ['/forecast/performance-forecast-by-project', '/plan-and-forecast/performance-plan-by-project'],
    [
      '/forecast/services-revenue-forecast-by-client-and-project',
      '/plan-and-forecast/services-revenue-plan-by-client-and-project',
    ],
    ['/forecast/utilization-forecast-by-member', '/plan-and-forecast/utilization-plan-by-member'],
    ['/forecast/utilization-forecast-by-member-practice', '/plan-and-forecast/utilization-plan-by-member-practice'],
    ['/variance', '/plan-and-forecast'],
    ['/variance/hours-variance-by-member-and-project', '/plan-and-forecast/hours-variance-by-member-and-project'],
    ['/variance/hours-variance-by-project-and-resource', '/plan-and-forecast/hours-variance-by-project-and-resource'],
    ['/variance/hours-variance-by-project-and-role', '/plan-and-forecast/hours-variance-by-project-and-role'],
  ];

  return (
    <Switch>
      <Route path={path.concat('/time')}>
        <TimeReportsArea />
      </Route>

      <Route path={path.concat('/expenses')}>
        <ExpenseReportsArea />
      </Route>

      {features.utilization && (
        <Route path={path.concat('/utilization')}>
          <UtilizationReportsArea />
        </Route>
      )}

      {auth.reports.planAndForecast && (
        <Route path={path.concat('/plan-and-forecast')}>
          <ForecastReportsArea />
        </Route>
      )}

      {auth.reports.pipeline && (
        <Route path={path.concat('/pipeline')}>
          <PipelineReportsArea />
        </Route>
      )}

      <Route path={path.concat('/financial')}>
        <FinancialReportsArea />
      </Route>

      <Route path={path.concat('/workspace')}>
        <WorkspaceReportsArea />
      </Route>

      {legacyRoutesRedirect.map(([from, to]) => (
        <Redirect key={from} from={path.concat(from)} to={url.concat(to).concat(search)} exact />
      ))}

      <Redirect from="/" to={url.concat('/time')} />
    </Switch>
  );
}

export default ReportsArea;
