import { useCurrencyFormat, useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const TopPadding = {
  beforeInit: function (chart) {
    chart.legend.afterFit = function () {
      this.height = this.height + 10;
    };
  },
};

function getActualDataset(data, project) {
  if (!data.actual.dates.length) return null;

  let rollingTotal = 0;
  let points = _.reduce(
    data.actual.dates,
    (result, value) => {
      rollingTotal = _.round(rollingTotal + value.revenue, 2);
      result.push({ x: value.date, y: rollingTotal, tooltip: true });
      return result;
    },
    [],
  );

  let end = moment(points[points.length - 1]?.x);

  if (data.forecast?.dates.length > 0) {
    if (end.isBefore(moment(), 'day')) end = moment();

    points.push({
      x: moment().format(dateFormats.isoDate),
      y: points[points.length - 1]?.y ?? 0,
      tooltip: false,
    });
  }

  const getEndDate = () => {
    const lastRevRecDate = moment(points[points.length - 1].x);
    const today = moment().startOf('day');
    const projectEnd = moment(project.end).startOf('day');
    let dateToReturn = projectEnd;

    if (today.isBefore(projectEnd)) dateToReturn = today;
    if (projectEnd.isBefore(lastRevRecDate)) dateToReturn = lastRevRecDate;

    return dateToReturn.format(dateFormats.isoDate);
  };

  switch (project.billingTypeId) {
    case 'fixed':
    case 'fixed_recurring': {
      // The points are "duplicated" to create rectangular lines instead of diagonal lines.
      // This is because the revenue isn't "progressive". Instead, it's recognized on given dates.
      // For T&M this will likely be smoothened since the revenue is recognized as time is tracked.
      // But for Fixed Fee projects the revenue may be recognized on dates that are spread apart,
      // and using diagonal lines would give the impression that the revenue is recognized
      // gradually between the two dates (which is inaccurate).

      const ffPoints = [];

      for (let index = 0; index < points.length; index++) {
        ffPoints.push(points[index]);

        if (index + 1 < points.length) {
          ffPoints.push({ x: points[index + 1].x, y: points[index].y, tooltip: false });
        }
      }

      points = [...ffPoints];

      // Setting the initial vertical and ending horizontal lines.
      points.unshift({ x: points[0].x, y: 0, tooltip: false });
      points.push({ x: getEndDate(), y: points[points.length - 1]?.y ?? 0, tooltip: false });
      break;
    }
  }

  return {
    id: 'revenue',
    label: 'Services Revenue      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    tension: 0,
    pointHitRadius: 10,
  };
}

function getForecastDataset(data, project) {
  if (!data.forecast?.dates.length) return null;

  let rollingTotal = data.actual.revenue;
  let points = _.reduce(
    data.forecast.dates,
    (result, value) => {
      rollingTotal = _.round(rollingTotal + value.revenue, 2);
      result.push({ x: value.date, y: rollingTotal, tooltip: true });
      return result;
    },
    [],
  );

  let start = moment(points[0]?.x);
  if (start.isAfter(moment(), 'day')) start = moment();

  if (data.actual.dates.length > 0) {
    points.unshift({ x: moment().format(dateFormats.isoDate), y: data.actual.revenue, tooltip: true });
  }

  switch (project.billingTypeId) {
    case 'fixed':
    case 'fixed_recurring': {
      // The points are "duplicated" to create rectangular lines instead of diagonal lines.
      // This is because the revenue isn't "progressive". Instead, it's recognized on given dates.
      // For T&M this will likely be smoothened since the revenue is recognized as time is tracked.
      // But for Fixed Fee projects the revenue may be recognized on dates that are spread apart,
      // and using diagonal lines would give the impression that the revenue is recognized
      // gradually between the two dates (which is inaccurate).

      const ffPoints = [];

      for (let index = 0; index < points.length; index++) {
        ffPoints.push(points[index]);

        if (index + 1 < points.length) {
          ffPoints.push({ x: points[index + 1].x, y: points[index].y, tooltip: false });
        }
      }

      points = ffPoints;
      break;
    }
  }

  return {
    id: 'forecasted',
    label: 'Forecasted Services Revenue      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary25,
    borderColor: colors.primary25,
    tension: 0,
    borderDash: [7],
    borderWidth: 3,
    pointHitRadius: 10,
  };
}

function getTMBasisDataset(data) {
  if (!data.tmBasis?.actual.dates?.length) return null;

  let rollingTotal = 0;
  let points = _.reduce(
    data.tmBasis.actual.dates,
    (result, value) => {
      rollingTotal = _.round(rollingTotal + value.revenue, 2);
      result.push({ x: value.date, y: rollingTotal, tooltip: true });
      return result;
    },
    [],
  );

  let end = moment(points[points.length - 1]?.x);

  if (data.forecast?.dates.length > 0) {
    if (end.isBefore(moment(), 'day')) end = moment();

    points.push({
      x: moment().format(dateFormats.isoDate),
      y: points[points.length - 1]?.y ?? 0,
      tooltip: false,
    });
  }

  if (data.tmBasis.forecast?.dates.length > 0) {
    if (end.isBefore(moment(), 'day')) end = moment();

    points.push({
      x: moment().format(dateFormats.isoDate),
      y: points[points.length - 1]?.y ?? 0,
      tooltip: false,
    });
  }

  return {
    id: 'tm_basis',
    label: 'Time & Materials Basis      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.grey25,
    borderColor: colors.grey25,
    tension: 0,
    pointHitRadius: 10,
  };
}

function getTMBasisForecastedDataset(data) {
  if (!data.tmBasis?.forecast?.dates?.length) return null;

  let rollingTotal = data.tmBasis?.actual.revenue;
  let points = _.reduce(
    data.tmBasis.forecast.dates,
    (result, value) => {
      rollingTotal = _.round(rollingTotal + value.revenue, 2);
      result.push({ x: value.date, y: rollingTotal, tooltip: true });
      return result;
    },
    [],
  );

  let start = moment(points[0]?.x);
  if (start.isAfter(moment(), 'day')) start = moment();

  if (data.tmBasis.actual.dates.length > 0) {
    points.unshift({ x: moment().format(dateFormats.isoDate), y: data.tmBasis.actual.revenue, tooltip: true });
  }

  return {
    id: 'tm_basis_forecasted',
    label: 'Forecasted Time & Materials Basis      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.grey10,
    borderColor: colors.grey10,
    tension: 0,
    borderDash: [7],
    borderWidth: 3,
  };
}

export default function ServicesRevenueProgressChart({ data, project }) {
  const [version, setVersion] = useState(0);

  // Set a new version when the chart metrics change
  useEffect(() => {
    setVersion((version) => version + 1);
  }, [data, project.start, project.end]);

  const currencyFormat = useCurrencyFormat({
    currency: project.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const dateTimeFormat = useDateTimeFormat();

  const { chartData, chartConfig } = useMemo(() => {
    const chartData = {
      datasets: _.compact([
        getActualDataset(data, project),
        getForecastDataset(data, project),
        getTMBasisDataset(data),
        getTMBasisForecastedDataset(data),
      ]),
    };

    const chartConfig = {
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },

        plugins: {
          legend: {
            display: true,
            position: 'top',
            onClick: null,
            padding: 12,
            labels: {
              font: {
                size: 12,
              },
              usePointStyle: true,
              pointStyleWidth: 14,
              boxHeight: 10,
            },
          },

          tooltip: {
            mode: 'nearest',
            intersect: true,
            filter: (point) => {
              return point.raw.tooltip;
            },
            callbacks: {
              title: ([tooltip]) => {
                if (!tooltip) return;
                return dateTimeFormat.format(tooltip.raw.x);
              },
              label: (tooltip) => {
                let label = (tooltip.dataset.label || '').trim();
                if (label) {
                  label += ': ';
                }
                label += currencyFormat.format(tooltip.parsed.y);
                return label;
              },
            },
          },

          annotation: {
            annotations: {},
          },
        },

        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            ticks: {
              maxTicksLimit: 10,
            },

            time: {
              unit: 'week',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM DD',
                quarter: 'MMM DD',
                year: 'MMM DD',
              },
            },
          },

          y: {
            grid: { display: false },
            count: 10,
            ticks: {
              font: {
                weight: 'bold',
              },
              color: colors.grey100,
              callback: function (value) {
                return currencyFormat.format(value);
              },
            },
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y',
          },
        },
      },
    };

    let max = data.actual.revenue;

    if (data.forecast) max = max + data.forecast.revenue.left;

    if (data.budget && data.budget.revenue.estimated > 0 && max / data.budget.revenue.estimated > 0.75) {
      max = data.budget.revenue.estimated;
    }

    max = Math.max(max, 1000);

    if (data.budget && data.budget.revenue.estimated > 0 && data.budget.revenue.estimated <= max) {
      // Set budget revenue line
      chartConfig.options.plugins.annotation.annotations.budget = {
        type: 'line',
        scaleID: 'y',
        value: data.budget.revenue.estimated,
        borderColor: rgba(colors.danger, 0.5),
        borderWidth: 3,
        borderDash: [3],
        label: {
          display: true,
          content: `Budget: ${currencyFormat.format(data.budget.revenue.estimated)}`,
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          z: 1,
        },
      };
    }

    chartConfig.options.scales.y.suggestedMin = 0;
    chartConfig.options.scales.y.suggestedMax = max;

    // Add a vertical line on today's date
    chartConfig.options.plugins.annotation.annotations.today = {
      type: 'line',
      mode: 'vertical',
      scaleID: 'x',
      value: moment().format(dateFormats.isoDate),
      borderWidth: 3,
      borderColor: rgba(colors.grey75, 0.5),
      label: {
        content: 'Today',
        display: true,
        position: 'start',
        padding: { top: 4, bottom: 2, left: 6, right: 6 },
        backgroundColor: colors.grey55,
        font: { weight: 'normal' },
        yAdjust: -4,
      },
    };

    let start = moment.min(
      _.compact([_.first(data.actual.dates)?.date, _.first(data.forecast?.dates)?.date, project.start])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!start.isValid()) start = moment();

    chartConfig.options.scales.x.min = start.startOf('isoWeek').format(dateFormats.isoDate);

    if (project.start) {
      chartConfig.options.plugins.annotation.annotations.start = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: moment(project.start).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.success, 0.5),
        label: {
          content: 'Start',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.success,
          font: { weight: 'normal' },
          xAdjust: -6,
          yAdjust: -4,
        },
      };
    }

    let end = moment.max(
      _.compact([_.last(data.actual.dates)?.date, _.last(data.forecast?.dates)?.date, project.end])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!end.isValid()) end = moment();

    chartConfig.options.scales.x.max = end.endOf('isoWeek').add(1, 'day').format(dateFormats.isoDate);

    if (project.end) {
      chartConfig.options.plugins.annotation.annotations.end = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: moment(project.end).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.danger, 0.5),
        label: {
          content: 'End',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          textAlign: 'center',
          xAdjust: -8,
          yAdjust: -4,
        },
      };
    }

    return { chartConfig, chartData };
  }, [data, project, currencyFormat, dateTimeFormat]);

  return <Line key={version} data={chartData} options={chartConfig.options} plugins={[TopPadding]} />;
}
