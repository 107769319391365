import _ from 'lodash';
import React from 'react';
import { InvoiceStatusFilter } from '~/components';
import InvoiceSentFilter from '~/components/filters/InvoiceSentFilter';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

export default function InvoiceFiltersGroup({ filters, onChange }) {
  return (
    <FiltersGroup
      label="Invoice Filters"
      filters={[
        filters.invoiceServicesThroughPeriod?.start,
        filters.invoiceServicesThroughPeriod?.end,
        filters.statuses,
        filters.invoiceSent,
      ]}>
      {_.has(filters, 'invoiceServicesThroughPeriod') && (
        <Filter>
          <ReportPeriodFilter
            value={filters.invoiceServicesThroughPeriod}
            label="Services Through Date Range"
            onChange={({ target: { value } }) => onChange({ invoiceServicesThroughPeriod: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'statuses') && (
        <Filter>
          <InvoiceStatusFilter
            value={filters.statuses}
            onChange={({ target: { value } }) => onChange({ statuses: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'invoiceSent') && (
        <Filter>
          <InvoiceSentFilter
            value={filters.invoiceSent}
            onChange={({ target: { value } }) => onChange({ invoiceSent: value })}
          />
        </Filter>
      )}
    </FiltersGroup>
  );
}
