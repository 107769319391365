import { Percent, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import MarginTooltip from '../components/MarginTooltip';
import {
  Box,
  Boxes,
  ChartContainer,
  Label,
  LowerBoxes,
  Metric,
  SmallBox,
  UpperBox,
  Value,
} from '../components/StyledComponents';
import ViewNav from '../components/ViewNav';
import TotalMarginByMonthChart from './TotalMarginByMonthChart';
import TotalMarginProgressChart from './TotalMarginProgressChart';

export default function TotalMarginWidget({ project, view, onViewChange }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).dashboard().progress.totalProfit();
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const boxes = {
    budget: !!data?.budget,
    forecast: data?.forecast?.dates.length > 0,
    get any() {
      return this.budget || this.forecast;
    },
  };

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '28.8rem' }} />
      <Widget.Content>
        <ViewNav view={view} onViewChange={onViewChange} />

        {data && (
          <>
            <ChartContainer>
              {data &&
                {
                  progress: () => <TotalMarginProgressChart project={project} data={data} />,
                  month: () => <TotalMarginByMonthChart project={project} data={data} />,
                }[view]()}
            </ChartContainer>

            <Boxes>
              <Box>
                <UpperBox>
                  <Value>
                    <Percent value={data.actual.margin} minimumFractionDigits={0} maximumFractionDigits={2} />
                  </Value>
                  <Label>Total Gross Margin</Label>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.budget && (
                      <SmallBox>
                        <Metric status={data.budget.margin.status}>
                          <MarginTooltip value={data.budget.margin.difference}>
                            <Value>
                              <Percent
                                value={data.budget.margin.estimated}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                              />
                            </Value>
                            <Label>Budget</Label>
                          </MarginTooltip>
                        </Metric>
                      </SmallBox>
                    )}

                    {boxes.forecast && (
                      <SmallBox>
                        <Metric status={data.forecast.margin.status}>
                          <MarginTooltip value={data.forecast.margin.difference}>
                            <Value>
                              <Percent
                                value={data.forecast.margin.forecasted}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                              />
                            </Value>
                            <Label>Forecast</Label>
                          </MarginTooltip>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>
            </Boxes>
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}
