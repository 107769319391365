import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import { AdminSessionProvider, ImpersonationProvider, useAdminSession } from './contexts';
import Dashboards from './dashboards';
import Emails from './emails';
import Login from './login/Login';
import Members from './members';
import Reports from './reports';
import Workspaces from './workspaces/Workspaces';
import WorkspaceRequests from './workspace-requests';
import Xero from './xero';

function Admin() {
  return (
    <AdminSessionProvider>
      <ImpersonationProvider>
        <AdminRoutes />
      </ImpersonationProvider>
    </AdminSessionProvider>
  );
}

function AdminRoutes() {
  const { isReady, isLoggedIn } = useAdminSession();
  const { path } = useRouteMatch();

  if (!isReady) return <PageLoader />;

  if (!isLoggedIn)
    return (
      <>
        <Route exact path={`${path}/login`}>
          <Login />
        </Route>
        <Redirect to={`${path}/login`} />
      </>
    );

  return (
    <Switch>
      <Route path={`${path}/dashboards`}>
        <Dashboards />
      </Route>
      <Route path={`${path}/reports`}>
        <Reports />
      </Route>
      <Route path={`${path}/workspace-requests`}>
        <WorkspaceRequests />
      </Route>
      <Route path={`${path}/workspaces`}>
        <Workspaces />
      </Route>
      <Route path={`${path}/members`}>
        <Members />
      </Route>
      <Route path={`${path}/emails`}>
        <Emails />
      </Route>
      <Route path={`${path}/xero`}>
        <Xero />
      </Route>
      <Redirect path={path} to={`${path}/dashboards`} />
    </Switch>
  );
}

export default Admin;
