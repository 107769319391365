import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink as BaseNavLink, Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import logo from '~/assets/logo.svg';
import { Icon } from '~/components';
import { colors, weights } from '~/styles';
import Impersonation from './Impersonation';
import User from './User';

// Tried to mimic the sizing of the public site header
const Container = styled.div`
  display: flex;
  align-items: center;
  height: 8rem;
  margin-bottom: 1rem;
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const LogoImage = styled.img`
  display: block;
  height: 2.5rem;
`;

const Title = styled.h1`
  margin-left: 0.5rem;
  color: ${colors.primary};
  font-size: 2.25rem;
  font-weight: ${weights.black};
  line-height: 1;
`;

const PageSelect = styled.div`
  display: flex;
  align-items: center;
  height: 4.5rem;
  margin-left: 1rem;
  padding: 0 1rem;
  border: solid 1px ${colors.grey10};
  border-radius: 0.625rem;
  cursor: pointer;
  user-select: none;
`;

const SelectIcon = styled(Icon)`
  color: ${colors.grey25};
`;

const PageTitle = styled.h2`
  margin-left: 0.75rem;
  font-size: 1.5rem;
  font-weight: ${weights.bold};
  line-height: 1;
`;

const Nav = styled.div`
  position: absolute;
  top: 5rem;
  left: 1rem;
  display: none;
  flex-direction: column;
  padding: 0.5rem 0;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey25};
  z-index: 1;
`;

const NavLink = styled(BaseNavLink)`
  margin: 0.25rem 1.5rem;
  color: ${colors.black};
  font-size: 1.25rem;

  &:hover {
    color: ${colors.grey75};
  }

  &.is-active {
    font-weight: ${weights.bold};
  }
`;

const Page = styled.div`
  position: relative;
  width: 19.25rem;
  flex-shrink: 0;

  &:hover ${PageSelect} {
    border-color: ${colors.grey75};
  }

  &:hover ${SelectIcon} {
    color: ${colors.grey75};
  }

  ${({ isOpen = false }) =>
    isOpen &&
    css`
      ${PageSelect} {
        border-color: ${colors.grey75};
      }

      ${SelectIcon} {
        color: ${colors.grey75};
      }

      ${Nav} {
        display: flex;
      }
    `}
`;

export default function Header() {
  const [isOpen, setOpen] = useState(false);
  const selectorElement = useRef();
  const location = useLocation();

  const pageTitle = useMemo(() => {
    const paths = location.pathname.split('/');
    const area = paths.length > 2 ? paths[2] : '';

    switch (area) {
      case 'dashboards':
        return 'Dashboards';
      case 'reports':
        return 'Reports';
      case 'workspace-requests':
        return 'Workspace Requests';
      case 'workspaces':
        return 'Workspaces';
      case 'members':
        return 'Members';
      case 'emails':
        return 'Suppressed Emails';
      case 'xero':
        return 'Xero';
      default:
        return null;
    }
  }, [location.pathname]);

  useEffect(() => {
    function closeMenu(event) {
      // Prevent this firing when the user clicks the selector again
      if (selectorElement.current && selectorElement.current.contains(event.target)) {
        return false;
      }
      setOpen(false);
    }

    // Only attach event listener if menu is open
    if (isOpen) {
      document.addEventListener('mouseup', closeMenu);
    }

    return function cleanup() {
      document.removeEventListener('mouseup', closeMenu);
    };
  }, [isOpen]);

  return (
    <Container>
      <Logo>
        <Link to="/app">
          <LogoImage src={logo} alt="Ruddr" />
        </Link>
      </Logo>
      <Title>
        <Link to="/admin">Admin</Link>
      </Title>
      <Page isOpen={isOpen}>
        <PageSelect ref={selectorElement} onClick={() => setOpen(!isOpen)}>
          <SelectIcon icon="bars" />
          <PageTitle>{pageTitle}</PageTitle>
        </PageSelect>
        <Nav>
          <NavLink to="/admin/dashboards" activeClassName="is-active">
            Dashboards
          </NavLink>
          <NavLink to="/admin/reports" activeClassName="is-active">
            Reports
          </NavLink>
          <NavLink to="/admin/workspace-requests" activeClassName="is-active">
            Workspace Requests
          </NavLink>
          <NavLink to="/admin/workspaces" activeClassName="is-active">
            Workspaces
          </NavLink>
          <NavLink to="/admin/members" activeClassName="is-active">
            Members
          </NavLink>
          <NavLink to="/admin/emails" activeClassName="is-active">
            Suppressed Emails
          </NavLink>
          <NavLink to="/admin/xero" activeClassName="is-active">
            Xero
          </NavLink>
        </Nav>
      </Page>
      <Impersonation />
      <User />
    </Container>
  );
}
