import _ from 'lodash';
import moment from 'moment';
import { darken } from 'polished';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from '~/components';
import { useDateTimeFormat } from '~/hooks';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import intervalOptions, { sortIntervals } from '~/utils/intervalOptions';

const Control = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 2.5rem;
  outline: none;
  box-shadow: none;
  cursor: default;
`;

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: ${colors.black};
  background-color: ${({ disabled }) => (disabled ? colors.grey5 : colors.white)};
  border: solid 1px ${({ hasFocus }) => (hasFocus ? colors.primary : colors.grey10)};
  border-radius: 0.3125rem;
  user-select: none;
`;

const Value = styled.div`
  flex: 1;
  padding: 0 0.25rem;
  overflow: hidden;
`;

const Text = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`;

const Placeholder = styled.span`
  color: ${colors.grey40};
  padding-left: 1rem;
`;

const ClearIndicator = styled(Button)`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  border-left: 1px solid ${colors.grey5};
  height: 100%;
  color: ${colors.primary};
  font-size: 0.875rem;
  border-radius: 0;

  &:hover {
    color: ${darken(0.1, colors.primary)};
  }
`;

const PreviousButton = styled(Button)`
  font-size: 0.75rem;
  margin-left: 1rem;
  color: ${colors.primary};
`;

const NextButton = styled(Button)`
  font-size: 0.75rem;
  margin-left: auto;
  margin-right: 1rem;
  color: ${colors.primary};
`;

export default function PeriodNavFilter({
  materialPlaceholder = 'Date Range',
  materialAlwaysVisible = true,
  placeholder = 'All Dates',
  value,
  intervals = sortIntervals(intervalOptions),
  clearable = true,
  onChange,
}) {
  const dateTimeFormat = useDateTimeFormat();

  const hasValue = value?.start || value?.end;
  const notSet = !value?.start || !value?.end ? true : false;

  const handleArrowClick = (direction) => {
    const { start, end, unit } = value;
    let interval;

    switch (unit) {
      case 'custom':
        {
          if (
            moment(start).isSame(moment(start).startOf('month'), 'day') &&
            moment(end).isSame(moment(start).endOf('month'), 'day')
          ) {
            interval = {
              start: moment(start)
                .add(direction === 'next' ? 1 : -1, 'months')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? 1 : -1, 'months')
                .endOf('months')
                .format(dateFormats.isoDate),
              unit: 'month',
            };
          } else if (
            moment(start).isSame(moment(start).startOf('year'), 'day') &&
            moment(end).isSame(moment(start).endOf('year'), 'day')
          ) {
            interval = {
              start: moment(start)
                .add(direction === 'next' ? 1 : -1, 'years')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? 1 : -1, 'years')
                .format(dateFormats.isoDate),
              unit: 'year',
            };
          } else {
            const customDays = moment(end).diff(moment(start), 'days') + 1 || 1;
            interval = {
              start: moment(start)
                .add(direction === 'next' ? customDays : customDays * -1, 'days')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? customDays : customDays * -1, 'days')
                .format(dateFormats.isoDate),
              unit: 'custom',
            };
          }
        }
        break;
      case 'day':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'days')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'days')
            .format(dateFormats.isoDate),
          unit: 'day',
        };
        break;
      case 'week':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'weeks')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'weeks')
            .format(dateFormats.isoDate),
          unit: 'week',
        };
        break;
      case 'semi_month':
        interval = {
          start: (moment(start).date() < 16
            ? moment(start)
                .add(direction === 'next' ? 0 : -1, 'months')
                .date(16)
            : moment(start)
                .add(direction === 'next' ? 1 : 0, 'months')
                .startOf('month')
          ).format(dateFormats.isoDate),

          end: (moment(end).date() < 16
            ? moment(end)
                .add(direction === 'next' ? 0 : -1, 'months')
                .endOf('month')
            : moment(end)
                .add(direction === 'next' ? 1 : 0, 'months')
                .date(15)
          ).format(dateFormats.isoDate),
          unit: 'semi_month',
        };
        break;
      case 'month':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'months')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'months')
            .endOf('months')
            .format(dateFormats.isoDate),
          unit: 'month',
        };
        break;
      case 'quarter':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'quarters')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'quarters')
            .endOf('quarters')
            .format(dateFormats.isoDate),
          unit: 'quarter',
        };
        break;
      case 'year':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'years')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'years')
            .format(dateFormats.isoDate),
          unit: 'year',
        };
        break;
    }

    const option = _.find(sortIntervals(intervals), (oi) => oi.start === interval.start && oi.end === interval.end);

    onChange({
      start: interval.start,
      end: interval.end,
      unit: option ? option.unit : interval.unit,
      key: option ? option.key : intervalOptions.custom.key,
    });
  };

  const materialPlaceholderValue = useMemo(() => {
    if (!materialPlaceholder) {
      return '';
    } else if (_.isString(materialPlaceholder)) {
      return materialPlaceholder;
    } else if (_.isString(placeholder) && !!placeholder) {
      return placeholder;
    }
    return '';
  }, [placeholder, materialPlaceholder]);

  const text = useMemo(() => {
    if (!hasValue) return null;
    return _.compact([
      value?.start ? dateTimeFormat.format(value.start) : 'Not Set',
      value?.end ? dateTimeFormat.format(value.end) : 'Not Set',
    ]).join(' - ');
  }, [hasValue, value, dateTimeFormat]);

  const isClearable = useMemo(
    () => hasValue && clearable && intervals.some((i) => i.key === 'all_dates'),
    [hasValue, intervals, clearable],
  );

  const handleClear = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const allDates = _.find(intervals, { key: 'all_dates' });
    onChange({
      start: allDates.start,
      end: allDates.end,
      unit: allDates.unit,
      key: allDates.key,
    });
  };

  if (!hasValue) return null;

  return (
    <Control>
      <Box>
        {hasValue ? (
          <>
            <PreviousButton isAnchor onClick={() => handleArrowClick('previous')} disabled={notSet}>
              <Icon icon="angle-left" />
            </PreviousButton>

            <Value>
              <Tooltip message={text} delay={300} placement="top">
                <Text>{text}</Text>
              </Tooltip>
            </Value>

            <NextButton isAnchor onClick={() => handleArrowClick('next')} disabled={notSet}>
              <Icon icon="angle-right" />
            </NextButton>

            {isClearable && (
              <ClearIndicator onMouseDown={(e) => e.stopPropagation()} isAnchor tabIndex={-1} onClick={handleClear}>
                <Icon icon="times" />
              </ClearIndicator>
            )}
          </>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
      </Box>

      {!!materialPlaceholderValue && (
        <MaterialPlaceholder isVisible={materialAlwaysVisible || !!value}>
          {materialPlaceholderValue}
        </MaterialPlaceholder>
      )}
    </Control>
  );
}
