import { useCurrencyFormat, useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const TopPadding = {
  beforeInit: function (chart) {
    chart.legend.afterFit = function () {
      this.height = this.height + 10;
    };
  },
};

function getActualDataset(data) {
  if (!data.actual.dates.length) return null;

  const points = Object.values(
    data.actual.dates.reduce((acc, value) => {
      const month = moment(value.date).startOf('month').format(dateFormats.isoDate);
      acc[month] = acc[month] || { x: month, y: 0 };
      acc[month].y = _.round(acc[month].y + value.revenue, 2);
      return acc;
    }, []),
  );

  return {
    id: 'revenue',
    label: 'Services Revenue      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    tension: 0,
    order: 3,
  };
}

function getForecastDataset(data) {
  if (!data.forecast?.dates.length) return null;

  const points = Object.values(
    data.forecast.dates.reduce((acc, value) => {
      const month = moment(value.date).startOf('month').format(dateFormats.isoDate);
      acc[month] = acc[month] || { x: month, y: 0 };
      acc[month].y = _.round(acc[month].y + value.revenue, 2);
      return acc;
    }, []),
  );

  return {
    id: 'planned',
    label: 'Planned Services Revenue      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary25,
    borderColor: colors.primary25,
    tension: 0,
    borderDash: [7],
    borderWidth: 3,
    order: 4,
  };
}

function getTMBasisDataset(data) {
  if (!data.tmBasis?.actual.dates?.length) return null;

  const currentMonth = moment().startOf('month');

  // Get the first month from actual dates
  const firstMonth = moment(data.tmBasis.actual.dates[0]?.date).startOf('month');

  // Get the last valid month
  const lastValidMonth = currentMonth.clone().subtract(1, 'month');

  // Get points and convert to array
  let points = _.reduce(
    data.tmBasis.actual.dates,
    (acc, { date, revenue }) => {
      const month = moment(date).startOf('month').format(dateFormats.isoDate);
      if (!acc[month]) acc[month] = { x: month, y: 0 };
      acc[month].y = _.round(acc[month].y + revenue, 2);
      return acc;
    },
    {},
  );
  points = Object.values(points);

  // Fill in missing months with 0
  const filledPoints = [];
  let monthCursor = firstMonth;
  while (monthCursor.isSameOrBefore(lastValidMonth)) {
    const formattedMonth = monthCursor.format(dateFormats.isoDate);
    const point = points.find((p) => p.x === formattedMonth) || { x: formattedMonth, y: 0 };
    filledPoints.push(point);
    monthCursor.add(1, 'month');
  }

  // This is here to connect the last actual t&m basis point
  // with the first forecasted t&m basis  point
  if (data.tmBasis?.forecast?.dates?.length) {
    const currentMonthRevenue = data.tmBasis.forecast.dates
      .filter(({ date }) => moment(date).isSame(currentMonth, 'month'))
      .reduce((total, { revenue }) => total + revenue, 0);

    if (currentMonthRevenue) {
      filledPoints.push({
        x: currentMonth.format(dateFormats.isoDate),
        y: _.round(currentMonthRevenue, 2),
        tooltip: false,
      });
    }
  }

  return {
    id: 'tm_basis',
    label: 'Time & Materials Basis      ',
    data: filledPoints,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.grey25,
    borderColor: colors.grey25,
    tension: 0,
    pointRadius: 4,
    order: 1,
    type: 'line',
    z: 200,
  };
}

function getTMBasisForecastedDataset(data) {
  if (!data.tmBasis?.forecast?.dates?.length) return null;

  const points = Object.values(
    data.tmBasis.forecast.dates.reduce((acc, value) => {
      const month = moment(value.date).startOf('month').format(dateFormats.isoDate);
      acc[month] = acc[month] || { x: month, y: 0 };
      acc[month].y = _.round(acc[month].y + value.revenue, 2);
      return acc;
    }, {}),
  );

  return {
    id: 'tm_basis_planned',
    label: 'Planned Time & Materials Basis',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.grey10,
    borderColor: colors.grey10,
    tension: 0,
    pointRadius: 4,
    borderDash: [7],
    type: 'line',
    order: 2,
  };
}

export default function ServicesRevenueByMonthChart({ data, project }) {
  const [version, setVersion] = useState(0);

  // Set a new version when the chart metrics change
  useEffect(() => {
    setVersion((version) => version + 1);
  }, [data, project.start, project.end]);

  const currencyFormat = useCurrencyFormat({
    currency: project.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const dateTimeFormat = useDateTimeFormat({ format: 'MMM YYYY' });

  const { chartData, chartConfig } = useMemo(() => {
    const chartData = {
      datasets: _.compact([
        getActualDataset(data, project),
        getForecastDataset(data, project),
        getTMBasisDataset(data),
        getTMBasisForecastedDataset(data),
      ]),
    };

    const chartConfig = {
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },

        plugins: {
          legend: {
            display: true,
            position: 'top',
            onClick: null,
            padding: 12,
            labels: {
              sort: (a, b) => {
                const order = {
                  'Services Revenue      ': 1,
                  'Planned Services Revenue      ': 2,
                  'Time & Materials Basis      ': 3,
                  'Planned Time & Materials Basis': 4,
                };
                return order[a.text] - order[b.text];
              },
              font: {
                size: 12,
              },
              usePointStyle: true,
              pointStyleWidth: 14,
              boxHeight: 10,
            },
          },

          tooltip: {
            intersect: true,
            filter: (point) => {
              return point.raw.tooltip === undefined ? true : point.raw.tooltip;
            },
            callbacks: {
              title: ([tooltip]) => {
                if (!tooltip) return;
                return dateTimeFormat.format(tooltip.raw.x);
              },
              label: (tooltip) => {
                let label = (tooltip.dataset.label || '').trim();
                if (label) {
                  label += ': ';
                }
                label += currencyFormat.format(tooltip.parsed.y);

                return label;
              },
            },
          },

          annotation: {
            annotations: {},
          },
        },

        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            stacked: true,
            time: {
              unit: 'month',
              minUnit: 'month',
              round: 'month',
              displayFormats: {
                month: 'MMM',
                quarter: 'MMM',
                year: 'MMM',
              },
            },
          },

          y: {
            stacked: false,
            ticks: {
              font: {
                weight: 'bold',
              },
              color: colors.grey100,
              callback: function (value) {
                return currencyFormat.format(value);
              },
            },
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y',
          },
        },
      },
    };

    if (data.monthlyBudget && data.monthlyBudget.revenue.estimated > 0) {
      // Set budget revenue line
      chartConfig.options.plugins.annotation.annotations.budget = {
        type: 'line',
        scaleID: 'y',
        value: data.monthlyBudget.revenue.estimated,
        borderColor: rgba(colors.danger, 0.5),
        borderWidth: 3,
        borderDash: [3],
        label: {
          display: true,
          content: `Monthly Budget: ${currencyFormat.format(data.monthlyBudget.revenue.estimated)}`,
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          z: 1,
        },
      };
    }

    chartConfig.options.scales.y.suggestedMin = 0;

    // Add a vertical line on today's date
    chartConfig.options.plugins.annotation.annotations.today = {
      type: 'line',
      mode: 'vertical',
      scaleID: 'x',
      value: moment().format(dateFormats.isoDate),
      borderWidth: 3,
      borderColor: rgba(colors.grey75, 0.5),
      label: {
        content: 'Today',
        display: true,
        position: 'start',
        padding: { top: 4, bottom: 2, left: 6, right: 6 },
        backgroundColor: colors.grey55,
        font: { weight: 'normal' },
        yAdjust: -4,
      },
    };

    let start = moment.min(
      _.compact([_.first(data.actual.dates)?.date, _.first(data.forecast?.dates)?.date, project.start])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!start.isValid()) start = moment();

    chartConfig.options.scales.x.min = start.startOf('month').format(dateFormats.isoDate);

    if (project.start) {
      chartConfig.options.plugins.annotation.annotations.start = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: moment(project.start).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.success, 0.5),
        label: {
          content: 'Start',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.success,
          font: { weight: 'normal' },
          xAdjust: -6,
          yAdjust: -4,
        },
      };
    }

    let end = moment.max(
      _.compact([_.last(data.actual.dates)?.date, _.last(data.forecast?.dates)?.date, project.end])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!end.isValid()) end = moment();

    chartConfig.options.scales.x.max = end.endOf('month').add(1, 'day').format(dateFormats.isoDate);

    if (project.end) {
      chartConfig.options.plugins.annotation.annotations.end = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: moment(project.end).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.danger, 0.5),
        label: {
          content: 'End',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          textAlign: 'center',
          xAdjust: -8,
          yAdjust: -4,
        },
      };
    }

    return { chartConfig, chartData };
  }, [data, project, currencyFormat, dateTimeFormat]);

  return <Bar key={version} data={chartData} options={chartConfig.options} plugins={[TopPadding]} />;
}
