import {
  Currency,
  DateTime,
  ExportDialog,
  FiltersBar,
  InlineTooltip,
  LinkButton,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  PaymentMethodSelect,
  PracticeSelect,
  SearchInput,
  SingleSelect,
  Spinner,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useActions, useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { QuerySort, dateFormats, mimeTypes } from '~/utils';
import QBOIndicator from '../components/QBOIndicator';
import XeroIndicator from '../components/XeroIndicator';
import ClientPaymentForm from './ClientPaymentForm';
import ClientPaymentView from './ClientPaymentView';
import DeletePaymentDialog from './DeletePaymentDialog';
import LoadPaymentFromQuickBooksDialog from './LoadFromQuickBooksDialog';
import SavePaymentToQuickBooksDialog from './SavePaymentToQuickBooksDialog';
import SavePaymentToXeroDialog from './SavePaymentToXeroDialog';
import ExportDropdown from '../../settings/ExportDropdown.jsx';
import { colors, weights } from '~/styles';

const Small = styled.small`
  display: block;
`;

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    paymentMethod: null,
    practice: null,
    period: null,
    sort: new QuerySort('receivedOn', 'desc'),
    page: 0,
    size: 50,
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItem: (item, { data }) => ({
    data: {
      ...data,
      results: data.results.some((i) => i.id === item.id)
        ? data.results.map((i) => (i.id === item.id ? { ...i, ...item } : i))
        : [...data.results, item],
    },
  }),
  removeItem: (id, { data }) => ({
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

const intervals = {
  this_month: {
    label: 'This Month',
    start: moment().startOf('month').format(dateFormats.isoDate),
    end: moment().endOf('month').format(dateFormats.isoDate),
  },
  this_quarter: {
    label: 'This Quarter',
    start: moment().startOf('quarter').format(dateFormats.isoDate),
    end: moment().endOf('quarter').format(dateFormats.isoDate),
  },
  this_year: {
    label: 'This Year',
    period: 'this_year',
    start: moment().startOf('year').format(dateFormats.isoDate),
    end: moment().endOf('year').format(dateFormats.isoDate),
  },
  last_month: {
    label: 'Last Month',
    start: moment().startOf('month').subtract(1, 'month').format(dateFormats.isoDate),
    end: moment().endOf('month').subtract(1, 'month').format(dateFormats.isoDate),
  },
  last_quarter: {
    label: 'Last Quarter',
    start: moment().subtract(1, 'quarter').startOf('quarter').format(dateFormats.isoDate),
    end: moment().subtract(1, 'quarter').endOf('quarter').format(dateFormats.isoDate),
  },
  last_year: {
    label: 'Last Year',
    period: 'last_year',
    start: moment().startOf('year').subtract(1, 'year').format(dateFormats.isoDate),
    end: moment().endOf('year').subtract(1, 'year').format(dateFormats.isoDate),
  },
};

function ClientPaymentsListPage() {
  const documentTitle = useDocumentTitle('Payments');

  const { workspace } = useWorkspace();
  const api = useApi();

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);

  const auth = useAuth();

  const confirmation = useConfirmation();
  const toast = useToast();

  const history = useHistory();
  const { url, path } = useRouteMatch();
  const location = useLocation();

  const integrations = useIntegrations();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        paymentMethod: searchParamsConfig.paymentMethod,
        period: { valid: _.keys(intervals) },
        practice: searchParamsConfig.practice,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'payments',
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = intervals[query.period] || {};

      const { data } = await api.www
        .workspaces(workspace.id)
        .clientPayments()
        .get({
          ..._.omit(query, 'period', 'practice'),
          start,
          end,
          q: query.q || undefined,
          paymentMethodId: query.paymentMethod?.id,
          practiceId: query.practice?.id,
        });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: { total: 0, results: [] } });
    }
  }, [actions, workspace.id, query, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const features = useFeatures();

  if (!isReady && !data) return <PageLoader />;

  const handleFilter = (value) => {
    actions.setParams({ ...value });
    searchParams.set({ ...value });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleView = async (payment) => {
    history.push({ pathname: `${url}/${payment.id}/view`, search: location.search });
  };

  const handleEdit = async (payment) => {
    history.push({ pathname: `${url}/${payment.id}/edit`, search: location.search });
  };

  const reloadPayment = async (paymentId) => {
    const payment = await api.www
      .workspaces(workspace.id)
      .clientPayments()
      .get({ ids: [paymentId] })
      .then((res) => res.data[0]);

    actions.updateItem(payment);

    return payment;
  };

  const handleDelete = async (payment) => {
    await confirmation.prompt((resolve) => (
      <DeletePaymentDialog
        payment={payment}
        onClose={() => resolve(false)}
        onDelete={() => {
          actions.removeItem(payment.id);
          toast.success(`Payment has been deleted`);
          resolve(true);
        }}
      />
    ));
  };

  const handleCloseDrawer = () => {
    history.push({ pathname: url, search: location.search });
    documentTitle.set('Payments');
  };

  const handleSaveToQuickBooks = async (payment) => {
    await confirmation.prompt((resolve) => (
      <SavePaymentToQuickBooksDialog
        payment={payment}
        resolve={async () => {
          await reloadPayment(payment.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromQuickBooks = async (payment) => {
    await confirmation.prompt((resolve) => (
      <LoadPaymentFromQuickBooksDialog
        payment={payment}
        resolve={async () => {
          await reloadPayment(payment.id);
          resolve();
        }}
      />
    ));
  };

  const handleSaveToXero = async (payment) => {
    await confirmation.prompt((resolve) => (
      <SavePaymentToXeroDialog
        payment={payment}
        resolve={async () => {
          await reloadPayment(payment.id);
          resolve();
        }}
      />
    ));
  };

  const handleExport = async (filename, mimeType) => {
    const { start, end } = intervals[query.period] || {};

    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .clientPayments()
          .export(
            {
              ..._.omit(query, 'period', 'practice'),
              size: null,
              start,
              end,
              q: query.q || undefined,
              paymentMethodId: query.paymentMethod?.id,
              practiceId: query.practice?.id,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Page>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Billing</Page.Eyebrow>
          <Page.Title>Payments</Page.Title>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`payments.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`payments.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <LinkButton disabled={!auth.payments.manage} to={`${url}/new${location.search}`} className="button">
            Receive Payment
            {!auth.payments.manage && (
              <InlineTooltip message="Your security role prohibits you from receiving payments." />
            )}
          </LinkButton>
        </Page.Actions>
      </Page.Header>

      <Page.Section>
        <FiltersBar>
          <SearchInput
            value={query.q}
            placeholder="Search"
            materialPlaceholder="Client Name, Reference # or Invoice #"
            materialAlwaysVisible
            onChange={({ target: { value } }) => handleFilter({ q: value })}
          />

          {features.practices && (
            <PracticeSelect
              name="practice"
              placeholder="All"
              materialPlaceholder="Client Practice"
              materialAlwaysVisible
              value={query.practice}
              onChange={({ target: { value } }) => handleFilter({ practice: value })}
            />
          )}

          <PaymentMethodSelect
            placeholder="All"
            materialPlaceholder="Payment Method"
            materialAlwaysVisible
            value={query.paymentMethod}
            onChange={({ target: { value } }) => handleFilter({ paymentMethod: value })}
          />

          <SingleSelect
            placeholder="All"
            materialPlaceholder="Issue Date"
            materialAlwaysVisible
            showEmptyOption
            value={query.period}
            onChange={({ target: { value } }) => handleFilter({ period: value })}>
            {_.map(intervals, ({ label }, key) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </SingleSelect>
        </FiltersBar>
      </Page.Section>

      <Page.Section>
        <ListView.Status>
          {!!action && <Spinner />}
          <ListView.Total value={data.total} label="Payment" />
        </ListView.Status>
      </Page.Section>

      <ListView>
        <ListView.Header>
          <ListView.Column sticky width="10rem" name="receivedOn" onSort={handleSort} sort={query.sort}>
            Date
          </ListView.Column>
          <ListView.Column minWidth="16rem" name="client.name" onSort={handleSort} sort={query.sort}>
            Client
          </ListView.Column>
          <ListView.Column minWidth="16rem">Invoices</ListView.Column>
          <ListView.Column minWidth="10rem" name="referenceNumber" onSort={handleSort} sort={query.sort}>
            Ref #
          </ListView.Column>
          <ListView.Column width="12rem" name="paymentMethod.name" onSort={handleSort} sort={query.sort}>
            Payment Method
          </ListView.Column>
          <ListView.Column width="10rem" align="right" name="convertedAmount" onSort={handleSort} sort={query.sort}>
            Amount
          </ListView.Column>
          <ListViewActions.Column />
        </ListView.Header>
        <ListView.Body fade={action === 'filter'}>
          {data.results.map((payment) => {
            const { currency, id, receivedOn, referenceNumber, client, amount, convertedAmount, paymentMethod } =
              payment;

            const savedToQuickBooks = integrations.qbo && payment.qboPaymentId;
            const savedToXero = integrations.xero && payment.xeroPaymentId;
            const isEditable = payment.permissions.manage && !savedToQuickBooks && !savedToXero;

            return (
              <ListView.Row key={id} onClick={() => (isEditable ? handleEdit(payment) : handleView(payment))}>
                <ListView.Cell>
                  <DateTime value={receivedOn} />
                </ListView.Cell>
                <ListView.Cell>
                  {client.name}
                  {payment.xeroPaymentId && <XeroIndicator message="This payment is in Xero." />}
                  {payment.qboPaymentId && <QBOIndicator message="This payment is in QuickBooks." />}
                </ListView.Cell>
                <ListView.Cell>
                  <FirstInvoice payment={payment} />
                  <Invoices payment={payment} />
                </ListView.Cell>
                <ListView.Cell>{referenceNumber}</ListView.Cell>
                <ListView.Cell>{paymentMethod?.name}</ListView.Cell>
                <ListView.Cell>
                  <p>
                    <Currency value={convertedAmount} currency={workspace.currency} />
                    {currency !== workspace.currency && (
                      <Small>
                        <Currency value={amount} currency={currency} />
                      </Small>
                    )}
                  </p>
                </ListView.Cell>

                <ListViewActions>
                  {isEditable ? (
                    <ListViewActions.Edit onClick={() => handleEdit(payment)} />
                  ) : (
                    <ListViewActions.View onClick={() => handleView(payment)} />
                  )}

                  <hr />
                  <ListViewMenu>
                    {({ setIsOpen }) => {
                      const handleAction = async (action) => {
                        setIsOpen(false);
                        await action();
                      };

                      return (
                        <>
                          <ListViewMenu.Item onClick={() => handleAction(() => handleView(payment))}>
                            View
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!isEditable}
                            tooltip={
                              !payment.permissions.manage
                                ? 'Insufficient permissions to edit this payment.'
                                : payment.qboPaymentId
                                  ? 'This payment must be edited in QuickBooks.'
                                  : undefined
                            }
                            onClick={() => handleAction(() => handleEdit(payment))}>
                            Edit
                          </ListViewMenu.Item>

                          {integrations.qbo &&
                            (payment.qboPaymentId ? (
                              <ListViewMenu.Item
                                disabled={!payment.permissions.manage}
                                tooltip={
                                  !payment.permissions.manage
                                    ? 'Insufficient permissions to reload this payment from QuickBooks.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleReloadFromQuickBooks(payment))}>
                                Reload from QuickBooks
                              </ListViewMenu.Item>
                            ) : (
                              <ListViewMenu.Item
                                disabled={!payment.permissions.manage || payment.qboStatus !== 'ready'}
                                tooltip={
                                  !payment.permissions.manage
                                    ? 'Insufficient permissions to save this payment to QuickBooks.'
                                    : payment.qboStatus !== 'ready'
                                      ? 'This payment is not ready to save to QuickBooks.'
                                      : undefined
                                }
                                onClick={() => handleAction(() => handleSaveToQuickBooks(payment))}>
                                Save to QuickBooks
                              </ListViewMenu.Item>
                            ))}

                          {integrations.xero && !payment.xeroPaymentId && (
                            <ListViewMenu.Item
                              disabled={!payment.permissions.manage || payment.xeroStatus !== 'ready'}
                              tooltip={
                                !payment.permissions.manage
                                  ? 'Insufficient permissions to save this payment to Xero.'
                                  : payment.xeroStatus !== 'ready'
                                    ? 'This payment is not ready to save to Xero.'
                                    : undefined
                              }
                              onClick={() => handleAction(() => handleSaveToXero(payment))}>
                              Save to Xero
                            </ListViewMenu.Item>
                          )}

                          <ListViewMenu.Item
                            disabled={!payment.permissions.manage}
                            tooltip={
                              !payment.permissions.manage
                                ? 'Insufficient permissions to delete this payment.'
                                : undefined
                            }
                            onClick={() => handleDelete(payment)}>
                            Delete
                          </ListViewMenu.Item>
                        </>
                      );
                    }}
                  </ListViewMenu>
                </ListViewActions>
              </ListView.Row>
            );
          })}

          {data.results.length === 0 && <ListView.Empty />}

          {data.total > data.results.length && (
            <ListView.Loader key={data.results.length} onIntersecting={actions.loadMore} />
          )}
        </ListView.Body>
      </ListView>

      <Switch>
        <Route path={`${path}/new`}>
          <ClientPaymentForm
            onSaved={fetchData}
            onDeleted={(payment) => actions.removeItem(payment.id)}
            onClose={handleCloseDrawer}
          />
        </Route>

        <Route path={`${path}/:paymentId/edit`}>
          <ClientPaymentForm
            onSaved={(payment) => reloadPayment(payment.id)}
            onDeleted={(payment) => actions.removeItem(payment.id)}
            onClose={handleCloseDrawer}
          />
        </Route>

        <Route path={`${path}/:paymentId/view`}>
          <ClientPaymentView onClose={handleCloseDrawer} />
        </Route>
      </Switch>
    </Page>
  );
}

const FirstInvoice = ({ payment }) => {
  const paymentInvoice = payment.paymentInvoices[0];
  if (!paymentInvoice) return null;

  return `Invoice #${paymentInvoice.invoice.number}`;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Invoices = ({ payment }) => {
  let paymentInvoicesCount = payment.paymentInvoices.length - 1;
  if (paymentInvoicesCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Invoices</Title>
          {payment.paymentInvoices.map((paymentInvoice) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={paymentInvoice.invoice.id}>
              <small>Invoice #{paymentInvoice.invoice.number}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{paymentInvoicesCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default ClientPaymentsListPage;
