import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { SingleSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useFeatures, useIsMounted } from '~/hooks';

export default function CurrencyFilter({ value, onChange }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.currencies().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted]);

  const handleChange = (event) => {
    onChange({
      target: {
        name: event.target.name,
        value: event.target.value ?? null,
      },
    });
  };

  const availableOptions = useMemo(() => {
    return options?.filter(
      (o) =>
        o.isActive &&
        (o.code === value ||
          o.code === workspace.currency ||
          (features.multicurrency && workspace.currencies.includes(o.code))),
    );
  }, [options, value, workspace.currency, workspace.currencies, features.multicurrency]);

  const text = useMemo(() => _.find(options, { code: value })?.name, [options, value]);

  return (
    <SingleSelect
      clearable={false}
      materialAlwaysVisible
      materialPlaceholder="Currency"
      value={value}
      valueRenderer={text}
      onChange={handleChange}>
      {availableOptions.map(({ code, name }) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}
