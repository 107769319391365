import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Page } from '~/components';
import { useAuth, useDocumentTitle, useSessionStorage } from '~/hooks';
import NavBox from '../components/NavBox';
import NavSection from '../components/NavSection';

export default function ForecastReportsPage() {
  useDocumentTitle('Plan & Forecast Reports');

  const { url } = useRouteMatch();
  const auth = useAuth();

  const sessionStorage = useSessionStorage({ key: 'forecast_reports' });

  const [sections, setSections] = useState(() => {
    const sections = { forecast: true, plan: true, variance: true };
    try {
      if (sessionStorage.hasValue()) {
        const value = JSON.parse(sessionStorage.get());
        if (value) {
          sections.forecast = !!value.forecast;
          sections.plan = !!value.plan;
          sections.variance = !!value.variance;
        }
      }
    } finally {
      sessionStorage.set(JSON.stringify(sections));
    }
    return sections;
  });

  const toggleSection = (section) => {
    const value = { ...sections, [section]: !sections[section] };
    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  return (
    <Page>
      <NavSection
        title="Forecast"
        subtitle="The combination of past actuals with the future plan in order to generate a performance forecast."
        expanded={sections.forecast}
        onToggle={() => toggleSection('forecast')}>
        {auth.reports.performanceForecastbyProject && (
          <NavBox to={url.concat('/performance-forecast-by-project')}>
            <NavBox.Title>Performance Forecast by Project</NavBox.Title>
            <NavBox.Description>
              A project performance forecast combining past actuals and the future plan. Considers time entries,
              resource allocations, billing schedule milestones, revenue recognition ledger entries, expenses, and other
              items to bill.
            </NavBox.Description>
          </NavBox>
        )}

        {auth.reports.performanceForecastbyProject && (
          <NavBox to={url.concat('/performance-forecast-by-project-by-time-unit')}>
            <NavBox.Title>Performance Forecast by Project by Time Unit</NavBox.Title>
            <NavBox.Description>
              A project performance forecast for a specific timeframe. The report shows starting values prior to the
              reporting period, values for each time unit, and ending forecast values.
            </NavBox.Description>
          </NavBox>
        )}
      </NavSection>

      <NavSection
        title="Plan"
        subtitle="The planned performance based on resource allocations, billing schedule milestones, revenue recognition ledger entries, expenses, and other items to bill. Does not include actuals."
        expanded={sections.plan}
        onToggle={() => toggleSection('plan')}>
        {auth.reports.hoursPlan && (
          <NavBox to={url.concat('/hours-plan-by-project-and-resource')}>
            <NavBox.Title>Hours Plan by Project and Resource</NavBox.Title>
            <NavBox.Description>A summary of allocated hours grouped by project and resource.</NavBox.Description>
          </NavBox>
        )}

        {auth.reports.hoursPlan && (
          <NavBox to={url.concat('/hours-plan-by-resource-and-project')}>
            <NavBox.Title>Hours Plan by Resource and Project</NavBox.Title>
            <NavBox.Description>A summary of allocated hours grouped by resource and project.</NavBox.Description>
          </NavBox>
        )}

        {auth.reports.performancePlanByProject && (
          <NavBox to={url.concat('/performance-plan-by-project')}>
            <NavBox.Title>Performance Plan by Project</NavBox.Title>
            <NavBox.Description>
              A project performance plan. The plan is derived from resource allocations, billing schedule milestones,
              manual revenue recognition ledger entries, expense items, and other items to bill.
            </NavBox.Description>
          </NavBox>
        )}

        {auth.reports.revenuePlan && (
          <NavBox to={url.concat('/services-revenue-plan-by-client-and-project')}>
            <NavBox.Title>Services Revenue Plan by Client and Project</NavBox.Title>
            <NavBox.Description>
              A services revenue plan grouped by client and project. The plan is derived from resource allocations,
              billing schedule milestones, and manual revenue recognition ledger entries.
            </NavBox.Description>
          </NavBox>
        )}

        {auth.reports.utilizationPlanByMember && (
          <NavBox to={url.concat('/utilization-plan-by-member')}>
            <NavBox.Title>Utilization Plan by Member</NavBox.Title>
            <NavBox.Description>The planned utilization based on allocated hours.</NavBox.Description>
          </NavBox>
        )}

        {auth.reports.utilizationPlanByMemberPractice && (
          <NavBox to={url.concat('/utilization-plan-by-member-practice')}>
            <NavBox.Title>Utilization Plan by Member Practice</NavBox.Title>
            <NavBox.Description>
              The planned utilization based on allocated hours by member practice.
            </NavBox.Description>
          </NavBox>
        )}
      </NavSection>

      <NavSection
        title="Variance"
        subtitle="The difference between the planned performance and the actual performance."
        expanded={sections.variance}
        onToggle={() => toggleSection('variance')}>
        {auth.reports.hoursVarianceByMemberAndProject && (
          <NavBox to={url.concat('/hours-variance-by-member-and-project')}>
            <NavBox.Title>Hours Variance by Member and Project</NavBox.Title>
            <NavBox.Description>
              The variance between planned hours and worked hours, grouped by member and project.
            </NavBox.Description>
          </NavBox>
        )}

        {auth.reports.hoursVarianceByProjectAndResource && (
          <NavBox to={url.concat('/hours-variance-by-project-and-resource')}>
            <NavBox.Title>Hours Variance by Project and Resource</NavBox.Title>
            <NavBox.Description>
              The variance between planned hours and worked hours, grouped by project and resource.
            </NavBox.Description>
          </NavBox>
        )}

        {auth.reports.hoursVarianceByProjectAndRole && (
          <NavBox to={url.concat('/hours-variance-by-project-and-role')}>
            <NavBox.Title>Hours Variance by Project and Role</NavBox.Title>
            <NavBox.Description>
              The variance between planned hours and worked hours, grouped by project and role.
            </NavBox.Description>
          </NavBox>
        )}
      </NavSection>
    </Page>
  );
}
