import {
  ActionButton,
  BillableIcon,
  Button,
  Buttons,
  CancelButton,
  Currency,
  DeleteConfirmation,
  Dropdown,
  Field,
  Form,
  Icon,
  InlineTooltip,
  Level,
  ModalCard,
  Radio,
  SingleSelect,
  SplitButton,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { useFeatures, useIsMounted } from '~/hooks';
import ClientRolesModal from './ClientRolesModal';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const AddRoleDropdown = styled(Dropdown)`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  > ${Dropdown.Trigger} {
    display: flex;
    align-items: center;
  }
`;

const AddRoleButton = styled(ActionButton)`
  position: relative;
  margin-left: 0.5rem;
  border-radius: 999rem;
`;

function RolesTable({ project, projectModel, onChange }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const [clientRoles, setClientRoles] = useState([]);

  const [showRolesModal, setShowRolesModal] = useState(false);

  const [formEntry, setEditForm] = useState(null);
  const handleAdd = () => setEditForm({});
  const handleEdit = (entry) => setEditForm(entry);
  const handleCancel = () => setEditForm(null);

  useEffect(() => {
    (async () => {
      if (!features.workspaceRoles) return;

      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .clientRoles({
          currency: projectModel.currency,
          isActive: true,
          isBillable: projectModel.isBillable === false ? false : undefined,
          size: 1000,
          clientId: projectModel.client.id,
        });
      if (!isMounted.current) return;
      setClientRoles(data);
    })();
  }, [
    api,
    isMounted,
    workspace.id,
    project.id,
    projectModel.currency,
    projectModel.isBillable,
    projectModel.client.id,
    features.workspaceRoles,
  ]);

  const [params, setParams] = useState({ isActive: 'active' });

  const handleSave = (value) => {
    value = emptyStringToNull(value);

    const roles = value.id
      ? projectModel.roles.map((pr) =>
          pr.id === value.id
            ? {
                ...pr,
                ...value,
                disciplineId: value.discipline?.id ?? null,
                practiceId: value.practice?.id ?? null,
                locationId: value.location?.id ?? null,
              }
            : pr,
        )
      : [
          ...projectModel.roles,
          {
            ...value,
            id: _.uniqueId('pr_'),
            disciplineId: value.discipline?.id ?? null,
            practiceId: value.practice?.id ?? null,
            locationId: value.location?.id ?? null,
          },
        ];

    onChange(roles);
    handleCancel();
  };

  const addRoles = (clientRoles) => {
    let currentNewIndex = _.reduce(
      projectModel.roles,
      (index, pr) => {
        if (pr.newIndex >= 0 && pr.newIndex > index) {
          return pr.newIndex;
        }
        return index;
      },
      0,
    );

    let projectRoles = [...projectModel.roles];

    for (const clientRole of [clientRoles].flat()) {
      const role = clientRole.workspaceRole ? clientRole.workspaceRole : clientRole;

      const projectRole = {
        id: _.uniqueId('pr_'),
        newIndex: currentNewIndex + 1,
        isActive: true,
        name: role.name,
        isBillable: role.isBillable,
        rate: role.rate,
        discipline: role.discipline,
        disciplineId: role.disciplineId,
        practice: role.practice,
        practiceId: role.practiceId,
        location: role.location,
        locationId: role.locationId,
      };

      projectRoles.unshift(projectRole);
    }

    onChange(projectRoles);
  };

  const handleDelete = (item) => {
    onChange(projectModel.roles.filter((r) => r.id !== item.id));
  };

  const handleFilter = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  const handleAddRoles = async (roles) => {
    setShowRolesModal(false);
    addRoles(roles);
  };

  const roles = useMemo(() => {
    let result = projectModel.roles;
    if (params.isActive != null) {
      result = result.filter((role) => (params.isActive === 'active') === role.isActive);
    }
    return result;
  }, [params.isActive, projectModel.roles]);

  return (
    <>
      <Section>
        <Level>
          <Level.Item width="19.25rem">
            <SingleSelect
              name="isActive"
              value={params.isActive}
              placeholder="All"
              materialPlaceholder="Status"
              materialAlwaysVisible
              onChange={handleFilter}>
              <option value={null}></option>
              <option value={'active'}>Active</option>
              <option value={'inactive'}>Inactive</option>
            </SingleSelect>
          </Level.Item>

          {features.workspaceRoles && !projectModel.client.isInternal && (
            <Level.Item>
              <AddRoleDropdown>
                {({ setIsOpen, isOpen }) => {
                  const actions = {
                    add: {
                      options: clientRoles,

                      get tooltip() {
                        if (clientRoles.length === 0) return 'No client roles exist.';
                        return null;
                      },

                      get disabled() {
                        return !!this.tooltip;
                      },
                    },

                    add_all: {
                      options: clientRoles.filter(
                        (cr) =>
                          !projectModel.roles.some((pr) => {
                            if (features.practices) {
                              return (
                                pr.name === cr.name &&
                                pr.practice?.id === cr.practice?.id &&
                                pr.location?.id === cr.location?.id
                              );
                            }

                            return pr.name === cr.name && pr.location?.id === cr.location?.id;
                          }),
                      ),

                      get tooltip() {
                        if (clientRoles.length === 0) return 'No client roles exist.';
                        if (this.options.length === 0) return 'All client-defined roles are in use.';
                        return null;
                      },

                      get disabled() {
                        return !!this.tooltip;
                      },
                    },
                  };

                  const handleAddAllRoles = () => {
                    setIsOpen(false);
                    addRoles(actions.add_all.options);
                  };

                  return (
                    <>
                      <Dropdown.Trigger>
                        <SplitButton>
                          <AddRoleButton
                            disabled={actions.add.disabled}
                            onClick={() => {
                              setShowRolesModal(true);
                              setIsOpen(!isOpen);
                            }}>
                            Add a Client Role
                            {actions.add.tooltip && <InlineTooltip message={actions.add.tooltip} />}
                          </AddRoleButton>

                          <SplitButton.Menu>
                            {({ setIsOpen }) => (
                              <>
                                <SplitButton.Item
                                  disabled={actions.add_all.disabled}
                                  onClick={() => setIsOpen(false) || handleAddAllRoles()}>
                                  Add all Client Roles
                                  {actions.add_all.tooltip && <InlineTooltip message={actions.add_all.tooltip} />}
                                </SplitButton.Item>
                              </>
                            )}
                          </SplitButton.Menu>
                        </SplitButton>
                      </Dropdown.Trigger>
                    </>
                  );
                }}
              </AddRoleDropdown>
            </Level.Item>
          )}
        </Level>
      </Section>

      <Section>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width={projectModel.isBillable ? '12rem' : null}>Project Role</Table.Column>
            <Table.Column width="12rem" isVisible={features.practices}>
              Practice
            </Table.Column>
            <Table.Column width="12rem">Location</Table.Column>
            <Table.Column width="12rem" isVisible={features.disciplines}>
              Discipline
            </Table.Column>
            <Table.Column isVisible={projectModel.isBillable} align="right">
              Bill Rate
            </Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {roles.map((item) => (
              <RoleRow
                key={item.id}
                project={projectModel}
                projectRole={item}
                onSaved={handleSave}
                onEdit={() => handleEdit(item)}
                onDeleted={handleDelete}
              />
            ))}

            <Table.Row>
              <Table.Cell>
                <Button isAnchor isStrong onClick={handleAdd} data-testid="role-quick-add">
                  <Icon icon="plus" size="xs" spaceRight />
                  Quick Add
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Section>

      {showRolesModal && (
        <ClientRolesModal
          clientRoles={clientRoles}
          onChange={handleAddRoles}
          onClose={() => setShowRolesModal(false)}
        />
      )}

      {formEntry && (
        <ProjectRoleForm
          project={projectModel}
          projectRole={formEntry}
          currency={projectModel.currency}
          onCancel={handleCancel}
          onSubmit={handleSave}
        />
      )}
    </>
  );
}

function RoleRow({ project, projectRole, disableActions, onEdit, onSaved, onDeleted }) {
  async function handleDelete() {
    onDeleted(projectRole);
  }

  async function handleActiveStatusChange(item, flag) {
    onSaved({ id: item.id, discipline: item.discipline, practice: item.practice, isActive: flag });
  }

  return (
    <RoleRowDetails
      project={project}
      projectRole={projectRole}
      disableActions={disableActions}
      onEdit={onEdit}
      onDelete={handleDelete}
      onActiveStatusChange={handleActiveStatusChange}
    />
  );
}

function RoleRowDetails({
  project,
  projectRole,
  projectRole: { id, name, isActive, isBillable, discipline, practice, location, rate },
  disableActions,
  onEdit,
  onDelete,
  onActiveStatusChange,
}) {
  const confirmation = useConfirmation();

  const isActuallyBillable = project.isBillable && isBillable;
  const actualRate = isActuallyBillable ? rate : null;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this project role?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow isDisabled={!isActive}>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{practice?.name}</Table.Cell>
      <Table.Cell>{location?.name}</Table.Cell>
      <Table.Cell>{discipline?.name}</Table.Cell>
      <Table.Cell>
        <Currency value={actualRate} maximumFractionDigits={7} currency={project.currency} />
        <BillableIcon style={{ marginLeft: '0.4rem' }} value={project.isBillable && isBillable} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Dropdown disabled={disableActions}>
          {({ setIsOpen }) => (
            <>
              <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onActiveStatusChange(projectRole, !isActive);
                  setIsOpen(false);
                }}>
                {isActive ? 'Deactivate' : 'Activate'}
              </Dropdown.Item>
              <Dropdown.DeleteItem
                tooltip="This project role is currently in use."
                onCheckDependencies={async (workspace) =>
                  // Disable if the role is assigned to a member or if it has dependencies in the database
                  project.members.some((pm) => pm.roles.some((pmr) => pmr.projectRoleId === id)) ||
                  (!id.startsWith('pr_') && (await workspace.projects(project.id).roles(id).hasDependencies()).data)
                }
                onClick={handleDelete}>
                Delete
              </Dropdown.DeleteItem>
            </>
          )}
        </TableBoxRowActions.Dropdown>
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function ProjectRoleForm({ project, projectRole, onSubmit, onCancel }) {
  const features = useFeatures();

  const initialValues = mergeValues(
    {
      discipline: null,
      practice: null,
      location: null,
      id: null,
      isBillable: project.isBillable,
      isActive: true,
      name: '',
      rate: '',
    },
    projectRole,
  );

  return (
    <>
      <ModalCard title={projectRole.id ? 'Edit Project Role' : 'Add Project Role'} onClose={onCancel}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            isBillable: Yup.bool().label('Is Billable'),
            name: Yup.string().label('Name').max(255).required(),
            rate: Yup.number().label('Rate').min(0).max(99999999999).nullable(),
          })}>
          {(formik) => {
            return (
              <Form>
                <ModalCard.Body>
                  <Form.Control>
                    <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                  </Form.Control>
                  {features.practices && (
                    <Form.Control>
                      <Field.PracticeSelect name="practice" placeholder="Practice" />
                    </Form.Control>
                  )}
                  <Form.Control>
                    <Field.LocationSelect name="location" placeholder="Location" />
                  </Form.Control>
                  {features.disciplines && (
                    <Form.Control>
                      <Field.DisciplineSelect name="discipline" placeholder="Discipline" />
                    </Form.Control>
                  )}
                  {project.isBillable && (
                    <Form.Control>
                      <Field.RadioGroup name="isBillable">
                        <Radio value={true} label="Billable" />
                        <Radio value={false} label="Non-billable" />
                      </Field.RadioGroup>
                    </Form.Control>
                  )}

                  {formik.values.isBillable && (
                    <>
                      <Form.Control>
                        <Field.Currency name="rate" placeholder="Rate" precision={7} currency={project.currency} />
                      </Form.Control>
                    </>
                  )}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Buttons align="right">
                    <CancelButton onClick={onCancel}>Close</CancelButton>

                    <Button onClick={formik.submitForm}>Save &amp; Close</Button>
                  </Buttons>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </>
  );
}

export default RolesTable;
