import { Currency, Percent, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import MarginTooltip from '../components/MarginTooltip';
import PastDueTooltip from '../components/PastDueTooltip';
import {
  Box,
  Boxes,
  ChartContainer,
  Label,
  LowerBoxes,
  Metric,
  SmallBox,
  UpperBox,
  Value,
  ValueContainer,
} from '../components/StyledComponents';
import ViewNav from '../components/ViewNav';
import ServicesProfitByMonthChart from './ServicesProfitByMonthChart';
import ServicesProfitProgressChart from './ServicesProfitProgressChart';

export default function ServicesProfitWidget({ project, view, onViewChange }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .progress.servicesProfit();
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const boxes = {
    budget: !!data?.budget,
    forecast: data?.forecast?.dates.length > 0,
    get any() {
      return this.budget || this.forecast;
    },
  };

  const currency = project.currency;

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '32.4rem' }} />
      <Widget.Content>
        <ViewNav view={view} onViewChange={onViewChange} />

        {data && (
          <>
            <ChartContainer>
              {data &&
                {
                  progress: () => <ServicesProfitProgressChart project={project} data={data} />,
                  month: () => <ServicesProfitByMonthChart project={project} data={data} />,
                }[view]()}
            </ChartContainer>

            <Boxes>
              <Box>
                <UpperBox>
                  <ValueContainer>
                    <Value>
                      <Currency
                        value={data.actual.profit}
                        currency={currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Value>
                    {project.revenueRecognitionMethod === 'invoiced' &&
                      ['fixed', 'fixed_recurring'].includes(project.billingTypeId) && (
                        <PastDueTooltip value={data.pastDue} currency={project.currency} />
                      )}
                  </ValueContainer>
                  <Label>Services Gross Profit</Label>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.budget && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.budget.profit.estimated}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Budget</Label>
                        </Metric>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.budget.profit.remaining}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Budget Left</Label>
                        </Metric>
                      </SmallBox>
                    )}

                    {boxes.forecast && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.forecast.profit.forecasted}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Forecast</Label>
                        </Metric>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.forecast.profit.left}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Forecast Left</Label>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>

              <Box>
                <UpperBox style={{ flex: 0 }}>
                  <Value>
                    <Percent value={data.actual.margin} minimumFractionDigits={0} maximumFractionDigits={2} />
                  </Value>
                  <Label>Services Gross Margin</Label>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes style={{ flex: 2 }}>
                    {data.budget && (
                      <SmallBox>
                        <Metric status={data.budget.margin.status}>
                          <MarginTooltip value={data.budget.margin.difference}>
                            <Value>
                              <Percent
                                value={data.budget.margin.estimated}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                              />
                            </Value>
                            <Label>Budget</Label>
                          </MarginTooltip>
                        </Metric>
                      </SmallBox>
                    )}

                    {boxes.forecast && (
                      <SmallBox>
                        <Metric status={data.forecast.margin.status}>
                          <MarginTooltip value={data.forecast.margin.difference}>
                            <Value>
                              <Percent
                                value={data.forecast.margin.forecasted}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                              />
                            </Value>
                            <Label>Forecast</Label>
                          </MarginTooltip>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>
            </Boxes>
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}
