import _ from 'lodash';
import React from 'react';
import { DisciplineFilter, LocationFilter, PracticeFilter, SkillFilter } from '~/components';
import { useFeatures } from '~/hooks';
import ResourceStatusFilter from '../../resources/allocations/member-schedule/filters/ResourceStatusFilter';
import ResourceTypeFilter from '../../resources/allocations/member-schedule/filters/ResourceTypeFilter';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';

export default function ResourceFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Resource Filters"
      filters={[
        filters.resourcePractices,
        filters.resourceDisciplines,
        filters.resourceLocations,
        filters.resourceSkills,
        filters.resourceStatusId,
        filters.resourceTypeId,
      ]}>
      {features.practices && _.has(filters, 'resourcePractices') && (
        <Filter>
          <PracticeFilter
            materialPlaceholder="Resource Practice"
            value={filters.resourcePractices}
            onChange={({ target: { value } }) => onChange({ resourcePractices: value })}
          />
        </Filter>
      )}

      {features.disciplines && _.has(filters, 'resourceDisciplines') && (
        <Filter>
          <DisciplineFilter
            materialPlaceholder="Resource Discipline"
            value={filters.resourceDisciplines}
            onChange={({ target: { value } }) => onChange({ resourceDisciplines: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'resourceLocations') && (
        <Filter>
          <LocationFilter
            materialPlaceholder="Resource Location"
            value={filters.resourceLocations}
            onChange={({ target: { value } }) => onChange({ resourceLocations: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'resourceSkills') && (
        <Filter>
          <SkillFilter
            materialPlaceholder="Resource Skills"
            value={filters.resourceSkills}
            onChange={({ target: { value } }) => onChange({ resourceSkills: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'resourceStatusId') && (
        <Filter>
          <ResourceStatusFilter
            value={filters.resourceStatusId}
            onChange={({ target: { value } }) => onChange({ resourceStatusId: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'resourceTypeId') && (
        <Filter>
          <ResourceTypeFilter
            value={filters.resourceTypeId}
            onChange={({ target: { value } }) => onChange({ resourceTypeId: value })}
          />
        </Filter>
      )}
    </FiltersGroup>
  );
}
