import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';
import { colors } from '~/styles';

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.5rem;
  right: 2rem;
`;

export default function Loader() {
  return <StyledIcon icon="spinner" spin color={colors.grey75} />;
}
