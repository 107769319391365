import slackIcon from '~/assets/public/slack-icon.svg';
import {
  Avatar,
  DateTime,
  Icon,
  ListView,
  ListViewActions,
  ListViewMenu,
  MemberContactPopover,
  Tooltip,
} from '~/components';
import { useMember } from '~/contexts';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import { SuppressedTooltip } from './components';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.div`
  display: flex;
  margin-left: 0.5rem;
`;

const SuppressedIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

const Tag = styled.span`
  padding: 0.1rem 0.4rem;
  background-color: ${colors.primary};
  border-radius: 4px;
  font-size: 0.5rem;
  color: ${colors.white};
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const SlackIcon = styled.img`
  display: block;
  width: 1rem;
  height: 1rem;
  filter: ${({ isConnected }) => (isConnected ? 'none' : 'grayscale(100%)')};
`;

export default function MembersList({
  data,
  action,
  query,
  isSlackConnected,
  onLoadMore,
  onRowClick,
  onSendInvitation,
  onActiveStatusChange,
  onRemove,
  onSort,
}) {
  const { url } = useRouteMatch();
  const { member: currentMember } = useMember();

  return (
    <ListView data-testid="members-list">
      <ListView.Header>
        <ListView.Column sticky name="member.name" width="20rem" onSort={onSort} sort={query.sort}>
          Name
        </ListView.Column>
        <ListView.Column name="employmentTypeId" width="7.5rem" onSort={onSort} sort={query.sort}>
          Type
        </ListView.Column>
        <ListView.Column name="isBillable" width="7.5rem" onSort={onSort} sort={query.sort}>
          Billability
        </ListView.Column>
        <ListView.Column name="securityRole.name" width="13rem" onSort={onSort} sort={query.sort}>
          Security Role
        </ListView.Column>
        <ListView.Column name="isActive" width="6rem" onSort={onSort} sort={query.sort}>
          Status
        </ListView.Column>
        <ListView.Column name="manager:member.name" width="16rem" onSort={onSort} sort={query.sort}>
          Manager
        </ListView.Column>
        <ListView.Column name="accessedAt" width="8.5rem" align="right" onSort={onSort} sort={query.sort}>
          Last Active
        </ListView.Column>
        <ListViewActions.Column />
      </ListView.Header>

      <ListView.Body fade={action === 'filter'}>
        {data.results.map((member) => {
          const {
            name,
            id,
            employmentType,
            isBillable,
            securityRole,
            isActive,
            invitationStatusId,
            invitationCreatedAt,
            slackUserId,
            loginEnabled,
            accessedAt,
            manager,
            suppressedEmail,
            sampleDataId,
          } = member;
          const isCurrentMember = id === currentMember.id;

          return (
            <ListView.Row key={id} isDisabled={!isActive} onClick={() => onRowClick(member)}>
              <ListView.Cell>
                <Member>
                  <MemberContactPopover member={member} placement="right">
                    <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
                  </MemberContactPopover>
                  <MemberInfo>
                    {name}
                    {suppressedEmail && (
                      <SuppressedTooltip suppressedEmail={suppressedEmail}>
                        <SuppressedIcon icon="exclamation-circle" color={colors.danger} />
                      </SuppressedTooltip>
                    )}
                  </MemberInfo>
                  {isSlackConnected && (
                    <span style={{ marginLeft: '.5rem' }} onClick={(event) => event.stopPropagation()}>
                      <Tooltip message={slackUserId ? 'Connected to Slack.' : 'Not connected to Slack.'}>
                        <Link to={`${url}/details/${id}/general#slack-identity`}>
                          <SlackIcon src={slackIcon} alt="Slack" isConnected={!!slackUserId} />
                        </Link>
                      </Tooltip>
                    </span>
                  )}
                </Member>
              </ListView.Cell>
              <ListView.Cell>{employmentType?.name}</ListView.Cell>
              <ListView.Cell>{isBillable ? 'Billable' : 'Non-Billable'}</ListView.Cell>
              <ListView.Cell>{securityRole.name}</ListView.Cell>
              <ListView.Cell>{isActive ? 'Active' : 'Inactive'}</ListView.Cell>
              <ListView.Cell>
                {manager && (
                  <Member>
                    <MemberContactPopover member={manager} placement="left">
                      <Avatar value={manager} isCircle hasBackground initialsFontSize=".9rem" />
                    </MemberContactPopover>
                    <MemberInfo>{manager.name}</MemberInfo>
                  </Member>
                )}
              </ListView.Cell>
              <ListView.Cell>
                {accessedAt ? <DateTime value={accessedAt} /> : 'Never'}
                {loginEnabled && invitationStatusId !== 'accepted' && (
                  <span>
                    <Tooltip
                      message={
                        <>
                          <span>Invitation Sent: </span>
                          {invitationCreatedAt ? (
                            <DateTime value={invitationCreatedAt} format={dateFormats.compactDateTime} />
                          ) : (
                            'Never'
                          )}
                        </>
                      }>
                      <Icon type="far" icon="envelope" color={colors.primary} spaceLeft />
                    </Tooltip>
                  </span>
                )}
              </ListView.Cell>

              <ListViewActions>
                {isCurrentMember ? (
                  <TagContainer>
                    <Tag>You</Tag>
                  </TagContainer>
                ) : sampleDataId ? (
                  <TagContainer>
                    <Tag>Sample</Tag>
                  </TagContainer>
                ) : (
                  <>
                    <ListViewActions.Edit onClick={() => onRowClick(member)} />
                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        return (
                          <>
                            <ListViewMenu.Item
                              onClick={() => {
                                onRowClick(member);
                                setIsOpen(false);
                              }}>
                              Edit
                            </ListViewMenu.Item>
                            <ListViewMenu.Item
                              disabled={
                                !isActive || !loginEnabled || invitationStatusId === 'accepted' || suppressedEmail
                              }
                              tooltip={(() => {
                                if (invitationStatusId === 'accepted' && isActive)
                                  return 'This member has already accepted the invitation.';
                                if (!loginEnabled) return 'This member is not allowed to login.';
                                if (!isActive) return 'This member is inactive.';
                                if (suppressedEmail)
                                  return 'Ruddr is not sending emails to this address because an undeliverable email occurred. Please chat with support or email help@ruddr.io if you would like Ruddr to resume sending emails to this address.';
                              })()}
                              onClick={async () => {
                                await onSendInvitation(member);
                                setIsOpen(false);
                              }}>
                              Send Invitation
                            </ListViewMenu.Item>
                            <ListViewMenu.Item
                              onClick={async () => {
                                onActiveStatusChange(member, !isActive);
                                setIsOpen(false);
                              }}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>
                            <ListViewMenu.DeleteItem
                              tooltip="This member is associated with data in Ruddr such as projects, time entries, or expense items. In order to delete this member, those associations must first be removed."
                              onCheckDependencies={async (workspace) =>
                                (await workspace.members(id).hasDependencies()).data
                              }
                              onClick={() => setIsOpen(false) || onRemove(member)}>
                              Delete
                            </ListViewMenu.DeleteItem>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </>
                )}
              </ListViewActions>
            </ListView.Row>
          );
        })}

        {data.results.length === 0 && <ListView.Empty />}

        {data.total > data.results.length && <ListView.Loader key={data.results.length} onIntersecting={onLoadMore} />}
      </ListView.Body>
    </ListView>
  );
}
