import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import TableCell from './TableCell';

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ borderBottom = `1px solid ${colors.grey10}` }) => borderBottom};

  &.is-clickable {
    cursor: pointer;
    transition: all 250ms ease-in-out;
  }

  &.is-clickable:hover,
  &.focused {
    margin-top: -1px;
    padding-top: 1px;
    background: white;
    border-color: transparent;
    box-shadow: 0 3px 15px ${colors.grey10};
  }

  &.is-disabled > ${TableCell}:not(:last-child) {
    opacity: 0.4;
  }
`;

function TableRow({ className, isDisabled, onClick, children, ...props }) {
  const cells = React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }));
  return (
    <Row
      {...props}
      className={classNames(className, { 'is-disabled': isDisabled, 'is-clickable': !!onClick })}
      onClick={onClick}>
      {cells}
    </Row>
  );
}

export default TableRow;
