import React from 'react';
import styled from 'styled-components';
import { Table } from '~/components';
import { colors, weights } from '~/styles';

const Group = styled(Table.Row)`
  &:not(:first-child) {
    margin-top: 1rem;
  }

  position: sticky;
  background: ${colors.white};
  z-index: 2;
  font-weight: ${weights.bold};
  top: ${({ top = 0 }) => top};
`;

export default function TableGroupRow(props) {
  return <Group {...props} />;
}
