import slackIcon from '~/assets/public/slack-icon.svg';
import {
  Avatar,
  BillableIcon,
  Button,
  DateTime,
  Dropdown,
  Icon,
  InfiniteScrollingObserver,
  MemberContactPopover,
  Tooltip,
} from '~/components';
import { BillableIconContainer } from '~/components/BillableIcon';
import { useMember } from '~/contexts';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import { dateFormats } from '~/utils';
import { SuppressedTooltip } from './components';

const Grid = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(12.75rem, 1fr));
`;

const Body = styled.div`
  display: block;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const Card = styled.div`
  padding: 2rem 0.75rem 1.275rem 1.5rem;
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: 0px 5px 15px 0px ${colors.grey25};
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      ${Body} {
        opacity: 0.3;
      }
    `}
`;

const LoaderCard = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
  background-color: ${colors.grey5};
  opacity: 0.5;
  min-height: 12.5625rem;
`;

const Actions = styled.div`
  position: absolute;
  top: 1rem;
  right: 1.125rem;
  display: flex;
  align-items: center;

  > div {
    height: 1rem;
    display: inline-flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: 0.75rem;
    }
  }

  img {
    width: 1rem;
    height: 1rem;
  }

  ${Dropdown.Trigger} {
    display: flex;
    align-items: center;
  }
`;

const SlackIcon = styled.img`
  display: block;
  width: 1rem;
  height: 1rem;
  filter: ${({ isConnected }) => (isConnected ? 'none' : 'grayscale(100%)')};
`;

const Name = styled.div`
  width: 6rem;
  padding-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  height: 3rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Role = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  margin-top: 0.5625rem;
`;

const Footer = styled.div`
  margin-top: 1.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Activity = styled.div`
  font-size: 0.625rem;
  color: ${colors.grey40};
  text-transform: uppercase;

  strong {
    font-weight: ${weights.black};
  }
`;

const Tag = styled.span`
  padding: 0.1rem 0.5rem;
  background-color: ${colors.primary};
  border-radius: 4px;
  font-size: 0.6rem;
  color: ${colors.white};
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const Billable = styled.div`
  position: absolute;
  bottom: 0;
  right: -0rem;

  ${BillableIconContainer} {
    border: 2px solid ${colors.grey5};
    margin-right: 0;
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

export default function MembersGrid({
  data,
  action,
  isSlackConnected,
  onLoadMore,
  onCardClick,
  onSendInvitation,
  onActiveStatusChange,
  onRemove,
}) {
  const { url } = useRouteMatch();
  const { member: currentMember } = useMember();

  return (
    <Grid data-testid="members-grid">
      {data.results.length === 0 && <NoResults>No members found.</NoResults>}

      {data.results.map((member) => {
        const {
          id,
          securityRole,
          isActive,
          invitationStatusId,
          slackUserId,
          loginEnabled,
          suppressedEmail,
          sampleDataId,
        } = member;
        const isCurrentMember = id === currentMember.id;
        const isSample = !!sampleDataId;

        return (
          <Card key={member.id} isActive={isActive} onClick={() => onCardClick(member)}>
            <Actions onClick={(event) => event.stopPropagation()}>
              {suppressedEmail && (
                <div>
                  <SuppressedTooltip suppressedEmail={suppressedEmail}>
                    <Icon icon="exclamation-circle" color={colors.danger} />
                  </SuppressedTooltip>
                </div>
              )}

              {isCurrentMember && (
                <div>
                  <Tag>You</Tag>
                </div>
              )}

              {isSample && (
                <div>
                  <Tag>Sample</Tag>
                </div>
              )}

              {!isCurrentMember && !isSample && (
                <div>
                  <a href={`mailto:${member.email}`}>
                    <Icon type="far" icon="envelope" />
                  </a>
                </div>
              )}

              {isSlackConnected && (
                <div>
                  <Tooltip message={slackUserId ? 'Connected to Slack.' : 'Not connected to Slack.'}>
                    <Link to={`${url}/details/${member.id}/general#slack-identity`}>
                      <SlackIcon src={slackIcon} alt="Slack" isConnected={!!slackUserId} />
                    </Link>
                  </Tooltip>
                </div>
              )}

              {!isCurrentMember && !isSample && (
                <div>
                  <Dropdown align="right">
                    {({ setIsOpen }) => (
                      <>
                        <Dropdown.Trigger data-testid="members-grid-open">
                          <Button isAnchor onClick={() => setIsOpen(true)}>
                            <Icon icon="ellipsis-h" />
                          </Button>
                        </Dropdown.Trigger>
                        <Dropdown.Menu>
                          {loginEnabled && invitationStatusId !== 'accepted' && (
                            <Dropdown.Item
                              disabled={!isActive || suppressedEmail}
                              tooltip={(() => {
                                if (!isActive) return 'This member is inactive.';
                                if (suppressedEmail)
                                  return 'Ruddr is not sending emails to this address because an undeliverable email occurred. Please chat with support or email help@ruddr.io if you would like Ruddr to resume sending emails to this address.';
                              })()}
                              onClick={async () => {
                                await onSendInvitation(member);
                                setIsOpen(false);
                              }}>
                              Send Invitation
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={async () => {
                              onActiveStatusChange(member, !isActive);
                              setIsOpen(false);
                            }}>
                            {isActive ? 'Deactivate' : 'Activate'}
                          </Dropdown.Item>
                          <Dropdown.DeleteItem
                            tooltip="This member is associated with data in Ruddr such as projects, time entries, or expense items. In order to delete this member, those associations must first be removed."
                            onCheckDependencies={async (workspace) =>
                              (await workspace.members(member.id).hasDependencies()).data
                            }
                            onClick={() => setIsOpen(false) || onRemove(member)}>
                            Delete
                          </Dropdown.DeleteItem>
                        </Dropdown.Menu>
                      </>
                    )}
                  </Dropdown>
                </div>
              )}
            </Actions>
            <Body fade={action === 'filter'}>
              <Name>{member.name}</Name>
              <Role>{securityRole.name}</Role>
              <Footer>
                <Activity>
                  <InvitationStatus member={member} />
                </Activity>
                <AvatarContainer>
                  <MemberContactPopover member={member} placement="left">
                    <Avatar value={member} isCircle size={54} fontSize="1.3rem" hasBackground hasBorder />
                  </MemberContactPopover>
                  <Billable>
                    <BillableIcon value={member.isBillable} />
                  </Billable>
                </AvatarContainer>
              </Footer>
            </Body>
          </Card>
        );
      })}
      {data.total > data.results.length && (
        <LoaderCard>
          <InfiniteScrollingObserver key={data.results.length} onIntersecting={onLoadMore} />
        </LoaderCard>
      )}
    </Grid>
  );
}

function InvitationStatus({ member }) {
  if (!member.loginEnabled) return <strong>Login Disabled</strong>;

  switch (member.invitationStatusId) {
    case 'invited':
      return (
        <>
          <strong>Invitation Sent</strong>
          <p>
            {member.invitationCreatedAt ? (
              <DateTime value={member.invitationCreatedAt} format={dateFormats.compactDateTime} />
            ) : (
              'Never'
            )}
          </p>
        </>
      );

    case 'not_invited':
      return <strong>Not Invited</strong>;

    case 'accepted':
      return (
        <>
          <strong>Last Active</strong>
          <p>
            {member.accessedAt ? <DateTime value={member.accessedAt} format={dateFormats.compactDateTime} /> : 'Never'}
          </p>
        </>
      );
  }
}
