import { Avatar, BackLink, ClientLink, DateTime, Icon, MemberContactPopover, RouteLink } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import Report from '../components/Report';
import Table from '../components/table';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

export default function ClientsList() {
  useDocumentTitle('Clients');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters(() => ({
    clientRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    sort: new QuerySort('client.name', 'asc'),
    ...clientFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        sort: { default: new QuerySort('client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().clientsList(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .clientsList(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/workspace`} />

        <Report.Info>
          <Report.Eyebrow>Workspace Reports</Report.Eyebrow>
          <Report.Title>Clients</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const auth = useAuth();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const report = query.data;

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Client" value={report.records.length} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column sticky name="client.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Name
          </Table.Column>
          <Table.Column
            name="practiceId"
            minWidth="16rem"
            onSort={onSort}
            sort={params.sort}
            isVisible={features.practices}>
            Practice
          </Table.Column>
          <Table.Column name="owner:member.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Relationship Owner
          </Table.Column>
          <Table.Column name="createdAt" width="9rem" align="right" onSort={onSort} sort={params.sort}>
            Created On
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((client) => {
            return (
              <Table.Row key={client.id}>
                <Table.Cell>
                  <ClientLink client={client} />
                </Table.Cell>
                <Table.Cell>{client.practice?.name}</Table.Cell>
                <Table.Cell>
                  {client.owner && (
                    <Member>
                      <MemberContactPopover member={client.owner} placement="left">
                        <Avatar value={client.owner} isCircle hasBackground initialsFontSize=".9rem" />
                      </MemberContactPopover>
                      <MemberInfo>
                        {auth.workspace.manage ? (
                          <RouteLink to={`/app/${workspace.key}/settings/members/details/${client.owner.id}`}>
                            {client.owner.name}
                          </RouteLink>
                        ) : (
                          client.owner.name
                        )}
                      </MemberInfo>
                    </Member>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <DateTime value={client.createdAt} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
