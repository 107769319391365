import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';
import { useNumberFormat } from '~/hooks';
import { weights } from '~/styles';

const Total = styled.div`
  text-align: right;
  font-size: 0.875rem;
  font-weight: ${weights.light};
  margin-bottom: 0.5rem;
`;

function ListViewTotal({ value, label = 'Result', ...props }) {
  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  return value !== undefined ? (
    <Total {...props}>{`${numberFormat.format(value)} ${pluralize(label, value)}`}</Total>
  ) : (
    <Total {...props}>{label}</Total>
  );
}

export default ListViewTotal;
