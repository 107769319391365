import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { MultiSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

export default function TimeOffTypeFilter({
  materialPlaceholder = 'Time Off Type',
  placeholder = 'All',
  name,
  value,
  onChange,
}) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      let options = data;
      if (!data) {
        const result = await api.www.workspaces(workspace.id).timeOffTypes().get({ size: 1000 });
        setData(result.data);
        options = result.data;
      }
      if (q) {
        options = new Fuse(options, { keys: ['name'], threshold: 0.4 }).search(q).map((result) => result.item);
      }
      setOptions(options);
    },
    [workspace.id, api, data],
  );

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy((o) => (o.isActive ? 0 : 1))
      .groupBy((o) => (o.isActive ? 'Active' : 'Inactive'))
      .value();
  }, [options]);

  return (
    <MultiSelect.Input
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return Object.keys(groups).map((status) => (
          <MultiSelect.Group key={status} label={status}>
            {groups[status].map((option) => (
              <MultiSelect.Option key={option.id} inactive={!option.isActive} {...optionsById[option.id].props}>
                {option.name}
              </MultiSelect.Option>
            ))}
          </MultiSelect.Group>
        ));
      }}
      isLoading={!data}
      onSearch={handleSearch}
      onChange={onChange}
    />
  );
}
