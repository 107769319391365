import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';
import {
  BackLink,
  ClientLink,
  DateTime,
  Hours,
  Icon,
  Percent,
  PeriodFilter,
  ProjectLink,
  SingleSelect,
  Widget,
  WorkspaceCurrencySelect,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import {
  useCurrencyFormat,
  useDateTimeFormat,
  useDocumentTitle,
  useFeatures,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import projectStatuses from '~/lookups/project-statuses';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import IconButton from '../components/IconButton';
import List from '../components/List';
import ListTooltip from '../components/ListTooltip';
import MultiCurrency from '../components/MultiCurrency';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import Report from '../components/Report';
import Table from '../components/table';
import { intervals, intervalsByScope } from './intervals';
import CurrencyFilter from '../components/CurrencyFilter';

const metricOptions = {
  total_revenue: 'Total Revenue',
  total_profit: 'Total Gross Profit',
  cost: 'Total Cost',
  margin: 'Total Gross Margin',
  hours: 'Total Hours',
};

const TimelineContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 1.5rem;
  background: ${colors.white};
`;

const TimelineBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const TimelineGutter = styled.div`
  flex: 1;
  min-width: 16rem;
  position: sticky;
  left: 0;
  background: linear-gradient(to right, ${colors.white} 90%, transparent 100%);
`;

function Timeline({ periods, unit }) {
  const groupedPeriods = useMemo(() => {
    const today = moment();
    return _.groupBy(periods, (p) => {
      const period = moment(p);
      if (period.isBefore(today, unit)) {
        return 'actuals';
      } else if (period.isSame(today, unit)) {
        return 'in_progress';
      } else {
        return 'plan';
      }
    });
  }, [periods, unit]);

  return (
    <TimelineContainer>
      <TimelineGutter />
      <div style={{ width: '10rem' }} />

      {_.map(groupedPeriods, (periods, key) => {
        let style;
        let label;
        const width = `${periods.length * 10}rem`;
        switch (key) {
          case 'actuals':
            style = { color: colors.white, backgroundColor: colors.grey55, width };
            label = 'Actuals';
            break;

          case 'in_progress':
            style = { color: colors.white, backgroundColor: colors.grey40, width };
            label = 'In Progress';
            break;

          case 'plan':
            style = { color: colors.black, backgroundColor: colors.grey25, width };
            label = 'Plan';
            break;
        }

        return (
          <TimelineBar key={key} style={style}>
            {label}
          </TimelineBar>
        );
      })}

      <div style={{ width: '10rem' }} />
      <div style={{ width: '10rem' }} />
    </TimelineContainer>
  );
}

export default function PerformanceForecastByProjectByTimeUnit() {
  useDocumentTitle('Performance Forecast by Project by Time Unit');
  const { workspace } = useWorkspace();
  const api = useApi();

  const searchParamsConfig = useSearchParamsConfig();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: { table: false },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: {
      ...searchParamsConfig.recordStatusId,
      default: 'active',
    },
    projectStatuses: {
      ...searchParamsConfig.projectStatuses,
      default: [projectStatuses.not_started, projectStatuses.in_progress],
    },
  }));

  const [params, setParams] = useState({
    period: null,
    unit: 'month',
    currency: workspace.currency,
    metric: 'total_revenue',
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          default: {
            start: moment().subtract(2, 'months').startOf('month').format(dateFormats.isoDate),
            end: moment().add(2, 'months').endOf('month').format(dateFormats.isoDate),
            key: intervalOptions.custom.key,
            unit: 'month',
          },
          keys: ['start', 'end', 'unit'],
          serialize: (value) => ({ start: value.start, end: value.end }),
          deserialize: (_value, urlSearchParams) => {
            let unit = urlSearchParams.get('unit');
            if (!['day', 'week', 'month'].includes(unit)) unit = 'month';

            const start = urlSearchParams.get('start');
            const end = urlSearchParams.get('end');

            if (!moment(start, true).isValid() || !moment(end, true).isValid()) {
              urlSearchParams.delete('start');
              urlSearchParams.delete('end');

              switch (unit) {
                case 'day':
                  return intervals.next_30_days;

                case 'week':
                  return intervals.next_12_weeks;

                case 'month':
                  return intervals.next_6_months;
              }
            }

            const option = _.find(intervalsByScope[unit], (i) => i.start === start && i.end === end);
            return {
              start,
              end,
              key: option ? option.key : intervalOptions.custom.key,
              unit: option ? option.unit : intervalOptions.custom.unit,
            };
          },
        },
        unit: { default: 'month', valid: ['day', 'week', 'month'] },
        currency: searchParamsConfig.currency,
        metric: { default: 'total_revenue', valid: _.keys(metricOptions) },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),
    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: moment(params.period?.start).subtract(1, params.unit).format(dateFormats.isoDate) ?? undefined,
      end: params.period?.end ?? undefined,
      unit: params.unit,
      currency: params.currency ?? undefined,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .performanceForecastByProjectByTimeUnit(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideFilters();
  };

  const [settingsVisible, setSettingsVisible] = useState(false);
  const showSettings = () => setSettingsVisible(true);
  const hideSettings = () => setSettingsVisible(false);
  const handleApplySettings = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(values);
    hideSettings();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .performanceForecastByProjectByTimeUnit(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  const features = useFeatures();

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/plan-and-forecast`} />

        <Report.Info>
          <Report.Eyebrow>Plan & Forecast Reports</Report.Eyebrow>
          <Report.Title>Performance Forecast by Project by Time Unit</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconButton icon="cog" onClick={showSettings} />

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter
          value={params.period}
          intervals={intervalsByScope[params.unit]}
          onChange={(period) => handleApplyFilters({ period })}
        />

        <SingleSelect
          materialPlaceholder="Unit"
          value={params.unit}
          onChange={({ target: { value } }) =>
            handleApplyFilters({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>

        {features.multicurrency && (
          <CurrencyFilter
            value={params.currency}
            onChange={({ target: { value } }) => handleApplySettings({ currency: value })}
          />
        )}

        <SingleSelect
          materialPlaceholder="Metric"
          value={params.metric}
          onChange={({ target: { value } }) => handleApplySettings({ metric: value })}>
          {_.map(metricOptions, (value, key) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </SingleSelect>

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} />
                <Settings
                  values={params}
                  isOpen={settingsVisible}
                  onApply={handleApplySettings}
                  onClose={hideSettings}
                />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params }) {
  const report = query.data;

  const { clientsById, projectsById, performancePeriods, periods } = useMemo(() => {
    const clientsById = _(report.projects)
      .map((p) => p.client)
      .keyBy('id')
      .value();

    const projectsById = _.keyBy(report.projects, 'id');

    const performancePeriods = _.orderBy(report.periods, (a) => [
      projectsById[a.projectId].client.name.toLowerCase(),
      projectsById[a.projectId].name.toLowerCase(),
    ]).reduce(
      (a, v) => {
        a.total = a.total || {
          totalHours: 0,
          billableHours: 0,
          nonBillableHours: 0,
          internalHours: 0,
          convertedCurrency: v.convertedCurrency,
          convertedServicesRevenue: 0,
          convertedExpensesRevenue: 0,
          convertedOtherItemsRevenue: 0,
          convertedTotalRevenue: 0,
          convertedLaborCost: 0,
          convertedExpensesCost: 0,
          convertedCost: 0,
          convertedProfit: 0,
          convertedServicesProfit: 0,
          get margin() {
            return this.convertedTotalRevenue ? this.convertedProfit / this.convertedTotalRevenue : 0;
          },
          get servicesMargin() {
            return this.convertedServicesRevenue ? this.convertedServicesProfit / this.convertedServicesRevenue : 0;
          },
        };
        if (moment(v.start).isBetween(params.period.start, params.period.end, params.unit, '[]')) {
          // Only include periods that are after the reporting start date
          a.total.totalHours += v.totalHours;
          a.total.billableHours += v.billableHours;
          a.total.nonBillableHours += v.nonBillableHours;
          a.total.internalHours += v.internalHours;
          a.total.convertedServicesRevenue += v.convertedServicesRevenue;
          a.total.convertedExpensesRevenue += v.convertedExpensesRevenue;
          a.total.convertedOtherItemsRevenue += v.convertedOtherItemsRevenue;
          a.total.convertedTotalRevenue += v.convertedTotalRevenue;
          a.total.convertedLaborCost += v.convertedLaborCost;
          a.total.convertedExpensesCost += v.convertedExpensesCost;
          a.total.convertedCost += v.convertedCost;
          a.total.convertedProfit += v.convertedProfit;
          a.total.convertedServicesProfit += v.convertedServicesProfit;
        }

        const project = projectsById[v.projectId];
        const client = project.client;

        // Clients
        a.clients[client.id] = a.clients[client.id] || {
          total: {
            totalHours: 0,
            billableHours: 0,
            nonBillableHours: 0,
            internalHours: 0,
            convertedCurrency: v.convertedCurrency,
            convertedServicesRevenue: 0,
            convertedExpensesRevenue: 0,
            convertedOtherItemsRevenue: 0,
            convertedTotalRevenue: 0,
            convertedLaborCost: 0,
            convertedExpensesCost: 0,
            convertedCost: 0,
            convertedProfit: 0,
            convertedServicesProfit: 0,
            get margin() {
              return this.convertedTotalRevenue ? this.convertedProfit / this.convertedTotalRevenue : 0;
            },
            get servicesMargin() {
              return this.convertedServicesRevenue ? this.convertedServicesProfit / this.convertedServicesRevenue : 0;
            },
          },
          periods: {},
          projects: {},
        };
        // Client Overall Totals
        if (moment(v.start).isBetween(params.period.start, params.period.end, params.unit, '[]')) {
          // Only include periods that are after the reporting start date
          a.clients[client.id].total.totalHours += v.totalHours;
          a.clients[client.id].total.billableHours += v.billableHours;
          a.clients[client.id].total.nonBillableHours += v.nonBillableHours;
          a.clients[client.id].total.internalHours += v.internalHours;
          a.clients[client.id].total.convertedServicesRevenue += v.convertedServicesRevenue;
          a.clients[client.id].total.convertedExpensesRevenue += v.convertedExpensesRevenue;
          a.clients[client.id].total.convertedOtherItemsRevenue += v.convertedOtherItemsRevenue;
          a.clients[client.id].total.convertedTotalRevenue += v.convertedTotalRevenue;
          a.clients[client.id].total.convertedLaborCost += v.convertedLaborCost;
          a.clients[client.id].total.convertedExpensesCost += v.convertedExpensesCost;
          a.clients[client.id].total.convertedCost += v.convertedCost;
          a.clients[client.id].total.convertedProfit += v.convertedProfit;
          a.clients[client.id].total.convertedServicesProfit += v.convertedServicesProfit;
        }

        // Client Periods
        if (!a.clients[client.id].periods[v.start]) {
          a.clients[client.id].periods[v.start] = {
            totalHours: 0,
            billableHours: 0,
            nonBillableHours: 0,
            internalHours: 0,
            convertedCurrency: v.convertedCurrency,
            convertedServicesRevenue: 0,
            convertedExpensesRevenue: 0,
            convertedOtherItemsRevenue: 0,
            convertedTotalRevenue: 0,
            convertedLaborCost: 0,
            convertedExpensesCost: 0,
            convertedCost: 0,
            convertedProfit: 0,
            convertedServicesProfit: 0,
            get margin() {
              return this.convertedTotalRevenue ? this.convertedProfit / this.convertedTotalRevenue : 0;
            },
            get servicesMargin() {
              return this.convertedServicesRevenue ? this.convertedServicesProfit / this.convertedServicesRevenue : 0;
            },

            cumulativeTotalHours: 0,
            cumulativeBillableHours: 0,
            cumulativeNonBillableHours: 0,
            cumulativeInternalHours: 0,
            convertedCumulativeServicesRevenue: 0,
            convertedCumulativeExpensesRevenue: 0,
            convertedCumulativeOtherItemsRevenue: 0,
            convertedCumulativeTotalRevenue: 0,
            convertedCumulativeLaborCost: 0,
            convertedCumulativeExpensesCost: 0,
            convertedCumulativeCost: 0,
            convertedCumulativeProfit: 0,
            convertedCumulativeServicesProfit: 0,
            get cumulativeMargin() {
              return this.convertedCumulativeTotalRevenue
                ? this.convertedCumulativeProfit / this.convertedCumulativeTotalRevenue
                : 0;
            },
            get cumulativeServicesMargin() {
              return this.convertedCumulativeServicesRevenue
                ? this.convertedCumulativeServicesProfit / this.convertedCumulativeServicesRevenue
                : 0;
            },
          };
        }
        a.clients[client.id].periods[v.start].totalHours += v.totalHours;
        a.clients[client.id].periods[v.start].billableHours += v.billableHours;
        a.clients[client.id].periods[v.start].nonBillableHours += v.nonBillableHours;
        a.clients[client.id].periods[v.start].internalHours += v.internalHours;
        a.clients[client.id].periods[v.start].servicesRevenue += v.servicesRevenue;
        a.clients[client.id].periods[v.start].expensesRevenue += v.expensesRevenue;
        a.clients[client.id].periods[v.start].otherItemsRevenue += v.otherItemsRevenue;
        a.clients[client.id].periods[v.start].totalRevenue += v.totalRevenue;
        a.clients[client.id].periods[v.start].laborCost += v.laborCost;
        a.clients[client.id].periods[v.start].expensesCost += v.expensesCost;
        a.clients[client.id].periods[v.start].cost += v.cost;
        a.clients[client.id].periods[v.start].profit += v.profit;
        a.clients[client.id].periods[v.start].servicesProfit += v.servicesProfit;
        a.clients[client.id].periods[v.start].convertedServicesRevenue += v.convertedServicesRevenue;
        a.clients[client.id].periods[v.start].convertedExpensesRevenue += v.convertedExpensesRevenue;
        a.clients[client.id].periods[v.start].convertedOtherItemsRevenue += v.convertedOtherItemsRevenue;
        a.clients[client.id].periods[v.start].convertedTotalRevenue += v.convertedTotalRevenue;
        a.clients[client.id].periods[v.start].convertedLaborCost += v.convertedLaborCost;
        a.clients[client.id].periods[v.start].convertedExpensesCost += v.convertedExpensesCost;
        a.clients[client.id].periods[v.start].convertedCost += v.convertedCost;
        a.clients[client.id].periods[v.start].convertedProfit += v.convertedProfit;
        a.clients[client.id].periods[v.start].convertedServicesProfit += v.convertedServicesProfit;
        a.clients[client.id].periods[v.start].cumulativeTotalHours += v.cumulativeTotalHours;
        a.clients[client.id].periods[v.start].cumulativeBillableHours += v.cumulativeBillableHours;
        a.clients[client.id].periods[v.start].cumulativeNonBillableHours += v.cumulativeNonBillableHours;
        a.clients[client.id].periods[v.start].cumulativeInternalHours += v.cumulativeInternalHours;
        a.clients[client.id].periods[v.start].cumulativeServicesRevenue += v.cumulativeServicesRevenue;
        a.clients[client.id].periods[v.start].cumulativeExpensesRevenue += v.cumulativeExpensesRevenue;
        a.clients[client.id].periods[v.start].cumulativeOtherItemsRevenue += v.cumulativeOtherItemsRevenue;
        a.clients[client.id].periods[v.start].cumulativeTotalRevenue += v.cumulativeTotalRevenue;
        a.clients[client.id].periods[v.start].cumulativeLaborCost += v.cumulativeLaborCost;
        a.clients[client.id].periods[v.start].cumulativeExpensesCost += v.cumulativeExpensesCost;
        a.clients[client.id].periods[v.start].cumulativeCost += v.cumulativeCost;
        a.clients[client.id].periods[v.start].cumulativeProfit += v.cumulativeProfit;
        a.clients[client.id].periods[v.start].cumulativeServicesProfit += v.cumulativeServicesProfit;
        a.clients[client.id].periods[v.start].convertedCumulativeTotalRevenue += v.convertedCumulativeTotalRevenue;
        a.clients[client.id].periods[v.start].convertedCumulativeServicesRevenue +=
          v.convertedCumulativeServicesRevenue;
        a.clients[client.id].periods[v.start].convertedCumulativeExpensesRevenue +=
          v.convertedCumulativeExpensesRevenue;
        a.clients[client.id].periods[v.start].convertedCumulativeOtherItemsRevenue +=
          v.convertedCumulativeOtherItemsRevenue;
        a.clients[client.id].periods[v.start].convertedCumulativeLaborCost += v.convertedCumulativeLaborCost;
        a.clients[client.id].periods[v.start].convertedCumulativeExpensesCost += v.convertedCumulativeExpensesCost;
        a.clients[client.id].periods[v.start].convertedCumulativeCost += v.convertedCumulativeCost;
        a.clients[client.id].periods[v.start].convertedCumulativeProfit += v.convertedCumulativeProfit;
        a.clients[client.id].periods[v.start].convertedCumulativeServicesProfit += v.convertedCumulativeServicesProfit;

        a.clients[client.id].projects[v.projectId] = a.clients[client.id].projects[v.projectId] || {
          periods: {},
          total: {
            totalHours: 0,
            billableHours: 0,
            nonBillableHours: 0,
            internalHours: 0,
            servicesRevenue: 0,
            expensesRevenue: 0,
            otherItemsRevenue: 0,
            totalRevenue: 0,
            laborCost: 0,
            expensesCost: 0,
            cost: 0,
            profit: 0,
            servicesProfit: 0,
            currency: v.currency,
            convertedCurrency: v.convertedCurrency,
            convertedServicesRevenue: 0,
            convertedExpensesRevenue: 0,
            convertedOtherItemsRevenue: 0,
            convertedTotalRevenue: 0,
            convertedLaborCost: 0,
            convertedExpensesCost: 0,
            convertedCost: 0,
            convertedProfit: 0,
            convertedServicesProfit: 0,
            get margin() {
              return this.totalRevenue ? this.profit / this.totalRevenue : 0;
            },
            get servicesMargin() {
              return this.servicesRevenue ? this.servicesProfit / this.servicesRevenue : 0;
            },
          },
        };

        // Project Overall Totals
        if (moment(v.start).isBetween(params.period.start, params.period.end, params.unit, '[]')) {
          // Only include periods that are after the reporting start date
          a.clients[client.id].projects[v.projectId].total.totalHours += v.totalHours;
          a.clients[client.id].projects[v.projectId].total.billableHours += v.billableHours;
          a.clients[client.id].projects[v.projectId].total.nonBillableHours += v.nonBillableHours;
          a.clients[client.id].projects[v.projectId].total.internalHours += v.internalHours;
          a.clients[client.id].projects[v.projectId].total.servicesRevenue += v.servicesRevenue;
          a.clients[client.id].projects[v.projectId].total.expensesRevenue += v.expensesRevenue;
          a.clients[client.id].projects[v.projectId].total.otherItemsRevenue += v.otherItemsRevenue;
          a.clients[client.id].projects[v.projectId].total.totalRevenue += v.totalRevenue;
          a.clients[client.id].projects[v.projectId].total.laborCost += v.laborCost;
          a.clients[client.id].projects[v.projectId].total.expensesCost += v.expensesCost;
          a.clients[client.id].projects[v.projectId].total.cost += v.cost;
          a.clients[client.id].projects[v.projectId].total.profit += v.profit;
          a.clients[client.id].projects[v.projectId].total.servicesProfit += v.servicesProfit;
          a.clients[client.id].projects[v.projectId].total.convertedServicesRevenue += v.convertedServicesRevenue;
          a.clients[client.id].projects[v.projectId].total.convertedExpensesRevenue += v.convertedExpensesRevenue;
          a.clients[client.id].projects[v.projectId].total.convertedOtherItemsRevenue += v.convertedOtherItemsRevenue;
          a.clients[client.id].projects[v.projectId].total.convertedTotalRevenue += v.convertedTotalRevenue;
          a.clients[client.id].projects[v.projectId].total.convertedLaborCost += v.convertedLaborCost;
          a.clients[client.id].projects[v.projectId].total.convertedExpensesCost += v.convertedExpensesCost;
          a.clients[client.id].projects[v.projectId].total.convertedCost += v.convertedCost;
          a.clients[client.id].projects[v.projectId].total.convertedProfit += v.convertedProfit;
          a.clients[client.id].projects[v.projectId].total.convertedServicesProfit += v.convertedServicesProfit;
        }

        // Projects
        a.clients[client.id].projects[v.projectId].periods[v.start] = { ...v };

        // Period Totals
        if (!a.periods[v.start]) {
          a.periods[v.start] = {
            start: v.start,

            totalHours: 0,
            billableHours: 0,
            nonBillableHours: 0,
            internalHours: 0,
            convertedCurrency: v.convertedCurrency,
            convertedServicesRevenue: 0,
            convertedExpensesRevenue: 0,
            convertedOtherItemsRevenue: 0,
            convertedTotalRevenue: 0,
            convertedLaborCost: 0,
            convertedExpensesCost: 0,
            convertedCost: 0,
            convertedProfit: 0,
            convertedServicesProfit: 0,
            get margin() {
              return this.convertedTotalRevenue ? this.convertedProfit / this.convertedTotalRevenue : 0;
            },
            get servicesMargin() {
              return this.convertedServicesRevenue ? this.convertedServicesProfit / this.convertedServicesRevenue : 0;
            },

            cumulativeTotalHours: 0,
            cumulativeBillableHours: 0,
            cumulativeNonBillableHours: 0,
            cumulativeInternalHours: 0,
            convertedCumulativeServicesRevenue: 0,
            convertedCumulativeExpensesRevenue: 0,
            convertedCumulativeOtherItemsRevenue: 0,
            convertedCumulativeTotalRevenue: 0,
            convertedCumulativeLaborCost: 0,
            convertedCumulativeExpensesCost: 0,
            convertedCumulativeCost: 0,
            convertedCumulativeProfit: 0,
            convertedCumulativeServicesProfit: 0,
            get cumulativeMargin() {
              return this.convertedCumulativeTotalRevenue
                ? this.convertedCumulativeProfit / this.convertedCumulativeTotalRevenue
                : 0;
            },
            get cumulativeServicesMargin() {
              return this.convertedCumulativeServicesRevenue
                ? this.convertedCumulativeServicesProfit / this.convertedCumulativeServicesRevenue
                : 0;
            },
          };
        }
        a.periods[v.start].totalHours += v.totalHours;
        a.periods[v.start].billableHours += v.billableHours;
        a.periods[v.start].nonBillableHours += v.nonBillableHours;
        a.periods[v.start].internalHours += v.internalHours;
        a.periods[v.start].convertedServicesRevenue += v.convertedServicesRevenue;
        a.periods[v.start].convertedExpensesRevenue += v.convertedExpensesRevenue;
        a.periods[v.start].convertedOtherItemsRevenue += v.convertedOtherItemsRevenue;
        a.periods[v.start].convertedTotalRevenue += v.convertedTotalRevenue;
        a.periods[v.start].convertedLaborCost += v.convertedLaborCost;
        a.periods[v.start].convertedExpensesCost += v.convertedExpensesCost;
        a.periods[v.start].convertedCost += v.convertedCost;
        a.periods[v.start].convertedProfit += v.convertedProfit;
        a.periods[v.start].convertedServicesProfit += v.convertedServicesProfit;
        a.periods[v.start].cumulativeTotalHours += v.cumulativeTotalHours;
        a.periods[v.start].cumulativeBillableHours += v.cumulativeBillableHours;
        a.periods[v.start].cumulativeNonBillableHours += v.cumulativeNonBillableHours;
        a.periods[v.start].cumulativeInternalHours += v.cumulativeInternalHours;
        a.periods[v.start].convertedCumulativeServicesRevenue += v.convertedCumulativeServicesRevenue;
        a.periods[v.start].convertedCumulativeExpensesRevenue += v.convertedCumulativeExpensesRevenue;
        a.periods[v.start].convertedCumulativeOtherItemsRevenue += v.convertedCumulativeOtherItemsRevenue;
        a.periods[v.start].convertedCumulativeTotalRevenue += v.convertedCumulativeTotalRevenue;
        a.periods[v.start].convertedCumulativeLaborCost += v.convertedCumulativeLaborCost;
        a.periods[v.start].convertedCumulativeExpensesCost += v.convertedCumulativeExpensesCost;
        a.periods[v.start].convertedCumulativeCost += v.convertedCumulativeCost;
        a.periods[v.start].convertedCumulativeProfit += v.convertedCumulativeProfit;
        a.periods[v.start].convertedCumulativeServicesProfit += v.convertedCumulativeServicesProfit;

        return a;
      },
      { total: null, clients: {}, periods: {} },
    );

    let start = params.period.start;
    let end = params.period.end;

    switch (params.unit) {
      case 'day':
        start = moment(start).format(dateFormats.isoDate);
        end = moment(end).format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(start).startOf('month').format(dateFormats.isoDate);
        end = moment(end).endOf('month').format(dateFormats.isoDate);
        break;
    }

    const periodCount = moment(end).diff(start, params.unit) + 1;
    const periods = [];
    for (let index = 0; index < periodCount; index++) {
      periods.push(moment(start).add(index, params.unit).format(dateFormats.isoDate));
    }

    return { clientsById, projectsById, performancePeriods, periods };
  }, [report, params.period.start, params.period.end, params.unit]);

  const cumulativeStart = moment(periods[0]).subtract(1, params.unit).format(dateFormats.isoDate);
  const cumulativeEnd = moment(periods[periods.length - 1]).format(dateFormats.isoDate);
  const metric = params.metric;

  return (
    <>
      <Report.Summary>
        <Chart periods={periods} records={performancePeriods} unit={params.unit} currency={report.currency} />
      </Report.Summary>

      <Report.Status>
        <div>
          {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
          <Table.Total label="Client" value={_.size(clientsById)} />
          {report.partialResults && <PartialResultsTooltip />}
        </div>
      </Report.Status>

      <Table>
        <Table.Header style={{ paddingTop: '1.5rem' }}>
          <Timeline periods={periods} unit={params.unit} />

          <Table.Column minWidth="16rem" sticky>
            Client
          </Table.Column>

          <Table.Column align="right" width="10rem">
            Starting
          </Table.Column>

          {periods.map((p) => {
            return (
              <Table.Column key={p} align="right" width="10rem">
                <DateTime value={p} />
              </Table.Column>
            );
          })}

          <Table.Column align="right" width="10rem">
            Total
          </Table.Column>

          <Table.Column align="right" width="10rem">
            Ending
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {_.map(performancePeriods.clients, (clientPerformance, clientId) => {
            const client = clientsById[clientId];

            return (
              <React.Fragment key={client.id}>
                <Table.GroupRow top="4.5rem">
                  <Table.Cell>
                    <ClientLink client={client} />
                  </Table.Cell>

                  <Table.Cell>
                    <CumulativePerformanceCell value={clientPerformance.periods[cumulativeStart]} metric={metric} />
                  </Table.Cell>

                  {periods.map((period) => {
                    return (
                      <Table.Cell key={period}>
                        <PerformanceCell value={clientPerformance.periods[period]} metric={metric} />
                      </Table.Cell>
                    );
                  })}

                  <Table.Cell>
                    <PerformanceCell value={clientPerformance.total} metric={metric} />
                  </Table.Cell>

                  <Table.Cell>
                    <CumulativePerformanceCell value={clientPerformance.periods[cumulativeEnd]} metric={metric} />
                  </Table.Cell>
                </Table.GroupRow>

                {_.map(clientPerformance.projects, (projectPerformance, projectId) => {
                  const project = projectsById[projectId];

                  return (
                    <Table.Row key={projectId}>
                      <Table.Cell>
                        <p style={{ marginLeft: '1rem' }}>
                          <ProjectLink project={project} />
                        </p>
                      </Table.Cell>

                      <Table.Cell>
                        <CumulativePerformanceCell
                          value={projectPerformance.periods[cumulativeStart]}
                          metric={metric}
                        />
                      </Table.Cell>

                      {periods.map((period) => {
                        return (
                          <Table.Cell key={period}>
                            <PerformanceCell value={projectPerformance.periods[period]} metric={metric} />
                          </Table.Cell>
                        );
                      })}

                      <Table.Cell>
                        <PerformanceCell value={projectPerformance.total} metric={metric} />
                      </Table.Cell>

                      <Table.Cell>
                        <CumulativePerformanceCell value={projectPerformance.periods[cumulativeEnd]} metric={metric} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>

            <Table.Cell>
              <CumulativePerformanceCell value={performancePeriods.periods[cumulativeStart]} metric={metric} />
            </Table.Cell>

            {periods.map((period) => {
              return (
                <Table.Cell key={period}>
                  <PerformanceCell value={performancePeriods.periods[period]} metric={metric} />
                </Table.Cell>
              );
            })}

            <Table.Cell>
              <PerformanceCell value={performancePeriods.total} metric={metric} />
            </Table.Cell>

            <Table.Cell>
              <CumulativePerformanceCell value={performancePeriods.periods[cumulativeEnd]} metric={metric} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          materialPlaceholder="Unit"
          value={filters.unit}
          onChange={({ target: { value } }) =>
            handleFilter({
              unit: value,
              period: {
                day: intervals.next_30_days,
                week: intervals.next_12_weeks,
                month: intervals.next_6_months,
              }[value],
            })
          }>
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="day">Day</option>
        </SingleSelect>
      </Filter>

      <Filter>
        <PeriodFilter
          clearable={false}
          scope={filters.unit}
          intervals={intervalsByScope[filters.unit]}
          materialPlaceholder="Date Range"
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

function Settings({ values, isOpen, onClose, onApply }) {
  const features = useFeatures();
  const [settings, setSettings] = useState(values);

  const handleApply = () => {
    onApply(settings);
  };

  const handleChange = (filter) => {
    setSettings({ ...settings, ...filter });
  };

  const handleCancel = () => {
    setSettings(values);
    onClose();
  };

  useEffect(() => {
    setSettings(values);
  }, [values]);

  return (
    <Report.FiltersDrawer title="Settings" isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <SingleSelect
          materialPlaceholder="Metric"
          value={settings.metric}
          onChange={({ target: { value } }) => handleChange({ metric: value })}>
          {_.map(metricOptions, (value, key) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </SingleSelect>
      </Filter>

      {features.multicurrency && (
        <Filter>
          <WorkspaceCurrencySelect
            clearable={false}
            value={settings.currency}
            onChange={({ target: { value } }) => handleChange({ currency: value })}
          />
        </Filter>
      )}
    </Report.FiltersDrawer>
  );
}

function PerformanceCell({ value, metric }) {
  return (
    <PerformanceTooltip value={value}>
      <div>
        <PerformanceValue value={value} metric={metric} />
      </div>
    </PerformanceTooltip>
  );
}

function PerformanceValue({ value, metric }) {
  switch (metric) {
    case 'total_revenue':
      return (
        <MultiCurrency
          value={[
            { value: value.convertedTotalRevenue, currency: value.convertedCurrency },
            { value: value.totalRevenue, currency: value.currency },
          ]}
        />
      );

    case 'hours':
      return <Hours value={value.totalHours} />;

    case 'cost':
      return (
        <MultiCurrency
          value={[
            { value: value.convertedCost, currency: value.convertedCurrency },
            { value: value.cost, currency: value.currency },
          ]}
        />
      );

    case 'total_profit':
      return (
        <MultiCurrency
          value={[
            { value: value.convertedProfit, currency: value.convertedCurrency },
            { value: value.profit, currency: value.currency },
          ]}
        />
      );

    case 'margin':
      return <Percent value={value.margin} />;

    default:
      return null;
  }
}

function PerformanceTooltip({ value, children }) {
  if (!value) return null;

  return (
    <ListTooltip
      message={
        <List>
          <List.Item label="Client Billable Hours">
            <Hours value={value.billableHours} />
          </List.Item>
          <List.Item label="Client Non-Billable Hours">
            <Hours value={value.nonBillableHours} />
          </List.Item>
          <List.Item label="Internal Hours">
            <Hours value={value.internalHours} />
          </List.Item>
          <List.Item label="Total Hours">
            <Hours value={value.totalHours} />
          </List.Item>
          <List.Item label="Services Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedServicesRevenue, currency: value.convertedCurrency },
                { value: value.servicesRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Expenses Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedExpensesRevenue, currency: value.convertedCurrency },
                { value: value.expensesRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Other Items Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedOtherItemsRevenue, currency: value.convertedCurrency },
                { value: value.otherItemsRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Total Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedTotalRevenue, currency: value.convertedCurrency },
                { value: value.totalRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Labor Cost">
            <MultiCurrency
              value={[
                { value: value.convertedLaborCost, currency: value.convertedCurrency },
                { value: value.laborCost, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Expenses Cost">
            <MultiCurrency
              value={[
                { value: value.convertedExpensesCost, currency: value.convertedCurrency },
                { value: value.expensesCost, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Total Cost">
            <MultiCurrency
              value={[
                { value: value.convertedCost, currency: value.convertedCurrency },
                { value: value.cost, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Services Gross Profit">
            <MultiCurrency
              value={[
                { value: value.convertedServicesProfit, currency: value.convertedCurrency },
                { value: value.servicesProfit, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Services Gross Margin">
            <Percent value={value.servicesMargin} />
          </List.Item>
          <List.Item label="Total Gross Profit">
            <MultiCurrency
              value={[
                { value: value.convertedProfit, currency: value.convertedCurrency },
                { value: value.profit, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Total Gross Margin">
            <Percent value={value.margin} />
          </List.Item>
        </List>
      }>
      {children}
    </ListTooltip>
  );
}

function CumulativePerformanceCell({ value, metric }) {
  return (
    <CumulativePerformanceTooltip value={value}>
      <CumulativePerformanceValue value={value} metric={metric} />
    </CumulativePerformanceTooltip>
  );
}

function CumulativePerformanceValue({ value, metric }) {
  switch (metric) {
    case 'total_revenue':
      return (
        <MultiCurrency
          value={[
            { value: value.convertedCumulativeTotalRevenue, currency: value.convertedCurrency },
            { value: value.cumulativeTotalRevenue, currency: value.currency },
          ]}
        />
      );

    case 'hours':
      return <Hours value={value.cumulativeTotalHours} />;

    case 'cost':
      return (
        <MultiCurrency
          value={[
            { value: value.convertedCumulativeCost, currency: value.convertedCurrency },
            { value: value.cumulativeCost, currency: value.currency },
          ]}
        />
      );

    case 'total_profit':
      return (
        <MultiCurrency
          value={[
            { value: value.convertedCumulativeProfit, currency: value.convertedCurrency },
            { value: value.cumulativeProfit, currency: value.currency },
          ]}
        />
      );

    case 'margin':
      return <Percent value={value.cumulativeMargin} />;

    default:
      return null;
  }
}

function CumulativePerformanceTooltip({ value, children }) {
  if (!value) return null;

  return (
    <ListTooltip
      message={
        <List>
          <List.Item label="Client Billable Hours">
            <Hours value={value.cumulativeBillableHours} />
          </List.Item>
          <List.Item label="Client Non-Billable Hours">
            <Hours value={value.cumulativeNonBillableHours} />
          </List.Item>
          <List.Item label="Internal Hours">
            <Hours value={value.cumulativeInternalHours} />
          </List.Item>
          <List.Item label="Total Hours">
            <Hours value={value.cumulativeTotalHours} />
          </List.Item>
          <List.Item label="Services Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeServicesRevenue, currency: value.convertedCurrency },
                { value: value.cumulativeServicesRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Expenses Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeExpensesRevenue, currency: value.convertedCurrency },
                { value: value.cumulativeExpensesRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Other Items Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeOtherItemsRevenue, currency: value.convertedCurrency },
                { value: value.cumulativeOtherItemsRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Total Revenue">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeTotalRevenue, currency: value.convertedCurrency },
                { value: value.cumulativeTotalRevenue, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Labor Cost">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeLaborCost, currency: value.convertedCurrency },
                { value: value.cumulativeLaborCost, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Expenses Cost">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeExpensesCost, currency: value.convertedCurrency },
                { value: value.cumulativeExpensesCost, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Total Cost">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeCost, currency: value.convertedCurrency },
                { value: value.cumulativeCost, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Services Gross Profit">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeServicesProfit, currency: value.convertedCurrency },
                { value: value.cumulativeServicesProfit, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Services Gross Margin">
            <Percent value={value.cumulativeServicesMargin} />
          </List.Item>
          <List.Item label="Total Gross Profit">
            <MultiCurrency
              value={[
                { value: value.convertedCumulativeProfit, currency: value.convertedCurrency },
                { value: value.cumulativeProfit, currency: value.currency },
              ]}
            />
          </List.Item>
          <List.Item label="Total Gross Margin">
            <Percent value={value.cumulativeMargin} />
          </List.Item>
        </List>
      }>
      {children}
    </ListTooltip>
  );
}

function Chart({ periods, records, unit, currency }) {
  const dateTimeFormat = useDateTimeFormat(dateFormats.compactDate);

  const { settings, datasets } = useMemo(() => {
    const data = periods.map((period) => records.periods[period] ?? period);

    const settings = {
      day: () => ({
        labels: data.map(({ start }) => dateTimeFormat.format(start)),
      }),
      week: () => ({
        labels: data.map(({ start }) => dateTimeFormat.format(start)),
      }),
      month: () => ({
        labels: data.map(({ start }) => moment(start).format(dateFormats.monthYear)),
      }),
    }[unit]();

    const datasets = [
      {
        id: 'revenue',
        label: 'Revenue    ',
        data: data.map(({ convertedTotalRevenue }) => convertedTotalRevenue),
        backgroundColor: colors.primary,
      },
      {
        id: 'profit',
        label: 'Profit    ',
        data: data.map(({ convertedProfit }) => convertedProfit),
        backgroundColor: colors.primary25,
      },
    ];

    return { settings, datasets };
  }, [periods, records, unit, dateTimeFormat]);

  const currencyFormat = {
    tooltip: useCurrencyFormat({ currency, minimumFractionDigits: 0, maximumFractionDigits: 2 }),
  };

  return (
    <Widget style={{ height: '20rem' }}>
      <Bar
        data={{
          labels: settings.labels,
          datasets: datasets,
        }}
        options={{
          maintainAspectRatio: false,

          layout: {
            padding: { top: 12 },
          },

          plugins: {
            legend: {
              onClick: null,
              labels: {
                font: { size: 12 },
                pointStyleWidth: 14,
                boxHeight: 10,
                filter: (item) => !item.text.includes('Total'),
                usePointStyle: true,
              },
            },

            tooltip: {
              filter: (item) => item.datasetIndex <= 3,
              callbacks: {
                title: () => '',
                label: (tooltip) => {
                  let label = (tooltip.dataset.label || '').trim();
                  if (label) {
                    label += ': ';
                  }
                  label += currencyFormat.tooltip.format(tooltip.parsed.y);
                  return label;
                },
              },
            },
          },

          scales: {
            x: {
              grid: { display: false },
              ticks: {
                font: {
                  size: 12,
                  weight: 'bold',
                },
                color: colors.grey40,
                minRotation: settings.rotation,
                maxRotation: settings.rotation,
              },
            },

            y: {
              display: true,
              grid: { display: true, color: colors.grey10 },
              border: { display: false },
              max: settings.max,
              ticks: { stepSize: settings.stepSize, color: colors.grey40 },
            },
          },
        }}
      />
    </Widget>
  );
}
