import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  ClientSelect,
  Currency,
  DayPickerInput,
  FiltersBar,
  Level,
  MemberSelect,
  Page,
  PracticeSelect,
  ProjectBillingTypeFilter,
  ProjectStatusFilter,
  ProjectTaskStatusFilter,
  RouteLink,
  SingleSelect,
} from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures } from '~/hooks';
import billingTypes from '~/lookups/project-billing-types';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, QueryString } from '~/utils';
import ApprovedExpensesIcon from './assets/approved-expenses.svg?react';
import ApprovedServicesIcon from './assets/approved-services.svg?react';
import OtherItemsIcon from './assets/other-items.svg?react';
import ReadyToBillIcon from './assets/ready-to-bill.svg?react';
import CreateInvoiceDialog from './invoice-dialogs/CreateInvoiceDialog';
import ReadyToBillResults from './ReadyToBillResults';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 1.25rem;
  margin: 0 -2rem;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0 3px 15px ${colors.grey10};
  border-radius: 5px;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const SummaryBox = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem 1rem;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const SummaryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 3.125rem;
  height: 3.125rem;
`;

const SummaryAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  font-weight: ${weights.bold};
  font-size: 1rem;

  p {
    font-size: 0.75rem;
    font-weight: ${weights.normal};
    line-height: 1rem;
    white-space: nowrap;
  }
`;

const Actions = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }
`;

const intervalKey = 'readyToBillInterval';

function getSavedInterval(intervals, defaultInterval) {
  const intervalJson = localStorage.getItem(intervalKey);
  if (intervalJson) {
    const savedInterval = JSON.parse(intervalJson);
    if (savedInterval && savedInterval.key && savedInterval.key in intervals) {
      const interval = intervals[savedInterval.key];
      if (interval.key === 'custom') {
        interval.start = savedInterval.start;
        interval.end = savedInterval.end;
      }
      return interval;
    }
  }
  return defaultInterval;
}

function setSavedInterval(interval) {
  localStorage.setItem(intervalKey, JSON.stringify(interval));
}

const includePriorUnbilledItemsKey = 'includePriorUnbilledItems';

function getSavedIncludePriorUnbilledItems() {
  const value = localStorage.getItem(includePriorUnbilledItemsKey);
  if (!_.includes(['yes', 'no'], value)) return 'yes';

  return value;
}

function setSavedIncludePriorUnbilledItems(value) {
  localStorage.setItem(includePriorUnbilledItemsKey, value);
}

const clientKey = 'client';

function getSavedClient() {
  const clientJson = localStorage.getItem(clientKey);
  return JSON.parse(clientJson);
}

function setSavedClient(value) {
  localStorage.setItem(clientKey, JSON.stringify(value || null));
}

const projectAdminKey = 'projectAdmin';

function getSavedProjectAdmin() {
  const projectAdmin = localStorage.getItem(projectAdminKey);
  return JSON.parse(projectAdmin);
}

function setSavedProjectAdmin(value) {
  localStorage.setItem(projectAdminKey, JSON.stringify(value || null));
}

const approvedItemsOnlyKey = 'approvedItemsOnly';

function getSavedApprovedItemsOnly() {
  const value = localStorage.getItem(approvedItemsOnlyKey);
  if (!_.includes(['yes', 'no'], value)) return 'no';

  return value;
}

function setSavedApprovedItemsOnly(value) {
  localStorage.setItem(approvedItemsOnlyKey, value);
}

const projectBillingTypesKey = 'projectBillingTypes';

function getSavedProjectBillingTypes() {
  const projectBillingTypes = localStorage.getItem(projectBillingTypesKey);
  if (projectBillingTypes) return JSON.parse(projectBillingTypes);
  return [];
}

function setSavedProjectBillingTypes(value) {
  localStorage.setItem(projectBillingTypesKey, JSON.stringify(value || []));
}

const projectStatusesKey = 'projectStatuses';

function getSavedProjectStatuses() {
  const projectStatuses = localStorage.getItem(projectStatusesKey);
  if (projectStatuses) return JSON.parse(projectStatuses);
  return [];
}

function setSavedProjectStatuses(value) {
  localStorage.setItem(projectStatusesKey, JSON.stringify(value || []));
}

const projectTaskStatusesKey = 'projectTaskStatuses';

function getSavedProjectTaskStatuses() {
  const projectTaskStatuses = localStorage.getItem(projectTaskStatusesKey);
  if (projectTaskStatuses) return JSON.parse(projectTaskStatuses);
  return [];
}

function setSavedProjectTaskStatuses(value) {
  localStorage.setItem(projectTaskStatusesKey, JSON.stringify(value || []));
}

function setSavedClientPractice(value) {
  localStorage.setItem('clientPractice', JSON.stringify(value || null));
}

function setSavedProjectPractice(value) {
  localStorage.setItem('projectPractice', JSON.stringify(value || null));
}

function getSavedClientPractice() {
  const clientPractice = localStorage.getItem('clientPractice');
  if (clientPractice) return JSON.parse(clientPractice);
  return null;
}

function getSavedProjectPractice() {
  const projectPractice = localStorage.getItem('projectPractice');
  if (projectPractice) return JSON.parse(projectPractice);
  return null;
}

function ReadyToBillPage() {
  useDocumentTitle('Ready to Bill');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [selection, setSelection] = useState([]);
  const [{ status, data }, setQuery] = useState({ status: 'loading', data: null });

  const intervals = useMemo(
    () =>
      _.pick(
        intervalOptions,
        'custom',
        'this_week',
        'this_semi_month',
        'this_month',
        'this_month_to_date',
        'last_week',
        'last_semi_month',
        'last_month',
      ),
    [],
  );
  const [{ key, start, end }, setInterval] = useState(getSavedInterval(intervals, intervals.this_month));
  const [includePriorUnbilledItems, setIncludePriorUnbilledItems] = useState(getSavedIncludePriorUnbilledItems);
  const [client, setClient] = useState(getSavedClient);
  const [projectAdmin, setProjectAdmin] = useState(getSavedProjectAdmin);
  const [approvedItemsOnly, setApprovedItemsOnly] = useState(getSavedApprovedItemsOnly);
  const [projectBillingTypes, setProjectBillingTypes] = useState(getSavedProjectBillingTypes);
  const [projectStatuses, setProjectStatuses] = useState(getSavedProjectStatuses);
  const [projectTaskStatuses, setProjectTaskStatuses] = useState(getSavedProjectTaskStatuses);
  const [clientPractice, setClientPractice] = useState(getSavedClientPractice);
  const [projectPractice, setProjectPractice] = useState(getSavedProjectPractice);

  const { notify } = useSubscription();

  const [dialogItem, setDialogItem] = useState(null);

  const toast = useToast();

  const features = useFeatures();

  const fetchData = useCallback(async () => {
    setQuery((state) => (state.status === 'ready' ? { ...state, status: 'filtering' } : state));

    const { data } = await api.www
      .workspaces(workspace.id)
      .invoices()
      .readyToBill({
        start: start || undefined,
        end: end || undefined,
        includePriorUnbilledItems: includePriorUnbilledItems === 'yes' || undefined,
        clientId: client ? client.id : undefined,
        projectAdminId: projectAdmin ? projectAdmin.id : undefined,
        approvedItemsOnly: approvedItemsOnly === 'yes' || undefined,
        projectBillingTypeId: projectBillingTypes?.map((v) => v.id),
        projectStatusId: projectStatuses?.map((v) => v.id),
        projectTaskStatusId: projectTaskStatuses?.map((v) => v.id),
        size: 200,
        clientPracticeId: clientPractice?.id,
        projectPracticeId: projectPractice?.id,
      });

    setQuery({ status: 'ready', data });
  }, [
    workspace.id,
    api,
    start,
    end,
    includePriorUnbilledItems,
    approvedItemsOnly,
    projectBillingTypes,
    projectStatuses,
    projectTaskStatuses,
    client,
    projectAdmin,
    clientPractice,
    projectPractice,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const history = useHistory();

  const handlePeriodChange = ({ target: { value } }) => {
    const { key, ...intervalSettings } = intervals[value];
    const interval = { key, start, end };

    if (value !== 'custom') {
      interval.start = intervalSettings.start;
      interval.end = intervalSettings.end;
    }

    handleIntervalChange(interval);
  };

  const handleDateChange = (name, value) => {
    handleIntervalChange({ key: 'custom', start, end, [name]: value });
  };

  const handleIntervalChange = (interval) => {
    setInterval(interval);
    setSelection([]);
    setSavedInterval(interval);
  };

  const handleCreateClick = () => {
    const projects = data.projects.filter((p) => selection.includes(p.id));
    const { client, currency } = projects[0];
    setDialogItem({ client, currency, projects });
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleCreate = async (client, currency, projects) => {
    try {
      const servicesThrough = moment(end || new Date()).format(dateFormats.isoDate);
      const { data: invoice } = await api.www
        .workspaces(workspace.id)
        .invoices()
        .createReadyToBill({
          clientId: client.id,
          currency,
          projectIds: projects.map((p) => p.id),
          issuedOn:
            workspace.invoiceIssueOn === 'through_date' ? servicesThrough : moment().format(dateFormats.isoDate),
          servicesThrough,
          periodStart: includePriorUnbilledItems === 'yes' ? null : start,
          periodEnd: end,
          approvedItemsOnly: approvedItemsOnly === 'yes' || undefined,
        });

      notify(useSubscription.keys.refresh_timer);
      notify(useSubscription.keys.refresh_time_approval_count);
      notify(useSubscription.keys.refresh_expense_approval_count);

      history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleIncludePriorUnbilledItemsChange = ({ target: { value } }) => {
    setIncludePriorUnbilledItems(value);
    setSavedIncludePriorUnbilledItems(value);
  };

  const handleClientChange = (client) => {
    setClient(client);
    setSavedClient(client);
  };

  const handleProjectAdminChange = (projectAdmin) => {
    setProjectAdmin(projectAdmin);
    setSavedProjectAdmin(projectAdmin);
  };

  const handleApprovedItemsOnlyChange = ({ target: { value } }) => {
    setApprovedItemsOnly(value);
    setSavedApprovedItemsOnly(value);
  };

  const handleProjectBillingTypesChange = (value) => {
    setProjectBillingTypes(value);
    setSavedProjectBillingTypes(value);
  };

  const handleProjectStatusesChange = (projectStatuses) => {
    setProjectStatuses(projectStatuses);
    setSavedProjectStatuses(projectStatuses);
  };

  const handleProjectTaskStatusesChange = (projectTaskStatuses) => {
    setProjectTaskStatuses(projectTaskStatuses);
    setSavedProjectTaskStatuses(projectTaskStatuses);
  };

  const handleClientPractice = (clientPractice) => {
    setClientPractice(clientPractice);
    setSavedClientPractice(clientPractice);
  };

  const handleProjectPractice = (projectPractice) => {
    setProjectPractice(projectPractice);
    setSavedProjectPractice(projectPractice);
  };

  const uninvoicedRevenue = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/financial/uninvoiced-revenue?${new QueryString(
        {
          start: includePriorUnbilledItems === 'yes' ? 'not_set' : start ?? 'not_set',
          end: end ?? 'not_set',
          projectRecordStatusId: 'all',
          client: client ? client.id : undefined,
          timeStatus: approvedItemsOnly === 'yes' ? ['approved'] : undefined,
          expenseStatus: approvedItemsOnly === 'yes' ? ['approved'] : undefined,
          projectBillingType: projectBillingTypes?.map((v) => v.id),
          projectStatus: projectStatuses?.map((v) => v.id),
          projectTaskStatus: projectTaskStatuses?.map((v) => v.id),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [
      includePriorUnbilledItems,
      end,
      start,
      client,
      approvedItemsOnly,
      projectBillingTypes,
      projectStatuses,
      projectTaskStatuses,
      workspace.key,
    ],
  );

  if (status === 'loading') return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Billing</Page.Eyebrow>
            <Page.Title>Ready to Bill</Page.Title>
          </Page.Info>
        </Page.Header>

        <Page.Section>
          <FiltersBar>
            <SingleSelect
              placeholder="Date Range"
              value={key}
              onChange={handlePeriodChange}
              data-testid="select_interval">
              {_.map(intervals, (interval, key) => (
                <option key={key} value={key}>
                  {interval.label}
                </option>
              ))}
            </SingleSelect>

            <>
              <DayPickerInput
                value={start}
                placeholder="Start"
                onChange={(value) => handleDateChange('start', value)}
              />

              <DayPickerInput value={end} placeholder="End" onChange={(value) => handleDateChange('end', value)} />
            </>

            <ClientSelect
              name="client"
              placeholder="All"
              materialAlwaysVisible
              materialPlaceholder="Client"
              activeOnly={false}
              value={client}
              onChange={({ target: { value } }) => handleClientChange(value)}
            />

            {features.practices && (
              <PracticeSelect
                name="clientPractice"
                placeholder="All"
                materialPlaceholder="Client Practice"
                materialAlwaysVisible
                value={clientPractice}
                onChange={({ target: { value } }) => handleClientPractice(value)}
              />
            )}

            <ProjectBillingTypeFilter
              value={projectBillingTypes}
              onChange={({ target: { value } }) => handleProjectBillingTypesChange(value)}
              options={[billingTypes.fixed, billingTypes.fixed_recurring, billingTypes.tm]}
            />

            <SingleSelect
              materialPlaceholder="Approved Items Only"
              value={approvedItemsOnly}
              onChange={handleApprovedItemsOnlyChange}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </SingleSelect>

            <SingleSelect
              materialPlaceholder="Include Unbilled Prior Items"
              value={includePriorUnbilledItems}
              onChange={handleIncludePriorUnbilledItemsChange}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </SingleSelect>

            {features.practices && (
              <PracticeSelect
                name="projectPractice"
                placeholder="All"
                materialPlaceholder="Project Practice"
                materialAlwaysVisible
                value={projectPractice}
                onChange={({ target: { value } }) => handleProjectPractice(value)}
              />
            )}

            <MemberSelect
              name="projectAdmin"
              placeholder="All"
              materialPlaceholder="Project Admin"
              materialAlwaysVisible
              value={projectAdmin}
              onChange={({ target: { value } }) => handleProjectAdminChange(value)}
            />

            <ProjectStatusFilter
              value={projectStatuses}
              onChange={({ target: { value } }) => handleProjectStatusesChange(value)}
            />

            <ProjectTaskStatusFilter
              value={projectTaskStatuses}
              onChange={({ target: { value } }) => handleProjectTaskStatusesChange(value)}
            />
          </FiltersBar>
        </Page.Section>

        <Page.Section>
          <SummarySection>
            <Summary fade={status === 'filtering'}>
              <SummaryBox>
                <SummaryIcon>
                  <ApprovedServicesIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue({ itemType: ['time_entry', 'fixed_fee_milestone'] })}>
                      <Currency value={data.totals.services} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total Services</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
              <SummaryBox>
                <SummaryIcon>
                  <ApprovedExpensesIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue({ itemType: ['expense_item', 'project_expense_item'] })}>
                      <Currency value={data.totals.expenses} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total Expenses</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
              <SummaryBox>
                <SummaryIcon>
                  <OtherItemsIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue({ itemType: 'other_item' })}>
                      <Currency value={data.totals.otherItems} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total Other Items</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
              <SummaryBox>
                <SummaryIcon>
                  <ReadyToBillIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue()}>
                      <Currency value={data.total} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total to Bill</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
            </Summary>
          </SummarySection>
        </Page.Section>

        <ReadyToBillResults
          includePriorUnbilledItems={includePriorUnbilledItems}
          start={start}
          end={end}
          data={data}
          selection={selection}
          onSelectionChange={handleSelectionChange}
          status={status}
          approvedItemsOnly={approvedItemsOnly}
          projectTaskStatuses={projectTaskStatuses}
        />
      </Page>

      {selection.length > 0 && (
        <Actions>
          <Level right>
            <Level.Item>
              <ActionButton disabled={selection.length === 0} onClick={() => handleCreateClick()}>
                Create Invoice
              </ActionButton>
            </Level.Item>
          </Level>
        </Actions>
      )}

      {dialogItem && (
        <CreateInvoiceDialog
          client={dialogItem.client}
          currency={dialogItem.currency}
          projects={dialogItem.projects}
          onCreate={handleCreate}
          onClose={() => setDialogItem(null)}
        />
      )}
    </>
  );
}

export default ReadyToBillPage;
