import styled from 'styled-components';

const Scroller = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  scrollbar-width: thin;
  min-height: 18rem;
`;

export default Scroller;
