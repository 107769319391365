import _ from 'lodash';
import React from 'react';
import { DisciplineFilter, LocationFilter, PracticeFilter, SkillFilter } from '~/components';
import ResourceStatusFilter from '../../resources/allocations/member-schedule/filters/ResourceStatusFilter';
import ResourceTypeFilter from '../../resources/allocations/member-schedule/filters/ResourceTypeFilter';

export default function ResourceFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.resourcePractices) && (
        <PracticeFilter
          materialPlaceholder="Resource Practice"
          value={filters.resourcePractices}
          onChange={({ target: { value } }) => onChange({ resourcePractices: value })}
        />
      )}

      {!_.isEmpty(filters.resourceDisciplines) && (
        <DisciplineFilter
          materialPlaceholder="Resource Discipline"
          value={filters.resourceDisciplines}
          onChange={({ target: { value } }) => onChange({ resourceDisciplines: value })}
        />
      )}

      {!_.isEmpty(filters.resourceLocations) && (
        <LocationFilter
          materialPlaceholder="Resource Location"
          value={filters.resourceLocations}
          onChange={({ target: { value } }) => onChange({ resourceLocations: value })}
        />
      )}

      {!_.isEmpty(filters.resourceSkills) && (
        <SkillFilter
          materialPlaceholder="Resource Skills"
          value={filters.resourceSkills}
          onChange={({ target: { value } }) => onChange({ resourceSkills: value })}
        />
      )}

      {!_.isEmpty(filters.resourceStatusId) && (
        <ResourceStatusFilter
          value={filters.resourceStatusId}
          onChange={({ target: { value } }) => onChange({ resourceStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.resourceTypeId) && (
        <ResourceTypeFilter
          value={filters.resourceTypeId}
          onChange={({ target: { value } }) => onChange({ resourceTypeId: value })}
        />
      )}
    </>
  );
}
