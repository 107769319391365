import {
  ActionButton,
  Button,
  ExportDialog,
  FiltersBar,
  Icon,
  JobTitleFilter,
  MemberSelect,
  MemberTagFilter,
  Page,
  PracticeSelect,
  SearchInput,
  SingleSelect,
  SkillFilter,
  Table,
  Tooltip,
} from '~/components';
import EmploymentTypeSelect from '~/components/EmploymentTypeSelect';
import SecurityRoleSelect from '~/components/SecurityRoleSelect';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import {
  useActions,
  useDocumentTitle,
  useFeatures,
  useIsMounted,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { QuerySort, dateFormats, mimeTypes } from '~/utils';
import DeleteMemberDialog from './DeleteMemberDialog';
import MemberInviteForm from './MemberInviteForm';
import MembersGrid from './MembersGrid';
import MembersList from './MembersList';
import DeactivateMemberConfirmation from '~/components/DeactivateMemberConfirmation';
import ExportDropdown from '../ExportDropdown.jsx';

const TableStatus = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ViewButton = styled(Button)`
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0;
  border: none;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }

  transition: all 100ms ease-in-out;
`;

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    isActive: 'true',
    invitationStatusId: '',
    securityRoleId: '',
    employmentTypeId: '',
    practice: null,
    skills: [],
    tags: [],
    jobTitles: [],
    manager: null,
    page: 0,
    sort: new QuerySort('member.name', 'asc'),
    size: 50,
    view: 'grid',
  },
  action: 'load',
  saved: false,
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  filter: (query, state) => ({
    query: { ...state.query, ...query, page: 0 },
    action: 'filter',
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItem: (item, { data }) => ({
    data: { ...data, results: data.results.map((i) => (i.id === item.id ? { ...i, ...item } : i)) },
  }),
  add: (value, state, actions) => {
    setTimeout(() => {
      actions.done();
    }, 1000);
    return { saved: true };
  },
  done: () => ({ saved: false }),
};

function MembersPage() {
  useDocumentTitle('Members');

  const { workspace } = useWorkspace();
  const features = useFeatures();

  const api = useApi();

  const [{ isReady: isDataReady, data, query, saved, searchParamsStatus, action }, actions] = useActions(
    handlers,
    initialState,
  );
  const [isSlackInfoLoading, setIsSlackInfoLoading] = useState(true);
  const [isSlackConnected, setIsSlackConnected] = useState(false);
  const toast = useToast();
  const confirmation = useConfirmation();
  const isMounted = useIsMounted();

  // Filter persistency
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        securityRoleId: {},
        employmentTypeId: { ...searchParamsConfig.employmentType, default: '' },
        isActive: {
          default: 'true',
          valid: ['true', 'false', 'all'],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        skills: searchParamsConfig.skills,
        tags: searchParamsConfig.memberTags,
        practice: searchParamsConfig.practice,
        invitationStatusId: searchParamsConfig.memberInvitationStatus,
        jobTitles: searchParamsConfig.jobTitles,
        manager: searchParamsConfig.member,
        view: { default: 'grid', valid: ['grid', 'list'] },
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'members_list',
    onChange: useCallback((params) => actions.filter(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.filter(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const history = useHistory();
  const location = useLocation();
  const { url, path } = useRouteMatch();

  const isReady = useMemo(() => isDataReady && !isSlackInfoLoading, [isDataReady, isSlackInfoLoading]);

  const fetchData = useCallback(async () => {
    try {
      const fetchQuery = _.omit(query, ['practice', 'securityRole', 'tags', 'skills', 'jobTitles', 'manager']);
      fetchQuery.practiceId = query.practice?.id ?? '';
      fetchQuery.q = query.q;
      fetchQuery.securityRoleId = query.securityRoleId ?? undefined;
      fetchQuery.isActive = query.isActive ?? undefined;
      fetchQuery.employmentType = query.employmentTypeId ?? undefined;
      fetchQuery.skillIds = query.skills?.map((v) => v.id);
      fetchQuery.tagIds = query.tags?.map((tag) => tag.id);
      fetchQuery.invitationStatusId = query.invitationStatusId ?? undefined;
      fetchQuery.jobTitleId = query.jobTitles?.map((title) => title.id);
      fetchQuery.managerId = query.manager?.id ?? undefined;
      fetchQuery.sort = query.sort;

      const { data } = await api.www.workspaces(workspace.id).members().list(fetchQuery);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [] });
    }
  }, [actions, workspace.id, query, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  useEffect(() => {
    (async () => {
      setIsSlackInfoLoading(true);

      const { data: isConnected } = await api.www.workspaces(workspace.id).integrations().slack.getConnected();

      setIsSlackInfoLoading(false);
      setIsSlackConnected(isConnected);
    })();
  }, [api, workspace.id, isMounted]);

  const handleFilter = ({ target: { name, value } }) => {
    actions.filter({ ...query, [name]: value });
    searchParams.set({ [name]: value });
  };

  async function handleActiveStatusChange(member, isActive) {
    if (!isActive) {
      const confirm = await confirmation.prompt((resolve) => (
        <DeactivateMemberConfirmation memberId={member.id} workspaceId={workspace.id} resolve={resolve} />
      ));
      if (!confirm) return;
    }

    let activeEndDate = null;
    if (!isActive) {
      if (member.activeStartDate) {
        activeEndDate = moment.max(moment(member.activeStartDate), moment()).format(dateFormats.isoDate);
      } else {
        activeEndDate = moment().format(dateFormats.isoDate);
      }
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).setActiveStatus({
        isActive,
        activeStartDate: member.activeStartDate,
        activeEndDate,
      });
      actions.updateItem(data);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleRemove(member) {
    confirmation.prompt((resolve) => (
      <DeleteMemberDialog
        member={member}
        onClose={resolve}
        onDelete={async () => {
          fetchData();
          resolve(true);
        }}
      />
    ));
  }

  async function handleInviteSuccess() {
    actions.add();
    await fetchData();
  }

  async function handleSendInvitation(member) {
    try {
      await api.www.workspaces(workspace.id).members(member.id).sendInvitation();
      const { data } = await api.www.workspaces(workspace.id).members().get({ ids: member.id });
      actions.updateItem(data[0]);
      toast.success(`Successfully sent the invitation to join ${workspace.name} to ${member.name}.`);
    } catch (error) {
      toast.error(error?.message || `An error has occurred sending the invitation to ${member.name}.`);
    }
  }

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.filter({ ...query, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  function handleMemberClick(member) {
    history.push(`${url}/details/${member.id}`);
  }

  const handleViewChange = (view) => {
    const querySort = new QuerySort('member.name', 'asc');
    actions.filter({ ...query, sort: querySort, view });
    searchParams.set({ sort: null, view });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .members()
          .export(
            {
              ..._.omit(query, ['practice', 'securityRole', 'tags', 'skills', 'jobTitles', 'manager']),
              practiceId: query.practice?.id ?? '',
              q: query.q,
              securityRoleId: query.securityRoleId ?? undefined,
              isActive: query.isActive ?? undefined,
              employmentType: query.employmentTypeId ?? undefined,
              skillIds: query.skills?.map((v) => v.id),
              tagIds: query.tags?.map((tag) => tag.id),
              invitationStatusId: query.invitationStatusId ?? undefined,
              jobTitleId: query.jobTitles?.map((title) => title.id),
              managerId: query.manager?.id ?? undefined,
              size: null,
              sort: query.sort,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady || !data) return <PageLoader />;
  return (
    <>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Settings</Page.Eyebrow>
          <Page.Title>Members</Page.Title>
        </Page.Info>

        <Page.Actions>
          {
            {
              list: (
                <ViewButton data-testid="grid-button" onClick={() => handleViewChange('grid')}>
                  <Tooltip message="Grid View" delay={500}>
                    <Icon icon="grid-2" />
                  </Tooltip>
                </ViewButton>
              ),
              grid: (
                <ViewButton data-testid="list-button" onClick={() => handleViewChange('list')}>
                  <Tooltip message="List View" delay={500}>
                    <Icon icon="table-rows" />
                  </Tooltip>
                </ViewButton>
              ),
            }[query.view]
          }

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`members.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`members.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <ActionButton
            ok={saved}
            className="button"
            onClick={() => history.push({ pathname: `${url}/new`, search: location.search })}>
            Add Member
          </ActionButton>
        </Page.Actions>
      </Page.Header>

      <Page.Section>
        <FiltersBar>
          <SearchInput
            value={query.q}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleFilter}
          />

          <SecurityRoleSelect
            name="securityRoleId"
            placeholder="All"
            materialAlwaysVisible
            materialPlaceholder="Security Role"
            value={query.securityRoleId}
            showEmptyOption
            onChange={handleFilter}
          />

          <MemberSelect
            name="manager"
            placeholder="All"
            materialPlaceholder="Manager"
            materialAlwaysVisible
            value={query.manager}
            onChange={handleFilter}
          />

          <EmploymentTypeSelect
            name="employmentTypeId"
            value={query.employmentTypeId}
            placeholder="All"
            materialAlwaysVisible
            materialPlaceholder="Member Type"
            showEmptyOption
            onChange={handleFilter}
          />

          <SingleSelect
            name="invitationStatusId"
            value={query.invitationStatusId}
            placeholder="All"
            materialPlaceholder="Invitation Status"
            materialAlwaysVisible
            showEmptyOption
            onChange={handleFilter}>
            <option value="invited">Invited</option>
            <option value="accepted">Accepted</option>
            <option value="not_invited">Not Invited</option>
          </SingleSelect>

          <SingleSelect
            name="isActive"
            value={query.isActive}
            placeholder="All"
            materialPlaceholder="Status"
            materialAlwaysVisible
            showEmptyOption
            onChange={handleFilter}>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </SingleSelect>

          <SkillFilter name="skills" value={query.skills} onChange={handleFilter} />

          <MemberTagFilter name="tags" value={query.tags} onChange={handleFilter} />

          <JobTitleFilter
            name="jobTitles"
            materialPlaceholder="Job Title"
            value={query.jobTitles}
            onChange={handleFilter}
          />

          {features.practices && (
            <PracticeSelect
              name="practice"
              value={query.practice}
              placeholder="All"
              materialPlaceholder="Practice"
              materialAlwaysVisible
              onChange={handleFilter}
            />
          )}
        </FiltersBar>
      </Page.Section>

      <Page.Section>
        <TableStatus>
          {!!action && <Icon icon="spinner" spin spaceRight style={{ fontSize: '0.875rem' }} />}
          <Table.Total value={data.total} label="Member" />
        </TableStatus>
      </Page.Section>

      {query.view === 'grid' && (
        <MembersGrid
          data={data}
          action={action}
          isSlackConnected={isSlackConnected}
          onLoadMore={actions.loadMore}
          onCardClick={handleMemberClick}
          onSendInvitation={handleSendInvitation}
          onActiveStatusChange={handleActiveStatusChange}
          onRemove={handleRemove}
        />
      )}

      {query.view === 'list' && (
        <MembersList
          data={data}
          action={action}
          query={query}
          isSlackConnected={isSlackConnected}
          onLoadMore={actions.loadMore}
          onRowClick={handleMemberClick}
          onSendInvitation={handleSendInvitation}
          onActiveStatusChange={handleActiveStatusChange}
          onRemove={handleRemove}
          onSort={handleSort}
        />
      )}

      <Route path={`${path}/new`}>
        <MemberInviteForm
          onClose={() => history.push({ pathname: url, search: location.search })}
          onInvited={handleInviteSuccess}
        />
      </Route>
    </>
  );
}

export default MembersPage;
