import _ from 'lodash';
import React from 'react';
import { projectHealthRatings } from '~/lookups';
import MultiSelect from '../MultiSelect';

const options = _.values(projectHealthRatings);

export default function ProjectHealthFilter({ materialPlaceholder, placeholder = 'All', name, value, onChange }) {
  return (
    <MultiSelect.Input
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      onChange={onChange}
    />
  );
}
