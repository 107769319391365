import _ from 'lodash';
import React from 'react';
import CreditNoteSentFilter from '~/components/filters/CreditNoteSentFilter';
import CreditNoteStatusFilter from '~/components/filters/CreditNoteStatusFilter';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';

export default function CreditNoteFiltersGroup({ filters, onChange }) {
  return (
    <FiltersGroup label="Credit Note Filters" filters={[filters.statuses, filters.creditNoteSent]}>
      {_.has(filters, 'statuses') && (
        <Filter>
          <CreditNoteStatusFilter
            value={filters.statuses}
            onChange={({ target: { value } }) => onChange({ statuses: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'creditNoteSent') && (
        <Filter>
          <CreditNoteSentFilter
            value={filters.creditNoteSent}
            onChange={({ target: { value } }) => onChange({ creditNoteSent: value })}
          />
        </Filter>
      )}
    </FiltersGroup>
  );
}
