import classNames from 'classnames';
import { useIsMounted, useOnClickOutside } from '~/hooks';
import { rgba } from 'polished';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';
import DropdownDeleteItem from './DropdownDeleteItem';
import InlineTooltip from './InlineTooltip';

const Content = styled.div`
  padding: 0.5rem 0;
  background-color: ${colors.white};
  border-radius: 0.25rem;
  box-shadow:
    0 0.5em 1em -0.125em ${rgba(colors.black, 0.1)},
    0 0 0 1px ${rgba(colors.black, 0.02)};
`;

const Menu = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  padding-top: 0.25rem;
  z-index: 20;
  min-width: 100%;

  &.is-open {
    display: block;
  }

  &.align-right {
    left: auto;
    right: 0;
  }

  &.menu-position-top {
    bottom: 100%;
    padding-bottom: 0.25rem;
    padding-top: initial;
    top: auto;
  }

  &.scrollable > ${Content} {
    max-height: 15rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const itemStyle = css`
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  color: ${colors.black};
  text-align: left;
  position: relative;

  &:hover {
    background-color: ${colors.grey5};
    color: ${colors.black};
  }
`;

const ItemButton = styled(Button)`
  ${itemStyle}

  &:disabled {
    cursor: not-allowed;

    &&&:hover {
      background: none;
      font-weight: normal;
      color: ${colors.black};
    }
  }
`;

const ItemLink = styled(Link)`
  ${itemStyle}
`;

const ItemText = styled.span`
  display: flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;
  color: ${colors.grey55};
  cursor: default;
`;

const Container = styled.div`
  position: relative;
  vertical-align: top;
`;

const DropdownContext = React.createContext();

function Dropdown({
  children,
  align = 'left',
  position = 'auto',
  offsetToShowMenuOnTop = 25,
  className,
  onClose,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const isMounted = useIsMounted();

  useOnClickOutside(
    dropdownRef,
    useCallback(() => {
      if (!isOpen && !props.isOpen) return;

      if (props.isOpen === undefined) {
        setIsOpen(false);
      } else {
        onClose && onClose(false);
      }
    }, [isOpen, props.isOpen, onClose]),
  );

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleSetIsOpen = (flag) => {
    if (!isMounted.current) return;
    setIsOpen(!!flag);
  };

  return (
    <DropdownContext.Provider value={{ align, position, isOpen, offsetToShowMenuOnTop }}>
      <Container ref={dropdownRef} className={className} {...props}>
        {typeof children === 'function' ? children({ setIsOpen: handleSetIsOpen, isOpen }) : children}
      </Container>
    </DropdownContext.Provider>
  );
}

const DropdownTrigger = styled.div``;

function DropdownMenu({ children, className, scrollable, ...props }) {
  const menuRef = useRef();
  let { align, position: defaultMenuPosition, isOpen, offsetToShowMenuOnTop } = useContext(DropdownContext);

  const [position, setMenuPosition] = useState(defaultMenuPosition);

  useLayoutEffect(() => {
    if (defaultMenuPosition !== 'auto') return;

    const el = menuRef.current;
    const rect = el.getBoundingClientRect();
    setMenuPosition(rect.bottom + offsetToShowMenuOnTop > window.outerHeight ? 'top' : 'bottom');
  }, [isOpen, offsetToShowMenuOnTop, defaultMenuPosition]);

  return (
    <Menu
      ref={menuRef}
      className={classNames(className, {
        'is-open': isOpen,
        'align-right': align === 'right',
        'menu-position-top': position === 'top',
        scrollable,
      })}
      {...props}>
      {isOpen && <Content>{children}</Content>}
    </Menu>
  );
}
DropdownMenu.displayName = 'DropdownMenu';

export function DropdownItem({ tooltip, tooltipPlacement, children, ...props }) {
  return (
    <ItemButton isAnchor {...props} onClick={props.disabled ? undefined : props.onClick}>
      {children}
      {tooltip && <InlineTooltip message={tooltip} placement={tooltipPlacement} />}
    </ItemButton>
  );
}

export function DropdownLink(props) {
  return <ItemLink {...props} />;
}

function DropdownText(props) {
  return <ItemText {...props} />;
}

const DropdownPanel = styled.div`
  padding: 0.5rem;
`;

Dropdown.Trigger = DropdownTrigger;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
Dropdown.DeleteItem = DropdownDeleteItem;
Dropdown.Link = DropdownLink;
Dropdown.Text = DropdownText;
Dropdown.Panel = DropdownPanel;

export default Dropdown;
export { Container, DropdownTrigger, Menu, Content, ItemButton, ItemLink };
