import {
  BillableIcon,
  DateTime,
  DeleteConfirmation,
  Duration,
  Icon,
  ListView,
  ListViewActions,
  ListViewMenu,
  TimeApprovalPopover,
  TimeLockIcon,
  Tooltip,
} from '~/components';
import { useConfirmation } from '~/contexts';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const Time = styled.div`
  position: relative;
  padding: 0.2rem 1.5rem;
  font-weight: ${weights.medium};
  border-radius: 999rem;
  width: 6.25rem;
  text-align: center;

  ${({ status }) =>
    ({
      not_submitted: css`
        background: ${colors.grey10};
        color: ${colors.black};
      `,
      pending_approval: css`
        background: ${colors.warning10};
        color: ${colors.warning};
      `,
      rejected: css`
        background: ${colors.danger10};
        color: ${colors.danger};
      `,
      approved: css`
        background: ${colors.primary10};
        color: ${colors.primary};
      `,
    })[status]}
`;

const Stopwatch = styled(Tooltip)`
  position: absolute;
  top: 0.2rem;
  left: 0.5rem;
`;

const TimeEntryInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div:first-child {
    margin-right: 1rem;
  }
`;

function TimeAuditingResults({
  data,
  sort,
  showProjectColumn = true,
  onLoadMore,
  onSort,
  onDelete,
  onResultClick,
  action,
}) {
  const confirmation = useConfirmation();

  return (
    <ListView>
      <ListView.Header sticky>
        <ListView.Column width="3.5rem" align="center" />
        <ListView.Column name="date" width="7.5rem" sort={sort} onSort={onSort}>
          Date
        </ListView.Column>
        <ListView.Column minWidth="16rem" name="member" sort={sort} onSort={onSort}>
          Member
        </ListView.Column>
        <ListView.Column
          minWidth="16rem"
          name="project"
          align="left"
          sort={sort}
          isVisible={showProjectColumn}
          onSort={onSort}>
          Project/Client
        </ListView.Column>
        <ListView.Column minWidth="16rem">Role/Task</ListView.Column>
        <ListView.Column align="center" width="11rem">
          Time
        </ListView.Column>
        <ListViewActions.Column />
      </ListView.Header>
      <ListView.Body fade={action === 'filter'}>
        {data.results.map((entry) => {
          const locked = entry.isLocked;
          const tooltip = locked ? 'The time entry is locked.' : undefined;

          const handleEdit = () => onResultClick(entry, 'edit');
          const handleView = () => onResultClick(entry, 'view');

          const handleDelete = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <DeleteConfirmation resolve={resolve} title="Delete Time Entry">
                Are you sure you want to delete this time entry?
              </DeleteConfirmation>
            ));
            if (!confirm) return;

            onDelete(entry);
          };

          return (
            <ListView.Row key={entry.id} onClick={locked ? handleView : handleEdit}>
              <ListView.Cell>{locked && <TimeLockIcon value={entry.lockStatusId} />}</ListView.Cell>
              <ListView.Cell>
                <DateTime value={entry.date} />
              </ListView.Cell>
              <ListView.Cell>{entry.member.name}</ListView.Cell>
              <ListView.Cell>
                {entry.typeId === 'project_time' ? (
                  entry.project && (
                    <ListView.CellContent>
                      {entry.project.name}
                      <small>{entry.project.client.name}</small>
                    </ListView.CellContent>
                  )
                ) : (
                  <ListView.CellContent>{entry.type.name}</ListView.CellContent>
                )}
              </ListView.Cell>
              <ListView.Cell>
                {entry.typeId === 'project_time' ? (
                  entry.project && (
                    <ListView.CellContent>
                      {entry.project?.useRoles && entry.role?.name}
                      {entry.task && <small>{entry.task.name}</small>}
                    </ListView.CellContent>
                  )
                ) : (
                  <ListView.CellContent>{entry.timeOffType?.name}</ListView.CellContent>
                )}
              </ListView.Cell>
              <ListView.Cell flex="1">
                <TimeApprovalPopover timeEntryId={entry.id}>
                  <TimeEntryInfo>
                    <BillableIcon value={entry.isActuallyBillable} />
                    <Time status={entry.statusId}>
                      {entry.timerStartedAt && (
                        <Stopwatch message="This time entry has a running timer">
                          <Icon icon="stopwatch" type="far" />
                        </Stopwatch>
                      )}
                      <Duration minutes={entry.minutes} timerStartedAt={entry.timerStartedAt} trim />
                    </Time>
                  </TimeEntryInfo>
                </TimeApprovalPopover>
              </ListView.Cell>
              <ListViewActions>
                {locked ? <ListViewActions.View onClick={handleView} /> : <ListViewActions.Edit onClick={handleEdit} />}

                <hr />

                <ListViewMenu>
                  {({ setIsOpen }) => {
                    return (
                      <>
                        <ListViewMenu.Item onClick={handleView}>View</ListViewMenu.Item>

                        <ListViewMenu.Item disabled={locked} tooltip={tooltip} onClick={handleEdit}>
                          Edit
                        </ListViewMenu.Item>

                        <ListViewMenu.Item
                          disabled={locked}
                          tooltip={tooltip}
                          onClick={() => setIsOpen(false) || handleDelete()}>
                          Delete
                        </ListViewMenu.Item>
                      </>
                    );
                  }}
                </ListViewMenu>
              </ListViewActions>
            </ListView.Row>
          );
        })}

        {data.results.length === 0 && <ListView.Empty />}

        {data.total > data.results.length && <ListView.Loader key={data.results.length} onIntersecting={onLoadMore} />}
      </ListView.Body>
    </ListView>
  );
}

export default TimeAuditingResults;
