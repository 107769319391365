import styled from 'styled-components';

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.1 : 1)};
`;

export default Body;
