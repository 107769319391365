import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  BillableIcon,
  Currency,
  ExportDialog,
  FiltersBar,
  HoursProgress,
  ListView,
  ListViewActions,
  ListViewMenu,
  MemberContactPopover,
  MemberSelect,
  Page,
  Percent,
  SingleSelect,
  Spinner,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, mimeTypes, QueryString } from '~/utils';
import ExportDropdown from '../ExportDropdown';
import ProjectTimeViewSelect from './ProjectTimeViewSelect';
import ReplaceRoleDialog from './ReplaceRoleDialog';

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Container = styled.div`
  font-size: 1rem;
`;

const Members = styled.div`
  margin: -0.25rem;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0.25rem;
  }
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

const RoleName = styled.div`
  color: ${({ active }) => (active ? colors.grey100 : colors.grey40)};
`;

const MembersByRole = ({ role }) => {
  if (_.isEmpty(role.members)) return null;

  const membersLength = role.members.length;
  const trimmedMembers = membersLength > 5 ? role.members.slice(5) : null;
  const members = membersLength > 5 ? role.members.slice(0, 5) : role.members;

  return (
    <>
      <Members>
        {members.map((member) => (
          <Member key={member.id}>
            <MemberContactPopover member={member} placement="left">
              <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
            </MemberContactPopover>
          </Member>
        ))}
      </Members>
      {trimmedMembers && (
        <Tooltip
          placement="right"
          message={
            <Container>
              <Title>Assigned To</Title>
              {trimmedMembers.map((member) => (
                <Tag style={{ backgroundColor: colors.grey5 }} key={member.id}>
                  <small>{member.name}</small>
                </Tag>
              ))}
            </Container>
          }>
          <Tag
            style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default', marginLeft: '0.50rem' }}>
            <small>+{trimmedMembers.length}</small>
          </Tag>
        </Tooltip>
      )}
    </>
  );
};

function ProjectTimeByRole({ project, view, onViewChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });
  const [params, setParams] = useState({
    refreshCounter: 0,
    member: null,
    billableTypeId: null,
  });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .timeByRole({
          projectMemberId: params.member?.id,
          billableTypeId: params.billableTypeId ?? undefined,
        });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const handleDataChange = () => {
    setParams({ ...params, refreshCounter: params.refreshCounter + 1 });
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .timeByRole(
            {
              projectMemberId: params.member?.id,
              billableTypeId: params.billableTypeId ?? undefined,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Page.Section>
        <FiltersBar.Wrapper>
          <FiltersBar>
            <ProjectTimeViewSelect project={project} value={view} onChange={onViewChange} />

            <MemberSelect
              materialPlaceholder="Member"
              placeholder="All"
              materialAlwaysVisible
              value={params.member}
              onChange={({ target: { value } }) => handleFilter({ member: value })}
            />

            <SingleSelect
              materialPlaceholder="Billable"
              placeholder="All"
              materialAlwaysVisible
              showEmptyOption
              value={params.billableTypeId}
              onChange={({ target: { value } }) => handleFilter({ billableTypeId: value })}>
              <option value="billable">Yes</option>
              <option value="non_billable">No</option>
            </SingleSelect>
          </FiltersBar>

          <FiltersBar.Actions>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`${_.snakeCase(project.key)}_time_by_role.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`${_.snakeCase(project.key)}_time_by_role.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </FiltersBar.Actions>
        </FiltersBar.Wrapper>
      </Page.Section>

      <Data data={data} project={project} params={params} isReady={isReady} onChange={handleDataChange} />
    </>
  );
}

function Data({ data, project, params, isReady, onChange }) {
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();

  if (!isReady) return <PageLoader />;

  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const { records, totals, columns } = data;

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time/time-entries${new QueryString({
      start: 'not_set',
      end: moment().format(dateFormats.isoDate),
      project: project.id,
      member: params.member?.id,
      billableType: params.billableTypeId
        ? project.client.isInternal && params.billableTypeId === 'non_billable'
          ? 'internal'
          : params.billableTypeId
        : undefined,
      ...query,
    }).toString(true)}`;

  const url = {
    total: (projectRole) => timeDetail({ projectRole }),
  };

  const currency = project.currency;

  const handleReplace = async (role) => {
    return await confirmation.prompt((resolve) => (
      <ReplaceRoleDialog
        roleId={role.id}
        projectId={project.id}
        currency={project.currency}
        onClose={() => resolve()}
        onSaved={() => {
          onChange();
          resolve(true);
        }}
      />
    ));
  };

  return (
    <>
      <Page.Section>
        <ListView.Status>
          {!isReady && <Spinner />}
          <ListView.Total value={records.length} />
        </ListView.Status>
      </Page.Section>

      <ListView>
        <ListView.Header>
          <ListView.Column width="2rem" />

          <ListView.Column minWidth="16rem">Role</ListView.Column>

          <ListView.Column minWidth="12rem">Assigned To</ListView.Column>

          <ListView.Column width="12rem">Hours</ListView.Column>

          <ListView.Column align="right" width="7rem" isVisible={columns.realizationRate}>
            Realization Rate
          </ListView.Column>

          <ListView.Column align="right" width="8rem" isVisible={columns.budgetRevenue}>
            Budget Revenue
          </ListView.Column>

          <ListView.Column align="right" width="8rem" isVisible={columns.revenue}>
            Earned Revenue
          </ListView.Column>

          <ListView.Column align="right" width="7rem" isVisible={columns.budgetEffectiveRate}>
            Budget Effective Rate
          </ListView.Column>

          <ListView.Column align="right" width="7rem" isVisible={columns.effectiveRate}>
            Effective Rate
          </ListView.Column>
          <ListView.Column width="3.25rem"></ListView.Column>
        </ListView.Header>

        <ListView.Body>
          {records.map((role) => {
            return (
              <ListView.Row key={role.id} data-testid="row">
                <ListView.Cell>
                  <BillableIcon value={role.isBillable} />
                </ListView.Cell>

                <ListView.Cell>
                  <RoleName active={role.isActive}>{role.name}</RoleName>
                </ListView.Cell>

                <ListView.Cell>
                  <MembersByRole role={role} />
                </ListView.Cell>

                <ListView.Cell>
                  <HoursProgress worked={role.totalHours} estimated={role.budgetTotalHours} to={url.total(role.id)} />
                </ListView.Cell>

                <ListView.Cell>
                  <Percent value={role.realizationRate} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={role.budgetTotalRevenue} currency={currency} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={role.revenue} currency={currency} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={role.budgetEffectiveRate} currency={currency} />
                </ListView.Cell>

                <ListView.Cell>
                  <Currency value={role.effectiveRate} currency={currency} />
                </ListView.Cell>
                <ListViewActions>
                  <ListViewMenu>
                    {({ setIsOpen }) => {
                      const handleAction = async (action) => {
                        setIsOpen(false);
                        await action();
                      };
                      return (
                        <ListViewMenu.Item onClick={() => handleAction(() => handleReplace(role))}>
                          Replace Role
                        </ListViewMenu.Item>
                      );
                    }}
                  </ListViewMenu>
                </ListViewActions>
              </ListView.Row>
            );
          })}

          <ListView.Row style={{ fontWeight: weights.bold }}>
            <ListView.Cell />

            <ListView.Cell>Total</ListView.Cell>

            <ListView.Cell />

            <ListView.Cell>
              <HoursProgress worked={totals.totalHours} estimated={totals.budgetTotalHours} to={url.total()} />
            </ListView.Cell>

            <ListView.Cell>
              <Percent value={totals.realizationRate} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.budgetTotalRevenue} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.revenue} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.budgetEffectiveRate} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.effectiveRate} currency={currency} />
            </ListView.Cell>
            <ListView.Cell></ListView.Cell>
          </ListView.Row>
        </ListView.Body>
      </ListView>
    </>
  );
}

export default ProjectTimeByRole;
