import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  Confirmation,
  DateTime,
  Dropdown,
  FiltersBar,
  Icon,
  InlineTooltip,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
  SingleSelect,
  Spinner,
  SplitButton,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import ContactCreateForm from '../contacts/ContactCreateForm';
import ContactForm from '../contacts/ContactForm';
import ContactSelect from '../contacts/ContactSelect';
import ViewContactDrawer from '../contacts/ViewContactDrawer';

const ContactInfo = styled.div`
  line-height: 1;
  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const AddContactButton = styled(ActionButton)`
  margin-left: 0.5rem;
  border-radius: 999rem 0rem 0rem 999rem;
`;

const AddContactDropdownMenu = styled(Dropdown)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 20rem;
`;

const StyledLink = styled(SplitButton.Link)`
  white-space: nowrap;
`;

function CompanyContactsTab({ company }) {
  const documentTitle = useDocumentTitle();

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const { contactId, action } = useParams();
  const { url } = useRouteMatch();
  const toast = useToast();

  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [params, setParams] = useState({
    q: '',
    isActive: 'true',
    companyIds: [company.id],
    sort: new QuerySort('name', 'asc'),
  });

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).contacts().get(params);

      const contacts = data.map((c) => {
        // Find the companyContact for the specific companyId
        const companyContact = c.companyContacts.find((cc) => cc.companyId === company.id);

        // Return a new object with the original contact data and the isActive and isPrimary properties from the relation
        return {
          ...c,
          isActive: companyContact.isActive,
          isPrimary: companyContact.isPrimary,
        };
      });

      setQuery({ status: 'ready', data: contacts, error: null });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, params, company.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    fetchData();
  }

  async function handleDeleted() {
    fetchData();
    handleClose();
  }

  async function handleCreated(contact) {
    fetchData();
    handleEdit(contact);
  }

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((state) => ({ ...state, sort: new QuerySort(column, direction) }));
  };

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/contacts`);
    documentTitle.set(company.name);
  }

  const handleDeleteConfirmation = async (contact) => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to remove the association with this contact?</Confirmation>
    ));
    if (!confirm) return;

    await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).remove();

    await handleDeleted();
  };

  async function handleActiveStatusChange(contact, flag) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).setActiveStatus(flag);

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem updating the contact status. Please try again.');
    }
  }

  async function handlePrimaryStatusChange(contact, flag) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).setPrimaryStatus(flag);

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem updating the contact status. Please try again.');
    }
  }

  async function handleAddContact(contact) {
    try {
      await api.www.workspaces(workspace.id).contacts(contact.id).companies(company.id).add();

      await handleSaved();
    } catch (error) {
      toast.error(error.message || 'There was a problem adding the contact. Please try again.');
    }
  }

  const handleView = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/contacts/${id}/view`);
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/contacts/${id}/edit`);
  };

  if (query.status === 'loading') return <PageLoader />;
  if (!query.data) return <ErrorPage />;

  return (
    <>
      <Page.Section>
        <FiltersBar.Wrapper>
          <FiltersBar>
            <SearchInput
              value={params.q}
              placeholder="Search"
              materialPlaceholder="Contact Name"
              materialAlwaysVisible
              onChange={handleChange}
            />

            <SingleSelect
              name="isActive"
              value={params.isActive}
              placeholder="All"
              materialPlaceholder="Status"
              materialAlwaysVisible
              showEmptyOption
              onChange={handleChange}>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </SingleSelect>
          </FiltersBar>

          <FiltersBar.Actions>
            <AddContactDropdownMenu>
              {({ setIsOpen, isOpen }) => (
                <>
                  <Dropdown.Trigger>
                    <SplitButton style={{ alignItems: 'center' }}>
                      <AddContactButton onClick={() => setIsOpen(!isOpen)} disabled={!auth.pipeline.manage}>
                        Associate a Contact{' '}
                        {!auth.pipeline.manage && (
                          <InlineTooltip message="Your security role prohibits you from associating contacts." />
                        )}
                      </AddContactButton>

                      <SplitButton.Menu disabled={!auth.pipeline.manage}>
                        <StyledLink to={`${url}/new`}>Create a Contact</StyledLink>
                      </SplitButton.Menu>
                    </SplitButton>
                  </Dropdown.Trigger>
                  <Dropdown.Menu>
                    <Dropdown.Panel>
                      <ContactSelect
                        filterContacts={query.data}
                        onChange={async ({ target: { value } }) => {
                          handleAddContact(value);
                          setIsOpen(false);
                        }}
                      />
                    </Dropdown.Panel>
                  </Dropdown.Menu>
                </>
              )}
            </AddContactDropdownMenu>
          </FiltersBar.Actions>
        </FiltersBar.Wrapper>
      </Page.Section>

      <Page.Section>
        <ListView.Status>
          {query.status !== 'ready' && <Spinner />}
          <ListView.Total value={query.data.length} label="Contact" />
        </ListView.Status>
      </Page.Section>

      <ListView>
        <ListView.Header>
          <ListView.Column sticky width="3.5rem" padding="1rem" align="center" />
          <ListView.Column minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
            Contact
          </ListView.Column>
          <ListView.Column minWidth="16rem" name="email" onSort={handleSort} sort={params.sort}>
            Email
          </ListView.Column>
          <ListView.Column width="8rem" name="createdAt" onSort={handleSort} sort={params.sort} align="right">
            Created
          </ListView.Column>
          <ListViewActions.Column />
        </ListView.Header>

        <ListView.Body fade={query.status === 'filtering'}>
          {query.data.map((contact) => {
            const { id, createdAt, name, title, isActive, isPrimary, email } = contact;

            async function handleRowClick(contact) {
              if (contact.permissions.manage) {
                handleEdit(contact);
              } else {
                handleView(contact);
              }
            }

            return (
              <ListView.Row isDisabled={!isActive} key={id} onClick={() => handleRowClick(contact)}>
                <ListView.Cell>
                  {isPrimary && (
                    <Tooltip message="This is a primary contact.">
                      <Icon color={colors.grey40} spaceLeft icon="star" />
                    </Tooltip>
                  )}
                </ListView.Cell>
                <ListView.Cell>
                  <ContactInfo>
                    <p>{name}</p>
                    {title && <small>{title}</small>}
                  </ContactInfo>
                </ListView.Cell>
                <ListView.Cell>{email}</ListView.Cell>
                <ListView.Cell>
                  <DateTime value={createdAt} />
                </ListView.Cell>

                <ListViewActions>
                  {contact.permissions.manage ? (
                    <ListViewActions.Edit onClick={() => handleEdit(contact)} />
                  ) : (
                    <ListViewActions.View onClick={() => handleView(contact)} />
                  )}

                  <hr />

                  <ListViewMenu>
                    {({ setIsOpen }) => {
                      const handleAction = (action) => setIsOpen(false) || action;

                      return (
                        <>
                          <ListViewMenu.Item onClick={() => handleAction(handleView(contact))}>View</ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!contact.permissions.manage}
                            tooltip={
                              !contact.permissions.manage ? 'Insufficient permissions to edit this contact.' : undefined
                            }
                            onClick={() => handleAction(handleEdit(contact))}>
                            Edit
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!contact.permissions.manage}
                            tooltip={
                              !contact.permissions.manage
                                ? 'Insufficient permissions to remove this contact.'
                                : undefined
                            }
                            onClick={() => handleAction(handleDeleteConfirmation(contact))}>
                            Remove
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!contact.permissions.manage}
                            tooltip={
                              !contact.permissions.manage
                                ? `Insufficient permissions to ${isActive ? 'deactivate' : 'activate'} this contact.`
                                : undefined
                            }
                            onClick={() => handleAction(handleActiveStatusChange(contact, !isActive))}>
                            {isActive ? 'Deactivate' : 'Activate'}
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!contact.permissions.manage}
                            tooltip={
                              !contact.permissions.manage
                                ? `Insufficient permissions to ${isActive ? 'deactivate' : 'activate'} this contact.`
                                : undefined
                            }
                            onClick={() => handleAction(handlePrimaryStatusChange(contact, !isPrimary))}>
                            {isPrimary ? 'Set as not primary' : 'Set as primary'}
                          </ListViewMenu.Item>
                        </>
                      );
                    }}
                  </ListViewMenu>
                </ListViewActions>
              </ListView.Row>
            );
          })}

          {query.data.length === 0 && <ListView.Empty />}
        </ListView.Body>
      </ListView>

      {action &&
        {
          new: <ContactCreateForm companyId={company.id} onSaved={handleCreated} onClose={handleClose} />,
          edit: contactId && (
            <ContactForm
              companyId={company.id}
              onSaved={handleSaved}
              onDeleted={handleDeleteConfirmation}
              onClose={handleClose}
            />
          ),
          view: contactId && <ViewContactDrawer companyId={company.id} onClose={handleClose} />,
        }[action]}
    </>
  );
}

export default CompanyContactsTab;
