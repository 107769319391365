import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { alignToJustify } from './ListViewColumn';
import { useListView } from './ListViewContext';

const Cell = styled.div`
  flex: ${({ column }) => (column.width ? `0 0 ${column.width}` : '1')};
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2rem;
  justify-content: ${({ column }) => alignToJustify(column.align)};
  min-width: ${({ column }) => column.minWidth ?? column.width ?? 'auto'};
  padding: ${({ column, padding }) => (padding ? padding : column?.padding ? column.padding : '0.5rem 1rem')};
  text-align: ${({ column }) => column.align};
  font-size: ${({ column }) => column.fontSize ?? '0.875rem'};

  ${({ column }) =>
    !!column?.sticky &&
    css`
      position: sticky;
      left: 1px;
      z-index: 1;
      background: linear-gradient(to right, ${colors.white} 90%, transparent 100%);

      &::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
        width: 100%;
        border-left: 1px solid ${colors.grey10};
        pointer-events: none;
      }
    `}
`;

function ListViewCell({ className, children, index, style, ...props }) {
  const table = useListView();
  const column = table.columns[index];
  const mergedStyle = { ...(style ?? {}), ...(column?.style ?? {}) };

  if (!column?.isVisible) return null;
  return (
    <Cell
      {...props}
      style={mergedStyle}
      column={column}
      className={classNames(className, 'table-cell', {
        [`cell-${column?.name}`]: column?.name,
      })}>
      {children}
    </Cell>
  );
}

export default styled(ListViewCell)``;
