import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  ClientSelect,
  FiltersBar,
  InlineTooltip,
  LinkButton,
  MemberSelect,
  Page,
  PracticeSelect,
  ProjectBillingTypeSelect,
  ProjectStatusSelect,
  ProjectTagFilter,
  SearchInput,
  SingleSelect,
} from '~/components';
import { useAuth, useFeatures } from '~/hooks';
import { mimeTypes } from '~/utils';
import ExportDropdown from './ExportDropdown';

function ProjectsListFilters({
  filters: { q, practice, status, recordStatusId, client, billingTypeId, tags, administrator } = {},
  onChange,
  onExport,
}) {
  const auth = useAuth();
  const { url } = useRouteMatch();
  const location = useLocation();
  const features = useFeatures();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Page.Header>
        <Page.Title>Projects</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <LinkButton to={`${url}/new${location.search}`} className="button" disabled={!auth.projects.create}>
            Create a Project
            {!auth.projects.create && (
              <InlineTooltip message="Your security role prohibits you from creating projects." />
            )}
          </LinkButton>
        </Page.Actions>
      </Page.Header>

      <Page.Section>
        <FiltersBar>
          <SearchInput
            value={q}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
          />

          {features.practices && (
            <PracticeSelect
              name="practice"
              value={practice}
              placeholder="All"
              materialPlaceholder="Practice"
              materialAlwaysVisible
              onChange={handleChange}
            />
          )}

          <ProjectTagFilter name="tags" value={tags} onChange={handleChange} />

          <MemberSelect
            name="administrator"
            placeholder="All"
            materialPlaceholder="Project Admin"
            materialAlwaysVisible
            value={administrator}
            onChange={handleChange}
          />

          <ClientSelect
            name="client"
            placeholder="All"
            materialPlaceholder="Client"
            materialAlwaysVisible
            activeOnly={false}
            value={client}
            onChange={handleChange}
          />

          <ProjectStatusSelect
            name="status"
            placeholder="All"
            materialPlaceholder="Project Status"
            materialAlwaysVisible
            value={status}
            showEmptyOption
            onChange={handleChange}
          />

          <ProjectBillingTypeSelect
            name="billingTypeId"
            placeholder="All"
            materialPlaceholder="Billing Type"
            materialAlwaysVisible
            value={billingTypeId}
            showEmptyOption
            onChange={handleChange}
          />

          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Project Archived"
            materialAlwaysVisible
            value={recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>
        </FiltersBar>
      </Page.Section>
    </>
  );
}

export default ProjectsListFilters;
