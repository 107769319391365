import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Avatar,
  CompanyLink,
  Confirmation,
  ExportDialog,
  FiltersBar,
  Icon,
  ListView,
  ListViewActions,
  ListViewMenu,
  MemberContactPopover,
  MemberSelect,
  Page,
  SearchInput,
  Spinner,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { ActiveStatusSelect } from '~/routes/app/settings/custom-data/components';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import CompanyCloneForm from './CompanyCloneForm';
import CompanyDeleteConfirmation from './CompanyDeleteConfirmation';
import CompanyForm from './CompanyForm';
import ExportDropdown from '../../settings/ExportDropdown.jsx';
import SalesforceIndicator from '../components/SalesforceIndicator';

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CompanyInfo = styled.div`
  line-height: 1;
  align-items: center;
  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const CompanyName = styled.div`
  display: flex;
  align-items: center;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 0.5rem;
  }
`;

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const AssignmentTitle = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function TagComponent({ entity, type, style, children }) {
  const backgroundColor = type === 'contact' ? colors.grey5 : colors.primary10;
  const name = type === 'contact' ? entity.contact.name : entity.name;

  return <Tag style={{ ...style, backgroundColor }}>{children || <p>{name}</p>}</Tag>;
}

function FirstAssignment({ entities, type }) {
  const firstDependency = entities[0];
  return firstDependency ? <TagComponent entity={firstDependency} type={type} style={{ color: colors.black }} /> : null;
}

function Assignments({ entities, type }) {
  const count = entities.length - 1;
  if (count <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <AssignmentTitle>{type === 'contact' ? 'Primary Contacts' : 'Clients'}</AssignmentTitle>
          {entities.map((entity) => (
            <TagComponent entity={entity} type={type} key={type === 'contact' ? entity.contactId : entity.id}>
              <small>{type === 'contact' ? entity.contact.name : entity.name}</small>
            </TagComponent>
          ))}
        </div>
      }>
      <TagComponent entity={entities[0]} type={type} style={{ color: colors.grey40 }}>
        <p style={{ margin: '0.1rem' }}>+{count}</p>
      </TagComponent>
    </Tooltip>
  );
}

export default function CompaniesListPage() {
  const documentTitle = useDocumentTitle('Companies');

  const { workspace } = useWorkspace();
  const api = useApi();
  const history = useHistory();
  const { companyId } = useParams();
  const { url } = useRouteMatch();
  const toast = useToast();

  const [cloneTarget, setCloneTarget] = useState(null);

  const [query, setQuery] = useState({
    data: null,
    action: 'load',
    status: 'loading',
  });
  const [params, setParams] = useState({
    q: '',
    owner: null,
    isActive: 'true',
    page: 0,
    size: 25,
    sort: new QuerySort('name', 'asc'),
  });

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        isActive: {
          default: 'true',
          valid: ['all', 'true', 'false'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        owner: searchParamsConfig.member,
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'companies_list',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      isActive: params.isActive ?? undefined,
      ownerId: params.owner?.id,
      page: params.page,
      size: params.size,
      sort: params.sort,
    }),
    [params],
  );

  const confirmation = useConfirmation();

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => {
          return { ...state, ...params };
        });
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).companies().get(urlSearchParams);

      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
      toast.error(error.message);
    }
  }, [workspace.id, api, urlSearchParams, toast]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleSaved = () => {
    setQuery((state) => ({ ...state, action: 'load' }));
    setParams((params) => ({ ...params, page: 0 }));
  };

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, [name]: value });
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort, page: 0 });
    searchParams.set({ sort: querySort });
  };

  const handleDelete = async (company) => {
    confirmation.prompt((resolve) => (
      <CompanyDeleteConfirmation
        company={company}
        onClose={resolve}
        onDelete={() => {
          history.push(`/app/${workspace.key}/pipeline/companies`);
          setQuery((state) => ({ ...state, status: 'load' }));
          setParams({ ...params, page: 0 });
          resolve(true);
        }}
      />
    ));
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/edit/${id}`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/companies/${id}/overview`);
  };

  async function handleCloned(company) {
    try {
      toast.success('Company cloned successfully.');
      history.push(`/app/${workspace.key}/pipeline/companies/${company.id}/overview/edit`);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/companies`);
    documentTitle.set('Companies');
  }

  const loadMore = () => {
    if (
      query.status !== 'ready' ||
      query.action !== null ||
      !query.data ||
      query.data.total <= query.data.results.length
    )
      return;

    setQuery((state) => {
      return {
        ...state,
        action: 'load-more',
      };
    });
    setParams((params) => ({ ...params, page: params.page + 1 }));
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .companies()
          .export(
            {
              ..._.omit(params, ['owner']),
              ownerId: params.owner?.id,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Pipeline</Page.Eyebrow>
            <Page.Title>Companies</Page.Title>
          </Page.Info>

          <Page.Actions>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`companies.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`companies.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>

            <Tooltip message="Create Company">
              <AddLink to={`${url}/new`} className="button">
                <Icon icon="plus" />
              </AddLink>
            </Tooltip>
          </Page.Actions>
        </Page.Header>
        <Page.Section>
          <FiltersBar>
            <SearchInput
              value={params.q}
              placeholder="Search"
              materialPlaceholder="Company Name"
              materialAlwaysVisible
              onChange={handleChange}
            />

            <MemberSelect
              value={params.owner}
              name="owner"
              placeholder="All"
              materialPlaceholder="Company Relationship Owner"
              materialAlwaysVisible
              onChange={handleChange}
            />

            <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
          </FiltersBar>
        </Page.Section>

        <Page.Section>
          <ListView.Status>
            {query.status !== 'ready' && <Spinner />}
            <ListView.Total value={query.data.total} label="Company" />
          </ListView.Status>
        </Page.Section>

        <ListView>
          <ListView.Header>
            <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
              Company Name
            </ListView.Column>
            <ListView.Column minWidth="16rem">Client</ListView.Column>
            <ListView.Column width="20rem">Relationship Owner</ListView.Column>
            <ListView.Column width="20rem">Primary Contacts</ListView.Column>
            <ListView.Column width="4rem" align="left"></ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body fade={query.status === 'filtering'}>
            {query.data.results.map((company) => {
              const { id, owner, isActive, industry, companyContacts, client, permissions, salesforceId } = company;

              async function handleRowClick(company) {
                handleOverview(company);
              }

              return (
                <ListView.Row key={id} isDisabled={!isActive} onClick={() => handleRowClick(company)}>
                  <ListView.Cell>
                    <CompanyInfo>
                      <CompanyName>
                        <CompanyLink company={company} onClick={(e) => e.stopPropagation()} />
                      </CompanyName>
                      {industry && <small>{industry.name}</small>}
                    </CompanyInfo>
                  </ListView.Cell>

                  <ListView.Cell>{client?.name}</ListView.Cell>

                  <ListView.Cell>
                    {owner && (
                      <Owner>
                        <MemberContactPopover member={owner} placement="left">
                          <Avatar value={owner} isCircle hasBackground initialsFontSize=".9rem" />
                        </MemberContactPopover>
                        <CompanyInfo>
                          <span>{owner.name}</span>
                          {owner.jobTitle && <small>{owner.jobTitle.name}</small>}
                        </CompanyInfo>
                      </Owner>
                    )}
                  </ListView.Cell>

                  <ListView.Cell>
                    <Tags>
                      <FirstAssignment entities={companyContacts} type="contact" />
                      <Assignments entities={companyContacts} type="contact" />
                    </Tags>
                  </ListView.Cell>

                  <ListView.Cell>{salesforceId && <SalesforceIndicator />}</ListView.Cell>

                  <ListViewActions>
                    <ListViewActions.Edit onClick={() => handleEdit(company)} />

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = (action) => setIsOpen(false) || action;

                        async function handleActiveStatusChange(company, flag) {
                          try {
                            setIsOpen(false);

                            const confirm = await confirmation.prompt((resolve) =>
                              flag ? (
                                <Confirmation resolve={resolve}>
                                  This action will activate the company. Note that this will not activate any
                                  opportunities within the company. Opportunities must be activated separately.
                                </Confirmation>
                              ) : (
                                <Confirmation resolve={resolve}>
                                  Are you sure that you want to deactivate this company? This will also deactivate all
                                  opportunities within the company.
                                </Confirmation>
                              ),
                            );

                            if (!confirm) return;

                            await api.www.workspaces(workspace.id).companies(company.id).setActiveStatus(flag);

                            handleSaved();
                          } catch (error) {
                            toast.error(error.message || 'There was a problem updating the company.');
                          }
                        }

                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleAction(handleOverview(company))}>
                              View
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to edit this company.' : undefined
                              }
                              onClick={() => handleAction(handleEdit(company))}>
                              Edit
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to delete this company.' : undefined
                              }
                              onClick={() => handleAction(handleDelete(company))}>
                              Delete
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to clone this company.' : undefined
                              }
                              onClick={() => handleAction(setCloneTarget(company))}>
                              Clone
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage
                                  ? `Insufficient permissions to ${
                                      company.isActive ? 'deactivate' : 'activate'
                                    } this company.`
                                  : undefined
                              }
                              onClick={() => handleAction(handleActiveStatusChange(company, !isActive))}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {query.data.results.length === 0 && <ListView.Empty />}

            {query.data.total > query.data.results.length && (
              <ListView.Loader key={query.data.results.length} onIntersecting={loadMore} />
            )}
          </ListView.Body>
        </ListView>
      </Page>
      {companyId && <CompanyForm onSaved={handleSaved} onClose={() => handleClose()} />}
      {cloneTarget && (
        <CompanyCloneForm target={cloneTarget} onClose={() => setCloneTarget(null)} onSaved={handleCloned} />
      )}
    </>
  );
}
