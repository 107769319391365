import _ from 'lodash';
import React from 'react';
import creditNoteStatuses from '~/lookups/credit-note-statuses';
import MultiSelect from '../MultiSelect';

const options = _.values(creditNoteStatuses);

export default function CreditNoteStatusFilter({
  materialPlaceholder = 'Status',
  placeholder = 'All',
  name,
  value,
  onChange,
}) {
  return (
    <MultiSelect.Input
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      onChange={onChange}
    />
  );
}
