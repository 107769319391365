import _ from 'lodash';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  FiltersBar,
  InlineTooltip,
  ProjectTaskStatusFilter,
  SearchInput,
  SingleSelect,
  SplitButton,
} from '~/components';
import LinkButton from '~/components/LinkButton';
import ExportDropdown from '~/routes/app/reports/components/ExportDropdown';
import { mimeTypes } from '~/utils';

const StyledLink = styled(SplitButton.Link)`
  white-space: nowrap;
`;

function ProjectTasksListFilters({ project, filters: { q, statusId, recordStatusId } = {}, onChange, onExport }) {
  const { url } = useRouteMatch();
  const location = useLocation();

  return (
    <FiltersBar.Wrapper>
      <FiltersBar>
        <SearchInput
          value={q}
          placeholder="Search"
          materialPlaceholder="Task Name"
          materialAlwaysVisible
          onChange={({ target: { value } }) => onChange({ q: value })}
        />

        <ProjectTaskStatusFilter value={statusId} onChange={({ target: { value } }) => onChange({ statusId: value })} />

        <SingleSelect
          name="recordStatusId"
          value={recordStatusId}
          placeholder="All"
          materialPlaceholder="Task Archived"
          materialAlwaysVisible
          showEmptyOption
          onChange={({ target: { value } }) => onChange({ recordStatusId: value })}>
          <option value="active">No</option>
          <option value="archived">Yes</option>
        </SingleSelect>
      </FiltersBar>

      <FiltersBar.Actions>
        <ExportDropdown>
          {({ setIsOpen }) => (
            <>
              <ExportDropdown.Item
                onClick={async () => {
                  await onExport(`${_.snakeCase(project.key)}_tasks.csv`, mimeTypes.csv);
                  setIsOpen(false);
                }}>
                Export to CSV
              </ExportDropdown.Item>

              <ExportDropdown.Item
                onClick={async () => {
                  await onExport(`${_.snakeCase(project.key)}_tasks.xlsx`, mimeTypes.xlsx);
                  setIsOpen(false);
                }}>
                Export to Excel
              </ExportDropdown.Item>
            </>
          )}
        </ExportDropdown>

        <SplitButton>
          <LinkButton disabled={!project.permissions.edit} to={`${url}/new${location.search}`} className="button">
            Create a Task
            {!project.permissions.edit && (
              <InlineTooltip message="Your security role prohibits you from creating tasks." />
            )}
          </LinkButton>
          <SplitButton.Menu disabled={!project.permissions.edit}>
            <StyledLink to={`${url}/add-from-templates`}>Add from Task Template</StyledLink>
          </SplitButton.Menu>
        </SplitButton>
      </FiltersBar.Actions>
    </FiltersBar.Wrapper>
  );
}

export default ProjectTasksListFilters;
