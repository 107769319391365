import slugify from '@sindresorhus/slugify';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form, FormMessage, Level, LinkButton, Spinner } from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useSession } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import locales from '~/lookups/locales';
import { colors, devices, weights } from '~/styles';
import { slugValidator } from '~/utils/validators';
import { Footer, Header } from '../components';
import CelebrateIcon from './assets/celebrate.svg?react';

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3.125rem;
  font-size: 2.5rem;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 13% 2.78rem;
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SuccessBox = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 4rem 2.78rem;
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 39rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  color: ${colors.grey55};
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
`;

const Description = styled.div`
  margin-left: 1rem;
  color: ${colors.grey55};
  font-size: 1.25rem;
  line-height: 1.25;
`;

const Action = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

const BoxTitle = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
  margin-bottom: 2rem;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto;
  }
`;

const UniqueId = styled.div`
  display: flex;

  > div:last-child {
    flex: 1;
    flex-basis: 12rem;
  }

  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`;

const Origin = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  color: ${colors.grey40};
  font-weight: ${weights.bold};
  padding-right: 0;
  white-space: nowrap;
  padding-left: 1.125em;
  height: 2.5rem;

  + div {
    padding-left: 0.25rem;
  }
`;

const initialState = {
  isSubmitting: false,
  status: null,
  message: null,
};

const handlers = {
  submit: () => ({ isSubmitting: true, status: null, message: null }),
  error: ({ message } = {}) => ({
    isSubmitting: false,
    status: 'form-error',
    message,
  }),
};

function CreateWorkspace() {
  useDocumentTitle('Create Workspace');

  const api = useApi();
  const { token } = useParams();
  const [view, setView] = useState('loading');
  const [request, setRequest] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaceInvitation({ token });
        setRequest(data);
        setView('form');
      } catch (error) {
        setError(error.response.data);
        setView('error');
      }
    })();
  }, [api, token]);

  return (
    <>
      <Header showSignUp={false} />
      <Container>
        {{
          loading: () => <Loading />,
          form: () => (
            <NewWorkspaceForm
              token={token}
              request={request}
              onSuccess={(workspace) => {
                setWorkspace(workspace);
                setView('success');
              }}
            />
          ),
          success: () => <Success workspace={workspace} />,
          error: () => <Error error={error} />,
        }[view]()}
      </Container>
      <Footer />
    </>
  );
}

function NewWorkspaceForm({ token, request, onSuccess }) {
  const api = useApi();
  const [{ isSubmitting, status, message }, actions] = useActions(handlers, initialState);

  async function handleSubmit(values) {
    try {
      actions.submit();

      const { data } = await api.www.createWorkspace({
        token,
        workspace: {
          companyName: values.companyName,
          companyServiceTypeId: values.companyServiceTypeId,
          name: values.workspaceName,
          key: values.key,
          currency: values.currency,
          locale: values.locale,
          timeZoneId: values.timeZoneId,
        },
        member: {
          name: values.name,
          password: values.password,
        },
      });

      onSuccess(data);
    } catch (error) {
      switch (error.status) {
        case 409:
          actions.error({ message: error.message ?? 'There was a problem signing up for a new workspace.' });
          return;
        case 422:
          actions.error({ message: `The Workspace URL ID "${values.key}" is already taken.` });
          return;
        default:
          actions.error(error?.message);
      }
    }
  }

  return (
    <>
      <Heading>
        <Title>Launch your workspace!</Title>
        <Byline>Provide the final workspace and account details below.</Byline>
      </Heading>

      <Box>
        <Formik
          initialValues={{
            // Workspace details
            companyName: request.companyName,
            companyServiceTypeId: '',
            workspaceName: request.companyName,
            key: slugify(request.companyName),
            currency: 'USD',
            locale: locales.find((l) => l === navigator.language) ?? 'en-US',
            timeZoneId: null,

            // Account details
            name: request.memberName,
            password: '',
            passwordVerification: '',
            termsAgree: false,
          }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            // Workspace details
            companyName: Yup.string().label('Company Name').max(255).required(),
            companyServiceTypeId: Yup.string().label('Professional Service Type').required(),
            workspaceName: Yup.string().label('Workspace Name').max(255).required(),
            key: Yup.string()
              .label('Workspace URL ID')
              .max(255)
              .matches(slugValidator.expression, { message: slugValidator.message })
              .required(),

            // Account details
            name: Yup.string().label('Name').max(255).required(),
            password: Yup.string()
              .label('Password')
              .min(8, 'Password must be at least 8 characters long')
              .max(255, 'Password must be at most 255 characters long')
              .required(),
            passwordVerification: Yup.string()
              .label('Confirm Password')
              .required()
              .oneOf([Yup.ref('password')], 'Passwords do not match'),
            termsAgree: Yup.bool().oneOf([true], "You must agree to Ruddr's terms"),
          })}>
          {(formik) => {
            return (
              <Form autoComplete="off">
                <div>
                  <BoxTitle>Workspace Details</BoxTitle>
                  <Form.Control>
                    <Field.Text
                      autoFocus
                      name="companyName"
                      placeholder="Company Name"
                      maxLength={255}
                      onChange={({ target: { value } }) => {
                        formik.setValues({
                          ...formik.values,
                          companyName: value,
                          workspaceName: value,
                          key: slugify(value),
                        });
                      }}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Field.CompanyServiceTypeSelect
                      name="companyServiceTypeId"
                      placeholder="Professional Service Type"
                    />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text
                      name="workspaceName"
                      placeholder="Workspace Name"
                      maxLength={255}
                      onChange={({ target: { value } }) => {
                        formik.setValues({ ...formik.values, workspaceName: value, key: slugify(value) });
                      }}
                    />
                  </Form.Control>
                  <Form.Control>
                    <UniqueId>
                      <Origin>{`${window.origin}/app/`}</Origin>
                      <Field.Control>
                        <Field.Text name="key" placeholder="Workspace URL ID" maxLength={255} />
                      </Field.Control>
                    </UniqueId>
                  </Form.Control>

                  <Form.Control>
                    <Field.CurrencySelect name="currency" clearable={false} />
                  </Form.Control>
                  <Form.Control>
                    <Field.RegionalFormatSelect name="locale" clearable={false} />
                  </Form.Control>
                  <Form.Control data-testid="timeZone">
                    <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                  </Form.Control>
                </div>

                <hr style={{ margin: '2.5rem 0' }} />

                <BoxTitle>Account Details</BoxTitle>
                <Form.Control>
                  <ReadTextbox label="Email" value={request.email} />
                </Form.Control>
                <Form.Control>
                  <Field.Text name="name" placeholder="Name" maxLength={255} />
                </Form.Control>
                <Form.Control>
                  <Field.Text
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    maxLength={255}
                  />
                </Form.Control>
                <Form.Control>
                  <Field.Text
                    name="passwordVerification"
                    placeholder="Confirm Password"
                    type="password"
                    maxLength={255}
                  />
                </Form.Control>

                <hr style={{ margin: '2.5rem 0' }} />

                <Form.Control>
                  <Field.Checkbox
                    name="termsAgree"
                    label={
                      <>
                        I agree to Ruddr's{' '}
                        <a href="/master-subscription-agreement" target="_blank">
                          terms
                        </a>
                      </>
                    }
                  />
                </Form.Control>

                {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}

                <FormAction>
                  <Button type="submit" isLoading={isSubmitting}>
                    Launch Workspace!
                  </Button>
                </FormAction>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}

function Success({ workspace }) {
  const { identify } = useSession();

  useEffect(() => {
    (async () => {
      await identify();
    })();
  }, [identify]);

  return (
    <SuccessBox>
      <Level>
        <Level.Item narrow>
          <CelebrateIcon />
        </Level.Item>
        <Level.Item>
          <BoxTitle>We've loaded sample data</BoxTitle>
          <Description>
            To help you quickly get a feel for Ruddr's capabilities, we've loaded your workspace with sample data. You
            can remove the sample data at any time from the Workspace Settings.
          </Description>
          <Action>
            <LinkButton to={`/app${workspace?.key ? `/${workspace.key}` : ''}`} className="button">
              Got it, let's go!
            </LinkButton>
          </Action>
        </Level.Item>
      </Level>
    </SuccessBox>
  );
}

function Loading() {
  return (
    <Loader>
      <Spinner />
    </Loader>
  );
}

function Error({ error }) {
  const message = useMemo(() => {
    switch (error.code) {
      case 'token_used':
        return 'This email link has already been used to launch a workspace.';
      case 'token_expired':
        return 'This email link has expired.';
      default:
        return 'There was a problem with your email link.';
    }
  }, [error]);

  return (
    <Box>
      <BoxTitle>Launch workspace error</BoxTitle>
      <Byline>
        {message} Contact <a href="mailto:help@ruddr.io">help@ruddr.io</a> for further assistance.
      </Byline>
    </Box>
  );
}

export default CreateWorkspace;
