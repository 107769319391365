import _ from 'lodash';
import React from 'react';
import pipelineActivityTypes from '~/lookups/pipeline-activity-types';
import MultiSelect from '../MultiSelect';

export default function PipelineActivityTypeFilter({
  materialPlaceholder = 'Pipeline Activity Type',
  placeholder = 'All',
  name,
  value,
  onChange,
  entity = 'opportunity',
}) {
  const options = _.values(pipelineActivityTypes[entity]);

  return (
    <MultiSelect.Input
      materialAlwaysVisible
      materialPlaceholder={materialPlaceholder}
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      onChange={onChange}
    />
  );
}
