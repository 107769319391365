import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form } from '~/components';
import { useToast } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { colors, weights } from '~/styles';
import { Page } from '../components';
import { useAdminSession } from '../contexts';

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 3.3125rem 13% 2.78rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
  margin-bottom: 2rem;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto 1rem;
  }
`;

export default function Login() {
  useDocumentTitle('Login - Admin');
  const toast = useToast();
  const { login } = useAdminSession();
  const history = useHistory();

  const handleSubmit = async (values, formik) => {
    // Remove whitespace
    values.code = values.code.replace(/\W/g, '');
    try {
      await login(values);
      history.push('/admin');
    } catch ({ message }) {
      if (message) {
        toast.error(message);
      } else {
        toast.error('Login failed. Please try again.');
      }
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <Page>
      <Container>
        <Main>
          <Box>
            <Title>Admin Login</Title>
            <Formik
              initialValues={{ email: '', password: '', code: '' }}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                email: Yup.string().label('Email').email().required(),
                password: Yup.string().label('Password').required(),
                code: Yup.string().label('Code'),
              })}>
              {({ isSubmitting }) => (
                <Form>
                  <Form.Control>
                    <Field.Text autoFocus name="email" placeholder="Email" type="email" autoComplete="email" />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text
                      name="password"
                      placeholder="Password"
                      type="password"
                      autoComplete="current-password"
                    />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text name="code" placeholder="Code" type="text" autoComplete="one-time-code" />
                  </Form.Control>
                  <FormAction>
                    <Button type="submit" isLoading={isSubmitting}>
                      Login
                    </Button>
                  </FormAction>
                </Form>
              )}
            </Formik>
          </Box>
        </Main>
      </Container>
    </Page>
  );
}
