import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { FiltersBar, Icon, OpportunityStageFilter, Page, SearchInput, SingleSelect } from '~/components';
import { useAuth } from '~/hooks';
import { colors } from '~/styles';
import OpportunitiesListPage from '../opportunities/OpportunitiesListPage';

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

const Filters = ({ params, handleChange }) => {
  const { url } = useRouteMatch();
  const auth = useAuth();

  return (
    <Page.Section>
      <FiltersBar.Wrapper>
        <FiltersBar>
          <SearchInput
            value={params.q}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleChange}
          />

          <SingleSelect
            name="opportunityStageStatusId"
            placeholder="All"
            showEmptyOption
            materialPlaceholder="Stage Category"
            materialAlwaysVisible
            value={params.opportunityStageStatusId}
            onChange={handleChange}>
            <option value="open">Open</option>
            <option value="won">Won</option>
            <option value="lost">Lost</option>
          </SingleSelect>

          <OpportunityStageFilter
            name="opportunityStages"
            value={params.opportunityStages}
            opportunityStageStatusId={params.opportunityStageStatusId}
            onChange={handleChange}
          />
        </FiltersBar>

        {auth.pipeline.manage && (
          <FiltersBar.Actions>
            <AddLink to={`${url}/new`} className="button">
              <Icon icon="fa-plus" />
            </AddLink>
          </FiltersBar.Actions>
        )}
      </FiltersBar.Wrapper>
    </Page.Section>
  );
};

function CompanyOpportunitiesTab({ companyId }) {
  return <OpportunitiesListPage mode="tab" companyId={companyId} renderFilters={(props) => <Filters {...props} />} />;
}

export default CompanyOpportunitiesTab;
