import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Container = styled.div`
  /* Layout */
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${colors.grey10};
  padding: 2rem;
  position: relative;

  // Prevent scrolling issues caused by selecting content
  user-select: none;
`;

export default function Schedule(props) {
  return <Container {...props} />;
}
