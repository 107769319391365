import _ from 'lodash';
import React from 'react';
import {
  ProjectTaskFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
} from '~/components';

export default function TaskFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.projectTasks) && (
        <ProjectTaskFilter
          project={filters.projects[0]}
          value={filters.projectTasks}
          onChange={({ value }) => onChange({ projectTasks: value })}
        />
      )}

      {!_.isEmpty(filters.projectTaskStatuses) && (
        <ProjectTaskStatusFilter
          value={filters.projectTaskStatuses}
          onChange={({ value }) => onChange({ projectTaskStatuses: value })}
        />
      )}

      {filters.projectTaskRecordStatusId && (
        <ProjectTaskRecordStatusFilter
          value={filters.projectTaskRecordStatusId}
          onChange={({ value }) => onChange({ projectTaskRecordStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.projectTaskTags) && (
        <ProjectTaskTagFilter
          value={filters.projectTaskTags}
          onChange={({ value }) => onChange({ projectTaskTags: value })}
        />
      )}
    </>
  );
}
