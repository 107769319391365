import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  BillableIcon,
  ClientLink,
  HoursProgress,
  Icon,
  InternalClientTooltip,
  ProjectLink,
  RevenueProgress,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import FilterButton from '../components/FilterButton';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import Report from '../components/Report';
import Table from '../components/table';
import TaskFiltersBar from '../components/TaskFiltersBar';
import TaskFiltersGroup from '../components/TaskFiltersGroup';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const AssignedTo = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function FirstAssignment({ task, project }) {
  // Find the first active project role
  const taskRole = task.roles.find((v) => v.role.isActive);
  if (project.useRoles && taskRole) {
    return (
      <Tag style={{ backgroundColor: colors.grey5, color: colors.black }}>
        <BillableIcon value={taskRole.role.isActuallyBillable} />
        <p>{taskRole.role.name}</p>
      </Tag>
    );
  } else {
    // Find the first active project member
    const taskMember = task.members.find((v) => v.projectMember.isActive && v.projectMember.member.isActive);
    if (!taskMember) {
      return null;
    }
    return (
      <Tag style={{ backgroundColor: colors.primary10, color: colors.black }}>
        <p>{taskMember.projectMember.member.name}</p>
      </Tag>
    );
  }
}

function Assignments({ task, project }) {
  let assignmentsCount = -1; // Remove the first assignment because it already shows a tag
  // Filter active project members
  const activeMembers = _.filter(task?.members, (v) => v.projectMember.isActive && v.projectMember.member.isActive);
  // Filter active project roles
  const activeRoles = _.filter(task?.roles, (v) => v.role.isActive);
  assignmentsCount += activeMembers.length; // Add members
  if (project.useRoles) assignmentsCount += activeRoles.length; // Add roles if the project uses roles
  if (assignmentsCount <= 0) return null;

  return (
    <Tooltip
      data-testid="assignment_tooltip"
      style={{ display: 'flex' }}
      message={
        <div data-testid="assignment_popper" style={{ fontSize: '1rem' }}>
          <AssignedTo>Assigned to</AssignedTo>
          {activeMembers.map(({ projectMember: { member } }) => (
            <Tag style={{ backgroundColor: colors.primary10 }} key={member.id}>
              <small>{member.name}</small>
            </Tag>
          ))}
          {project.useRoles &&
            activeRoles.map(({ role }) => (
              <Tag style={{ backgroundColor: colors.grey5 }} key={role.id}>
                <BillableIcon value={role.isActuallyBillable} />
                <small>{role.name}</small>
              </Tag>
            ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <p style={{ margin: '0.1rem' }}>+{assignmentsCount}</p>
      </Tag>
    </Tooltip>
  );
}

function ProjectTasksList() {
  useDocumentTitle('Project Tasks');
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    sort: new QuerySort('project.name', 'asc'),
    projectTaskStatuses: [],
    projectTaskRecordStatusId: null,
    projectTaskTags: [],
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        projectTaskRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
        projectTaskTags: searchParamsConfig.projectTaskTags,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  const urlSearchParams = useMemo(
    () => ({
      sort: params.sort,
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      projectTaskRecordStatusId: params.projectTaskRecordStatusId ?? undefined,
      projectTaskTagId: params.projectTaskTags?.map((v) => v.id),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().projectTasksList(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .projectTasksList(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/workspace`} />

        <Report.Info>
          <Report.Eyebrow>Workspace Reports</Report.Eyebrow>
          <Report.Title>Project Tasks</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <TaskFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;

  const auth = useAuth();

  const viewBudgetHours = auth.projects.viewBudgetHours;
  const viewBudgetRevenue = auth.projects.viewBudgetRevenue;

  return (
    <>
      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Task" value={report.records.map((project) => project.tasks).flat().length} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column width="3rem">
            <BillableIcon showTooltip={false} />
          </Table.Column>
          <Table.Column sticky minWidth="16rem" name="project.name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column width="10rem">Status</Table.Column>
          <Table.Column minWidth="16rem">Assigned To</Table.Column>
          <Table.Column width="16rem" isVisible={viewBudgetHours}>
            Hours
          </Table.Column>
          <Table.Column width="16rem" isVisible={viewBudgetRevenue}>
            Revenue
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((project) => {
            const currency = project.currency;

            return (
              <React.Fragment key={project.id}>
                <Table.GroupRow top="2.95rem">
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <ProjectInfo>
                      <p>
                        <ProjectLink project={project} />
                      </p>
                      <small>
                        <ClientLink client={project.client} />
                        {project.client.isInternal && <InternalClientTooltip />}
                      </small>
                    </ProjectInfo>
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                </Table.GroupRow>

                {project.tasks.map((task) => {
                  return (
                    <Table.Row key={task.id}>
                      <Table.Cell>
                        <BillableIcon value={task.isActuallyBillable} />
                      </Table.Cell>
                      <Table.Cell>
                        <div style={{ marginLeft: '1rem' }}>{task.name}</div>
                      </Table.Cell>
                      <Table.Cell>{task.status.name}</Table.Cell>
                      <Table.Cell>
                        <Tags style={{ fontSize: '0.75rem' }}>
                          <FirstAssignment task={task} project={project} />
                          <Assignments task={task} project={project} />
                        </Tags>
                      </Table.Cell>
                      <Table.Cell>
                        {project.permissions.viewTimeAndExpenses ? (
                          <HoursProgress worked={task.hours} estimated={task.budgetHours} />
                        ) : (
                          <></>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {project.permissions.viewRevenue && project.permissions.viewRates ? (
                          <RevenueProgress earned={task.revenue} budgeted={task.budgetRevenue} currency={currency} />
                        ) : (
                          <></>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default ProjectTasksList;
