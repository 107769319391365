import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, ThemeProvider } from 'styled-components';
import logo from '~/assets/logo.svg';
import { useSession } from '~/contexts';
import { colors, devices, weights } from '~/styles';
import ResponsiveContainer from './ResponsiveContainer';
import TouchNav from './TouchNav';

// Some properties are only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledLink = ({ isOutline, ...props }) => <Link {...props} />;

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4.75rem;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-top: solid 1px transparent;
  border-bottom: solid 1px ${({ theme }) => theme.borderColor};
  transition: all 0.2s ease-in-out;
  z-index: 10;
`;

const Container = styled(ResponsiveContainer)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Logo = styled.img`
  display: block;
  height: 1.875rem;
  transition: all 0.2s ease-in-out;

  @media ${devices.mobile} {
    height: 1.625rem;
  }
`;

const Navigation = styled.nav`
  display: flex;
  height: 100%;
  margin-left: auto;

  @media ${devices.mobile} {
    display: none;
  }
`;

const TouchNavigation = styled.nav`
  margin-left: auto;

  @media ${devices.tabletPlus} {
    display: none;
  }
`;

const HamburgerButton = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    height: 0.25rem;
    background-color: ${({ theme }) => theme.linkColor};
    border-radius: 999rem;
  }

  &::before {
    top: calc(50% - 0.25rem);
    width: 1.5rem;
  }

  &::after {
    top: calc(50% + 0.3125rem);
    width: 1.125rem;
  }

  &:hover {
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.linkColorHover};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const ActionLink = styled(StyledLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 14rem;
  height: 2.5rem;
  margin-left: 1.5rem;
  padding: 0 1.75em;
  color: ${({ theme }) => theme.buttonTextColor};
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${({ theme }) => theme.buttonBackgroundColor};
  border: solid 2px transparent;
  border-radius: 999rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.buttonTextColorHover};
    background-color: ${({ theme }) => theme.buttonBackgroundColorHover};
  }

  ${({ isOutline }) =>
    isOutline &&
    css`
      color: ${({ theme }) => theme.outlineButtonColor};
      background-color: transparent;
      border-color: ${({ theme }) => theme.outlineButtonColor};

      &:hover {
        color: ${({ theme }) => theme.outlineButtonColorHover};
        background-color: transparent;
        border-color: ${({ theme }) => theme.outlineButtonColorHover};
      }
    `}
`;

const ActionOverflow = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Buffer = styled.div`
  width: 100%;
  height: 4.75rem;
`;

const defaultTheme = {
  backgroundColor: colors.white,
  borderColor: colors.grey10,
  linkColor: colors.black,
  linkColorHover: colors.grey55,
  buttonTextColor: colors.white,
  buttonTextColorHover: colors.white,
  buttonBackgroundColor: colors.primary,
  buttonBackgroundColorHover: colors.accent,
  outlineButtonColor: colors.primary,
  outlineButtonColorHover: colors.accent,
};

export default function Header({ showSignUp = true }) {
  const { isReady, isLoggedIn } = useSession();

  const [isNavOpen, setIsNavOpen] = useState(false);

  let actions = null;

  if (isReady) {
    if (!isLoggedIn) {
      actions = (
        <Actions>
          <ActionLink to="/login" isOutline={true}>
            Login
          </ActionLink>
          {showSignUp && <ActionLink to="/signup">Sign Up</ActionLink>}
        </Actions>
      );
    } else {
      actions = (
        <Actions>
          <ActionLink to="/logout" isOutline={true}>
            Logout
          </ActionLink>
          <ActionLink to="/app">
            <ActionOverflow>Dashboard</ActionOverflow>
          </ActionLink>
        </Actions>
      );
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Wrapper>
        <Container>
          <Link to="/">
            <Logo src={logo} alt="Ruddr" />
          </Link>
          <Navigation>{actions}</Navigation>
          <TouchNavigation>
            <HamburgerButton role="button" onClick={() => setIsNavOpen(true)} />
          </TouchNavigation>
        </Container>
      </Wrapper>
      {isNavOpen && <TouchNav isReady={isReady} onClose={() => setIsNavOpen(false)} />}
      <Buffer />
    </ThemeProvider>
  );
}
