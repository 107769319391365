import { Button, Buttons, CancelButton, Drawer } from '~/components';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  > * {
    margin-bottom: 2rem;
  }

  font-size: 0.875rem;
`;

function FiltersDrawer({ children, isOpen, onClose, onApply }) {
  return (
    <Drawer isOpen={isOpen} width="40rem" title="Filters" onClose={onClose}>
      <Container>{children}</Container>
      <Drawer.Actions>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button onClick={onApply}>Apply</Button>
        </Buttons>
      </Drawer.Actions>
    </Drawer>
  );
}

export default FiltersDrawer;
