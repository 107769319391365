import {
  Avatar,
  Button,
  DateTime,
  DeleteConfirmation,
  ExportDialog,
  Level,
  ListView,
  ListViewActions,
  ListViewMenu,
  MemberContactPopover,
  Page,
  ProjectHealthCircle,
  ProjectHealthScoreBar,
  Spinner,
} from '~/components';
import styled from 'styled-components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import ProjectHealthReportForm from './ProjectHealthReportForm';
import ProjectHealthReportView from './ProjectHealthReportView';
import ExportDropdown from '../ExportDropdown';
import mimeTypes from '~/utils/mimeTypes.js';
import _ from 'lodash';
import { useDocumentTitle } from '~/hooks';

const Member = styled.div`
  display: flex;
  align-items: center;
`;

const MemberInfo = styled.span`
  margin-left: 0.5rem;
`;

export default function ProjectHealthTab({ project, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data, isReady, isFetching }, setQuery] = useState({ data: null, isReady: false, isFetching: false });
  const { projectHealthReportId, action } = useParams();
  const history = useHistory();
  const confirmation = useConfirmation();
  const documentTitle = useDocumentTitle();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).healthReports().get();
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  useEffect(() => {
    const redirect = () => {
      documentTitle.set(project.name);
      history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/health`);
    };

    switch (action) {
      case 'edit':
        setDialog(
          <ProjectHealthReportForm
            id={projectHealthReportId}
            projectId={project.id}
            onDeleted={() => {
              fetchData();
              onChange && onChange();
            }}
            onSaved={() => {
              fetchData();
              onChange && onChange();
            }}
            onClose={redirect}
          />,
        );
        break;

      case 'view':
        setDialog(<ProjectHealthReportView id={projectHealthReportId} projectId={project.id} onClose={redirect} />);
        break;

      default:
        setDialog(null);
        break;
    }
  }, [
    projectHealthReportId,
    action,
    project.id,
    fetchData,
    onChange,
    history,
    project.client.key,
    project.key,
    workspace.key,
    project.name,
    documentTitle,
  ]);

  const handleCreate = () => {
    setDialog(
      <ProjectHealthReportForm
        projectId={project.id}
        onClose={() => {
          documentTitle.set(project.name);
          setDialog(null);
        }}
        onSaved={() => {
          fetchData();
          onChange && onChange();
        }}
      />,
    );
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .healthReports()
          .export({
            headers: { accept: mimeType },
            responseType: 'blob',
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Page.Section>
        <Level>
          <Level.Item right narrow>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`${_.snakeCase(project.key)}_health_reports.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`${_.snakeCase(project.key)}_health_reports.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Level.Item>

          <Level.Item narrow>
            <Button onClick={handleCreate}>Create a Project Health Report</Button>
          </Level.Item>
        </Level>
      </Page.Section>
      {!isReady ? (
        <PageLoader />
      ) : !data ? (
        <ErrorPage.NotFound publicSite={false} />
      ) : (
        <>
          <Page.Section>
            <ListView.Status>
              {isFetching && <Spinner />}
              <ListView.Total label="Project Health Report" value={data.length} />
            </ListView.Status>
          </Page.Section>

          <ListView data-testid="table">
            <ListView.Header>
              <ListView.Column width="7rem">Date</ListView.Column>

              <ListView.Column align="center" width="12rem">
                Budget Health
              </ListView.Column>

              <ListView.Column align="center" width="12rem">
                Schedule Health
              </ListView.Column>

              <ListView.Column align="center" width="12rem">
                Client Satisfaction
              </ListView.Column>

              <ListView.Column align="center" width="12rem">
                Team Satisfaction
              </ListView.Column>

              <ListView.Column align="center" width="8rem">
                Score
              </ListView.Column>

              <ListView.Column minWidth="16rem">Created By</ListView.Column>

              <ListViewActions.Column />
            </ListView.Header>

            <ListView.Body fade={isFetching}>
              {data.map((report) => {
                const handleView = () => {
                  history.push(
                    `/app/${workspace.key}/projects/${project.client.key}/${project.key}/health/${report.id}/view`,
                  );
                };

                const handleEdit = () => {
                  history.push(
                    `/app/${workspace.key}/projects/${project.client.key}/${project.key}/health/${report.id}/edit`,
                  );
                };

                const handleDelete = async () => {
                  await confirmation.prompt((resolve) => (
                    <DeleteConfirmation
                      resolve={async (confirm) => {
                        if (!confirm) {
                          resolve(false);
                          return;
                        }

                        await api.www.workspaces(workspace.id).projects(project.id).healthReports(report.id).delete();

                        await fetchData();
                        onChange && onChange();

                        resolve(true);
                      }}>
                      Are you sure you want to delete this project health report?
                    </DeleteConfirmation>
                  ));
                };

                return (
                  <ListView.Row
                    key={report.id}
                    data-testid="row"
                    onClick={project.permissions.edit ? handleEdit : handleView}>
                    <ListView.Cell>
                      <DateTime value={report.date} />
                    </ListView.Cell>

                    <ListView.Cell>
                      <ProjectHealthCircle value={report.budgetHealth} />
                    </ListView.Cell>

                    <ListView.Cell>
                      <ProjectHealthCircle value={report.scheduleHealth} />
                    </ListView.Cell>

                    <ListView.Cell>
                      <ProjectHealthCircle value={report.clientSatisfaction} />
                    </ListView.Cell>

                    <ListView.Cell>
                      <ProjectHealthCircle value={report.teamSatisfaction} />
                    </ListView.Cell>

                    <ListView.Cell>
                      <ProjectHealthScoreBar score={report.score} />
                    </ListView.Cell>

                    <ListView.Cell>
                      <Member>
                        <MemberContactPopover member={report.createdBy} placement="left">
                          <Avatar value={report.createdBy} isCircle hasBackground initialsFontSize=".9rem" />
                        </MemberContactPopover>
                        <MemberInfo>{report.createdBy.name}</MemberInfo>
                      </Member>
                    </ListView.Cell>

                    <ListViewActions>
                      {project.permissions.edit ? (
                        <ListViewActions.Edit onClick={handleEdit} />
                      ) : (
                        <ListViewActions.View onClick={handleView} />
                      )}

                      <hr />

                      <ListViewMenu>
                        {({ setIsOpen }) => {
                          const handleAction = (action) => setIsOpen(false) || action;

                          return (
                            <>
                              <ListViewMenu.Item onClick={() => handleAction(handleView())}>View</ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!project.permissions.edit}
                                tooltip={
                                  !project.permissions.edit
                                    ? 'Insufficient permissions to edit this project health report.'
                                    : undefined
                                }
                                onClick={() => handleAction(handleEdit())}>
                                Edit
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!project.permissions.edit}
                                tooltip={
                                  !project.permissions.edit
                                    ? 'Insufficient permissions to delete this project health report.'
                                    : undefined
                                }
                                onClick={() => handleAction(handleDelete())}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          );
                        }}
                      </ListViewMenu>
                    </ListViewActions>
                  </ListView.Row>
                );
              })}

              {data.length === 0 && <ListView.Empty />}
            </ListView.Body>
          </ListView>
        </>
      )}

      {dialog}
    </>
  );
}
