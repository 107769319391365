import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAuth } from '~/hooks';
import { ErrorPage } from '~/routes/public/pages';
import ForecastReportsPage from './ForecastReportsPage';
import HoursPlanByProjectAndResource from './HoursPlanByProjectAndResource';
import HoursPlanByResourceAndProject from './HoursPlanByResourceAndProject';
import HoursVarianceByMemberAndProject from './HoursVarianceByMemberAndProject';
import HoursVarianceByProjectAndResource from './HoursVarianceByProjectAndResource';
import HoursVarianceByProjectAndRole from './HoursVarianceByProjectAndRole';
import PerformanceForecastByProject from './PerformanceForecastByProject';
import PerformanceForecastByProjectByTimeUnit from './PerformanceForecastByProjectByTimeUnit';
import PerformancePlanByProject from './PerformancePlanByProject';
import ServicesRevenuePlanByClientAndProject from './ServicesRevenuePlanByClientAndProject';
import UtilizationPlanByMember from './UtilizationPlanByMember';
import UtilizationPlanByMemberPractice from './UtilizationPlanByMemberPractice';

export default function ForecastReportsArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  return (
    <Switch>
      <Route path={path} exact>
        <ForecastReportsPage />
      </Route>

      <Route path={path.concat('/performance-forecast-by-project')}>
        {auth.reports.performanceForecastbyProject ? (
          <PerformanceForecastByProject />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/performance-forecast-by-project-by-time-unit')}>
        {auth.reports.performanceForecastbyProject ? (
          <PerformanceForecastByProjectByTimeUnit />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-plan-by-project-and-resource')}>
        {auth.reports.hoursPlan ? <HoursPlanByProjectAndResource /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/hours-plan-by-resource-and-project')}>
        {auth.reports.hoursPlan ? <HoursPlanByResourceAndProject /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>

      <Route path={path.concat('/performance-plan-by-project')}>
        {auth.reports.performancePlanByProject ? <PerformancePlanByProject /> : <ErrorPage.Forbidden />}
      </Route>

      <Route path={path.concat('/services-revenue-plan-by-client-and-project')}>
        {auth.reports.revenuePlan ? (
          <ServicesRevenuePlanByClientAndProject />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/utilization-plan-by-member')}>
        {auth.reports.utilizationPlanByMember ? (
          <UtilizationPlanByMember />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/utilization-plan-by-member-practice')}>
        {auth.reports.utilizationPlanByMemberPractice ? (
          <UtilizationPlanByMemberPractice />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-variance-by-member-and-project')}>
        {auth.reports.hoursVarianceByMemberAndProject ? (
          <HoursVarianceByMemberAndProject />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-variance-by-project-and-resource')}>
        {auth.reports.hoursVarianceByProjectAndResource ? (
          <HoursVarianceByProjectAndResource />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Route path={path.concat('/hours-variance-by-project-and-role')}>
        {auth.reports.hoursVarianceByProjectAndRole ? (
          <HoursVarianceByProjectAndRole />
        ) : (
          <ErrorPage.Forbidden publicSite={false} />
        )}
      </Route>

      <Redirect from="/" to={url} />
    </Switch>
  );
}
