import styled from 'styled-components';

const FiltersBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 1rem;
  flex: 1;

  &:empty {
    display: none;
  }

  &,
  & input,
  & span > strong {
    font-size: 0.875rem;
  }
`;

FiltersBar.Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap-reverse;
`;

FiltersBar.Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-left: auto;
  gap: 1rem;
`;

export default FiltersBar;
