import { Button, FiltersBar, ListView, ListViewActions, MemberSelect, Page, SingleSelect, Spinner } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import CloneConfirmation from './CloneConfirmation';
import DeleteExpenseReportConfirmation from './DeleteExpenseReportConfirmation';
import Drawer from './Drawer';
import Row from './Row';

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 2rem;
  background-color: ${colors.white};
  z-index: 50;

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }

  & > * {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const initialState = {
  isReady: false,
  data: null,
  searchParamsStatus: 'pending',
  query: { year: '', sort: new QuerySort('number', 'desc'), page: 0, size: 25 },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  setParams: (params, { query }) => ({
    query: { ...query, ...params, page: 0 },
    action: 'filter',
    searchParamsStatus: 'ready',
  }),
  sort: ({ column, sort }, { query }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    return {
      query: { ...query, sort: new QuerySort(column, direction), page: 0 },
      action: 'sort',
    };
  },
  ready: ({ data }, state) => ({
    isReady: true,
    dialog: null,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItem: (item, { data }) => ({
    data: {
      ...data,
      results: data.results.some((i) => i.id === item.id)
        ? data.results.map((i) => (i.id === item.id ? item : i))
        : [...data.results, item],
    },
  }),
  closeDialog: () => ({ dialog: null }),
  showDeleteConfirmation: ({ target }) => ({ dialog: 'delete', target }),
  showCloneConfirmation: ({ target }) => ({ dialog: 'clone', target }),
  showCreateDrawer: () => ({ drawer: 'new' }),
};

function ListPage({ member, setMember, drawer, reportsUrl }) {
  useDocumentTitle('Expense Reports');
  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const [{ isReady, data, query, target, dialog, searchParamsStatus, action }, actions] = useActions(
    handlers,
    initialState,
  );

  const { path, url } = useRouteMatch();

  const history = useHistory();

  const searchParamsConfig = useSearchParamsConfig();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        year: initialState.query.year,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'expense_reports',
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      query.memberId = member ? member.id : workspace.member.id;
      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseReports()
        .get({
          ...query,
          year: query.year ?? undefined,
        });
      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [] });
    }
  }, [actions, workspace.id, workspace.member, member, query, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, member, query, searchParamsStatus]);

  const filterMembers = useMemo(() => {
    return [workspace.member.id];
  }, [workspace]);

  async function handleDeleted() {
    actions.load();
  }

  async function handleCloned() {
    actions.load();
  }

  function handleMemberChange(member) {
    actions.setParams();
    query.year = undefined;
    setMember(member);
  }

  function handleFilterChange({ target: { name, value } }) {
    actions.setParams({ [name]: value });
    searchParams.set({ [name]: value });
  }

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  if (!isReady && !data) return <PageLoader />;

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Expenses</Page.Eyebrow>
            <Page.Title>Expense Reports</Page.Title>
          </Page.Info>
        </Page.Header>

        <Page.Section>
          <FiltersBar>
            <SingleSelect
              name="year"
              placeholder="All"
              materialPlaceholder="Year"
              materialAlwaysVisible
              showEmptyOption
              value={query.year}
              onChange={handleFilterChange}>
              {_.map(data.availableYears, ({ year, count }, key) => {
                return (
                  <option key={key} value={year}>
                    {year} ({count})
                  </option>
                );
              })}
            </SingleSelect>

            {auth.members.manageTimeAndExpenses && (
              <MemberSelect
                filterMembers={filterMembers}
                placeholder="Teammates"
                permission="manageTimeAndExpenses"
                withPermissions
                onChange={(event) => handleMemberChange(event.target.value)}
                value={member}
                align="right"
              />
            )}
          </FiltersBar>
        </Page.Section>

        <Page.Section>
          <ListView.Status>
            {!!action && <Spinner />}
            <ListView.Total value={data.total} label="Expense Report" />
          </ListView.Status>
        </Page.Section>

        <ListView>
          <ListView.Header>
            <ListView.Column sticky width="4rem" name="number" align="left" onSort={handleSort} sort={query.sort}>
              #
            </ListView.Column>
            <ListView.Column minWidth="16rem" name="title" onSort={handleSort} sort={query.sort}>
              Title
            </ListView.Column>
            <ListView.Column width="8rem" name="date" onSort={handleSort} sort={query.sort}>
              Date
            </ListView.Column>
            <ListView.Column width="12rem">Item Status</ListView.Column>
            <ListView.Column width="8rem" name="total" align="right">
              Total
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {data.results?.map((item) => (
              <Row
                key={item.id}
                expenseReport={item}
                onSaved={actions.updateItem}
                onClone={(target) => actions.showCloneConfirmation({ target })}
                onDelete={(target) => actions.showDeleteConfirmation({ target })}
              />
            ))}
          </ListView.Body>

          {data.results.length === 0 && <ListView.Empty />}

          {data.total > data.results.length && <ListView.Loader onIntersecting={actions.loadMore} />}
        </ListView>
      </Page>
      <Actions>
        <Button onClick={() => history.push(`${url}/new`)}>New Expense Report</Button>
      </Actions>

      {dialog === 'delete' && (
        <DeleteExpenseReportConfirmation
          onClose={actions.closeDialog}
          onDelete={handleDeleted}
          id={target.expenseReportId}
          title={target.name}
        />
      )}
      {dialog === 'clone' && <CloneConfirmation onClose={actions.closeDialog} onSaved={handleCloned} target={target} />}
      {drawer === 'new' && <Drawer member={member} onSaved={actions.load} onClose={() => history.push(reportsUrl)} />}
      {drawer === 'edit' && <Drawer member={member} onSaved={actions.load} onClose={() => history.push(reportsUrl)} />}
      <Switch>
        <Route path={`${path}/new`}>
          <Drawer member={member} onClose={() => history.push(url)} />
        </Route>
      </Switch>
    </>
  );
}
export default ListPage;
export { ListPage };
