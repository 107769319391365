import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  CompanySelect,
  Currency,
  DateTime,
  ExportDialog,
  Icon,
  OpportunityLink,
  OpportunityStageFilter,
  Page,
  ProjectLink,
  SearchInput,
  SingleSelect,
  Spinner,
  Tooltip,
  OpportunityTypeSelect,
  MemberSelect,
  PracticeSelect,
  ListView,
  ListViewActions,
  ListViewMenu,
  FiltersBar,
} from '~/components';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import OpportunitiesSummaryWidget from './OpportunitiesSummaryWidget';
import OpportunityCloneForm from './OpportunityCloneForm';
import OpportunityDeleteConfirmation from './OpportunityDeleteConfirmation';
import OpportunityForm from './OpportunityForm';
import OpportunityToProjectForm from './OpportunityToProjectForm';
import StageProbabilityBar from './StageProbabilityBar';
import ExportDropdown from '../../settings/ExportDropdown.jsx';
import SalesforceIndicator from '../components/SalesforceIndicator';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 2rem;
  margin: 0 -2rem;
`;

const InfoContainer = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const OpportunityName = styled.div`
  display: flex;
  align-items: center;
`;

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Small = styled.small`
  display: block;
`;

const AddLink = styled(Link)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};

  &:hover {
    color: ${colors.accent};
    background-color: ${colors.grey10};
  }
`;

export default function OpportunitiesListPage({ renderFilters, mode = 'page', companyId }) {
  const integrations = useIntegrations();

  const documentTitle = useDocumentTitle();
  useEffect(() => {
    if (mode === 'page') documentTitle.set('Opportunities');
  }, [mode, documentTitle]);

  const { workspace } = useWorkspace();
  const features = useFeatures();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const { opportunityId } = useParams();
  const { url } = useRouteMatch();
  const toast = useToast();
  const confirmation = useConfirmation();

  const [cloneTarget, setCloneTarget] = useState(null);
  const [opportunityToProject, setOpportunityToProject] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const [query, setQuery] = useState({
    data: null,
    action: 'load',
    status: 'loading',
  });
  const [params, setParams] = useState({
    q: '',
    company: null,
    opportunityStages: [],
    opportunityStageStatusId: 'open',
    opportunityType: null,
    practice: null,
    salesforceSynced: null,
    owner: null,
    page: 0,
    size: 25,
    sort: new QuerySort('convertedWeightedAmount', 'desc'),
  });

  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        company: searchParamsConfig.company,
        owner: searchParamsConfig.member,
        opportunityStageStatusId: {
          default: 'open',
          valid: ['all', 'open', 'won', 'lost'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        opportunityStages: searchParamsConfig.opportunityStages,
        opportunityType: searchParamsConfig.opportunityType,
        practice: searchParamsConfig.practice,
        salesforceSynced: { default: null },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'opportunities_list',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      ownerId: params.owner?.id,
      opportunityStageStatusId: params.opportunityStageStatusId ?? undefined,
      opportunityStageId: params.opportunityStages.length ? params.opportunityStages.map((v) => v.id) : [],
      opportunityTypeId: params.opportunityType?.id,
      practiceId: params.practice?.id,
      salesforceSynced: integrations?.salesforce && params.salesforceSynced ? params.salesforceSynced : undefined,
      page: params.page,
      size: params.size,
      sort: params.sort,
      companyId: params.company?.id || companyId,
    }),
    [params, companyId, integrations?.salesforce],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => {
          return { ...state, ...params };
        });
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).opportunities().get(urlSearchParams);

      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
      toast.error(error.message);
    }
  }, [workspace.id, api, urlSearchParams, toast]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleSaved = () => {
    setQuery((state) => ({ ...state, action: 'load' }));
    setParams((params) => ({ ...params, page: 0 }));
    refreshSummary();
  };

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, [name]: value });
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort, page: 0 });
    searchParams.set({ sort: querySort });
  };

  const handleDelete = (opportunity) => {
    confirmation.prompt((resolve) => (
      <OpportunityDeleteConfirmation
        opportunity={opportunity}
        onClose={resolve}
        onDelete={() => {
          setQuery((state) => ({ ...state, status: 'load' }));
          setParams({ ...params, page: 0 });
          refreshSummary();
          resolve(true);
        }}
      />
    ));
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/opportunities/edit/${id}`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/opportunities/${id}/overview`);
  };

  const refreshSummary = () => {
    setRefreshKey((refreshKey) => refreshKey + 1);
  };

  async function handleCloned(opportunity) {
    try {
      toast.success('Opportunity cloned successfully.');
      history.push(`/app/${workspace.key}/pipeline/opportunities/${opportunity.id}/overview/edit`);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/opportunities`);
    documentTitle.set('Opportunities');
  }

  const loadMore = () => {
    if (
      query.status !== 'ready' ||
      query.action !== null ||
      !query.data ||
      query.data.total <= query.data.results.length
    )
      return;

    setQuery((state) => {
      return {
        ...state,
        action: 'load-more',
      };
    });
    setParams((params) => ({ ...params, page: params.page + 1 }));
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .opportunities()
          .export(
            {
              ...params,
              opportunityStageId: params.opportunityStages.length ? params.opportunityStages.map((v) => v.id) : [],
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  const salesforceEnabled = integrations?.salesforce;

  const Container = mode === 'page' ? Page : React.Fragment;

  return (
    <Container>
      {mode === 'page' && (
        <>
          <Page.Header>
            <Page.Info>
              <Page.Eyebrow>Pipeline</Page.Eyebrow>
              <Page.Title>Opportunities</Page.Title>
            </Page.Info>

            <Page.Actions>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`opportunities.csv`, mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`opportunities.xlsx`, mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>

              {auth.pipeline.manage && (
                <Tooltip message="Create Opportunity">
                  <AddLink to={`${url}/new`} className="button">
                    <Icon icon="plus" />
                  </AddLink>
                </Tooltip>
              )}
            </Page.Actions>
          </Page.Header>
        </>
      )}

      {renderFilters ? (
        renderFilters({ params, handleChange })
      ) : (
        <Page.Section>
          <FiltersBar>
            <SearchInput
              value={params.q}
              placeholder="All"
              materialPlaceholder="Search"
              materialAlwaysVisible
              onChange={handleChange}
            />

            <SingleSelect
              name="opportunityStageStatusId"
              placeholder="All"
              showEmptyOption
              materialPlaceholder="Stage Category"
              materialAlwaysVisible
              value={params.opportunityStageStatusId}
              onChange={handleChange}>
              <option value="open">Open</option>
              <option value="won">Won</option>
              <option value="lost">Lost</option>
            </SingleSelect>

            <OpportunityStageFilter
              name="opportunityStages"
              value={params.opportunityStages}
              opportunityStageStatusId={params.opportunityStageStatusId}
              onChange={handleChange}
            />

            {features.practices && (
              <PracticeSelect
                name="practice"
                value={params.practice}
                placeholder="All"
                materialPlaceholder="Practice"
                materialAlwaysVisible
                onChange={handleChange}
              />
            )}

            <CompanySelect
              name="company"
              placeholder="All"
              materialPlaceholder="Company"
              materialAlwaysVisible
              value={params.company}
              onChange={handleChange}
            />

            <OpportunityTypeSelect
              name="opportunityType"
              value={params.opportunityType}
              placeholder="All"
              materialPlaceholder="Opportunity Type"
              materialAlwaysVisible
              onChange={handleChange}
            />

            <MemberSelect
              value={params.owner}
              name="owner"
              placeholder="All"
              materialPlaceholder="Relationship Owner"
              materialAlwaysVisible
              onChange={handleChange}
            />

            {salesforceEnabled && (
              <SingleSelect
                name="salesforceSynced"
                placeholder="All"
                showEmptyOption
                materialPlaceholder="Salesforce Synced"
                materialAlwaysVisible
                value={params.salesforceSynced}
                onChange={handleChange}>
                <option value="synced">Yes</option>
                <option value="not-synced">No</option>
              </SingleSelect>
            )}
          </FiltersBar>
        </Page.Section>
      )}

      {mode === 'page' && (
        <Page.Section>
          <SummarySection>
            <OpportunitiesSummaryWidget
              key={refreshKey}
              q={urlSearchParams.q}
              opportunityStageStatusId={urlSearchParams.opportunityStageStatusId}
              opportunityStageId={urlSearchParams.opportunityStageId}
              opportunityTypeId={urlSearchParams.opportunityTypeId}
              salesforceSynced={urlSearchParams.salesforceSynced}
              ownerId={urlSearchParams.ownerId}
            />
          </SummarySection>
        </Page.Section>
      )}

      <Page.Section>
        <ListView.Status>
          {query.status !== 'ready' && <Spinner />}
          <ListView.Total value={query.data.total} label="Opportunity" />
        </ListView.Status>
      </Page.Section>

      <ListView>
        <ListView.Header>
          <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
            Opportunity Name
          </ListView.Column>
          <ListView.Column minWidth="16rem" name="project" onSort={handleSort} sort={params.sort}>
            Project
          </ListView.Column>
          <ListView.Column width="8rem" name="stage" onSort={handleSort} sort={params.sort}>
            Stage
          </ListView.Column>
          <ListView.Column width="10rem" name="convertedAmount" onSort={handleSort} sort={params.sort} align="right">
            Amount
          </ListView.Column>
          <ListView.Column
            name="convertedWeightedAmount"
            onSort={handleSort}
            sort={params.sort}
            width="11rem"
            align="right">
            <Tooltip message="Amount x Probability">Weighted Amount</Tooltip>
          </ListView.Column>
          <ListView.Column width="8rem" name="closeDate	" onSort={handleSort} sort={params.sort} align="right">
            Closing
          </ListView.Column>
          {salesforceEnabled && (
            <ListView.Column width="3rem" name="salesforceId" onSort={handleSort} sort={params.sort} align="right">
              <SalesforceIndicator />
            </ListView.Column>
          )}
          <ListViewActions.Column isVisible={mode === 'page'} />
        </ListView.Header>

        <ListView.Body fade={query.status === 'filtering'}>
          {query.data.results.map((opportunity) => {
            const {
              id,
              amount,
              opportunityStage,
              probability,
              closeDate,
              currency,
              convertedAmount,
              project,
              permissions,
              weightedAmount,
              convertedWeightedAmount,
              salesforceId,
            } = opportunity;

            async function handleRowClick(opportunity) {
              handleOverview(opportunity);
            }

            return (
              <ListView.Row key={id} onClick={() => handleRowClick(opportunity)}>
                <ListView.Cell>
                  <InfoContainer>
                    <OpportunityName>
                      <OpportunityLink opportunity={opportunity} onClick={(e) => e.stopPropagation()} />
                    </OpportunityName>
                    <small>{opportunity.company.name}</small>
                  </InfoContainer>
                </ListView.Cell>
                <ListView.Cell>
                  {project && (
                    <InfoContainer>
                      <ProjectLink project={project} onClick={(e) => e.stopPropagation()} />
                      <small>{project.client.name}</small>
                    </InfoContainer>
                  )}
                </ListView.Cell>
                <ListView.Cell>
                  <StageContainer>
                    <span>{opportunityStage.name}</span>
                    <StageProbabilityBar probability={probability} stageStatus={opportunityStage.statusId} />
                  </StageContainer>
                </ListView.Cell>
                <ListView.Cell>
                  <p>
                    <Currency
                      value={convertedAmount}
                      currency={workspace.currency}
                      minimumFractionDigits={0}
                      maximumFractionDigits={2}
                    />
                    {currency !== workspace.currency && (
                      <Small>
                        <Currency
                          value={amount}
                          currency={currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                        />
                      </Small>
                    )}
                  </p>
                </ListView.Cell>
                <ListView.Cell>
                  <Tooltip message="Amount x Probability">
                    <p>
                      <Currency
                        value={convertedWeightedAmount}
                        currency={workspace.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                      />
                      {currency !== workspace.currency && (
                        <Small>
                          <Currency
                            value={weightedAmount}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                          />
                        </Small>
                      )}
                    </p>
                  </Tooltip>
                </ListView.Cell>
                <ListView.Cell>
                  <DateTime value={closeDate} />
                </ListView.Cell>
                {salesforceEnabled && <ListView.Cell>{salesforceId && <SalesforceIndicator />}</ListView.Cell>}
                <ListViewActions isVisible={mode === 'page'}>
                  {auth.pipeline.manage ? (
                    <ListViewActions.Edit onClick={() => handleEdit(opportunity)} />
                  ) : (
                    <ListViewActions.View onClick={() => handleOverview(opportunity)} />
                  )}

                  <hr />

                  <ListViewMenu>
                    {({ setIsOpen }) => {
                      const handleAction = (action) => setIsOpen(false) || action;

                      return (
                        <>
                          <ListViewMenu.Item onClick={() => handleAction(handleOverview(opportunity))}>
                            View
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!permissions.manage}
                            tooltip={
                              !permissions.manage ? 'Insufficient permissions to edit this opportunity.' : undefined
                            }
                            onClick={() => handleAction(handleEdit(opportunity))}>
                            Edit
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!permissions.manage}
                            tooltip={
                              !permissions.manage ? 'Insufficient permissions to delete this opportunity.' : undefined
                            }
                            onClick={() => handleAction(handleDelete(opportunity))}>
                            Delete
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!permissions.manage}
                            tooltip={
                              !permissions.manage ? 'Insufficient permissions to clone this opportunity.' : undefined
                            }
                            onClick={() => handleAction(setCloneTarget(opportunity))}>
                            Clone
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            disabled={!!opportunity.project}
                            tooltip={(() => {
                              if (opportunity.project) return 'This opportunity is already linked to a project.';
                            })()}
                            onClick={() => handleAction(setOpportunityToProject(opportunity))}>
                            Create Project
                          </ListViewMenu.Item>
                        </>
                      );
                    }}
                  </ListViewMenu>
                </ListViewActions>
              </ListView.Row>
            );
          })}

          {query.data.results.length === 0 && <ListView.Empty />}

          {query.data.total > query.data.results.length && (
            <ListView.Loader key={query.data.results.length} onIntersecting={loadMore} />
          )}
        </ListView.Body>
      </ListView>

      {opportunityId && <OpportunityForm onSaved={handleSaved} onClose={() => handleClose()} />}
      {cloneTarget && (
        <OpportunityCloneForm target={cloneTarget} onClose={() => setCloneTarget(null)} onSaved={handleCloned} />
      )}
      {opportunityToProject && (
        <OpportunityToProjectForm
          opportunity={opportunityToProject}
          onClose={() => setOpportunityToProject(false)}
          onSaved={(project) =>
            history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/overview/edit`)
          }
        />
      )}
    </Container>
  );
}
