import Big from 'big.js';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDateTimeFormat, usePercentFormat } from '~/hooks';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';
import Timestamp from './Timestamp.jsx';

const ChartContainer = styled.div`
  height: 20rem;
  max-height: 20rem;

  canvas {
    /* Force width to prevent shrink/resize issue */
    width: 100% !important;
    max-height: 40rem;
  }
`;

function getActualDataset(data) {
  if (!data.actual.length) return null;

  const points = Object.values(
    data.actual.reduce((acc, value) => {
      const month = moment(value.date).startOf('month').format(dateFormats.isoDate);
      acc[month] = acc[month] || {
        x: month,
        get y() {
          return this.capacity != 0 ? Big(this.billableHours).div(this.capacity).toNumber() : null;
        },
        capacity: 0,
        billableHours: 0,
      };
      acc[month].capacity = Big(acc[month].capacity)
        .plus(value.capacity ?? 0)
        .round(2)
        .toNumber();
      acc[month].billableHours = Big(acc[month].billableHours)
        .plus(value.billableHours ?? 0)
        .round(2)
        .toNumber();
      return acc;
    }, []),
  );

  return {
    id: 'actual',
    label: 'Billable Utilization      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    tension: 0,
  };
}

function getForecastDataset(data) {
  if (!data.forecast?.length) return null;

  const points = Object.values(
    data.forecast.reduce((acc, value) => {
      const month = moment(value.date).startOf('month').format(dateFormats.isoDate);
      acc[month] = acc[month] || {
        x: month,
        get y() {
          return this.capacity != 0 ? Big(this.billableHours).div(this.capacity).toNumber() : null;
        },
        capacity: 0,
        billableHours: 0,
      };
      acc[month].capacity = Big(acc[month].capacity)
        .plus(value.capacity ?? 0)
        .round(2)
        .toNumber();
      acc[month].billableHours = Big(acc[month].billableHours)
        .plus(value.billableHours ?? 0)
        .round(2)
        .toNumber();
      return acc;
    }, []),
  );

  return {
    id: 'plan',
    label: 'Plan      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary25,
    borderColor: colors.primary25,
    tension: 0,
    borderDash: [7],
    borderWidth: 3,
  };
}

export default function BillableUtilization({ actual, forecast }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady, timestamp }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(
    async (behavior) => {
      try {
        if (workspace.sampleData) behavior = 'bypass';
        const { data, headers } = await api.www
          .workspaces(workspace.id)
          .executiveDashboard()
          .billableUtilization(
            { start: actual.start, end: actual.end, forecastStart: forecast.start, forecastEnd: forecast.end },
            behavior,
          );
        setQuery({ data, isReady: true, timestamp: headers['x-cache-timestamp'] });
      } catch (error) {
        setQuery({ data: null, isReady: true });
      }
    },
    [workspace.id, workspace.sampleData, api, actual.start, actual.end, forecast.start, forecast.end],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const percentFormat = {
    ticks: usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    tooltip: usePercentFormat(),
  };

  const dateTimeFormat = useDateTimeFormat({ format: 'MMM YYYY' });

  const history = useHistory();

  const { chartData, chartConfig } = useMemo(() => {
    if (!data) return {};

    const chartData = {
      datasets: _.compact([getActualDataset(data), getForecastDataset(data)]),
    };

    const chartConfig = {
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },

        plugins: {
          legend: {
            display: true,
            position: 'top',
            onClick: null,
            padding: 12,
            labels: {
              font: {
                size: 12,
              },
              usePointStyle: true,
              pointStyleWidth: 14,
              boxHeight: 10,
            },
          },

          tooltip: {
            intersect: false,
            callbacks: {
              title: ([tooltip]) => {
                if (!tooltip) return;
                return dateTimeFormat.format(tooltip.raw.x);
              },
              label: (tooltip) => {
                let label = (tooltip.dataset.label || '').trim();
                if (label) {
                  label += ': ';
                }
                label += percentFormat.tooltip.format(tooltip.parsed.y);
                return label;
              },
            },
          },

          annotation: {
            annotations: {},
          },
        },

        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            min: data.forecast ? forecast.start : actual.start,
            max: data.forecast ? forecast.end : actual.end,
            time: {
              unit: 'month',
              minUnit: 'month',
              round: 'month',
              displayFormats: {
                month: 'MMM',
                quarter: 'MMM',
                year: 'MMM',
              },
            },
          },

          y: {
            ticks: {
              font: {
                weight: 'bold',
              },
              color: colors.grey100,
              callback: function (value) {
                return percentFormat.ticks.format(value);
              },
            },
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y',
          },
        },

        hover: {
          intersect: false,
        },

        onHover: (e, chartElement) => {
          e.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
        },

        onClick: (event, [target]) => {
          if (!target) return;

          const date = target?.element?.$context.raw.x;
          const start = moment(date).startOf('month').format(dateFormats.isoDate);
          const end = moment(date).endOf('month').format(dateFormats.isoDate);

          let url;
          switch (target.datasetIndex) {
            case 0:
              url = `/app/${workspace.key}/reports/utilization/utilization-by-member?${new URLSearchParams({
                start,
                end: moment.min([moment(actual.end), moment(end)]).format(dateFormats.isoDate),
              }).toString()}`;
              break;

            case 1:
              url = `/app/${workspace.key}/reports/plan-and-forecast/utilization-plan-by-member?${new URLSearchParams({
                start,
                end,
                projectRecordStatusId: 'all',
                employmentType: 'employee',
                memberBillableType: 'billable',
              }).toString()}`;
              break;
          }

          // Prevent throwing an exception because of navigating away from the page.
          setTimeout(() => {
            if (event?.native.ctrlKey) window.open(url, '_blank');
            else history.push(url);
          }, 1);
        },
      },
    };

    chartConfig.options.scales.y.suggestedMin = 0;
    chartConfig.options.scales.y.suggestedMax = 1;

    if (_.isEmpty(data.actual) && _.isEmpty(data.forecast)) {
      chartConfig.options.plugins.annotation.annotations.empty = {
        type: 'label',
        content: 'No data available.',
        color: colors.grey25,
        font: {
          size: 18,
        },
      };
    }

    return { chartConfig, chartData };
  }, [
    data,
    percentFormat.ticks,
    percentFormat.tooltip,
    dateTimeFormat,
    history,
    workspace.key,
    forecast.start,
    forecast.end,
    actual.start,
    actual.end,
  ]);

  return (
    <Widget loading={!isReady}>
      <Widget.Header>
        <Widget.Help message="The billable hours tracked by billable workspace members divided by the capacity of those members." />
        <Widget.Title>Billable Utilization</Widget.Title>
        <Timestamp timestamp={timestamp} onClick={() => fetchData('invalidate')} />
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '20rem' }} />
      <Widget.Content>
        {data && (
          <ChartContainer>
            <Line data={chartData} options={chartConfig.options} />
          </ChartContainer>
        )}
      </Widget.Content>
    </Widget>
  );
}
