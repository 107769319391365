import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, FormMessage, Icon, ModalCard } from '~/components';
import { useApi } from '~/contexts';
import { useForm } from '~/hooks';
import { colors, weights } from '~/styles';

const Warning = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem;
  background-color: ${colors.warning};
  border-radius: 0.3125rem;
`;

const Danger = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem;
  background-color: ${colors.danger};
  border-radius: 0.3125rem;
  color: ${colors.white};
`;

const WarningIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${colors.white};
  font-size: 1.5rem;
`;

const Description = styled.div`
  margin-bottom: 1rem;
`;

const Identity = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const IdentityRow = styled.div`
  display: flex;
  margin: 0.125rem 0;
`;

const IdentityLabel = styled.div`
  flex-shrink: 0;
  width: 6rem;
`;

const IdentityValue = styled.div`
  font-weight: ${weights.bold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default function SendInvite({ workspaceRequest, onChange, onClose }) {
  const [{ isSubmitting, message, status }, form] = useForm();
  const api = useApi();
  const [emailSuppressed, setEmailSuppressed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.admin.emails.get({ email: workspaceRequest.email });
        if (data.length > 0) {
          setEmailSuppressed(true);
        }
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspaceRequest]);

  const handleSubmit = async (scope) => {
    form.submit(scope);

    try {
      const { data } = await api.admin.workspaceRequests.invite(workspaceRequest.id);

      onChange(data);
      onClose();
      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  };

  return (
    <ModalCard title="Send Create Workspace Invitation" onClose={onClose}>
      <ModalCard.Body>
        {status && <FormMessage.Error style={{ marginBottom: '1rem' }}>{message}</FormMessage.Error>}
        {emailSuppressed && (
          <Danger>
            <WarningIcon icon="warning" />
            The email associated with this request is on the suppression list.
          </Danger>
        )}
        {workspaceRequest.statusId === 'rejected' && (
          <Danger>
            <WarningIcon icon="warning" />
            This request is currently rejected. Sending an invitation revert that.
          </Danger>
        )}
        {workspaceRequest.activeInvitationCount > 0 && (
          <Warning>
            <WarningIcon icon="warning" />
            This request already has an outstanding invitation.
          </Warning>
        )}
        <Description>
          Are you sure you want to send an invitation to create a workspace to:
          <Identity>
            <IdentityRow>
              <IdentityLabel>Name:</IdentityLabel>
              <IdentityValue title={workspaceRequest.memberName}>{workspaceRequest.memberName}</IdentityValue>
            </IdentityRow>
            <IdentityRow>
              <IdentityLabel>Email:</IdentityLabel>
              <IdentityValue title={workspaceRequest.email}>{workspaceRequest.email}</IdentityValue>
            </IdentityRow>
            <IdentityRow>
              <IdentityLabel>Company:</IdentityLabel>
              <IdentityValue title={workspaceRequest.companyName}>{workspaceRequest.companyName}</IdentityValue>
            </IdentityRow>
            <IdentityRow>
              <IdentityLabel>Website:</IdentityLabel>
              <IdentityValue title={workspaceRequest.website}>{workspaceRequest.website}</IdentityValue>
            </IdentityRow>
          </Identity>
        </Description>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Send Invite
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
