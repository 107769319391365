import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  FiltersBar,
  InlineTooltip,
  LinkButton,
  Page,
  ProjectStatusSelect,
  SearchInput,
  SingleSelect,
} from '~/components';
import { useAuth } from '~/hooks';
import ProjectsListPage from '../projects/ProjectsListPage';

const Filters = ({ query, onFilter }) => {
  const auth = useAuth();
  const { url } = useRouteMatch();

  const handleChange = ({ target: { name, value } }) => {
    onFilter({ [name]: value });
  };

  return (
    <Page.Section>
      <FiltersBar.Wrapper>
        <FiltersBar>
          <SearchInput
            value={query.q}
            placeholder="Search"
            materialPlaceholder="Project Name"
            materialAlwaysVisible
            onChange={handleChange}
          />

          <ProjectStatusSelect
            name="status"
            placeholder="All"
            materialPlaceholder="Project Status"
            materialAlwaysVisible
            value={query.status}
            showEmptyOption
            onChange={handleChange}
          />

          <SingleSelect
            name="recordStatusId"
            placeholder="All"
            materialPlaceholder="Project Archived"
            materialAlwaysVisible
            value={query.recordStatusId}
            showEmptyOption
            onChange={handleChange}>
            <option value="active">No</option>
            <option value="archived">Yes</option>
          </SingleSelect>
        </FiltersBar>

        <FiltersBar.Actions>
          <LinkButton to={`${url}/new${location.search}`} className="button" disabled={!auth.projects.create}>
            Create a Project
            {!auth.projects.create && (
              <InlineTooltip message="Your security role prohibits you from creating projects." />
            )}
          </LinkButton>
        </FiltersBar.Actions>
      </FiltersBar.Wrapper>
    </Page.Section>
  );
};

function ClientProjectsTab({ client }) {
  return (
    <ProjectsListPage
      mode="tab"
      clientId={client.id}
      client={client}
      renderFilters={(props) => <Filters {...props} />}
    />
  );
}

export default ClientProjectsTab;
