import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Page = styled.article`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => {
    return backgroundColor ? backgroundColor : 'transparent';
  }};
  padding: ${({ padding }) => {
    return padding ? padding : '2rem';
  }};
`;

Page.Header = styled.header`
  display: flex;
  align-items: center;
`;

Page.Info = styled.div`
  display: flex;
  flex-direction: column;
`;

Page.Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

Page.Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${weights.light};
  align-self: center;
`;

Page.Actions = styled.div`
  margin-left: auto;
  display: flex;
  gap: 1rem;
`;

Page.Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;

  &:first-child {
    margin-top: 0;
  }
`;

export default Page;
