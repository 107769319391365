import React from 'react';
import {
  ApprovalStatusFilter,
  ClientApprovalStatusFilter,
  ExpenseBillableTypeFilter,
  ExpenseCategoryFilter,
  ExpenseReimbursableTypeFilter,
  InvoicedFilter,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import { useFeatures } from '~/hooks';
import ExpenseTypeFilter from './ExpenseTypeFilter';
import Filter from './Filter';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';
import _ from 'lodash';

export default function ExpenseFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Expense Filters"
      filters={[
        filters.createdPeriod?.start,
        filters.createdPeriod?.end,
        filters.modifiedPeriod?.start,
        filters.modifiedPeriod?.end,
        filters.approvalStatuses,
        filters.clientApprovalStatuses,
        filters.invoiced,
        filters.expenseCategories,
        filters.expenseType,
        filters.billableType,
        filters.reimbursableType,
        filters.isReimbursed,
      ]}>
      {_.has(filters, 'createdPeriod') && (
        <Filter>
          <ReportPeriodFilter
            value={filters.createdPeriod}
            label="Created Date Range"
            onChange={({ target: { value } }) => onChange({ createdPeriod: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'modifiedPeriod') && (
        <Filter>
          <ReportPeriodFilter
            value={filters.modifiedPeriod}
            label="Modified Date Range"
            onChange={({ target: { value } }) => onChange({ modifiedPeriod: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'approvalStatuses') && (
        <Filter>
          <ApprovalStatusFilter
            value={filters.approvalStatuses}
            onChange={({ target: { value } }) => onChange({ approvalStatuses: value })}
          />
        </Filter>
      )}

      {features.clientApprovals && _.has(filters, 'clientApprovalStatuses') && (
        <Filter>
          <ClientApprovalStatusFilter
            value={filters.clientApprovalStatuses}
            onChange={({ target: { value } }) => onChange({ clientApprovalStatuses: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'invoiced') && (
        <Filter>
          <InvoicedFilter
            value={filters.invoiced}
            onChange={({ target: { value } }) => onChange({ invoiced: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'expenseCategories') && (
        <Filter>
          <ExpenseCategoryFilter
            value={filters.expenseCategories}
            onChange={({ target: { value } }) => onChange({ expenseCategories: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'expenseType') && (
        <Filter>
          <ExpenseTypeFilter
            value={filters.expenseType}
            onChange={({ target: { value } }) => onChange({ expenseType: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'billableType') && (
        <Filter>
          <ExpenseBillableTypeFilter
            value={filters.billableType}
            onChange={({ target: { value } }) => onChange({ billableType: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'reimbursableType') && (
        <Filter>
          <ExpenseReimbursableTypeFilter
            value={filters.reimbursableType}
            onChange={({ target: { value } }) => onChange({ reimbursableType: value })}
          />
        </Filter>
      )}

      {_.has(filters, 'isReimbursed') && (
        <Filter>
          <ReimbursedFilter
            value={filters.isReimbursed}
            onChange={({ target: { value } }) => onChange({ isReimbursed: value })}
          />
        </Filter>
      )}
    </FiltersGroup>
  );
}
