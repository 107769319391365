import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BackLink, Currency, Icon, Percent, RouteLink, Widget } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '../components/ClientFiltersBar';
import ClientFiltersGroup from '../components/ClientFiltersGroup';
import CurrencyFilter from '../components/CurrencyFilter';
import ExpenseFiltersBar from '../components/ExpenseFiltersBar';
import ExpenseFiltersGroup from '../components/ExpenseFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import ExportDropdown from '../components/ExportDropdown';
import Filter from '../components/Filter';
import FilterButton from '../components/FilterButton';
import MemberFiltersBar from '../components/MemberFiltersBar';
import MemberFiltersGroup from '../components/MemberFiltersGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import PartialResultsTooltip from '../components/PartialResultsTooltip';
import PeriodNavFilter from '../components/PeriodNavFilter';
import ProjectFiltersBar from '../components/ProjectFiltersBar';
import ProjectFiltersGroup from '../components/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '../components/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';

const NoMemberPractice = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey25};
`;

function ExpenseSummaryByMemberPractice() {
  useDocumentTitle('Expense Summary by Member Practice');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: {
      summary: false,
      table: false,
    },
  });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    invoiced: null,
    expenseCategories: [],
    currency: workspace.currency,
    sort: new QuerySort('name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        invoiced: searchParamsConfig.invoiced,
        expenseCategories: searchParamsConfig.expenseCategories,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      invoiced: params.invoiced ?? undefined,
      expenseCategoryId: params.expenseCategories?.map((v) => v.id),
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().expenseSummaryByMemberPractice(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        summary: false,
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };
  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .expenseSummaryByMemberPractice(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <BackLink defaultPath={`/app/${workspace.key}/reports/expenses`} />

        <Report.Info>
          <Report.Eyebrow>Expense Reports</Report.Eyebrow>
          <Report.Title>Expense Summary by Member Practice</Report.Title>
        </Report.Info>

        <Report.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <PeriodNavFilter value={params.period} onChange={(period) => handleApplyFilters({ period })} />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />

        <ExpenseFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const expenseDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/expenses/expense-items?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          clientStatus: params.clientApprovalStatuses?.map((v) => v.id),
          projectRole: params.projectRoles?.map((v) => v.id),
          invoiced: params.invoiced ?? undefined,
          expenseCategory: params.expenseCategories?.map((v) => v.id),
          expenseType: 'member',
          currency: params.currency ?? undefined,
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary fade={query.loading.summary}>
        <Widget>
          <Meter>
            <Meter.Value
              label="Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Currency value={report.totals.billable} currency={report.currency} /> (
                  <Percent value={report.totals.billable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Currency value={report.totals.nonBillable} currency={report.currency} /> (
                  <Percent value={report.totals.nonBillable / report.totals.total} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <RouteLink
                to={expenseDetail({
                  billableType: 'billable',
                  expenseType: 'member',
                })}>
                <Metric.Value>
                  <Currency value={report.totals.billable} currency={report.currency} />
                </Metric.Value>
                <Metric.Label>Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink
                to={expenseDetail({
                  billableType: 'non_billable',
                  expenseType: 'member',
                })}>
                <Metric.Value>
                  <Currency value={report.totals.nonBillable} currency={report.currency} />
                </Metric.Value>
                <Metric.Label>Non-Billable</Metric.Label>
              </RouteLink>
            </Metric>
            <Metric>
              <RouteLink
                to={expenseDetail({
                  expenseType: 'member',
                })}>
                <Metric.Value>
                  <Currency value={report.totals.total} currency={report.currency} />
                </Metric.Value>
                <Metric.Label>Total</Metric.Label>
              </RouteLink>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Status>
        {query.status !== 'ready' && <Icon icon="spinner" spin spaceRight />}
        <Table.Total label="Member Practice" value={report.records.length} />
        {report.partialResults && <PartialResultsTooltip />}
      </Report.Status>

      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="name" onSort={onSort} sort={params.sort}>
            Member Practice
          </Table.Column>
          <Table.Column name="billable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
            Billable
          </Table.Column>
          <Table.Column name="nonBillable" width="9rem" align="right" onSort={onSort} sort={params.sort}>
            Non-Billable
          </Table.Column>
          <Table.Column name="total" width="9rem" align="right" onSort={onSort} sort={params.sort}>
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((memberPractice) => {
            return (
              <Table.Row key={memberPractice.id || 'no_member_practice'}>
                <Table.Cell>
                  {memberPractice.id ? memberPractice.name : <NoMemberPractice>No Member Practice</NoMemberPractice>}
                </Table.Cell>
                <Table.Cell>
                  {memberPractice.id ? (
                    <RouteLink
                      to={expenseDetail({
                        billableType: 'billable',
                        memberPractice: memberPractice.id,
                      })}>
                      <Currency value={memberPractice.billable} currency={report.currency} />
                    </RouteLink>
                  ) : (
                    <Currency value={memberPractice.billable} currency={report.currency} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {memberPractice.id ? (
                    <RouteLink
                      to={expenseDetail({
                        billableType: 'non_billable',
                        memberPractice: memberPractice.id,
                      })}>
                      <Currency value={memberPractice.nonBillable} currency={report.currency} />
                    </RouteLink>
                  ) : (
                    <Currency value={memberPractice.nonBillable} currency={report.currency} />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {memberPractice.id ? (
                    <RouteLink to={expenseDetail({ memberPractice: memberPractice.id })}>
                      <Currency value={memberPractice.total} currency={report.currency} />
                    </RouteLink>
                  ) : (
                    <Currency value={memberPractice.total} currency={report.currency} />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <RouteLink
                to={expenseDetail({
                  billableType: 'billable',
                  expenseType: 'member',
                })}>
                <Currency value={report.totals.billable} currency={report.currency} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink
                to={expenseDetail({
                  billableType: 'non_billable',
                  expenseType: 'member',
                })}>
                <Currency value={report.totals.nonBillable} currency={report.currency} />
              </RouteLink>
            </Table.Cell>
            <Table.Cell>
              <RouteLink
                to={expenseDetail({
                  expenseType: 'member',
                })}>
                <Currency value={report.totals.total} currency={report.currency} />
              </RouteLink>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Filter>
        <ReportPeriodFilter
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </Filter>

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      {auth.expenses.view && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <ExpenseFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

export default ExpenseSummaryByMemberPractice;
