import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AutoComplete from './AutoComplete';
import Dropdown from './Dropdown';
import FieldControl from './FieldControl';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

function ClientProjectSelect({
  value,
  clientId,
  initialValue,
  assignedMemberId,
  assignedOnly,
  activeOnly = true,
  unlockedOnly,
  enforceBillability,
  pastExpenses,
  pastTime,
  currency,
  withPermissions,
  onChange,
  onFilter,
  ...props
}) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const handleSearch = useCallback(
    async (q) => {
      let { data } = await api.www
        .workspaces(workspace.id)
        .projects()
        .selectOptions({
          query: q,
          memberId: assignedMemberId,
          assignedOnly,
          activeOnly,
          unlockedOnly,
          enforceBillability,
          pastExpenses,
          pastTime,
          include: initialValue ? initialValue.id : undefined,
          currency,
          limit: 1000,
          clientId,
          withPermissions,
        });

      if (onFilter) {
        data = onFilter(data);
      }

      if (activeOnly) {
        const groups = _.orderBy(
          data.reduce((a, v) => {
            const key = v.client.id;
            a[key] = a[key] || { name: v.client.name, key: v.client.id, projects: [] };
            a[key].projects.push(v);
            return a;
          }, {}),
          'name',
        );

        const options = [];
        for (const client of groups) {
          options.push({ type: 'client', name: client.name, key: client.key, disabled: true });
          client.projects.forEach((project) => {
            options.push({ type: 'project', project, disabled: false });
          });
        }
        return options;
      } else {
        const groups = _.orderBy(
          data.reduce((a, v) => {
            const status = v.active ? 'Active' : 'Archived';
            a[status] = a[status] || { key: status, clients: {} };
            a[status].clients[v.client.id] = a[status].clients[v.client.id] || {
              id: v.client.id,
              client: v.client.name,
              projects: [],
              key: `${status}_${v.client.id}`,
            };
            a[status].clients[v.client.id].projects.push(v);
            return a;
          }, {}),
          'key',
        );

        const options = [];
        for (const group of groups) {
          options.push({ type: 'group', key: group.key, disabled: true });
          for (const y in group.clients) {
            const client = group.clients[y];
            options.push({ type: 'client', name: client.client, key: client.key, disabled: true });
            for (const project of client.projects) {
              options.push({ type: 'project', project, disabled: false });
            }
          }
        }
        return options;
      }
    },
    [
      workspace.id,
      initialValue,
      api,
      assignedMemberId,
      activeOnly,
      assignedOnly,
      unlockedOnly,
      enforceBillability,
      pastExpenses,
      pastTime,
      clientId,
      currency,
      withPermissions,
      onFilter,
    ],
  );

  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target.name, value: event.target.value?.project } };
    onChange(changeArgument);
  };

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions, clientId]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? `${value.client.name} / ${value.name}` : ''}
      defaultOptions={defaultOptions}
      onChange={handleChange}
      renderValue={(option) => option.text}
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            switch (o.type) {
              case 'group':
                return (
                  <Group style={{ marginTop: '0.5rem' }} key={o.key}>
                    <strong>{o.key}</strong>
                  </Group>
                );
              case 'client':
                return (
                  <Group style={activeOnly ? undefined : { marginLeft: '0.5rem' }} key={o.key}>
                    <strong>{o.name}</strong>
                  </Group>
                );
              case 'project':
                return (
                  <AutoComplete.Option
                    grey={o.project.recordStatusId === 'archived' ? 'true' : undefined}
                    style={activeOnly ? { padding: '0.25rem 0 0.25rem 2rem' } : { padding: '0.25rem 0 0.25rem 2.5rem' }}
                    key={o.project.id}
                    tooltip={o.project.name}
                    value={o}
                    index={index++}>
                    {o.project.name}
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

function FieldClientProjectSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <ClientProjectSelect {...field} {...props} />
    </FieldControl>
  );
}

export default ClientProjectSelect;
export { FieldClientProjectSelect };
