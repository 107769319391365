import _ from 'lodash';
import React from 'react';
import { InvoiceStatusFilter } from '~/components';
import InvoiceSentFilter from '~/components/filters/InvoiceSentFilter';
import PeriodNavFilter from './PeriodNavFilter';

export default function InvoiceFiltersBar({ filters, onChange }) {
  return (
    <>
      {(filters.invoiceServicesThroughPeriod?.start || filters.invoiceServicesThroughPeriod?.end) && (
        <PeriodNavFilter
          materialPlaceholder="Services Through Date Range"
          value={filters.invoiceServicesThroughPeriod}
          onChange={(value) => onChange({ invoiceServicesThroughPeriod: value })}
        />
      )}

      {!_.isEmpty(filters.statuses) && (
        <InvoiceStatusFilter
          value={filters.statuses}
          onChange={({ target: { value } }) => onChange({ statuses: value })}
        />
      )}

      {!_.isEmpty(filters.invoiceSent) && (
        <InvoiceSentFilter
          value={filters.invoiceSent}
          onChange={({ target: { value } }) => onChange({ invoiceSent: value })}
        />
      )}
    </>
  );
}
