import _ from 'lodash';
import React from 'react';
import {
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  IndustryFilter,
  LocationFilter,
  MemberFilter,
  PracticeFilter,
} from '~/components';

function ClientFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.clients) && (
        <ClientFilter value={filters.clients} onChange={({ value }) => onChange({ clients: value })} />
      )}

      {!_.isEmpty(filters.clientRecordStatusId) && (
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ value }) => onChange({ clientRecordStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.clientPractices) && (
        <PracticeFilter
          materialPlaceholder="Client Practice"
          value={filters.clientPractices}
          onChange={({ value }) => onChange({ clientPractices: value })}
        />
      )}

      {!_.isEmpty(filters.clientSalesRepresentatives) && (
        <MemberFilter
          materialPlaceholder="Client Sales Representative"
          value={filters.clientSalesRepresentatives}
          onChange={({ value }) => onChange({ clientSalesRepresentatives: value })}
        />
      )}

      {!_.isEmpty(filters.clientOwners) && (
        <MemberFilter
          materialPlaceholder="Client Relationship Owner"
          value={filters.clientOwners}
          onChange={({ value }) => onChange({ clientOwners: value })}
        />
      )}

      {!_.isEmpty(filters.clientTags) && (
        <ClientTagFilter value={filters.clientTags} onChange={({ value }) => onChange({ clientTags: value })} />
      )}

      {!_.isEmpty(filters.clientLocations) && (
        <LocationFilter
          materialPlaceholder="Client Location"
          value={filters.clientLocations}
          onChange={({ value }) => onChange({ clientLocations: value })}
        />
      )}

      {!_.isEmpty(filters.clientIndustries) && (
        <IndustryFilter
          materialPlaceholder="Client Industry"
          value={filters.clientIndustries}
          onChange={({ value }) => onChange({ clientIndustries: value })}
        />
      )}
    </>
  );
}

export default ClientFiltersBar;
