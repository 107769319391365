import styled from 'styled-components';
import FiltersDrawer from './FiltersDrawer';
import { colors, weights } from '~/styles';

const Report = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const Summary = styled.div`
  margin-top: 2rem;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.1 : 1)};
`;

const Table = styled.div`
  margin-top: 4rem;
`;

const Status = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;

  .icon {
    font-size: 0.875rem;
  }

  > div {
    display: flex;
    align-items: center;

    &:only-child {
      margin-left: auto;
    }
  }
`;

const Total = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.875rem;

  .icon {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${weights.light};
  align-self: start;
  margin-top: 0.25rem;
`;

const Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const Actions = styled.div`
  display: flex;
  margin-left: auto;
  gap: 1.5rem;
`;

const FiltersBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 0.875rem;
  margin-top: 1.5rem;
  font-size: 0.875rem;

  &:empty {
    display: none;
  }
`;

Report.Header = Header;
Report.Info = Info;
Report.Title = Title;
Report.Eyebrow = Eyebrow;
Report.Actions = Actions;
Report.FiltersBar = FiltersBar;
Report.Summary = Summary;
Report.FiltersDrawer = FiltersDrawer;
Report.Status = Status;

// Deprecated
Report.Table = Table;
Report.Total = Total;

export default Report;
