import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Confirmation,
  Currency,
  DateTime,
  ListView,
  ListViewActions,
  ListViewMenu,
  FiltersBar,
  Hours,
  InlineTooltip,
  MemberSelect,
  Page,
  SingleSelect,
  Spinner,
  SplitButton,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import ClientApprovalWebLinkModal from './actions/ClientApprovalWebLinkModal';
import SendClientApprovalDrawer from './actions/SendClientApprovalDrawer';
import DateRange from './components/DateRange';
import { Legend, Progress, ProgressContainer, ProgressLine } from './components/Progress';
import CreateClientApprovalForm from './CreateClientApprovalForm';
import ClientApprovalSchedulesDrawer from './schedule/ClientApprovalSchedulesDrawer';

export default function ClientApprovalsList({ project, showCreateForm, onCreateFormClose, onCreated }) {
  const [query, setQuery] = useState({ status: 'loading', data: null, error: null });
  const [params, setParams] = useState({ statusId: '', member: null, sort: new QuerySort('date', 'desc') });
  const [dialog, setDialog] = useState(null);
  const { workspace } = useWorkspace();
  const api = useApi();
  const { url } = useRouteMatch();
  const history = useHistory();
  const toast = useToast();
  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .clientApprovals()
        .get({ ...params, memberId: params.member?.id });

      setQuery({ status: 'ready', data });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [workspace.id, api, project.id, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilterChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setParams((state) => ({ ...state, sort: new QuerySort(column, direction) }));
  };

  const handleSend = async (clientApproval) => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .clientApprovals(clientApproval.id)
      .get();

    setDialog(<SendClientApprovalDrawer clientApproval={data} onClose={handleCloseDialog} onSent={fetchData} />);
  };

  const handleGetWebLink = (clientApproval) => {
    setDialog(<ClientApprovalWebLinkModal clientApproval={clientApproval} onClose={handleCloseDialog} />);
  };

  const handleOpenClientApprovalSchedulesDrawer = () => {
    setDialog(<ClientApprovalSchedulesDrawer project={project} onChange={fetchData} onClose={handleCloseDialog} />);
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  if (query.status === 'loading') return <PageLoader />;

  if (query.error) return <ErrorPage publicSite={false} />;

  return (
    <>
      <Page.Section>
        <FiltersBar.Wrapper>
          <FiltersBar>
            <SingleSelect
              name="statusId"
              placeholder="All"
              materialPlaceholder="Status"
              materialAlwaysVisible
              showEmptyOption
              value={params.statusId}
              onChange={handleFilterChange}>
              <option value="not_sent">Not Sent</option>
              <option value="sent">Sent</option>
              <option value="submitted">Submitted</option>
            </SingleSelect>

            <MemberSelect
              name="member"
              placeholder="All"
              materialPlaceholder="Member"
              materialAlwaysVisible
              value={params.member}
              onChange={handleFilterChange}
            />
          </FiltersBar>

          <FiltersBar.Actions>
            <SplitButton>
              <Link to={`${url}/new`} className="button">
                Create a Client Approval
              </Link>

              <SplitButton.Menu>
                <SplitButton.Item onClick={handleOpenClientApprovalSchedulesDrawer}>
                  Manage Client Approval Schedules
                </SplitButton.Item>
              </SplitButton.Menu>
            </SplitButton>
          </FiltersBar.Actions>
        </FiltersBar.Wrapper>
      </Page.Section>

      <Page.Section>
        <ListView.Status>
          {query.status !== 'ready' && <Spinner />}
          <ListView.Total value={query.data.length} label="Client Approval" />
        </ListView.Status>
      </Page.Section>

      <ListView data-testid="client_approvals_table">
        <ListView.Header sticky>
          <ListView.Column minWidth="7rem" name="date" onSort={handleSort} sort={params.sort}>
            Created
          </ListView.Column>

          <ListView.Column width="12rem" name="periodStart" onSort={handleSort} sort={params.sort}>
            Time Period
          </ListView.Column>

          <ListView.Column minWidth="16rem">Members</ListView.Column>

          <ListView.Column width="10rem" align="right" name="totalHours" onSort={handleSort} sort={params.sort}>
            Hours
          </ListView.Column>

          <ListView.Column width="10rem" align="right" name="totalExpenses" onSort={handleSort} sort={params.sort}>
            Expenses
          </ListView.Column>

          <ListView.Column width="8rem" name="statusId" onSort={handleSort} sort={params.sort}>
            Status
          </ListView.Column>

          <ListViewActions.Column />
        </ListView.Header>

        <ListView.Body fade={query.status !== 'ready'}>
          {query.data.map((clientApproval) => {
            const handleView = () => {
              history.push(url.concat(`/${clientApproval.id}`));
            };

            const handleDelete = async () => {
              const confirm = await confirmation.prompt((resolve) => (
                <Confirmation resolve={resolve}>
                  Are you sure that you want to delete this client approval?
                </Confirmation>
              ));
              if (!confirm) return;

              try {
                await api.www.workspaces(workspace.id).projects(project.id).clientApprovals(clientApproval.id).delete();

                toast.success('Client approval has been deleted.');
                fetchData();
              } catch ({ message }) {
                toast.error(message);
              }
            };

            return (
              <ListView.Row key={clientApproval.id} data-testid="row" onClick={handleView}>
                <ListView.Cell>
                  <DateTime value={clientApproval.date} />
                </ListView.Cell>

                <ListView.Cell>
                  <DateRange clientApproval={clientApproval} />
                </ListView.Cell>

                <ListView.Cell>
                  <Members members={clientApproval.members} />
                </ListView.Cell>

                <ListView.Cell>
                  {clientApproval.totalTimeEntriesCount > 0 && <TimeProgress clientApproval={clientApproval} />}
                </ListView.Cell>

                <ListView.Cell>
                  {clientApproval.totalExpenseItemsCount > 0 && <ExpensesProgress clientApproval={clientApproval} />}
                </ListView.Cell>

                <ListView.Cell>{clientApproval.status.name}</ListView.Cell>

                <ListViewActions>
                  <ListViewActions.View onClick={handleView} />

                  <hr />

                  <ListViewMenu data-testid="row_actions">
                    {({ setIsOpen }) => {
                      return (
                        <>
                          <ListViewMenu.Item onClick={handleView}>View</ListViewMenu.Item>

                          <ListViewMenu.Item
                            onClick={() => {
                              setIsOpen(false);
                              handleSend(clientApproval);
                            }}>
                            Send
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            onClick={() => {
                              setIsOpen(false);
                              handleGetWebLink(clientApproval);
                            }}>
                            Get Web Link
                          </ListViewMenu.Item>

                          <ListViewMenu.Item
                            onClick={() => {
                              setIsOpen(false);
                              handleDelete();
                            }}>
                            Delete
                          </ListViewMenu.Item>
                        </>
                      );
                    }}
                  </ListViewMenu>
                </ListViewActions>
              </ListView.Row>
            );
          })}

          {query.data.length === 0 && <ListView.Empty />}
        </ListView.Body>
      </ListView>

      {showCreateForm && <CreateClientApprovalForm project={project} onSaved={onCreated} onClose={onCreateFormClose} />}

      {dialog}
    </>
  );
}

function TimeProgress({ clientApproval }) {
  const progress = useMemo(() => {
    return {
      approved: clientApproval.approvedTimeEntriesCount,
      rejected: clientApproval.rejectedTimeEntriesCount,
      pending: clientApproval.pendingTimeEntriesCount,
      total: clientApproval.totalTimeEntriesCount,

      get ready() {
        return this.approved + this.rejected;
      },

      get readyPercentage() {
        return `${_.round((this.ready / this.total) * 100)}%`;
      },

      get approvedPercentage() {
        return `${(this.approved / this.total) * 100}%`;
      },

      get rejectedPercentage() {
        return `${(this.rejected / this.total) * 100}%`;
      },

      get pendingPercentage() {
        return `${(this.pending / this.total) * 100}%`;
      },
    };
  }, [clientApproval]);

  return (
    <>
      <ProgressContainer>
        <Legend>
          <span />

          <span>
            <Hours value={clientApproval.totalHours} /> hours
          </span>
        </Legend>

        <Progress>
          <ProgressLine $width={progress.approvedPercentage} color={colors.success}>
            <InlineTooltip message={`${progress.approved} approved`} placement="top" />
          </ProgressLine>
          <ProgressLine $width={progress.rejectedPercentage} color={colors.danger}>
            <InlineTooltip message={`${progress.rejected} rejected`} placement="top" />
          </ProgressLine>
          <ProgressLine $width={progress.pendingPercentage} color={colors.grey5} />
        </Progress>

        <Legend>
          <span />

          <span>
            {progress.total} {pluralize('time entry', progress.total)}
          </span>
        </Legend>
      </ProgressContainer>
    </>
  );
}

function ExpensesProgress({ clientApproval }) {
  const progress = useMemo(() => {
    return {
      approved: clientApproval.approvedExpenseItemsCount,
      rejected: clientApproval.rejectedExpenseItemsCount,
      pending: clientApproval.pendingExpenseItemsCount,
      total: clientApproval.totalExpenseItemsCount,

      get ready() {
        return this.approved + this.rejected;
      },

      get readyPercentage() {
        return `${_.round((this.ready / this.total) * 100)}%`;
      },

      get approvedPercentage() {
        return `${(this.approved / this.total) * 100}%`;
      },

      get rejectedPercentage() {
        return `${(this.rejected / this.total) * 100}%`;
      },

      get pendingPercentage() {
        return `${(this.pending / this.total) * 100}%`;
      },
    };
  }, [clientApproval]);

  const currency = clientApproval.project.currency;

  return (
    <>
      <ProgressContainer>
        <Legend>
          <span />

          <span>
            <Currency value={clientApproval.totalExpenses} currency={currency} />
          </span>
        </Legend>

        <Progress>
          <ProgressLine $width={progress.approvedPercentage} color={colors.success}>
            <InlineTooltip message={`${progress.approved} approved`} placement="top" />
          </ProgressLine>
          <ProgressLine $width={progress.rejectedPercentage} color={colors.danger}>
            <InlineTooltip message={`${progress.rejected} rejected`} placement="top" />
          </ProgressLine>
          <ProgressLine $width={progress.pendingPercentage} color={colors.grey5} />
        </Progress>

        <Legend>
          <span />

          <span>
            {progress.total} {pluralize('expense item', progress.total)}
          </span>
        </Legend>
      </ProgressContainer>
    </>
  );
}

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Members = ({ members }) => {
  if (members.length === 0) return null;

  return (
    <Tags style={{ fontSize: '.75rem' }}>
      <Tag style={{ backgroundColor: colors.grey5 }}>{members[0].name}</Tag>

      {members.length > 1 && (
        <>
          <Tooltip
            placement="right"
            message={
              <div style={{ fontSize: '1rem' }}>
                <Title>Project Members</Title>

                {members.map((member) => (
                  <Tag style={{ backgroundColor: colors.grey5 }} key={member.id}>
                    <small>{member.name}</small>
                  </Tag>
                ))}
              </div>
            }>
            <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40, cursor: 'default' }}>
              +{members.length - 1}
            </Tag>
          </Tooltip>
        </>
      )}
    </Tags>
  );
};
