import React, { useCallback, useContext, useMemo, useState } from 'react';

const ListViewContext = React.createContext();

function ListViewProvider({ children }) {
  const [columns, setColumns] = useState([]);
  const setColumn = useCallback(
    (props) =>
      setColumns((state) =>
        state.some((column) => column.index === props.index)
          ? state.map((column) => (column.index === props.index ? props : column))
          : [...state, props],
      ),
    [],
  );

  const listView = useMemo(() => ({ setColumn, columns }), [setColumn, columns]);
  return <ListViewContext.Provider value={listView}>{children}</ListViewContext.Provider>;
}

function useListView() {
  return useContext(ListViewContext);
}

export { ListViewContext, useListView, ListViewProvider };
